import { Switch, message } from 'antd';
import React, { useEffect, useState } from 'react'
import MainClient from '../../utilities/MainClient';
import { optionsList } from '../myInvestor/UpdateForm';
import MultiSelectComponent from '../newSponsor/Multiselect';
import { useDispatch, useSelector } from 'react-redux';
import { togglePopNotification } from '../../store/actions/NotificationActions';
import { useTheme } from '@mui/material';
import { getInputStyles } from '../newSponsor/FormStyles';
import { FormHeader } from '../newSponsor/FormElements';

const ProfileDetails = ({ data, user, handleSubmit, changeHandler, setData }) => {
    // console.log('user', user)
    // console.log('data', data)
    const isNoti = localStorage.getItem('isNoti')
    const theme = useTheme()
    const {palette} = theme
    const profileHeadStyle = {
    color : '#fff',
    background : palette.primary.main
  }
  const profileBgStyle = { 
    background : palette.customColors.bodyBg
  }
  const inputStyles = getInputStyles(theme)
    const dispatch = useDispatch()
    const ontoggleNotification = (value) => {
        dispatch(togglePopNotification(value))
        localStorage.setItem("isNoti", value)
    }
    return (
        <div class="row mb-3">
            <div class="col-12">
                <form onSubmit={handleSubmit}>
                    <div style={profileBgStyle} class="card data-card">
                        <div class="card-body">
                        <FormHeader theme={theme}>Personal Details</FormHeader>                            <div class="divider"></div>
                            <div class="row g-3 mb-3">
                                <div class="col-sm-6 col-md-4">
                                    <label for="firstName" class="form-label">First Name</label>
                                    <input style={inputStyles} type="text" class="form-control" id="firstName"
                                        placeholder="Enter First Name" name="first_name" value={user.first_name} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="lastName" class="form-label">Last Name</label>
                                    <input style={inputStyles} type="text" class="form-control" id="lastName" placeholder="Enter Last Name" name="last_name" value={user.last_name} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="email" class="form-label">Email</label>
                                    <input style={inputStyles} type="email" class="form-control" id="email"
                                        placeholder="Enter Email Address" value={user.email} name="email" />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="companyCountry" class="form-label">Address</label>
                                    <textarea style={inputStyles} class="form-control" id="exampleFormControlTextarea1" rows="2" name="address" value={user.address} onChange={changeHandler}></textarea>
                                    {/* <input type="textbox" class="form-control" id="companyCountry"
                                        placeholder="Enter Your Address" name="address" value={user.address} onChange={changeHandler} /> */}
                                </div>

                                <div class="col-sm-6 col-md-4">
                                    <label for="company" class="form-label">City</label>
                                    <input style={inputStyles} type="text" class="form-control" id="company"
                                        placeholder="Enter Your City" name="city" value={user.city} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="address" class="form-label">State</label>
                                    <input style={inputStyles} type="text" class="form-control" id="address"
                                        placeholder="Enter Your State" name="state" value={user.state} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="companyCountry" class="form-label">Country</label>
                                    <input style={inputStyles} type="text" class="form-control" id="companyCountry"
                                        placeholder="Enter Your Country" name="country" value={user.country} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="companyCountry" class="form-label">Zip</label>
                                    <input style={inputStyles} type="text" class="form-control" id="companyCountry"
                                        placeholder="Enter Your Zip" name="zip" value={user.zip} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="email" class="form-label">Phone</label>
                                    <input style={inputStyles} type="text" class="form-control" id="email"
                                        placeholder="Enter Your Phone number" value={user.phone} name="phone_number" onChange={changeHandler} />
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mb-2" id="submit-btn">
                                {/* <button type="submit" class="btn btn-secondary clr-btn">Reset</button> */}
                                <button style={profileHeadStyle} type="submit" class="btn button_color ms-3">Update</button>
                            </div>
                        </div>
                    </div>
                    <div style={profileBgStyle} class="card data-card mt-3">
                        <div class="card-body">
                        <FormHeader theme={theme}>Settings</FormHeader>
                            <div class="divider"></div>
                            <div class="row g-3 mb-3">
                                {/* <div class="col-sm-6 col-md-4">
                                    <label for="companyCountry" class="form-label">Investment Entity Name</label>
                                    <input type="text" class="form-control" id="companyCountry"
                                        placeholder="Enter Entity Name" name="investment_entity_name" value={user.investment_entity_name} onChange={changeHandler} />
                                </div> */}

                                <div class="col-sm-6 col-md-4">
                                    <label for="companyCountry" class="form-label">Market Interest</label>
                                    <MultiSelectComponent
                                        defaultName='markets'
                                        value={user}
                                        setValue={setData}
                                        options={optionsList} />
                                </div>
                                {/* <div class="col-sm-6 col-md-4">
                                    <label for="zip" className="form-label">SSN or EIN</label>
                                    <input type="text" class="form-control" id="companyCountry"
                                        placeholder="Enter Entity Name" name="ssn_or_ein" value={user.ssn_or_ein} onChange={changeHandler} />
                                </div> */}
                            </div>
                            <div class="d-flex justify-content-end mb-2" id="submit-btn">
                                {/* <button type="submit" class="btn btn-secondary clr-btn">Reset</button> */}
                                <button style={profileHeadStyle} type="submit" class="btn button_color ms-3" >Update</button>
                            </div>
                        </div>
                    </div>

                    <div style={profileBgStyle} class="card data-card mt-3">
                        <div class="card-body">
                        <FormHeader theme={theme}>Notifications</FormHeader>
                            <div class="divider"></div>
                            <div class="row g-3 mb-3"></div>

                            <div className='d-flex justify-content-between col-4'>
                                <label for="enable-noti" class="form-label">Toggle Incoming Notifications</label>

                                <Switch onChange={ontoggleNotification} checkedChildren="off" unCheckedChildren="on" defaultChecked={isNoti} className="bg-success mx-2" />
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default ProfileDetails
import React from 'react'
import { BsFillXCircleFill } from 'react-icons/bs'
import { Table } from 'reactstrap'
import { IoMdDownload } from "react-icons/io";
import { message } from 'antd';
import { FormHeader } from '../newSponsor/FormElements';
import { useTheme } from '@mui/material';
function DocumentDetails({ data}) {
    const theme = useTheme()
     const {palette} = theme
    const omHandler = (key) => {

        
          const file = data[key]?.file;
          const file_name = data[key]?.file_name;
        //   alert(file_name)
        if (file && file_name) {
          saveAs(file, file_name);
        } else {
            message.error("Error - cannot file the file")
        }
        
    }
    console.log('document data from doc view',data)
    return (
       <>
       { data &&
  
       <div class="card-body">
           <FormHeader theme={theme}>Uploaded Documents</FormHeader>
           <Table responsive className="mt-2">
               <thead >
                   <tr
                       style={{ 
                        color: '#fff',
                        background: theme.palette.primary.main,
                        // background:'rgb(62,62,61)',
                        // background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)", color: "#fff" 
                    }}
                   >
                       <th>ID</th>
                       <th>Name</th>
                       <th>Action</th>

                   </tr>
               </thead>
               <tbody>
                  {data?.map((file, key) => {
                      return(
                      <tr style={{color: palette.text.primary}} key={key}>
                          <td>{file.id}</td>
                          <td>{file.file_name}</td>
                          <td>
                              <IoMdDownload onClick={() => omHandler(key)} className='mx-1' />
                          {/* <BsFillXCircleFill className='mx-1' /> */}
                          </td>
                         
                      </tr>)
                  })}
                   

               </tbody>
           </Table>
       </div>

        
       }
       </>
    )
}

export default DocumentDetails
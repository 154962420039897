import { useState } from "react"
import { BsFillXCircleFill } from "react-icons/bs"
import { useParams } from "react-router-dom"
import numberWithCommas from "../../utilities/extrafunction/commaseparate"
import MainClient from "../../utilities/MainClient"
import NewDept from "../Investor/NewDept"
import { useSelector } from "react-redux"
import { useTheme } from "@mui/material"
import { FormHeader, FormLabel } from "../newSponsor/FormElements"


const CapitalStackDetails = ({ totalCapital, purchasePrice, data, setData, seniorDept, setSeniorDept, updateHandler, sponsorUpdate, setDataLen }) => {
    const [stackUpdate, setStackUpdate] = useState(false)
    const [update, setUpdate] = useState(false)
    const [submit, setSubmit] = useState(false)
    console.log('stackData', data)
    const theme = useTheme()
    const {palette} = theme


    const { id } = useParams()
    // const [seniorDept, setSeniorDept] = useState({
    //     capital_stack: '',
    //     amount: '',
    //     purchase_basis: '',
    //     total_capital_basis: '',
    // })

    // const [data, setData] = useState([{
    //     capital_title: 'Class A Equity Shares',
    //     capital_stack: '',
    //     equity: 0,
    //     amount: 0,
    //     purchase_basis: 0,
    //     total_capital_basis: 0,
    //     pref: '',
    //     min_equity_multiple: '',
    //     profit_share: ''
    // }])

    const seniorDeptChangeHandler = (e) => {
        setSeniorDept({ [e.target.name]: e.target.value })
        setStackUpdate(true)
    }

    const changeHandler = (e) => {
        const { name, value, id } = e.target
        const listData = data.slice()
        const newCap = listData.map((cap, index) => {
            for (var key in cap) {
                if (name === "capital_stack" && cap.capital_title === id) {
                    cap["capital_stack"] = value
                    cap["equity"] = ((parseFloat(value) / 100) / (1 - (parseFloat(seniorDept.capital_stack) / 100)) * 100).toFixed()
                    cap["amount"] = ((totalCapital * parseFloat(value)) / 100).toFixed()
                    // cap["purchase_basis"] = ((parseFloat(value) / 100) + (parseFloat(seniorDept.capital_stack) / 100) * purchasePrice).toFixed(2)
                    let sumStack = 0
                    if (data.length > 0) {
                        // sumStack = 0
                        // console.log('eqData', data)
                        // const sliceData = data.slice(0, data.length)
                        let l = []
                        listData.map(dt => {
                            l.push(parseFloat(dt.capital_stack))
                        })
                        // console.log('l', l)
                        const sliceData = l.splice(0, index + 1)
                        // console.log('spliceData', sliceData)
                        // console.log('l', sliceData.reduce((a, b) => a + b, 0))
                        sumStack = sliceData.reduce((a, b) => a + b, 0)
                    }
                    // console.log('sum', sumStack + seniorDept.capital_stack)
                    cap["sums"] = parseFloat(sumStack) + parseFloat(seniorDept.capital_stack)
                    cap["purchase_basis"] = (((parseFloat(sumStack) / 100) + (parseFloat(seniorDept.capital_stack) / 100)) * purchasePrice).toFixed(0)
                    cap["total_capital_basis"] = (((parseFloat(sumStack) / 100) + (parseFloat(seniorDept.capital_stack) / 100)) * totalCapital).toFixed(0)
                }
                // console.log('capName', key, name)
                else if (key === name && cap.capital_title === id) {
                    cap[name] = value
                    // console.log('cap', cap.name)
                }
                // else{
                //     cap[name] = value
                // }
            }
            return cap
        })
        setData(newCap)
        // setStackUpdate(!stackUpdate)
    }

    const sponsorProfitShare = data && (1 - data.map(dt => {
        return parseFloat(dt.profit_share)
    }).reduce((a, b) => a + b) / 100) * 100

    const addHandler = (e) => {
        const mt = [
            { 1: 'A' }, { 2: 'B' }, { 3: 'C' }, { 4: 'D' }, { 5: 'E' }, { 6: 'F' }, { 7: 'G' }, { 8: 'H' },
            { 9: 'I' }, { 10: 'J' }, { 11: 'K' }, { 12: 'L' }, { 13: 'M' }, { 14: 'N' }, { 15: 'O' }, { 16: 'P' },
            { 17: 'Q' }, { 18: 'R' }, { 19: 'S' }, { 20: 'T' }
        ]
        const len = data.length
        const value = Object.values(mt)[len]
        const capital_class = Object.values(value)[0]
        // const l = ...data
        const dt = {
            capital_title: `Class ${capital_class} Equity Shares`,
            capital_stack: '',
            equity: 0,
            amount: 0,
            purchase_basis: 0,
            total_capital_basis: 0,
            pref: '',
            min_equity_multiple: '',
            profit_share: ''
        }
        data.push(dt)
        setData(data)
        setDataLen(data.length)
        setUpdate(!update)
    }

    // let renData = ((parseFloat(dt.capital_stack)/100)/(1-(parseFloat(seniorDept.capital_stack)/100))*100).toFixed(2)

    // useEffect(() => {
    //     if (stackUpdate === true) {
    //         renderCapitalStack()
    //     } else {
    //         renderCapitalStack()
    //     }
    // }, [stackUpdate])


    console.log('dt', data)
    const stack = data.map((dt, index) =>
        <tr style={{ color: palette.text.primary, height: "1rem auto" }} className="mt-3">
            <td>{dt.capital_title}</td>
            {id ? <td className="text-center">
                {parseFloat(dt.capital_stack).toFixed(2)}%
            </td> :
                <td className="col text-center">
                    <div className="d-flex align-items-center">
                        <input placeholder="capital stack %" className="form-control table-control"
                            type="text" name="capital_stack" id={dt.capital_title} value={dt.capital_stack} onChange={changeHandler} /> %
                    </div>
                </td>}
            <td className="col text-center">
                {dt.equity !== 'NaN' ? dt.equity : 0}%
                {/* {dt.capital_stack && seniorDept.capital_stack ? ((parseFloat(dt.capital_stack)/100)/(1-(parseFloat(seniorDept.capital_stack)/100))*100).toFixed(2) : 0}% */}
            </td>
            <td className="col text-center">
                {dt.amount !== 'NaN' ? numberWithCommas(dt.amount) : 0}
            </td>
            <td className="col text-center">
                {dt.purchase_basis !== 'NaN' ? numberWithCommas(dt.purchase_basis) : 0}
            </td>
            <td className="col text-center">
                {dt.total_capital_basis !== 'NaN' ? numberWithCommas(dt.total_capital_basis) : 0}
            </td>
            <td className="col text-center">
                {dt.pref}%
            </td>
            <td className="col text-center">
                {dt.irr_hurdle[0] > 0.0 ? dt.irr_hurdle[0] > 0.0 + " %" : dt.min_equity_multiple + " X"}
                {/* {dt.min_equity_multiple} */}
            </td>
            {/* <td>
                <input placeholder="IRR Hurdle"
                    className="form-control table-control" type="text" name="irr_hurdle" onChange={changeHandler}
                    id={dt.irr_hurdle} />
            </td> */}
            <td className="col text-center">
                {dt.profit_share}%
            </td>
            {!(data.length - 1 == index && data.length > 1) ?
                <td className='text-center'>
                    {dt.LP ? dt.LP.toFixed(2) : (0).toFixed(2)} % / {dt.Sponsor ? dt.Sponsor.toFixed(2) : (0).toFixed(2)} %
                </td> :
                <td className='text-center'>
                    {dt.LP ? dt.LP.toFixed(2) : (0).toFixed(2)} %
                </td>}
        </tr>
    )
    // console.log('data', data)
    let p = []
    let percent = []
    let amount = []
    const value = [data.map(dt => {
        p.push(parseFloat(dt.total_capital_basis))
        amount.push(parseFloat(dt.amount))
    })]
    const totalCapitalBasis = p.reduce((a, b) => a + b, 0)
    const tatalAmount = amount.reduce((a, b) => a + b, 0)
    const seniorDebtAmount = totalCapital && seniorDept.capital_stack ? ((totalCapital * parseFloat(seniorDept.capital_stack)) / 100).toFixed() : 0

    const submitHandler = () => {
        setSubmit(true)
        const dt = {
            "deal_id": id,
            "senior_debt": seniorDept.capital_stack,
            "senior_debt_amount": seniorDebtAmount,
            "classesData": data,
            "sponsor_profit_share": sponsorProfitShare
        }
        const postData = () => {
            const fetchClient = new MainClient(`/deal/stack-creation/`, dt)
            const response = fetchClient.postAPI()
            response.then(res => {
                updateHandler(!sponsorUpdate)
                setSubmit(false)
                // navigate(`/dashboard/companies/${id}`)
                // updateHandler(!sponsorUpdate)
            })
        }
        postData()
        // console.log('capPostData', dt)
    }
      // redux state
  const State = useSelector((state) => state);
  const CurrencySign = State.deal?.currency?.sign
    const color = ['#2F80ED', '#009D55', '#EB5757', '#ffbf00', '#ff00ff', '#660033']
    const bgColor = ['#E4EFFF', '#DAEAFF', '#CBE0FD', '#00ffff', '#00ffbf', '#00bfff']

    return (
        <div className="mb-3">
            <div className="col-12">
                <div 
                      

                >
                    <div className="card-body">
                        <div className='mb-2'>
                            <div className="d-flex justify-content-between align-items-center">
                            <FormHeader theme={theme}>Capital Stack</FormHeader>
                            <FormLabel theme={theme}>Total Capitalization : <span
                                    className="fw-bold">{CurrencySign}{totalCapital ? numberWithCommas(totalCapital) : 0}</span> </FormLabel>
                            </div>
                        </div>
                        <div className="data-body sponser-data-body">
                            <div className="table-responsive">
                                <table className="table data-table align-middle table-borderless sponser-index-table"
                                    id="sponser-table">
                                    <thead >
                                        <tr style={{color: '#fff' , backgroundColor: theme.palette.primary.main }}>
                                            <th className="col">Capital title</th>
                                            <th className="col text-center">Capital Stack %</th>
                                            <th className="col text-center">Equity %
                                            </th>
                                            <th className="col text-center">Amount</th>
                                            <th className="col text-center">Purchase Basis</th>
                                            <th className="col text-center">Total Capitalization Basis
                                            </th>
                                            <th className="col text-center">
                                                Pref %
                                            </th>
                                            <th className="col text-center">
                                                Min Equity Multiple
                                                {/* <select class="form-select" aria-label="" style={{ fontSize: ".8rem", fontWeight:"bold" }}>
                                                    <option selected>
                                                        Min Equity Multiple
                                                    </option>
                                                    <option className="font-weight-bold" value="1">IRR</option>
                                                </select> */}
                                            </th>
                                            <th className="col text-center">Profit Share %
                                            </th>
                                            <th className='text-center'>
                                                Profit Class Allocation %
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ color: palette.text.primary, height: "1rem auto" }}>
                                            <td>Senior Debt</td>
                                            {id ? <td className="text-center">
                                                {(parseFloat(seniorDept.capital_stack)).toFixed(2)} %
                                            </td> :
                                                <td className="col mt-2">
                                                    <div className="d-flex align-items-center ">
                                                        <input style={{ color: palette.text.primary, height: "1rem auto" }} placeholder="Senior Dept" className="form-control table-control"
                                                            type="text" name="capital_stack" value={seniorDept.capital_stack} onChange={seniorDeptChangeHandler} />%
                                                    </div>
                                                </td>}
                                            <td className="col text-center"></td>
                                            <td className="col text-center">
                                                {numberWithCommas(seniorDebtAmount)}
                                            </td>
                                            <td className="text-center"></td>
                                            <td className="text-center"></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        {stack}
                                        <tr style={{ color: palette.text.primary, height: "1rem auto" }}>
                                            <td className="col">
                                                <FormLabel theme={theme}>Sponsor profit share</FormLabel>
                                            </td>
                                            <td className="col"></td>
                                            <td className="col"></td>
                                            <td className="col"></td>
                                            <td className="col"></td>
                                            <td className="col"></td>
                                            <td className="col"></td>
                                            <td className="col"></td>
                                            <td className="col text-center fw-6">{sponsorProfitShare ? sponsorProfitShare.toFixed() : 0}%</td>
                                            <td className="col text-center fw-6">{sponsorProfitShare ? sponsorProfitShare.toFixed() : 0} % / {sponsorProfitShare ? 100 - sponsorProfitShare.toFixed() : 0} %</td>
                                        </tr>

                                    </tbody>
                                    <caption>
                                        <div>
                                            <FormHeader theme={theme} className="my-2">Capital Stack Graph</FormHeader>
                                            {/* <div className="d-flex mt-4 w-100 mr-5"> */}
                                                <NewDept data={data} seniorDept={seniorDept} total={"2000000"}  />
                                            {/* </div> */}
                                        </div>
                                    </caption>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* <div class="row m-3 align-items-center justify-content-end">

                        <div class="col-auto ">
                            {submit ?
                                <button class="btn btn-primary ms-1 save-btn" type="button" disabled>
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    Saving...
                                </button> :
                                <button class="btn btn-success save-btn" onClick={submitHandler}>Save</button>}
                        </div>
                    </div> */}
                    {/* <div className="d-flex justify-content-end mt-0 mb-3 mx-4">
                    <button className="btn btn-md btn-primary" type="submit" onClick={submitHandler}>Submit</button>
                </div> */}
                </div>
            </div>
        </div>
    )
}

export default CapitalStackDetails
import { createSlice } from '@reduxjs/toolkit' 

const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        data: null,
        connection : 'close',
        status:null,
        list:[],
        error:null,
        isCustom : false,
        isPopup: true
    },
    reducers:{
        connect(state, action){
            state.status = action.payload
        },
        socketStatus(state, action){
            state.connection = action.payload
        },
        getNotify(state, action){
            state.data = action.payload
        },
        getNoticationsList(state, action){
            state.list = action.payload
        },
        getError(state, action){
            state.error = action.payload
        },
        setIsCustom(state, action){
            state.isCustom = action.payload
        },
        setIsPopup(state, action){
            state.isPopup = action.payload
        },

    }
})

export const notificationActions = notificationSlice.actions
export default notificationSlice
// ** React Imports
import { Fragment, useEffect, useState } from 'react'

// ** MUI Imports
import { Modal, Box } from "@mui/material";
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,

  Row,
  Col,
} from 'reactstrap';
import IconButton from '@mui/material/IconButton'

// ** Icon Imports
// import Icon from 'src/@core/components/icon'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import { Icon } from '@mui/material'
import { BsFillXCircleFill } from 'react-icons/bs'
import { useSelector } from 'react-redux';


const FileUploaderMultiple = ({ files, setFiles, details, setDetails, handleOpen }) => {
  // ** State
  const [fileObj, setFileObj] = useState({})
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [deed, setDeed] = useState('');
  const [contract, setContract] = useState('');

  // const fileState = useSelector(state => state.deal?.docFileDetails)
  const getTitle = (name) =>  {
    const getName = details?.filter(d => d.path === name)
    const newTitle =  getName?.length > 0 ? getName[getName.length-1].title : null
    if (newTitle) {
    return newTitle 
    } else {
      return name
    } 
  }
  // ** Hooks
  // useEffect(() => {
    
  //   setFileObj(fileState)
  // }, [fileState])
  
  // const handleFileChange = (e) => {
  //   const  {name, value} = e.target
  //   setFileObj(prevState => { return {...prevState, ...{[name] : value}}});
  // };
  //  const fileList = files.filter(item =>item.name !== file.name)
  //  const fileobj = new Object(file)
  //  const updatedfile = {...fileobj, title : value }
  //  console.log(
  //   'updatedfile' , updatedfile,
  //   'files', fileobj
  //  )
  //  if (updatedfile[name]) {
  //   fileList.push(updatedfile)
  //   setFiles([...fileList]);
  //  }

  // const updatedFiles = files
  // .map(item => {
  //   if (item.name === file.name){
  //   // const newobj = Object.assign({}, item ,{
  //   //   [name] : value
  //   // })
  //   item[name] = value
  //   return item
  // } else{
  //   return item
  // }

  // })
  //  console.log(
  //   'updatedfile' , updatedFiles,
  //   'files', file
  //  )
  // setFiles(updatedFiles);
  // const updatedfile = {...fileObj, [name] : value }
//   setFileObj({...{[name] : value }})
  

// };
  
 
  const handleCloseModal = () => {
    setIsOpen(false)
    // setDetails([...details, ...[fileObj] ])  
    if (details?.length <= files.length ){
    setDetails([...details, ...Array.from(fileObj)]);
    }
    console.log(' hanle close is working')
  }
  const handleOpenModal = (obj) => {
    // setFileObj(obj)
    
    setIsOpen(true)
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles?.map(file => Object.assign(file)))
    }
  })

  const renderFilePreview = file => {
    if (file.type.startsWith('image')) {
      return <img width={38} height={38} alt={file.name} src={URL.createObjectURL(file)} />
    } else {
      return null  // <Icon icon='tabler:file-description' />
    }
  }

  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)
    setFiles([...filtered])
  }
  const RenameModal = ({ file }) => {
    // const title =  file?.title || file?.name;
    // const deed =  file?.deed || "";
    // const contract =  file?.contract || "";
  
    return (
      <Modal
        open={isOpen}
        onClose={() => handleCloseModal()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Form key={file}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="title">Title</Label>
                  <Input
                    type="text"
                    name="title"
                    id="title"
                    value={name}
                    placeholder="Enter Title"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="deed">Deed</Label>
                  <Input
                    type="text"
                    className="form-control smaller-input"
                    name="deed"
                    id="deed"
                    value={deed}
                    placeholder="Enter Deed"
                    onChange={(e) => setDeed(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="contract">Contract</Label>
                  <Input
                    type="text"
                    className="form-control smaller-input"
                    name="contract"
                    id="contract"
                    value={contract}
                    placeholder="Enter Contract"
                    onChange={(e) => setContract(e.target.value)}
                  />
                </FormGroup>
                <Button
                  className="m-1"
                  onClick={() => handleCloseModal()}
                  variant="outlined"
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Form>
        </Box>
      </Modal>
    );
  };
  const fileList = files?.map(file => (
    <ListItem key={file.name}>
      <div className='file-details'>
        {/* <div className='file-preview'>{renderFilePreview(file)}</div> */}
        <div onClick={() => handleOpen(file || {})}>
          <Typography  className='file-name'>{ getTitle(file.name)}</Typography>
          {/* added custom title  file.title*/}
          <Typography className='file-size' variant='body2'>
            {Math.round(file.size / 100) / 10 > 1000
              ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
          </Typography>
        </div>
      </div>

      <IconButton onClick={() => handleRemoveFile(file)}>
        <BsFillXCircleFill />
        {/* <Icon icon='tabler:x' fontSize={20} /> */}
      </IconButton>
    
    </ListItem>
  ))

  const handleRemoveAllFiles = () => {
    setFiles([])
  }

  return (
    <>
    <Fragment>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {/* <Box sx={{ display: 'flex', textAlign: 'center', alignItems: 'center', flexDirection: 'column' }}> */}
        <Box
          sx={{
            // mb: 8.75,
            // width: 48,
            // height: 48,
            display: 'flex',
            // borderRadius: 1,
            // alignItems: 'center',
            // justifyContent: 'center',
            // backgroundColor: theme => `rgba(${theme.palette.customColors.main}, 0.08)`
          }}
        >
          <div class="d-flex upload-style align-items-center justify-content-center h-100">
            <div>
              <div class="text-center">
                {/* <img src={uploadSvg} alt="" /> */}
                <svg
                  width="25"
                  height="18"
                  viewBox="0 0 25 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.9941 10.9854C11.9941 11.3996 12.3299 11.7354 12.7441 11.7354C13.1584 11.7354 13.4941 11.3996 13.4941 10.9854H11.9941ZM13.2745 0.469481C12.9816 0.176588 12.5067 0.176588 12.2138 0.469481L7.44084 5.24245C7.14795 5.53535 7.14795 6.01022 7.44084 6.30311C7.73373 6.59601 8.20861 6.59601 8.5015 6.30311L12.7441 2.06047L16.9868 6.30311C17.2797 6.59601 17.7545 6.59601 18.0474 6.30311C18.3403 6.01022 18.3403 5.53535 18.0474 5.24245L13.2745 0.469481ZM13.4941 10.9854V0.999811H11.9941V10.9854H13.4941Z"
                    fill="#52575C"
                    fill-opacity="0.9"
                  />
                  <path
                    d="M1 5.99268V13.3623C1 15.0191 2.34315 16.3623 4 16.3623H21.0002C22.657 16.3623 24.0002 15.0191 24.0002 13.3623V5.99268"
                    stroke="#52575C"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <p
                class="text-muted fs-14 fw-5 mt-2 mb-0"
                id="upload-id-card"
              >
                Upload
              </p>
            </div>
          </div>
          {/* <Icon icon='tabler:upload' fontSize='1.75rem' /> */}
        </Box>

      </div>
      {files.length ? (
        <Fragment>
          <List>{fileList}</List>
          <div className='buttons'>
            <Button className='m-1' color='error' variant='outlined' onClick={handleRemoveAllFiles}>
              Remove All
            </Button>
            {/* <span {...getRootProps({ className: 'dropzone' })}>
            <Button className='m-1'  variant='contained'>Upload Files</Button>
            </span> */}


          </div>
        
        </Fragment>
      ) : null}
    </Fragment>

    </>
  )
}

export default FileUploaderMultiple

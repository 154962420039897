export const ADD_PURCHASE = 'ADD_PURCHASE'
export const ADD_BUYSELL= 'ADD_BUYSELL'
export const ADD_FEESBOX_DATA = 'ADD_FEESBOX_DATA'
export const ADD_FEESBOX_BUYSELL= 'ADD_FEESBOX_BUYSELL'
export const ADD_FEESBOX_PURCHASE = 'ADD_FEESBOX_PURCHASE'

export const ADD_FEESBOX_BUYSELL2 = 'ADD_FEESBOX_BUYSELL2'
export const ADD_FEESBOX_PURCHASE2 = 'ADD_FEESBOX_PURCHASE2'
export const SET_FEES = 'SET_FEES'
export const addPurchaseData = (data) => ({
    type: ADD_PURCHASE,
    payload: data
  })

  export const addBuySellData = (data) => ({
    type: ADD_BUYSELL,
    payload: data
  })  

  export const addFeesBoxData = (data) => ({
    type: ADD_FEESBOX_DATA,
    payload: data
  })  

  export const addFeesBoxBuySell = (data) => ({
    type: ADD_FEESBOX_BUYSELL,
    payload: data
  })
  
  export const addFeesBoxBuySell2 = (data) => ({
    type: ADD_FEESBOX_BUYSELL2,
    payload: data
  })  
  
  export const addFeesBoxPurchase = (data) => ({
    type: ADD_FEESBOX_PURCHASE,
    payload: data
  })

  export const addFeesBoxPurchase2 = (data) => ({
    type: ADD_FEESBOX_PURCHASE2,
    payload: data
  })

  // for fees form
  export const SetFeesInForm = (data) => ({
    type: SET_FEES,
    payload: data
  })
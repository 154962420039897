import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import { Box, IconButton, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

const DealModal = ({open, handleClose, data}) => {
  const navigate = useNavigate()  
  
  useLayoutEffect(() => {
   
  }, [])
  
  const goToDeal = (id) =>  navigate(`/dashboard/deals/${id}`)
  const handleCheckboxChange = (value) => {
    setCheckedValues((prevValues) =>
      prevValues.includes(value)
        ? prevValues.filter((v) => v !== value)
        : [...prevValues, value]
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            overflowY: "scroll",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            height: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            '@media (max-width: 992px)': {
              width: '100%',
              overflowY:'auto',
              height:'100%'
            }
          }}
        >
           <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            sx={{ position: 'relative', top: '2%', left: '95%' }}
          >
            <CloseIcon />
          </IconButton>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Category</th>
                <th>Size</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th></th>

              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? data?.map((deal) => (
                <tr>
                  <td>{deal.id}</td>
                  <td>{deal.deal_name}</td>
                  <td>{deal.deal_category?.selected.map(s => s.value)?.toString()}</td>
                  <td>{deal.size}</td>
                  <td>{deal.city}</td>
                  <td>{deal.state}</td>
                  <td>{deal.country}</td>

                  <td><Button 
                        className="" onClick={() => goToDeal(deal.id)}>Go to Deal</Button></td>
                </tr>
               ) ) : <> 
               <tr>
               <td colSpan={6} className="w-100 text-center d-flex">
               <h6 className="" 
              //  style={{marginLeft:'auto'}}
               >
                  No Deals yet !
               </h6>
               </td>

               </tr>
               </>}
              
            </tbody>
          </Table>

          <Button type="outline" onClick={() => handleClose()} sx={{ mb: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default DealModal;

export const FormData = {
    countries: [
      "United States",
      "United Arab Emirates",
      "United Kingdom",
      "Canada",
      "Australia",
      "Germany",
      "France",
      "Japan",
      "China",
      "India",
      "Brazil",
      "South Africa",
      "Mexico",
      "Italy",
      "Spain",
      "Russia",
      "South Korea"
    ]
  }

  
  export const countriesWithCode = [
    { country: "United States", code: "US" },
    { country: "United Kingdom", code: "GB" },
    { country: "Canada", code: "CA" },
    { country: "Germany", code: "DE" },
    { country: "France", code: "FR" },
    { country: "Japan", code: "JP" },
    { country: "Australia", code: "AU" },
    { country: "Brazil", code: "BR" },
    { country: "India", code: "IN" },
    { country: "UAE", code: "UA" },
    { country: "China", code: "CN" },
    { country: "Abu Dhabi", code: "AE" },
    { country: "Oman", code: "OM" },
    { country: "Spain", code: "ES" },
    { country: "Portugal", code: "PT" },



  ];

import React, { useState } from 'react'
import numberWithCommas from '../../utilities/extrafunction/commaseparate'
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import { CardHeader } from 'reactstrap';
import { Icon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'


const StyledGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    [theme.breakpoints.up('md')]: {
        borderRight: `1px solid ${theme.palette.divider}`
    }
}))



function PropertyCard({ data, image, search, userType }) {

    const [tab, setTab] = useState('1')
    const navigate = useNavigate()
    const handleTabChange = (event, newValue) => {
        setTab(newValue)
    }
    const theme = useTheme()
    const color = ['bg-primary', 'bg-success', 'bg-danger'];
    const classes = ['class A', 'class B', 'class C'];
    const barHeigth = ['5rem', '4rem', '3rem'];

    console.log('searchData', data);
    const classesData = data && (
        search === 'Offerings' ? data?.classes_Data : data?.class_data
    )?.map((dt, index) => (
        <Typography sx={{ mb: 3.5, color: 'text.secondary' }}>
            Average Return:{' '}
            <Box component='span' sx={{ fontWeight: 'bold' }}>
                {dt.class_name}{' '}
                {dt.capital_title ? dt.capital_title?.slice(0, 7) : ''}

            </Box>
        </Typography>
    ));
    const classesAmount = data?.classes_Data?.map((dt) => (
        <Typography sx={{ mb: 3.5, color: 'text.secondary' }}>
            Share amount:{' '}
            <Box component='span' sx={{ fontWeight: 'bold' }}>
                ${numberWithCommas(dt.amount) || 0}

            </Box>
        </Typography>
    ));
    const classesIRR = data?.irr_data?.map((dt) => (
        <Typography sx={{ mb: 3.5, color: 'text.secondary' }}>
            IRR:{' '}
            <Box component='span' sx={{ fontWeight: 'bold' }}>
                {numberWithCommas(dt.irr) || 0}%

            </Box>
        </Typography>
    ));
    const classesAVG = data?.irr_data?.map((dt) => (
        <Typography sx={{ mb: 3.5, color: 'text.secondary' }}>
            Average Return:{' '}
            <Box component='span' sx={{ fontWeight: 'bold' }}>
                {numberWithCommas(dt.avg_annual_return) || 0}%

            </Box>
        </Typography>
    ));

    const amountRequest = data?.class_data?.map((dt) => (
        <Typography sx={{ mb: 3.5, color: 'text.secondary' }}>
            Amount Requested:{' '}
            <Box component='span' sx={{ fontWeight: 'bold' }}>
                ${numberWithCommas(dt?.amount || 0)}
            </Box>
        </Typography>
    ));
    const amountAccepted = data?.class_data?.map((dt) => (
        <Typography sx={{ mb: 3.5, color: 'text.secondary' }}>
            Amount Accepted:{' '}
            <Box component='span' sx={{ fontWeight: 'bold' }}>
                ${numberWithCommas(dt.amount_accepted) || 0}

            </Box>
        </Typography>
    ));




    return (
        <>
            <Card className='m-1' style={{boxShadow:'0px 2px 6px 0px rgba(47, 43, 61, 0.14)', background : theme.palette.customColors.bodyBg}}>
                <Grid container spacing={6}>
                    <StyledGrid item md={5} xs={12}>
                        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img className='img-fluid' src={image} />
                        </CardContent>
                    </StyledGrid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        sx={{
                            pt: ['0 !important', '0 !important', '1.5rem !important'],
                            pl: ['1.5rem !important', '1.5rem !important', '0 !important']
                        }}
                    >
                        <TabContext value={tab}>
                            <TabList onChange={handleTabChange} aria-label='card navigation example' sx={{ '& .MuiTab-root': { py: 3.5 } }}>
                                <Tab value='1' label='Deal' />
                                <Tab value='2' label='More Details' />
                                <Tab value='3' label='Description' />
                            </TabList>
                            <CardContent>
                            <TabPanel value='1' sx={{ p: 0 }}>
                                <Typography variant='h5' sx={{ mb: 2 }}>

                                </Typography>
                                <Typography sx={{ mb: 3.5, color: 'text.secondary' }}>
                                    Deal Name:{' '}
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>
                                        {data?.deal_name}
                                    </Box>
                                </Typography>
                                <Typography sx={{ mb: 3.5, color: 'text.secondary' }}>
                                    Square feet/Metre Square :{' '}
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>
                                        {data?.square_feet}
                                    </Box>
                                </Typography>
                                <Typography sx={{ mb: 3.5, color: 'text.secondary' }}>
                                    Purchase Price :{' '}
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>
                                        ${' '}
                                        {data?.purchase_price
                                            ? numberWithCommas(data?.purchase_price)
                                            : 0}
                                    </Box>
                                </Typography>
                                <Typography sx={{ mb: 3.5, color: 'text.secondary' }}>
                                    Equity shares :{' '}
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>
                                        $ {numberWithCommas(data?.equity_shares || 0) || 0}
                                    </Box>
                                </Typography>

                                <Typography sx={{ fontWeight: 500, mb: 3 }}>

                                <Button onClick={() => navigate(`/dashboard/investors/${data.id}`)} variant='contained'>View</Button>
                                </Typography>
                                </TabPanel>
                                <TabPanel value='2' sx={{ p: 0 }}>
                                { data?.classes_Data || data?.irr_data ?  <>
                                  {classesData}
                                    {classesAmount}
                                    {classesIRR}
                                    {classesAVG}
                                    {amountRequest}
                                    {amountAccepted}
                                  </> : <Typography sx={{ mb: 3.5, color: 'text.secondary' }}>
                                     No details to show right now !
                                     </Typography>
                                }
                                </TabPanel>
                                <TabPanel value='3' sx={{ p: 0 }}>
                                <Typography sx={{ mb: 3.5, color: 'text.secondary', height:'26vh', overflowY:'scroll'}}>
                                        {data?.description || 'No description !'}
                                     </Typography>
                                </TabPanel>

                            </CardContent>
                        </TabContext>
                    </Grid>
                </Grid>
            </Card>

        </>
    )
}

export default PropertyCard
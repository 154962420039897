import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
import numFormatter from '../../utilities/extrafunction/numberConveter';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
        datalabels: {
            backgroundColor: function (context) {
                return context.dataset.backgroundColor;
            },
            formatter: (val, context) =>
                `${(Number(val) * 100) /
                context.chart.data.datasets[context.datasetIndex].data.reduce(
                    (a, b) => Number(a) + Number(b),
                    0
                ).toFixed(2)
                }`,
            borderRadius: 25,
            borderWidth: 3,
            color: "black",
            font: {
                weight: "bold"
            },
            padding: 6
        },

        tooltip: {
            callbacks: {
                label: (ttItem) => `${ttItem.label}: ${numFormatter(ttItem.parsed.y)}`
            }
        }
    },
    scales: {
        x: {
            ticks: {
                beginAtZero: false,
                display: true,
            },
            grid: {
                display: false,
                drawBorder: false
            },
        },
        y: {
            display: false,
        }
    },
};

export function TotalBarChart({ pdata }) {
    const labels = ['Subscriptions', 'Pending'];
    const color = ['#2B4479', '#5D91FF']
    function poolColors(a) {
        var pool = [];
        for (let i = 0; i < color.length; i++) {
            pool.push(color[i]);
        }
        return pool;
    }
    const data = {
        labels,
        datasets: [
            {
                data: pdata,
                backgroundColor: poolColors(color.length),
                borderRadius: 5,
                innerHeight: 10,
                // borderSkipped: false,
            },

        ],

    };
    return <Bar data={data} options={options} />;
}

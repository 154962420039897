import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainClient from "../utilities/MainClient";
import PublicUserContext from "./PublicUserContext";

import "../assets/css/icons.css";
import "../assets/css/multi-slider.css";
import "../assets/css/style1.css";
import WaterFall from "../components/Investor/Waterfall";
import SubscriptionColumn from "../components/Investor/SubscriptionColumn";
import NewDealDetails from "../components/Investor/NewDealDetails";
import NewDept from "../components/Investor/NewDept";
import Result from "../components/newSponsor/Result";

import { Button } from "@mui/material";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import CapitalResult from "../components/newSponsor/CapitalResult";
import FinalResult from "../components/newSponsor/FinalResult";
import { Container, Navbar, Row, Col } from "react-bootstrap";

function PublicUrlPage() {
  const { dealUser, setDealUser, set_DealId } = useContext(PublicUserContext);

  const toggle = useSelector((state) => state.toggle);
  console.log("toggles", toggle);
  const [share, setShare] = useState();
  const [document, setDocument] = useState();
  const [terms, setTerms] = useState(1);
  const [data, setData] = useState([]);
  const [opData, setOpdata] = useState();
  const [cpData, setCpdata] = useState();
  const [opCashFlow, setOpCashFlow] = useState([]);
  const [cpCashFlow, setCpCashFlow] = useState([]);
  const [result, setResult] = useState();
  const [showResult, setShowResult] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  const [isValid, setIsValid] = useState(false);
  const [deal_id, setDeal_id] = useState();
  const user_id = localStorage.getItem("user_id");

  const currentURL = window.location.href;
  useEffect(() => {
    async function fetchData() {
      try {
        const fetchClient = new MainClient("/deal/api/sharetoken/");
        const response = await fetchClient.getNewPublicUrl();
        const data = response.data;
        console.log("Data is", data);

        data.forEach((item) => {
          if (currentURL === item.url_link) {
            const urlParts = currentURL.split("/");
            const value = urlParts[urlParts.length - 2];

            console.log("Value is as",value);

            console.log("Data is ", item.token);
            localStorage.setItem("temp_token", item.token);
            setDealUser(item.deal_user);
            set_DealId(value)
            setDeal_id(value);
            setIsValid(true);
          }
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [currentURL]);

  const [seniorDept, setSeniorDept] = useState({
    capital_stack: "",
    amount: "",
    purchase_basis: "",
    total_capital_basis: "",
  });

  // ***** property start ******* //
  const [property, setProperty] = useState({
    deal_name: "",
    property_title: "",
    address: "",
    city: "",
    state: "",
    units: "",
    description: "",
    user: 2,
  });
  const [metrics, setMetrics] = useState({
    purchase_price: "",
    closing_cost: "",
    capex: "",
    reserves: "",
    entry_noi: "",
    stabilized_noi: "",
    pro_noi: "",
    entry_capbasis: "",
    stabilized_capbasis: "",
    pro_capbasis: "",
  });
  const [entryNoiSelect, setEntryNoiSelect] = useState({
    purchase_price: true,
    closing_cost: false,
    capex: false,
    reserves: false,
    custom: false,
  });
  const [stabilizedNoiSelect, setStabilizedNoiSelect] = useState({
    purchase_price: true,
    closing_cost: false,
    capex: false,
    reserves: false,
    custom: false,
  });
  const [proNoiSelect, setProNoiSelect] = useState({
    purchase_price: true,
    closing_cost: false,
    capex: false,
    reserves: false,
    custom: false,
  });
  const [sponsorData, setSponsorData] = useState();
  console.log("tMetics", metrics);
  const total =
    metrics.purchase_price +
    metrics.closing_cost +
    metrics.capex +
    metrics.reserves;

  useEffect(() => {
    if (deal_id) {
      getAdditionPropertyInfo();
      getData();
      getCashflow();
      getCapitalStack();
      getShare();
      getEquityWaterfall();
      getResult();
    }
    // getWaterfall()
  }, [deal_id]);

  const getCashflow = () => {
    const fetchClient = new MainClient(
      `/deal/view-deal-cash-flow/?deal_id=${deal_id}`
    );
    const response = fetchClient.getPublicURL();
    response.then((res) => {
      console.log("cashFlos", res.data);
      setOpCashFlow(res.data[0].op_cash_flow_years);
      setCpCashFlow(res.data[0].cp_cash_flow_years);
    });
  };

  let T = [];
  const CP = [];
  const CashFlow = opCashFlow.map(
    (dt) => dt.value && T.push(parseInt(dt.value))
  );

  const SeconedCashFlow = cpCashFlow.map(
    (dt) => dt.value && CP.push(parseInt(dt.value))
  );

  let totalCashflow =
    T.reduce((a, b) => a + b, 0) + CP.reduce((a, b) => a + b, 0);

  const getCapitalStack = () => {
    const fetchClient = new MainClient(
      `/deal/view-capital_stack/?deal_id=${deal_id}`
    );
    const response = fetchClient.getPublicURL();
    response.then((res) => {
      const data = res.data[0];
      console.log("newCap", res.data);
      setSeniorDept({
        // sponsor_profit_share: "70",
        // ...seniorDept,
        capital_stack: res.data[0].senior_debt,
        amount: res.data[0].senior_debt_amount,
      });
      setData(data.classesData);
      // setDataLen(data.classesData.length)
      // setCapId(data.id)
    });
  };

  const getResult = () => {
    const fetchClient = new MainClient(`/deal/waterfall/${deal_id}/`);
    const response = fetchClient.getPublicURL();
    response
      .then((res) => {
        console.log("response", res.data.response);
        setResult(res.data.response);
      })
      .catch((err) => {
        console.log("responseError", err);
      });
    // response.error('')
  };

  const getAdditionPropertyInfo = () => {
    const fetchClient = new MainClient(`/deal/getpropertyinfodeal/${deal_id}/`);
    const response = fetchClient.getPublicURL();
    response
      .then((response) => {
        const data = response.data;
        const properties = data.map((item) => item.fields);
        setPropertyList(properties);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const capAmount = data
    ? data.map((dt) => parseInt(dt.amount)).reduce((a, b) => a + b, 0)
    : "";
  const getData = () => {
    const fetchClient = new MainClient(`/deal/api/view-add-deal/${deal_id}/`);
    const response = fetchClient.getPublicURL();
    response.then((res) => {
      const data = res.data;
      setDocument(data.upload_document);
      console.log("dta", data);
      const total =
        parseInt(data.purchase_price) +
        parseInt(data.closing_cost) +
        parseInt(data.capex) +
        parseInt(data.reserves);
      console.log("logdata", res.data);
      setProperty(res.data);
      setMetrics({
        purchase_price: parseInt(data.purchase_price),
        closing_cost: parseInt(data.closing_cost),
        capex: parseInt(data.capex),
        reserves: parseInt(data.reserves),
        entry_noi: data.entry_noi,
        stabilized_noi: data.stabilized_noi,
        pro_noi: data.proForma_noi,
      });
      setEntryNoiSelect(data.noi_data[0]);
      setStabilizedNoiSelect(data.noi_data[1]);
      // setProNoiSelect(data.noi_data[2])
      setTerms(data.investment_term);
      // setSeniorDept({
      //     // sponsor_profit_share: "70",
      //     ...seniorDept,
      //     purchase_basis: parseInt(data.purchase_price),
      //     total_capital_basis: total,
      // })
    });
  };

  const getShare = () => {
    const fetchClient = new MainClient(`/deal/investor/${deal_id}/`);
    const response = fetchClient.getPublicURL();
    response.then((res) => {
      setShare(res.data.result1);
      console.log("companyName", res.data.profile_data[0]);
      setSponsorData(res.data.profile_data[0]);
    });
  };

  const getEquityWaterfall = () => {
    const fetchClient = new MainClient(
      `/deal/equity-waterfall/?deal_id=${deal_id}`
    );
    const response = fetchClient.getPublicURL();
    response.then((res) => {
      console.log("watarfalls", res.data);
      const data = res.data[0];
      setOpdata(data.op_waterfall);
      setCpdata(data.cp_waterfall);
    });
  };
  const targeted_irr = share ? share.targeted_irr : "";
  console.log("response2", result);

  // To Show and Hide Result "Operating Cash Flow"
  const handleClick = () => {
    setShowResult(!showResult);
  };

  console.log("deal_id is", deal_id);

  return (
    <div>
      {isValid ? (
        <>
          {/* <Sidebar/>
          <Header /> */}
          {/* <div className="xp-rightbar">
              <div className="xp-contentbar"> */}
          {/* <DealDetails property={property} metrics={metrics} terms={terms} total={total} targeted_irr={targeted_irr} totalCashflow={totalCashflow} capAmount={capAmount}/> */}
          <Navbar variant="light" shadow style={{ backgroundColor: "#2b4479" }}>
            <Container className="d-flex justify-content-center">
              <Navbar.Brand>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/icon%2Fstackshare%20logo.png?alt=media&token=23fea7a6-8ab0-44e8-b7cc-3d77c7a27a79"
                  alt="StackShare Logo"
                  height="30"
                  className="d-inline-block align-center"
                  style={{ filter: "brightness(0) invert(1)" }}
                />
              </Navbar.Brand>
            </Container>
          </Navbar>

          <div style={{ margin: "50px" }}> 
          <NewDealDetails
        property={property}
        propertyList={propertyList}
        setPropertyList={setPropertyList}
        metrics={metrics}
        terms={terms}
        total={total}
        targeted_irr={targeted_irr}
        totalCashflow={totalCashflow}
        capAmount={capAmount}
        sponsor={sponsorData}
        document={document}
      /> 
            {/* <div className="card data-card h-100 ">
              <NewDept data={data} seniorDept={seniorDept} total={total} />
            </div> */}
            {result ? (
        <SubscriptionColumn
          shares={share}
          data={data}
          result={result}
          terms={terms}
          totals={total}
          targeted_irr={targeted_irr}
          totalCashflow={totalCashflow}
          capAmount={capAmount}
          property={property}
          propertyList={propertyList}
          seniorDept={seniorDept}
          Opdata={opData}
          Cpdata={cpData}
        />
      ) : null}
            {/* <Shares share={share} data={data} result={result} />  
          {result  ? <Test data={data} result={result}/> : 'loading...'} */}
            {/* {result  ? <Subscribe data={data} result={result}/> : 'loading...'} */}
            {/* <WaterFall Opdata={opData} Cpdata={cpData} /> */}

            {/* Add Operating Cash Flow */}
            {/* <div className="text-right mb-3">
              <Button
                onClick={handleClick}
                className="btn button_color text-bold"
                style={{ border: "1px solid" }}
                onMouseOver={(e) => (e.target.style.backgroundColor = "black")}
                onMouseOut={(e) => (e.target.style.backgroundColor = "#f2f2f2")}
              >
                Waterfall Summary{" "}
                {showResult ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
              </Button>{" "}
              <br />
            </div> */}
            {/* {showResult && (
              <div className="card-container">
                {result ? (
                  <>
                    <Result data={result} opData={opData} />
                    <CapitalResult data={result} cpData={cpData} />
                    <FinalResult data={result} />
                  </>
                ) : (
                  <div
                    className="card-container d-flex justify-content-center"
                    style={{ padding: "1rem" }}
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/stackshare-ebcb9.appspot.com/o/404-not-found.png?alt=media&token=beff32fb-13d3-4d7c-9b4f-765e7f6ddddf"
                      className="img-fluid"
                      alt="Responsive image"
                    />
                  </div>
                )}
              </div>
            )} */}
          </div>
        </>
      ) : (
        <>
          <Navbar variant="light" shadow style={{ backgroundColor: "#2b4479" }}>
            <Container className="d-flex justify-content-center">
              <Navbar.Brand>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/icon%2Fstackshare%20logo.png?alt=media&token=23fea7a6-8ab0-44e8-b7cc-3d77c7a27a79"
                  alt="StackShare Logo"
                  height="30"
                  className="d-inline-block align-center"
                  style={{ filter: "brightness(0) invert(1)" }}
                />
              </Navbar.Brand>
            </Container>
          </Navbar>

          <div style={{ minHeight: "100vh" }}>
            <Container className="text-center">
              <Row>
                <Col>
                  <h1
                    style={{
                      fontSize: "4rem",
                      marginTop: "5rem",
                    }}
                  >
                    404
                  </h1>
                  <h2
                    style={{
                      fontSize: "2.5rem",
                      marginBottom: "3rem",
                    }}
                  >
                    Page Not Found
                  </h2>
                  <p
                    style={{
                      fontSize: "1.5rem",
                      marginBottom: "3rem",
                    }}
                  >
                    The page you are looking for could not be found. Please
                    check the URL and try again.
                  </p>
                  <Button
                    variant="light"
                    href="https://stackshares.io/"
                    size="lg"
                  >
                    Go to Homepage
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </div>
  );
}

export default PublicUrlPage;

import { ASSIGN_COMPANIES, GET_INVESTOR_DEAL } from "../../actions/investors"

const initialState = {
  companies : [],
  deals : []
}

const investorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGN_COMPANIES:
      return {
        ...state,
        companies: action.payload
      }
      case GET_INVESTOR_DEAL:
      return {
        ...state,
        deals: action.payload
      }
     
    default:
      return state
  }
}

export default investorReducer

import { useSelector } from "react-redux";
import numberWithCommas from "../../utilities/extrafunction/commaseparate"
import { useTheme } from "@mui/material";
import { FormHeader } from "../newSponsor/FormElements";


const MetricsDetails = ({ metrics, total }) => {

      // redux state
  const State = useSelector((state) => state);
  const CurrencySign = State.deal?.currency?.sign
  const theme = useTheme()
  const {palette} = theme
    return (
        <div className="row p-3 pb-4" 
        style={{ color: palette.text.primary, height: "1rem auto" }}
        >
            <div class='mb-4'>
                <div class='d-flex justify-content-between align-content-center'>
                <FormHeader theme={theme}>Metrics and Capitalization :</FormHeader>
                <FormHeader theme={theme}>Total : 
                        <span class='fw-bold '> {CurrencySign}{numberWithCommas(total)}</span>
                        {/* <span class='fw-bold text-black'> ${numberWithCommas(total)}</span> */}
                        {/* <span class='fw-bold text-black'>${capital ? numberWithCommas(capital) : 0}</span> */}
                    </FormHeader>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Purchase Price
                        </div>
                        <div className="metrics-text-2">
                            {metrics.purchase_price ? numberWithCommas(metrics.purchase_price) : ''}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Closing Cost
                        </div>
                        <div className="metrics-text-2">
                            {metrics.closing_cost ? numberWithCommas(metrics.closing_cost) : ''}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Capex
                        </div>
                        <div className="metrics-text-2">
                            {metrics.capex ? numberWithCommas(metrics.capex) : ''}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Reservers
                        </div>
                        <div className="metrics-text-2">
                            {metrics.reserves ? numberWithCommas(metrics.reserves) : ''}
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Entry NOI
                        </div>
                        <div className="metrics-text-2">
                        {metrics.entry_noi ? numberWithCommas(metrics.entry_noi) : ''}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Stabilized NOI
                        </div>
                        <div className="metrics-text-2">
                        {metrics.stabilized_noi ? numberWithCommas(metrics.stabilized_noi) : ''}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Proforma NOI
                        </div>
                        <div className="metrics-text-2">
                        {metrics.pro_noi ? numberWithCommas(metrics.pro_noi) : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MetricsDetails
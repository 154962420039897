import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import Search from "./Search";
import GoogleMapKey from "../Keys/apiKeys";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { message } from "antd";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import MainClient from "../../utilities/MainClient";

const ShowAdditionalProperty = ({
  id,
  property,
  setProperty,
  addPropertyCount,
}) => {
  const mapRef = useRef();

  const [isEditMode, setIsEditMode] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const changeHandler = (event) => {
    setProperty({ ...property, [event.target.name]: event.target.value });
  };

  // const changeHandler = (e) => {
  //   const { name, value } = e.target;
  //   setProperty(() => ({
  //     ...property,
  //     [name]: value,
  //   }));
  // };
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const [center, setCenter] = useState({
    lat: 43.6532,
    lng: -79.3832,
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GoogleMapKey,
    libraries: ["places"],
  });

  const panTo = useCallback(({ lat, lng }) => {
    console.log("mapLocations", lat, lan);
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(5);
  }, []);

  const mapContainerStyle = {
    height: "100%",
    width: "100%",
  };
  const options = {
    // styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };


  useEffect(() => {
    const getLocation = async (address) => {
      try {
        const results = await getGeocode({ address });
        console.log("mapd", results);
        const { lat, lng } = await getLatLng(results[0]);
        setCenter({
          lat: lat,
          lng: lng,
        });
      } catch (error) {
        console.log("😱 Error: ", error);
        console.log("Error: ", error);
      }
    };
    getLocation(property.address);
  }, [property.address]);


  const getLocationNew = async (address) => {
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setCenter({
        lat: lat,
        lng: lng,
      });
    } catch (error) {
      console.log("😱 Error: ", error);
      console.log("Error: ", error);
    }
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleUpdate = async () => {
    try {
      const fetchClient = new MainClient(`/deal/api/additonal_propertyinfo_deal/${id}/`,property)
      const response = fetchClient.putAPI()
      // const response = await FetchClient.putAPI(
      //   `http://127.0.0.1:8000/deal/api/additonal_propertyinfo_deal/${id}/`,
      //   property
      // );
      console.log("Property updated:", response.data);
      message.success("Property Updated");
      window.location.reload();
    } catch (error) {
      console.error("Error updating property:", error);
    }

  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this property?")) {
      try {

        const fetchClient = new MainClient(`/deal/api/additonal_propertyinfo_deal/${id}/`)
        fetchClient.deleteAPI()
        // await axios.delete(
        //   `http://127.0.0.1:8000/deal/api/additonal_propertyinfo_deal/${id}/`
        // );
        console.log("Property deleted:", id);
        message.success("Property deleted:", id);
        window.location.reload();
      } catch (error) {
        console.error("Error deleting property:", error);
      }
    }
  };

  return (
    <div className="container ml-1">
    
      <div className="row g-3 mb-3 mt-3">
        <div className="row">
          <div className="col-md-8">
            <h2 className="mb-0 fs-16 fw-6 text-black">
              Property: {addPropertyCount +1}
            </h2>
          </div>
          <div className="col-md-4">
            <div
              className="btn btn-sm position-relative"
              style={{
                backgroundColor: "#2B4479",
                color: "#FFFFFF",
                marginRight:"10px"
              }}
              onClick={handleUpdate}
            >
              Update
            </div>

            <div
              className="btn btn-sm position-relative"
              style={{
                backgroundColor: "#2B4479",
                color: "#FFFFFF",
              }}
              onClick={handleDelete}
            >
              Delete
            </div>
          </div>
        </div>

        <div className="col-lg-2 col-md-4 col-sm-6">
              <label htmlFor="propertyTitle" className="form-label">
                Property Title
              </label>
              <input
                type="text"
                className="form-control"
                id="propertyTitle"
                placeholder="Enter Property Title"
                name="property_title"
                value={property.property_title}
                onChange={changeHandler}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <Search
                panTo={panTo}
                property={property}
                setProperty={setProperty}
                setCenter={setCenter}
                getLocation={getLocationNew}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <label htmlFor="city" className="form-label">
                City
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                placeholder="Enter City"
                name="city"
                value={property.city}
                onChange={changeHandler}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <label htmlFor="state" className="form-label">
                State
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                placeholder="Enter State"
                name="state"
                value={property.state}
                onChange={changeHandler}
              />
            </div>
            <div className="col-lg-1 col-md-2 col-sm-4">
              <label htmlFor="units" className="form-label">
                Units
              </label>
              <input
                type="text"
                className="form-control smaller-input"
                id="units"
                placeholder="Enter Units"
                name="units"
                value={property.units}
                onChange={changeHandler}
              />
            </div> 
            <div className="col-lg-1 col-md-2 col-sm-4">
              <label htmlFor="squareFeet" className="form-label sq-ft-label">
                Square Feet
              </label>
              <NumberFormat
                type="text"
                className="form-control smaller-input"
                id="squareFeet"
                placeholder="Enter sq. ft"
                name="square_feet"
                thousandSeparator={true}
                value={property.square_feet}
                onChange={changeHandler}
              />
            </div>

        <div className="col-sm-12 col-md-4 col-lg-4 p-0 ">
          <div
            className="google-map-box border rounded"
            style={{ height: "100px" }}
          >
            <GoogleMap
              id="map"
              mapContainerStyle={mapContainerStyle}
              zoom={8}
              center={center}
              options={options}
              onLoad={onMapLoad}
            >
              <Marker
                key={`${center.lat}-${center.lng}`}
                position={{ lat: center.lat, lng: center.lng }}
                icon={{
                  url: `https://img.icons8.com/office/40/000000/marker.png`,
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(15, 15),
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
              />
            </GoogleMap>
          </div>
        </div>

        {/* <div className="col-md-4">
          <span className="btn" onClick={handleUpdate}>
            Update
          </span>
          <button className="btn" onClick={handleDelete}>
            Delete
          </button>
        </div> */}
      </div>
      
    </div>
  );
};

export default ShowAdditionalProperty;

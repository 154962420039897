import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import MainClient from '../../utilities/MainClient'
import CompanyDetails from './companyDetails'
import ProfileDetails from './ProfileDetails'
import ProfileImage from './ProfileImage'
import Protfolio from './Protfolio'
import { useSelector } from 'react-redux'

const SponsorProfile = ({ UserUpdateHandler }) => {
  const [update, setUpdate] = useState(false)
  const [profileId, setProfileId] = useState('')
  const user = useSelector(state=>state.user.userData)
  const useId = user?.user_id
  const [data, setData] = useState({
    "user": {
      "first_name": "",
      "last_name": "",
      "email": ""
    },
    "company": "",
    "company_address": "",
    "country": "",
    "sponsor_image": "",
    "phone_number": ""
  })
  console.log('submitData',data)
  useEffect(() => {
    const getData = (id) => {
      const fetchClient = new MainClient(`/investor/sponsor-profile/${id}/`)
      const response = fetchClient.getAPI()
      response.then(res => {
        // console.log('responseUser', res.data)
        // setData((res.data.results).reverse())
        setData(res.data)
      })
    }
    const getUserData = () => {
      const fetchClient = new MainClient(`/user/user-details`)
      const response = fetchClient.getAPI()
      response.then(res => {
        console.log('responseUser', res.data.user_id)
        getData(res.data.profile_id)
        setProfileId(res.data.profile_id)
      })
    }
    getUserData()
  }, [])

  const changeHandler = (e) => {
    const { name, value } = e.target
    if (name === "first_name" || name === "last_name") {
      let user = {
        ...data.user, [name]: value
      }
      setData({ ...data, user: user })
    }
    else {
      setData({ ...data, [name]: value })
    }
  }
  const submitHandler = (event) => {
    event.preventDefault();
    console.log('submitData', data)
    const newData = {...data}
    const submData = delete newData["sponsor_image"]
    const updateData = () => {
      const fetchClient = new MainClient(`/investor/sponsor-profile/${newData.id}/`, newData)
      const response = fetchClient.putAPI()
      message.success('Your profile successfully updated')
      UserUpdateHandler()
    }
    updateData()
  }
  console.log('data', data)
  const imageUpload = (e) => {
    console.log('image upload')
    setUpdate(true)
    let form_data = new FormData()
    form_data.append('sponsor_image', e.target.files[0], e.target.files[0].name)
    const fetchClient = new MainClient(`/investor/sponsor-profile-image/${data.id}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    fetchClient.putAPI()
      .then(res => {
        setData({ ...data, sponsor_image: res.data.sponsor_image })
        setUpdate(false)
        message.success("Image Upload successfully")
        UserUpdateHandler()
      })
  }
  console.log('sposorData', data)
  return (
    <div class="mt-3">
      <ProfileImage data={data} imageUpload={imageUpload} update={update} />
      <ProfileDetails changeHandler={changeHandler} data={data} submitHandler={submitHandler} />
      <Protfolio id={useId}/>
      <CompanyDetails id={useId} />
    </div>

  )
}

export default SponsorProfile
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(Tooltip);
const DemoPie = ({data}) => {
    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Doughnut Chart',
                color:'white',
                font: {
                    size:34
                },
                padding:{
                    top:10,
                    bottom:10
                },
                width:"100",
                height:"100",
                responsive:true,
                maintainAspectRatio: false,
                animation:{
                    animateScale: true,
                               }
            },
            datalabels: {
                backgroundColor: function(context) {
                  return context.dataset.backgroundColor;
                },
                formatter: (val, context) =>
                  `${
                              (Number(val) * 100) /
                              context.chart.data.datasets[context.datasetIndex].data.reduce(
                                (a, b) => Number(a) + Number(b),
                                0
                              )
                            }%`,
                //formatter: (val, context) => `${val}%`,
                borderRadius: 25,
                borderWidth: 3,
                color: "black",
                font: {
                  weight: "bold"
                },
                padding: 6
              },
            
              tooltip: {
                callbacks: {
                  label: (ttItem) =>
                    `${ttItem.label}: ${
                                (ttItem.parsed * 100) /
                                ttItem.dataset.data.reduce(
                                  (a, b) => Number(a) + Number(b),
                                  0
                                ).toFixed(2)
                              }%`
                  //label: (ttItem) => `${ttItem.label}: ${ttItem.parsed}%`
                }
              }
        }}
    
    return (
        <div>
           <Pie data={data} options={options} width="100px" height="100px"/>
        </div>
    )
}

export default DemoPie
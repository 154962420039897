export const getPhoneFormat = (inputValue) => {
    if (inputValue) {
        const sanitizedInput = inputValue.replace(/[^0-9-]/g, '');

    // Format the phone number with dashes
    const formattedPhoneNumber = sanitizedInput
      .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
      .slice(0, 12);
      return formattedPhoneNumber
    } 
    
  }
import { InputNumber, Space } from "antd"
import { useEffect, useRef, useState } from "react"
import numberWithCommas from "../../utilities/extrafunction/commaseparate"
import NumberFormat from "react-number-format"
import numberWithOutCommas from "../../utilities/extrafunction/numberWithoutComma"
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import MetricsStepper from "./MetricsStepper"
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useSelector } from "react-redux"
import { useTheme } from "@mui/material"
import { getBasicInputStyles, getCapBasisStyles, getInputStyles } from "./FormStyles"
import { FormHeader, FormLabel } from "./FormElements"
const Metrics = ({ total, metrics, setMetrics, entryNoiSelect, setEntryNoiSelect, stabilizedNoiSelect, setStabilizedNoiSelect, proNoiSelect, setProNoiSelect, updateHandler, id, submit, units, area_type }) => {
    const iEleStabilized = useRef(null)
    useEffect(() => {
        if (iEleStabilized.current) {
            iEleStabilized.current.focus();
        }
    }, [iEleStabilized]);
    const theme = useTheme()
    const inputStyles = getInputStyles(theme) // get input style from import
    const inputbasicStyles = getBasicInputStyles(theme)
    const capBasisStyles = getCapBasisStyles(theme, false)
    const capBasisStylesActive = getCapBasisStyles(theme, true)

    // const [metrics, setMetrics] = useState({
    //     purchase_price: '',
    //     closing_cost: '',
    //     capex: '',
    //     reserves: ''
    // })
    // const [entryNoiSelect, setEntryNoiSelect] = useState({
    //     purchase_price: false,
    //     closing_cost: false,
    //     capex: false,
    //     reserves: false,
    // })
    // const [stabilizedNoiSelect, setStabilizedNoiSelect] = useState({
    //     purchase_price: false,
    //     closing_cost: false,
    //     capex: false,
    //     reserves: false,
    // })
    // const [proNoiSelect, setProNoiSelect] = useState({
    //     purchase_price: false,
    //     closing_cost: false,
    //     capex: false,
    //     reserves: false,
    // })
    useEffect(() => {
      
        setMetrics({ ...metrics, ...{ cost_per_square : metrics.investment / (units || 1) } });
        // setMetrics({ ...metrics, ...{ investment : metrics.investment } });


       
      }, [metrics.investment]);

      useEffect(() => {
      
        setMetrics({ ...metrics, ...{ exp_cost_per_square : metrics.valuation / (units || 1) } });
        setMetrics({ ...metrics, ...{ valuation : metrics.valuation } });
       
      }, [metrics.valuation]);  

    const changeHandler = (e) => {
        const { name, value } = e.target;
        if (name === "units" || name === "square_feet" || name === 'investment' ||  name === 'valuation') {
            setMetrics({ ...metrics, [name]: numberWithOutCommas(value) });
        }   
    }
    const calculatedCostPerSquare = metrics.investment / (units || 1) || 0
    const calculatedInvestment = metrics?.investment || 0  
    const expCostperSquare = metrics?.valuation || 1
    const costVariablePercent = () =>  { 
       const VariablePercent =  Math.ceil(expCostperSquare - calculatedInvestment) / expCostperSquare * 100
       if (Math.sign(VariablePercent) === 1) {
        return <span className="text-success">+{VariablePercent.toFixed(2)}%</span>
       } else if(Math.sign(VariablePercent) === -1 && VariablePercent >= -10){
        return <span className="text-danger">{VariablePercent.toFixed(2)}%</span>
       } else{
        return null
       }
    }
    const reservesChange = (value) => {
        setMetrics({ ...metrics, reserves: value })
    }
    const purchasePriceChange = (value) => {
        setMetrics({ ...metrics, purchase_price: value })
    }
    const closingCostChange = (value) => {
        setMetrics({ ...metrics, closing_cost: value })
    }
    const capexChange = (value) => {
        setMetrics({ ...metrics, capex: value })
    }
    const entrynoiChange = (value) => {
        setMetrics({ ...metrics, entry_noi: value })
    }
    const stabilizednoiChange = (value) => {
        setMetrics({ ...metrics, stabilized_noi: value })
    }
    const pronoiChange = (value) => {
        setMetrics({ ...metrics, pro_noi: value })
    }
    console.log("metrix", metrics)

    const State = useSelector((state) => state);
    const DealCategory = State.deal?.category?.map(obj => obj.value)
    const MetricsStep = State.deal?.metricStep
    const CurrencySign = State.deal?.currency?.sign

    console.log('DealCategory', DealCategory, MetricsStep)

    // const total = Object.values(metrics).slice(0, 4).reduce((a, b) => a + b, 0)
    const entryNoiBasis = [(entryNoiSelect.purchase_price && metrics.purchase_price), (entryNoiSelect.closing_cost && metrics.closing_cost), (entryNoiSelect.capex && metrics.capex), (entryNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)
    const stabilizedNoiBasis = [(stabilizedNoiSelect.purchase_price && metrics.purchase_price), (stabilizedNoiSelect.closing_cost && metrics.closing_cost), (stabilizedNoiSelect.capex && metrics.capex), (stabilizedNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)
    const proNoiBasis = [(proNoiSelect.purchase_price && metrics.purchase_price), (proNoiSelect.closing_cost && metrics.closing_cost), (proNoiSelect.capex && metrics.capex), (proNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)

    return (
        <div class='row' style={{ fontFamily: 'Verdana' }}>
          {DealCategory?.length > 0 && <div class='col-12 matrix-card'>
          <MetricsStepper metrics={metrics} />
                </div >}
                {/* <h2 class='mt-4 fs-16 p-2 fw-6 text-black'>Metrics and Capitalization : Cost Per Square {calculatedInvestment} {costVariablePercent()}</h2> */}
             { DealCategory?.includes('Equity') && MetricsStep !== 'Purchase' && MetricsStep !== 'Buy/Sell' && <div class='col-12 matrix-card'>
                <div class=''>
                    <div className="p-2">
                        <div class='mb-4 mt-2'>
                            <div class='d-flex justify-content-between align-content-center'>
                            <FormHeader theme={theme}>Metrics and Capitalization : { area_type && `Cost Per ${area_type}`}  {CurrencySign}{calculatedCostPerSquare.toFixed(2)} {costVariablePercent()}</FormHeader>
                            <FormHeader theme={theme}>Total
                                    <span class='fw-bold '> {CurrencySign}{numberWithCommas(total)}</span>
                                    {/* <span class='fw-bold text-black'>${capital ? numberWithCommas(capital) : 0}</span> */}
                                </FormHeader>
                            </div>
                        </div>
                        <div className=''>
                            <form action="" id="deal-form" >
                                <div class="row g-3">
                                <div className="col-lg-3 col-md-2 col-sm-4">
                      <FormLabel theme={theme} htmlFor="squareFeet" className="form-label">
                        Investment
                      </FormLabel>
                      <NumberFormat
                        style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="squareFeet"
                        placeholder="Enter Investment"
                        name="investment"
                        thousandSeparator={true}
                        value={metrics.investment}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-4">
                      <FormLabel theme={theme} htmlFor="squareFeet" className="form-label">
                        Valuation
                      </FormLabel>
                      <NumberFormat
                        type="text"
                        style={inputStyles}
                        className="form-control smaller-input"
                        id="squareFeet"
                        placeholder="Enter Valuation"
                        name="valuation"
                        thousandSeparator={true}
                        value={metrics.valuation}
                        onChange={changeHandler}
                      />
                    </div>
                                    <div class="col-sm-6 col-md-3 ">
                                        <label theme={theme} for="purchasePrice" class="form-label">Purchase Price</label>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                                
                                                defaultValue={metrics.purchase_price}
                                                placeholder="Enter Purchase Price"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                name="purchase_price"
                                                onChange={purchasePriceChange}
                                            />
                                        </Space>
                                    </div>
                                    <div class="col-sm-6 col-md-3 ">
                                        <label theme={theme} for="closingCost" class="form-label">Closing Costs</label>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                        <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                                defaultValue={metrics.closing_cost}
                                                placeholder="Enter Closing Costs"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                name="closing_cost"
                                                onChange={closingCostChange}
                                            />

                                        </Space>
                                        {/* <input type="number" class="form-control" id="closingCost"
                                            placeholder='Enter Closing Costs'
                                            name="closing_cost"
                                            value={property.closing_cost.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            onChange={changeHandler}
                                            required
                                        /> */}
                                    </div>
                                    <div class="col-sm-6 col-md-3 ">
                                        <label for="reserves" class="form-label">Capex</label>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                        <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                                defaultValue={metrics.capex}
                                                placeholder="Enter Capex"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                // name="closing_cost"
                                                onChange={capexChange}
                                            />
                                        </Space>
                                    </div>
                                    <div class="col-sm-6 col-md-3 ">
                                        <label theme={theme} for="reserves" class="form-label">Reserves</label>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                        <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                                defaultValue={metrics.reserves}
                                                placeholder="Enter Reserves"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                // name="closing_cost"
                                                onChange={reservesChange}
                                            />
                                        </Space>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
           
            <div class="noi-container">
                <div class="noi-card ">
                    <div>
                        <FormLabel theme={theme} for="noi" class="form-label ">Entry NOI <AiOutlineQuestionCircle id="q1" className=" mx-1"/>     </FormLabel>
                        <Space direction="vertical" style={{ width: '100%' }}>
                        <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                placeholder="Enter Entry NOI"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                value={metrics.entry_noi}
                                onChange={entrynoiChange}
                            />
                        </Space>
                    </div>

                <FormLabel theme={theme} for="reserves" class="form-label pt-2">Capitalization Basis <AiOutlineQuestionCircle id="c1" className=" mx-1"/></FormLabel>
                    <div className="noi-selected">
                        <button style={entryNoiSelect.purchase_price ? capBasisStylesActive : capBasisStyles} onClick={() => setEntryNoiSelect({ ...entryNoiSelect, purchase_price: !entryNoiSelect.purchase_price })}>Purchase Price</button>
                        <button style={entryNoiSelect.closing_cost ? capBasisStylesActive : capBasisStyles} onClick={() => setEntryNoiSelect({ ...entryNoiSelect, closing_cost: !entryNoiSelect.closing_cost })}>Closing Cost</button>
                        <button style={entryNoiSelect.capex ? capBasisStylesActive : capBasisStyles} onClick={() => setEntryNoiSelect({ ...entryNoiSelect, capex: !entryNoiSelect.capex })}>Capex</button>
                        <button style={entryNoiSelect.reserves ? capBasisStylesActive : capBasisStyles} onClick={() => setEntryNoiSelect({ ...entryNoiSelect, reserves: !entryNoiSelect.reserves })}>Reserves</button>
                        <button style={entryNoiSelect.custom ? capBasisStylesActive : capBasisStyles} onClick={() => { setEntryNoiSelect({ purchase_price: false, closing_cost: false, capex: false, reserves: false, custom: !entryNoiSelect.custom }) }}>Custom</button>
                    </div>
                    {!entryNoiSelect.custom &&
                        <div className="mt-2">
                            <Space direction="vertical" style={{ width: '100%' }}>
                            <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                    value={entryNoiBasis > 0 && entryNoiBasis}
                                    placeholder="Cap Basis"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}


                                />
                            </Space>
                        </div>}
                    {entryNoiSelect.custom &&
                        <div className="mt-2">
                            <Space direction="vertical" style={{ width: '100%' }}>
                            <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                    value={metrics.entry_capbasis}
                                    placeholder="Enter Cap Basis"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(value) => setMetrics({ ...metrics, entry_capbasis: value })}
                                />
                            </Space>
                        </div>}
                </div>
                <div class="noi-card">
                    <div>
                        <FormLabel theme={theme} for="stabilized" class="form-label">Stabilized NOI <AiOutlineQuestionCircle id="q2" className=" mx-1"/></FormLabel>
                        <Space direction="vertical" style={{ width: '100%' }}>
                        <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                placeholder="Enter Entry NOI"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                value={metrics.stabilized_noi}
                                // name="closing_cost"
                                onChange={stabilizednoiChange}
                            />
                        </Space>
                    </div>
                    <FormLabel theme={theme} for="reserves" class="form-label pt-2">Capitalization Basis <AiOutlineQuestionCircle id="c2" className=" mx-1"/></FormLabel>
                    <div className="noi-selected">
                        <button style={stabilizedNoiSelect.purchase_price ?   capBasisStylesActive : capBasisStyles} onClick={() => setStabilizedNoiSelect({ ...stabilizedNoiSelect, purchase_price: !stabilizedNoiSelect.purchase_price })}>Purchase Price</button>
                        <button style={stabilizedNoiSelect.closing_cost ?   capBasisStylesActive : capBasisStyles} onClick={() => setStabilizedNoiSelect({ ...stabilizedNoiSelect, closing_cost: !stabilizedNoiSelect.closing_cost })}>Closing Cost</button>
                        <button style={stabilizedNoiSelect.capex ?   capBasisStylesActive : capBasisStyles} onClick={() => setStabilizedNoiSelect({ ...stabilizedNoiSelect, capex: !stabilizedNoiSelect.capex })}>Capex</button>
                        <button style={stabilizedNoiSelect.reserves ?   capBasisStylesActive : capBasisStyles} onClick={() => setStabilizedNoiSelect({ ...stabilizedNoiSelect, reserves: !stabilizedNoiSelect.reserves })}>Reserves</button>
                        <button style={stabilizedNoiSelect.custom ?   capBasisStylesActive : capBasisStyles} onClick={() => setStabilizedNoiSelect({ purchase_price: false, closing_cost: false, capex: false, reserves: false, custom: !stabilizedNoiSelect.custom })}>Custom</button>
                    </div>
                    {!stabilizedNoiSelect.custom &&
                        <div className="mt-2">
                            <Space direction="vertical" style={{ width: '100%' }}>
                            <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                    value={stabilizedNoiBasis > 0 && stabilizedNoiBasis}
                                    placeholder="Cap Basis"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Space>
                        </div>}
                    {stabilizedNoiSelect.custom &&
                        <div className="mt-2">
                            <Space direction="vertical" style={{ width: '100%' }}>
                            <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                    value={metrics.stabilized_capbasis}
                                    placeholder="Enter Cap Basis"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(value) => setMetrics({ ...metrics, stabilized_capbasis: value })}

                                />
                            </Space>
                        </div>}
                </div>
                {/* <div className="row"></div> */}
                <div class='noi-card'>
                    <div class='row g-3'>
                        <div class="col-12">
                            <div>
                                <FormLabel theme={theme} for="performa" class="form-label">ProForma NOI <AiOutlineQuestionCircle id="q3" className=" mx-1"/></FormLabel>
                                <Space direction="vertical" style={{ width: '100%' }}>
                                <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                        value={metrics.pro_noi}
                                        placeholder="Enter ProForma NOI"
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={pronoiChange}
                                    />
                                </Space>
                            </div>
                            <FormLabel theme={theme} for="reserves" class="form-label pt-2">Capitalization Basis <AiOutlineQuestionCircle id="c3" className=" mx-1"/></FormLabel>
                            <div className="noi-selected">
                                <button style={proNoiSelect.purchase_price ?   capBasisStylesActive : capBasisStyles} onClick={() => setProNoiSelect({ ...proNoiSelect, purchase_price: !proNoiSelect.purchase_price })}>Purchase Price</button>
                                <button style={proNoiSelect.closing_cost ?   capBasisStylesActive : capBasisStyles} onClick={() => setProNoiSelect({ ...proNoiSelect, closing_cost: !proNoiSelect.closing_cost })}>Closing Cost</button>
                                <button style={proNoiSelect.capex ?   capBasisStylesActive : capBasisStyles} onClick={() => setProNoiSelect({ ...proNoiSelect, capex: !proNoiSelect.capex })}>Capex</button>
                                <button style={proNoiSelect.reserves ?   capBasisStylesActive : capBasisStyles} onClick={() => setProNoiSelect({ ...proNoiSelect, reserves: !proNoiSelect.reserves })}>Reserves</button>
                                <button style={proNoiSelect.custom ?   capBasisStylesActive : capBasisStyles} onClick={() => setProNoiSelect({ purchase_price: false, closing_cost: false, capex: false, reserves: false, custom: !proNoiSelect.custom })}>Custom</button>
                            </div>
                            {!proNoiSelect.custom &&
                                <div className="mt-2">
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                    <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                            value={proNoiBasis > 0 && proNoiBasis}
                                            placeholder="Cap Basis"
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Space>
                                </div>}
                            {proNoiSelect.custom &&
                                <div className="mt-2">
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                    <InputNumber style={{...{ width: '100%', height: '2.3rem' }, ...inputbasicStyles}}
                                            value={metrics.pro_capbais}
                                            placeholder="Enter Cap Basis"
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={(value) => setMetrics({ ...metrics, pro_capbais: value })}
                                        />
                                    </Space>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            {id &&
                <div class="row m-3 mb-2 pb-0 align-items-center justify-content-end">
                    <div class="col-auto ">
                        {submit ?
                            <button class="btn ms-1 save-btn" type="button" disabled style={{ background: "rgb(62,62,61)" }}>
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                Update...
                            </button> :
                            <button class="btn btn-success save-btn" onClick={updateHandler} style={{ background: "rgb(62,62,61)" }}>Update</button>}
                    </div>
                </div>}
                <div>
                    <UncontrolledTooltip placement="right" target='q1' >
                        this is entry NOI
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement="right" target='q2' >
                        this is Stabilized NOI
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement="right" target='q3' >
                        this is ProForma NOI
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement="right" target='c1' >
                        this is capitalization Basis 1
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement="right" target='c2' >
                        this is capitalization Basis 2
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement="right" target='c3' >
                        this is capitalization Basis 3
                    </UncontrolledTooltip>
                </div>
                </div>  }
            {DealCategory?.length === 0 && <div style={{fontWeight:700}} class='col-12 matrix-card text-center py-2'>Please choose a deal category first</div>}                

        </div >)
}

export default Metrics
import { Switch } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { togglePopNotification } from '../../store/actions/NotificationActions'
import { useTheme } from '@mui/material'
import { FormHeader } from '../newSponsor/FormElements'
import { getInputStyles } from '../newSponsor/FormStyles'

const ProfileDetails = ({ changeHandler, data, submitHandler }) => {
    const isNoti = localStorage.getItem('isNoti')
    const theme = useTheme()
    const {palette} = theme
    const profileHeadStyle = {
    color : '#fff',
    background : palette.primary.main

  }
  const profileBgStyle = { 
    background : palette.customColors.bodyBg
  }
  const inputStyles = getInputStyles(theme)
    const dispatch = useDispatch()
    const ontoggleNotification = (value) => {
        dispatch(togglePopNotification(value))
        localStorage.setItem("isNoti", value)
    }
    return (
        <div class="row mb-3">
            <div class="col-12">
                <div style={profileBgStyle} class="card data-card">
                    <div class="card-body">
                        <FormHeader theme={theme}>Personal Details</FormHeader>
                        <div class="divider"></div>
                        <form>
                            <div class="row g-3 mb-3">
                                <div class="col-sm-6 col-md-4">
                                    <label for="firstName" class="form-label">First Name</label>
                                    <input style={inputStyles} type="text" class="form-control" id="firstName"
                                        placeholder="Enter First Name" name="first_name" value={data?.user?.first_name} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="lastName" class="form-label">Last Name</label>
                                    <input style={inputStyles} type="text" class="form-control" id="lastName" placeholder="Enter Last Name"
                                        name="last_name" value={data?.user?.last_name} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="email" class="form-label">Email</label>
                                    <input style={inputStyles} type="email" class="form-control" id="email"
                                        placeholder="Enter Email Address"
                                        name="email" value={data?.user?.email} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="companyCountry" class="form-label">Phone Number</label>
                                    <input style={inputStyles} type="text" class="form-control" id="companyCountry"
                                        placeholder="Enter Phone Number"
                                        name="phone_number" value={data?.phone_number} onChange={changeHandler} />
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mb-2" id="submit-btn">
                                {/* <button type="submit" class="btn btn-secondary clr-btn">Reset</button> */}
                                <button style={profileHeadStyle} type="submit" class="btn ms-3 " onClick={submitHandler}>Update</button>
                            </div>
                        </form>

                    </div>
                </div>
                <div style={profileBgStyle} class="card data-card mt-3">
                    <div class="card-body">
                        <FormHeader theme={theme}>Settings</FormHeader>
                        <div class="divider"></div>
                        <div class="row g-3 mb-3"></div>

                        <div className='d-flex justify-content-between col-4'>
                            <label for="enable-noti" class="form-label">Toggle Incoming Notifications</label>

                            <Switch onChange={ontoggleNotification} checkedChildren="off" unCheckedChildren="on" defaultChecked={isNoti} className="bg-success mx-2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileDetails
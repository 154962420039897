import numberWithCommas from "../../utilities/extrafunction/commaseparate";
import styled from "styled-components";
import "../../assets/css/subscriptioncol.css";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";

const PercentageDiv = styled.div`
  background: ${(props) => (props.bg ? props.bg : "")};
  width: 100%;
  height: ${(props) => (props.h ? props.h : 0)}%;
  position: absolute;
  bottom: 0px;
  border-radius: ${(props) => (props.h === 100 ? "5px" : "0px 0px 5px 5px")};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.h > 50 ? "#FFFFFF" : "transparent")};
  font-size: 10px;
`;
const Text = styled.div`
  color: ${(props) => (props.h > 20 ? "#fff" : "#000")};
  z-index: 2;
`;

const PercentDept = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.bg ? props.bg : "")};;
  color: white;
  padding: 2rem 0 2rem 0;
  position: relative;
  font-size: 10px;
  min-width: 80px;
  height:${(props) => (props.hg ? props.hg : "")};

  @media screen and (max-width: 768px) {
    width: ${(props) => (props.hg ? props.hg : "")};
    height: 63px;
  }

`

const NewDept = ({ data, seniorDept, total }) => {
    const theme = useTheme()
    const {palette} = theme
  const color = [
    "#2F80ED",
    "#009D55",
    "#EB5757",
    "#ffbf00",
    "#ff00ff",
    "#660033",
  ];
  const bgColor = [
    palette.primary.light,
    palette.primary.main,
    palette.primary.dark,
    palette.secondary.light,
    palette.secondary.main,
    palette.secondary.dark,
    palette.warning.light,
    palette.warning.main,
    palette.warning.dark,

  ];
  let lastResult = parseFloat(
    seniorDept.capital_stack
      ? parseFloat(seniorDept.capital_stack).toFixed()
      : 0
  );
  let accumulator = 0;

  console.log("the data is a follow", total); 
  
  // redux state
  const State = useSelector((state) => state);
  const CurrencySign = State.deal?.currency?.sign


  return (
    // <div className="graph_values">
    // {/* For Bigger Devices */}
    //   {/* <div
    //     className="debt_grap"
    //     style={{
    //       height:
    //         seniorDept.capital_stack < "15px"
    //           ? "auto"
    //           : seniorDept.capital_stack + "%",
    //     }}
    //   >
    //   </div> */}
    //   <PercentDept hg={seniorDept.capital_stack < "15px" ? "auto": seniorDept.capital_stack + "%"} bg={'#2b4479'}>
    //     <span className="dept_percentaged">
    //       {seniorDept.capital_stack
    //         ? parseFloat(seniorDept.capital_stack).toFixed()
    //         : 0}
    //       %
    //     </span>
    //     <span className="dept_name">Debt</span>
    //     <span className="dept_values">
    //       $
    //       {total && seniorDept.capital_stack
    //         ? numberWithCommas(
    //             ((total * parseFloat(seniorDept.capital_stack)) / 100).toFixed()
    //           )
    //         : 0}
    //     </span>
    //     <span className="scaler_class">
    //       {parseFloat(seniorDept.capital_stack).toFixed()}%
    //       <div className="indicator"></div>
    //     </span>
    //   </PercentDept>

    //   {/* For Small Devices */}

    //   {data.map((dt, index) => {
    //     const val1 = seniorDept.capital_stack
    //       ? parseFloat(seniorDept.capital_stack).toFixed()
    //       : 0;
    //     const val2 = dt.capital_stack
    //       ? parseFloat(dt.capital_stack).toFixed()
    //       : 0;

    //     let total;

    //     if (index === 0) {
    //       total = parseFloat(val1) + parseFloat(val2);
    //       accumulator = total;
    //     } else {
    //       total = accumulator + parseFloat(val2);
    //       accumulator = total;
    //     }

    //     return (
          
    //        <PercentDept hg={dt.capital_stack + "%"} bg={bgColor[index]} key={dt.capital_title}>
    //         <span className="dept_percentaged">
    //           {dt.capital_stack ? parseFloat(dt.capital_stack).toFixed() : 0}%
    //         </span>
    //         <span className="dept_name">{dt.capital_title.slice(0, 7)}</span>
    //         <span className="dept_values">
    //           ${dt.capital_stack ? numberWithCommas(dt.amount) : 0}
    //         </span>
    //         <span className="scaler_class">
    //           {total.toFixed()}%<div className="indicator"></div>
    //         </span>
    //         </PercentDept>
    //     );
    //   })}
    // </div>

    <div className="table-responsive">
        {/* <div> */}
            <table className="table data-table align-middle table-borderless sponser-index-table"
                id="sponser-table">
                <caption>
                    {/* <Graph /> */}
                    <div className="d-flex mt-5 p-3">
                        {/* <Graph /> */}
                        <div style={{ width: seniorDept.capital_stack < "15px" ? "auto" : seniorDept.capital_stack + "%", background: palette.secondary.contrastText, padding: ".6rem", textAlign: 'center', position: 'relative', minHeight: "67px", borderRight: '2px solid #FFFFFF', }}>
                            <div className="d-flex flex-column align-items-center me-5 justify-content-center" >
                                <h2 className="mb-0 fs-12  ms-2 fw-4">
                                    {seniorDept.capital_stack ? parseFloat(seniorDept.capital_stack).toFixed() : 0}%
                                </h2>
                                <h2 className="mb-0 fs-14  ms-2">
                                    Debt
                                </h2>
                                <h2 className="mb-0 fs-14  ms-2">
                                    {CurrencySign} {total && seniorDept.capital_stack ? numberWithCommas(((total * parseFloat(seniorDept.capital_stack)) / 100).toFixed()) : 0}
                                </h2>
                            </div>
                            {/* <div style={{ position: "absolute", right: 12, top: 40, width: "60px", lineHeight: "100.61px" }}>
                                <div style={{ border: '.5px solid #FFFFFF', transform: "rotate(-90deg)", width: "75px" }}></div>
                            </div> */}
                            <div className="fw-4 fs-12 mt-1" style={{ position: "absolute", right: -1, top: -58, width: "36px", lineHeight: "45.61px", boxShadow: "-1px -1px 5px rgba(0, 0, 0, 0.15), 1px 1px 5px rgba(0, 0, 0, 0.15)", borderRadius: "5px" }}>
                                {/* <div style={{ border: '1px solid', transform: "rotate(-90deg)" }}></div> */}
                                {/* <div style={{ border: '1px solid #A1C9FF', borderBottom: 0, width: '30px', height: "20px" }}>
                                    <div className="text-dark" style={{ position: "absolute", top: -15, right: -14, fontSize: "12px", textAlign: "center", fontWeight: "600" }}>{seniorDept.capital_stack ? parseFloat(seniorDept.capital_stack).toFixed() : 0}%</div>
                                </div> */}
                                <div style={{ width: "100%", height: "100%", position: "relative" }}>
                                    <Text>
                                        <Text style={{color:palette.text.primary, fontWeight:600}}>
                                            {seniorDept.capital_stack ? parseFloat(seniorDept.capital_stack).toFixed() : 0}%
                                        </Text>
                                    </Text>
                                    <PercentageDiv h={seniorDept?.capital_stack} bg={bgColor[2]}>
                                        {/* <div className="position-relative">
                                            <div className="position-absolute"> */}
                                                {seniorDept.capital_stack ? parseFloat(seniorDept.capital_stack).toFixed() : 0}%
                                            {/* </div>
                                        </div> */}
                                    </PercentageDiv>
                                </div>
                                {/* <h2 className="mb-0 fs-12 text-white fw-4"> */}
                                {/* </h2> */}
                                {/* <div style={{ width: 0, height: 0, borderLeft: "15px solid #A1C9FF", borderRight: "15px solid #A1C9FF", borderTop: "10px solid #A1C9FF" }}></div> */}
                            </div>
                        </div>
                        {data && data.map((dt, index) =>
                            <div className="border" style={{ minWidth:  dt.capital_stack + "%", background: bgColor[index], paddingTop: ".5rem", textAlign: 'center', position: "relative", borderRight: '2px solid #FFFFFF', }} key={dt.capital_title}>
                                <div className="d-flex flex-column  align-items-center me-3 ms-2  justify-content-center" >
                                    <h2 className="mb-0 fs-12 text-white ms-2 fw-4">
                                        {dt.capital_stack ? parseFloat(dt.capital_stack).toFixed() : 0}%
                                    </h2>
                                    <h2 className="mb-0 fs-14 text-white ms-2">
                                        {(dt.capital_title).slice(0, 7)}
                                    </h2>
                                    <h2 className="mb-0 fs-14 text-white ms-2">
                                        {CurrencySign}{dt.capital_stack ? numberWithCommas(dt.amount) : 0}
                                    </h2>
                                </div>
                                <div className="fw-4 fs-12 mt-1" style={{ position: "absolute", right: -1, top: -58, width: "36px", lineHeight: "45.61px", boxShadow: "-1px -1px 5px rgba(0, 0, 0, 0.15), 1px 1px 5px rgba(0, 0, 0, 0.15)", borderRadius: "5px" }}>

                                    <div style={{ width: "100%", height: "100%", position: "relative" }}>
                                        <div style={{color:palette.text.primary, fontWeight:600}}>
                                            {dt.capital_stack ? parseFloat(dt.sums).toFixed() : 0}%
                                        </div>
                                        <PercentageDiv h={dt?.sums} bg={bgColor[index]}>
                                            {dt.capital_stack ? parseFloat(dt.sums).toFixed() : 0}%
                                        </PercentageDiv>
                                    </div>
                                </div>
                                {/* <div style={{ position: "absolute", right: 4, top: -30, width: "14px", lineHeight: "53.61px" }}>
                                    <div style={{ border: '1px solid #A1C9FF', borderBottom: 0, width: '36px', height: "20px" }}>
                                        <div className="text-dark" style={{ position: "absolute", top: -15, right: -19, fontSize: "12px", textAlign: "center", fontWeight: "600" }}>{dt.capital_stack ? parseFloat(dt.sums).toFixed() : 0}%</div>
                                    </div>
                                    <div style={{ width: 0, height: 0, borderLeft: "16px solid #A1C9FF", borderRight: "20px solid #A1C9FF", borderTop: "10px solid #A1C9FF" }}></div>
                                </div> */}
                                {/* <div className="d-flex align-items-center justify-content-center p-2 me-2 ">
                                    <div className="primary-box" style={{ background: color[index] }}></div>
                                    <h2 className="mb-0 fs-12 text-black ms-2">{(dt.capital_title).slice(0, 7)}
                                        <span><br />{dt.capital_stack ? parseFloat(dt.capital_stack).toFixed() : 0}% ${dt.capital_stack ? numberWithCommas(dt.amount) : 0}</span> </h2>
                                </div> */}
                                {/* <div style={{ position: "absolute", right:-6, top:-30, width: "14px", lineHeight: "53.61px", transform: "matrix(1, 0, 0, -1, 0, 0)" }}>
                                               | <div style={{ position: "absolute", left: "73.54%", right: "24.65%", top: "40.29%", bottom: "57.6%", background: "#FFFFFF", border: "1px solid #A2C9FF", boxSizing: "border-box" }}></div>
                                            </div> */}
                                {/* <div style={{ position: "absolute", right: 12, top: 28, width: "26px", lineHeight: "53.61px" }}>
                                    <div style={{ border: '.5px solid #A1C9FF', transform: "rotate(-90deg)", width: "75px" }}></div>
                                </div> */}
                                {/* <div style={{ position: "absolute", right: 4, top: -30, width: "14px", lineHeight: "53.61px" }}>
                                    <div style={{ border: '1px solid #A1C9FF', borderBottom: 0, width: '36px', height: "20px" }}>
                                        <div className="text-dark" style={{ position: "absolute", top: -15, right: -19, fontSize: "12px", textAlign: "center", fontWeight: "600" }}>{dt.capital_stack ? parseFloat(dt.sums).toFixed() : 0}%</div>
                                    </div>
                                    <div style={{ width: 0, height: 0, borderLeft: "16px solid #A1C9FF", borderRight: "20px solid #A1C9FF", borderTop: "10px solid #A1C9FF" }}></div>
                                </div> */}

                            </div>
                        )}
                        {/* <div>
                                        <div className="d-flex align-items-center me-5">
                                            <div className="primary-box"></div>
                                            <h2 className="mb-0 fs-12 text-black ms-2">class A Shares: <span
                                                className="text-primary"><br />15% $3.25mm</span> </h2>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-center me-5">
                                            <div className="success-box"></div>
                                            <h2 className="mb-0 fs-12 text-black ms-2">class B Shares: <span
                                                className="text-primary"><br />10% $1.25mm</span> </h2>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-center me-5">
                                            <div className="danger-box"></div>
                                            <h2 className="mb-0 fs-12 text-black ms-2">class C Shares: <span
                                                className="text-primary"><br />10% $1.25mm</span> </h2>
                                        </div>
                                    </div> */}
                        {/* <div>
                                        <div className="d-flex align-items-center me-5">
                                            <div className="primary-box"></div>
                                            <h2 className="mb-0 fs-12 text-black ms-2">class A Shares: <span
                                                className="text-primary"><br />15% $3.25mm</span> </h2>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-center me-5">
                                            <div className="success-box"></div>
                                            <h2 className="mb-0 fs-12 text-black ms-2">class B Shares: <span
                                                className="text-primary"><br />10% $1.25mm</span> </h2>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-center me-5">
                                            <div className="danger-box"></div>
                                            <h2 className="mb-0 fs-12 text-black ms-2">class C Shares: <span
                                                className="text-primary"><br />10% $1.25mm</span> </h2>
                                        </div>
                                    </div> */}
                    </div>
                </caption>
            </table>
        {/* </div> */}
    </div>
  );
};

export default NewDept;

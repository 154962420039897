import { ADD_COMPANY, LIMIT_COMPANY } from "../../actions/companies"

const initialState = {
  data: null,
  limit: 0,
}

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COMPANY:
      return {
        ...state,
        data: action.payload
      }
     
     case LIMIT_COMPANY:
          return {
            ...state,
            limit: action.payload
          }   
    default:
      return state
  }
}

export default companyReducer

import { useCallback, useRef } from "react";
import { BsFillXCircleFill } from "react-icons/bs";
import NumberFormat from "react-number-format";
import numberWithOutCommas from "../../utilities/extrafunction/numberWithoutComma";
import "../../assets/css/loader.css";
import { RxCross1 } from "react-icons/rx";
import Select from 'react-select';

import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  Autocomplete,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import GoogleMapKey from "../Keys/apiKeys";
import AddProperty from "./AditionalProperty";

import Search from "./Search";
import { useState } from "react";
import { useEffect } from "react";
import { message } from "antd";
import ShowAdditionalProperty from "./ShowAdditionalProperty";
import axios from "axios";
import MainClient from "../../utilities/MainClient";
import { Button, useTheme } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import { Modal } from "react-bootstrap";
import MultiSelectComponent from "./Multiselect";
import { Input } from "reactstrap";
import AdditionalDocs from "./AdditionalDocs";
import { useDispatch, useSelector } from "react-redux";
import { setCurrencyData, updateDealCategory, updateFileDetails } from "../../store/actions/deals";
import { FormData } from '../../data/formData';

import FileUploaderMultiple from "./FileUploaderMultiple";
import RenameModal from "./RenameModal";
import styled from "styled-components";
import { getInputStyles } from "./FormStyles";
import { FormHeader, FormLabel } from "./FormElements";



const formCountries = FormData?.countries

const libraries = ["places"];

const PropertyInfo = ({
  property,
  setProperty,
  setImages,
  images,
  imageData,
  updateHandler,
  refreshUpdate,
  id,
  submit,
  imageDelete,
  setDocument,
  documentData,
  document,
  docDetails,
  setDocDetails
}) => {
  const mapRef = useRef();
  const theme = useTheme()
  const themeMode = theme.palette.mode
  const currencyButtonStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.customColors.bodyBg,
      color: theme.palette.text.primary
      // Add multiselect styles here
    }),
    // control: (provided, state) => ({
    //   ...provided,
    //   backgroundColor: state.isFocused ? '#444' : '#333', // Adjust background color for focus
    //   borderColor: state.isFocused ? '#666' : '#555', // Adjust border color for focus
    //   color: '#fff', // Text color
    // }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: theme.palette.customColors.bodyBg ,
      color: theme.palette.text.primary, // Adjust text color for selected
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.customColors.bodyBg, // Menu background color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.palette.text.primary, // Text color for the selected value
    }),
  };
 
  const inputStyles = getInputStyles(theme) // get input style from import

  const FormInput = styled.input`
 
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.palette.text.primary};
  background-color: ${theme.palette.customColors.bodyBg};
  background-clip: padding-box;
  border: 1px solid ${theme.palette.customColors.avatarBg};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  &:focus {
     border-color: #80bdff;
     outline: 0;
     box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
   }
  `  


  // Additional Property
  const [numProperties, setNumProperties] = useState(0);
  const [additionalProperty, setAdditionalProperty] = useState([]);
  const [showAddProperty, setShowAddProperty] = useState(false);
  const [showAddDocs, setShowAddDocs] = useState(false);

  const [numAdditionalProperties, setNumAdditionalProperties] = useState(0);
  const [addPropertyCount, setAddPropertyCount] = useState(0);
  const [numAdditionalDocs, setNumAdditionalDocs] = useState(0);
  const [addDocsCount, setAddDocsCount] = useState(0);
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [toggleRename, setToggleRename] = useState(false);
  const [fileObj, setFileObj] = useState({})
  const [isOpen, setIsOpen] = useState(false);

  const [center, setCenter] = useState({
    lat: 43.6532,
    lng: -79.3832,
  });

  const [propertyList, setPropertyList] = useState([]);
  const [pkValues, setPkValues] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  // For Public Url
  const linkRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [PublicUrlPage, setPublicUrlPage] = useState("");
  const [NewPublicUrl, setNewPublicUrl] = useState("");
  const mainClientInstance = new MainClient(/* url, data, header */);
  const State = useSelector((state) => state);
  const DealState = State.deal?.category
  const CurrencyState = State.deal?.currency
  // console.log('CurrencyState', CurrencyState)
  const dispatch = useDispatch()
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  
  // useEffect(() => {
  //     setProperty(...property, ...{deal_category: property?.deal_category})
  // }, [property?.deal_category])

  useEffect(() => {

    dispatch(updateDealCategory(property.deal_category?.selected))

  }, [DealState?.category, property.deal_category])


  const optionsList = [
    { value: "Equity", label: "Equity", },
    { value: "Purchase", label: "Purchase" },
    { value: "Buy/Sell", label: "Buy/Sell" },
  ];
  const propertyTypes = ['Single-Family Home', 'Multi-Family Home', 'Apartment', 'Condominium', 'Townhouse', 'Duplex', 'Triplex', 'Quadplex', 'Villa', 'Penthouse', 'Studio Apartment', 'Loft', 'Ranch', 'Chalet', 'Mansion', 'Estate', 'Mixed-Use Property', 'Commercial Property', 'Industrial Property', 'Land', 'Resort Property', 'Vacation Home', 'Beach House', 'Lake House', 'Foreclosure Property', 'Fixer-Upper', 'Luxury Property']

  useEffect(() => {
    const getData = (type, id) => {
      const fetchClient = new MainClient(`/investor/${type}-profile/${id}/`);
      const response = fetchClient.getAPI();
      response.then((res) => {
        setCurrentUserEmail(
          `${res.data.user.email},${res.data.user.first_name}`
        );
        // setData((res.data.results).reverse())
      });
    };
    const getUserData = () => {
      const fetchClient = new MainClient(`/user/user-details`);
      const response = fetchClient.getAPI();
      response.then((res) => {
        const user_type = res.data.user_type;
        const profile_id = res.data.profile_id;

        console.log("responseUser", profile_id, user_type);
        getData(user_type, profile_id);
      });
    };
    // getData()
    getUserData();
  }, []);
  console.log('fileObj', fileObj)
  const handleCloseRenameModal = () => {
    setIsOpen(false)
    // setDetails([...details, ...[fileObj] ])  
    setDocDetails([...docDetails, ...[fileObj]]);
    console.log(' hanle close is working')
  }
  const handleOpenRenameModal = (obj) => {
    setFileObj(obj)
    // dispatch(updateFileDetails(obj))
    console.log(' hanle open is working')
    setIsOpen(true)
  }
  console.log("state", property.deal_category, State);
  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        const fetchClient = new MainClient(`/deal/getpropertyinfodeal/${id}/`);
        const response = await fetchClient.getAPI();
        const data = response.data;
        const pkList = data?.map((item) => item.pk);
        setPkValues(pkList);

        const additionalPropertyData = await Promise.all(
          pkList.map(async (pk) => {
            const additionalClient = new MainClient(
              `/deal/api/additonal_propertyinfo_deal/${pk}/`
            );
            const additionalResponse = await additionalClient.getAPI();
            return additionalResponse.data;
          })
        );

        setPropertyList(additionalPropertyData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPropertyData();
  }, [id]);

  const reloadPageWithDelay = () => {
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  console.log("Your id is ", id);
  const user_id = localStorage.getItem("user_id");

  console.log("UserId is", user_id);

  const { isLoaded, loadError } = useLoadScript({
    // process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    googleMapsApiKey: GoogleMapKey,
    libraries,
  });
  const panTo = useCallback(({ lat, lng }) => {
    console.log("mapLocations", lat, lan);
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(5);
  }, []);
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);
  const mapContainerStyle = {
    height: "100%",
    width: "100%",
  };
  const options = {
    // styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };
  useEffect(() => {
    setProperty({ ...property, ...{ square_feet: property.investment / property.units } });

  }, [property.investment]);
  console.log("images", images);
  const changeHandler = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    if (name === "units" || name === "square_feet" || name === 'investment') {
      setProperty({ ...property, [name]: numberWithOutCommas(value) });
    }
    else {
      setProperty({ ...property, [name]: value });
    }
    // setProperty({ ...property, ...{ valuation : property.investment * property.square_feet } });
  };
  const imageHandler = (e) => {
    console.log(e.target.files[0]);
    setImages(e.target.files);
  };
  const documentHandler = (e) => {
    console.log("files", e.target.files[0]);
    setDocument(e.target.files[0]);
  };
  const handleDocumentUpload = (e, index) => {
    // Update the array of uploaded files

    let file = e.target.files[0]
    // file = {...file, ...{ name : filename}}
    const isItemExist = uploadedFiles.indexOf(file) !== -1
    if (isItemExist) {
      alert('file is here')
    } else {
      const updatedFiles = [...uploadedFiles, ...[file]]
      setUploadedFiles(updatedFiles);
      setDocument(updatedFiles)
      alert('file is not here', updatedFiles[0] === updatedFiles[1])
    }
    // updatedFiles[index] = file;

  };

  const renameDoc = (doc, index) => {

  }


  useEffect(() => {

    if (documentData) {
      // setAddDocsCount(documentData.length - 1)
      // setDocument([])
      setNumAdditionalDocs(documentData.length - 1)
      setShowAddDocs(true)
      console.log('useffect is working !')
    }


  }, [documentData])
  console.log('showAddDocs', showAddDocs)
  const multipleImageContainer = () => {
    for (let i = 0; i < images.length; i++) {
      return (
        <div class="col-sm-4 mt-4">
          <div class="alert alert-primary" role="alert">
            <img src={mg} />
          </div>
        </div>
      );
    }
  };
  useEffect(() => {
    setAdditionalProperty(Array(numProperties).fill({}));
  }, [numProperties]);

  const addProperty = () => {
    if (property.address == "") {
      message.info("Please input all values");
      return;
    }
    setAddPropertyCount(addPropertyCount + 1);
    setNumAdditionalProperties(numAdditionalProperties + 1);
    setShowAddProperty(true);
  };

  const addDocs = () => {
    // 
    console.log('Uploaded Files:', uploadedFiles, 'addDocsCount', addDocsCount, numAdditionalDocs);
    setAddDocsCount(addDocsCount + 1);
    setNumAdditionalDocs(numAdditionalDocs + 1);
    setShowAddDocs(true);
  };
  // const additionalChangeHandler = (e, index) => {
  //   const { name, value } = e.target;
  //   const newProperties = Array.isArray(additionalProperty) ? [...additionalProperty] : [];
  //   newProperties[index][name] = value;
  //   setAdditionalProperty(newProperties);
  // };

  // const handleClick = () => {
  //   setClickCount(clickCount + 1);
  // };

  const getLocation = async (address, city) => {
    try {
      let results = await getGeocode({ address });
      console.log("mapd", results);
      if (results.length === 0) {
        throw new Error("No results found for address");
      }
      const { lat, lng } = await getLatLng(results[0]);
      setCenter({
        lat: lat,
        lng: lng,
      });
    } catch (error) {
      console.log("😱 Error: ", error);
      console.log("Error: ", error);

      try {
        let cityResults = await getGeocode({ address: city });
        console.log("mapd with city", cityResults);
        if (cityResults.length === 0) {
          throw new Error("No results found for city");
        }
        const { lat, lng } = await getLatLng(cityResults[0]);
        setCenter({
          lat: lat,
          lng: lng,
        });
      } catch (error) {
        console.log("😱 Error: ", error);
        console.log("Error: ", error);
      }
    }
  };

  const updateProperty = (updatedProperty, index) => {
    setPropertyList((prevPropertyList) => {
      console.log("PropertInfoPrev", prevPropertyList);
      const updatedList = [...prevPropertyList];
      updatedList[index] = updatedProperty;
      return updatedList;
    });
  };

  useEffect(() => {
    if (id) {
      getLocation(property.address, property.city);
    }
  }, [property.address]);
  console.log("document", document, 'documentData', documentData);
  console.log("doc data details", docDetails)
  // console.log("Token is", localStorage.getItem("token"));
  // Generate Public Url Code
  const generateRandomNumber = () => {
    const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
    return randomNumber;
  };

  const generateLink = () => {
    if (!linkRef.current) {
      const randomNum = generateRandomNumber();
      const uniqueURL = `${mainClientInstance.instance.defaults.baseURL}/${id}/${randomNum}`;
      linkRef.current = uniqueURL;
    }
    return linkRef.current;
  };

  const handleButtonClick = async () => {
    try {
      const fetchClient = new MainClient("/deal/api/sharetoken/");
      const response = await fetchClient.getNewPublicUrl();
      const data = response.data;
      console.log("Response Data ", data);
      let foundUrl = false;

      data.forEach((item) => {
        if (item.deal_id === parseInt(id)) {
          if (item.deal_user.split(",")[0] === currentUserEmail.split(",")[0]) {
            console.log("Url Token", item.url_link);
            setPublicUrlPage(item.url_link);
            foundUrl = true;
          }
        }
      });

      console.log("Public Url is ", PublicUrlPage);

      if (!foundUrl) {
        if (!PublicUrlPage) {
          setShowModal(true);
          const uniqueURL = generateLink();
          setNewPublicUrl(uniqueURL);
          console.log("current user email", currentUserEmail);

          const data = {
            deal_id: id,
            deal_user: currentUserEmail,
            url_link: uniqueURL,
            token: localStorage.getItem("token"),
          };

          const fetchClient = new MainClient("/deal/api/sharetoken/", data);
          const response = await fetchClient.postAPI(); // Await the response here

          console.log(response);
          // Check if the PublicUrlPage variable has already been set
          if (PublicUrlPage) {
            // Do not copy the URL to the clipboard
          } else {
            // Copy the uniqueURL to the clipboard
            await navigator.clipboard
              .writeText(uniqueURL)
              .then(() => {
                // message.success("Link copied to clipboard");
              })
              .catch((error) => {
                // message.error("Please manually copy the link");
                // console.error("Failed to copy link to clipboard:", error);
              });
          }
        } else {
          // Copy the PublicUrlPage to the clipboard
          await navigator.clipboard
            .writeText(PublicUrlPage)
            .then(() => {
              // message.success("Link copied to clipboard");
            })
            .catch((error) => {
              // message.error("Please manually copy the link");
              // console.error("Failed to copy link to clipboard:", error);
            });
        }
      } else {
        setShowModal(true);
        // Copy the PublicUrlPage to the clipboard
        navigator.clipboard
          .writeText(PublicUrlPage)
          .then(() => {
            // message.success("Link copied to clipboard");
          })
          .catch((error) => {
            // message.error("Please manually copy the link");
            console.error("Failed to copy link to clipboard:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCopyLink = async () => {
    navigator.clipboard
      .writeText(NewPublicUrl || PublicUrlPage)
      .then(() => {
        message.success("Link copied to clipboard");
      })
      .catch((error) => {
        message.error("Please manually copy the link");
        console.error("Failed to copy link to clipboard:", error);
      });
    setShowModal(false);
  };


  console.log('property_data', property)

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";
  if (isLoading) {
    // Return your loader component here
    return (
      <>
        <div className="loader_body">
          <div className="loader"></div>
        </div>
      </>
    );
  }

  const selectOptions = [
    {label : 'USD', value: 'USD', sign : '$'},
     {label : 'EUR', value : 'EUR', sign : '€' }
  ] 

  const handleCurrency = (val) => {
    console.log('currency change', val)
    dispatch(setCurrencyData(val))
  }

  const handleBlur = (event) => {
    // Prevent the input from losing focus
    event.preventDefault();
};


  return (
    <div class="row mb-1 col-auto">
      <div class="col-12">
        <div class="data-card mb-4 sponser-index-card">
          <div class="card-body">
            <div class="mb-3 mt-2">
              <div class="d-flex justify-content-between align-content-center">
                <FormHeader theme={theme}>Property Info :</FormHeader>
                {id ? (
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "rgb(62,62,61)" }}
                    onClick={handleButtonClick}
                  >
                    Share
                    <ReplyIcon
                      style={{ transform: "scaleX(-1)", transition: "revert" }}
                    />
                  </Button>
                ) : (
                  <>
                    <Select
                      menuPlacement='auto'
                      value={CurrencyState}
                      styles={currencyButtonStyles}
                      // placeholder={''}
                      options={selectOptions}
                      onChange={(val) => handleCurrency(val)}
                    />

                  </>
                )}

                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Share Link</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Generated Link:{" "}
                      {PublicUrlPage ? PublicUrlPage : generateLink()}{" "}
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                    </Button>

                    <Button variant="primary" onClick={handleCopyLink}>
                      Copy
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* <h2 class='mb-0 fs-16 fw-5'>Total Capitalization:- 
                                <span class='fw-bold text-black'>${capital ? numberWithCommas(capital) : 0}</span>
                                    </h2> */}
              </div>
            </div>
            <div class="data-body">
              <form action="" id="deal-form">
                <div class="row g-3 mb-3 align-items-center">
                  <div class="col-md-4">
                    <FormLabel theme={theme} for="dealName" class="form-label">
                      Deal Name
                    </FormLabel>
                    <input
                      style={inputStyles}
                      type="text"
                      class="form-control"
                      id="dealName"
                      placeholder="Enter Deal Name"
                      name="deal_name"
                      value={property.deal_name}
                      onChange={changeHandler}
                      required
                    />
                  </div>
                  <div class="col-md-4">
                    <FormLabel theme={theme} for="dealName" class="form-label">
                      Deal Category
                    </FormLabel>


                    <MultiSelectComponent value={property} defaultName={'deal_category'} setValue={setProperty} options={optionsList} />
                  </div>
                  <div className="col-md-4">
                    <FormLabel theme={theme} for="dealName" class="form-label">
                      Property Type
                    </FormLabel>
                    <Input
                      type="select"
                      name="property_type"
                      id="exampleSelect"
                      style={{backgroundColor: theme.palette.customColors.bodyBg,
                        color: theme.palette.text.primary}}
                      value={property.property_type}
                      onChange={changeHandler}>
                      <option unselectable='on' value={''} >select</option>
                      {
                        propertyTypes && propertyTypes.map(p => {
                          return (
                            <>


                              <option value={p}>{p}</option>
                            </>
                          )
                        }

                        )
                      }
                    </Input>
                  </div>
                  <div class="row g-3 mb-3">
                    <div class="d-flex justify-content-between align-content-center">
                      <FormHeader theme={theme}>Property 1 :</FormHeader>
                    </div>
                    {/* <div class="col-md-4">
                      <FormLabel theme={theme} for="propertyTitle" class="form-label">
                        Property Title
                      </FormLabel>
                      <input
                      style={inputStyles}
                        type="text"
                        //class="form-control"
                        id="propertyTitle"
                        placeholder="Enter Property Title"
                        name="property_title"
                        value={property.property_title}
                        onChange={changeHandler}
                      />
                    </div>
                    <div class="col-md-4">
                      <FormLabel theme={theme} for="address" class="form-label">
                        Address
                      </FormLabel>

                      <Search
                        panTo={panTo}
                        property={property}
                        setProperty={setProperty}
                        setCenter={setCenter}
                        getLocation={getLocation}
                      />
                    </div>
                    <div class="col-md-4 ">
                      <FormLabel theme={theme} for="city" class="form-label">
                        City
                      </FormLabel>
                      <input
                      style={inputStyles}
                        type="text"
                        //class="form-control"
                        id="city"
                        placeholder="Enter City"
                        name="city"
                        value={property.city}
                        onChange={changeHandler}
                      />
                    </div>
                    <div class="col-md-4 ">
                      <FormLabel theme={theme} for="state" class="form-label">
                        State
                      </FormLabel>
                      <input
                      style={inputStyles}
                        type="text"
                        //class="form-control"
                        id="state"
                        placeholder="Enter State"
                        name="state"
                        value={property.state}
                        onChange={changeHandler}
                      />
                    </div>
                    <div class="col-md-4 ">
                      <FormLabel theme={theme} for="units" class="form-label">
                        Units
                      </FormLabel>
                      <NumberFormat
                        type="text"
                        //class="form-control"
                        id="units"
                        placeholder="Enter Units"
                        name="units"
                        value={property.units}
                        onChange={changeHandler}
                        thousandSeparator={true}
                      />
                    </div>
                    <div class="col-md-4 ">
                      <FormLabel theme={theme} for="squareFeet" class="form-label">
                        Square Feet
                      </FormLabel>
                      <NumberFormat
                        type="text"
                        //class="form-control"
                        id="squareFeet"
                        placeholder="Enter sq. ft"
                        name="square_feet"
                        thousandSeparator={true}
                        value={property.square_feet}
                        onChange={changeHandler}
                      />
                    </div> */}

                    <div className="col-lg-2 col-md-4 col-sm-6">
                      <FormLabel theme={theme} htmlFor="propertyTitle" className="">
                        Property Title
                      </FormLabel>
                      <input
                      style={inputStyles}
                        type="text"
                        className="form-control"
                        id="propertyTitle"
                        placeholder="Enter Property Title"
                        name="property_title"
                        value={property.property_title}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6"></div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                      <FormLabel theme={theme} htmlFor="address" className="">
                        Address
                      </FormLabel>
                      <Search
                        panTo={panTo}
                        property={property}
                        setProperty={setProperty}
                        setCenter={setCenter}
                        getLocation={getLocation}
                      />
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                      <FormLabel theme={theme} htmlFor="city" className="">
                        City
                      </FormLabel>
                      <input
                      style={inputStyles}
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder="Enter City"
                        name="city"
                        value={property.city}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                      <FormLabel theme={theme} htmlFor="state" className="">
                        State
                      </FormLabel>
                      <input
                      style={inputStyles}
                        type="text"
                        className="form-control"
                        id="state"
                        placeholder="Enter State"
                        name="state"
                        value={property.state}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                      <FormLabel theme={theme} htmlFor="country" className="">
                        Country
                      </FormLabel>
                      <input
                      style={inputStyles}
                        type="text"
                        className="form-control"
                        id="country"
                        placeholder="Enter Country"
                        name="country"
                        value={property.country}
                        onChange={changeHandler}
                      />
                    </div>

                    <div className="col-lg-2 col-md-2 col-sm-4">
                      <FormLabel theme={theme} htmlFor="beds" className="">
                        Beds
                      </FormLabel>
                      <NumberFormat
                      style={inputStyles}
                        type="number"
                        className="form-control smaller-input"
                        id="beds"
                        placeholder="Enter No. of Beds"
                        name="beds"
                        value={property.beds}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4">
                      <FormLabel theme={theme} htmlFor="baths" className="">
                        Baths
                      </FormLabel>
                      <NumberFormat
                      style={inputStyles}
                        type="number"
                        className="form-control smaller-input"
                        id="baths"
                        placeholder="Enter No. of Baths"
                        name="baths"
                        value={property.baths}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4">
                      <FormLabel theme={theme} htmlFor="garage" className="">
                        Garage
                      </FormLabel>
                      <NumberFormat
                      type="text"
                      style={inputStyles}
                      className="form-control smaller-input"
                        id="garage"
                        placeholder="Enter No. of Garage"
                        name="garage"
                        value={property.garage}
                        onChange={changeHandler}
                      />
                    </div>

                    <div className="col-lg-1 col-md-4 col-sm-4">
                      <FormLabel theme={theme} htmlFor="size" className="">
                        Size
                      </FormLabel>
                      <NumberFormat
                      type="text"
                      className="form-control smaller-input"
                      id="size"
                      style={{...inputStyles, ...{backgroundColor:theme.palette.customColors.bodyBg}}}

                      placeholder="Enter Value"
                      name="size"
                      thousandSeparator={true}
                      value={property.size}
                      onChange={changeHandler}
                      />
                    </div>

                   
                    <div className="col-sm-12 col-md-4 col-lg-4 p-0 ms-auto">
                      <div
                        className="google-map-box border rounded"
                        style={{ height: "100px" }}
                      >
                        <GoogleMap
                          id="map"
                          style={{backgroundColor:theme.palette.customColors.bodyBg}}
                          mapContainerStyle={mapContainerStyle}
                          zoom={8}
                          center={center}
                          options={options}
                          // onClick={onMapClick}
                          onLoad={onMapLoad}
                        >
                          <Marker
                            key={`${center.lat}-${center.lng}`}
                            position={{ lat: center.lat, lng: center.lng }}
                            // onClick={() => {
                            //     setSelected(marker);
                            // }}
                            icon={{
                              url: `https://img.icons8.com/office/40/000000/marker.png`,
                              origin: new window.google.maps.Point(0, 0),
                              anchor: new window.google.maps.Point(15, 15),
                              scaledSize: new window.google.maps.Size(30, 30),
                            }}
                          />
                        </GoogleMap>
                      </div>
                    </div>

                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-4">
                    <span className="d-flex align-items-center">
                      <FormLabel theme={theme} htmlFor="squareFeet" className="form-label mx-1 mb-0">
                        Square Feet
                      </FormLabel>
                      <input
                      style={inputStyles} id='squareFeet'  checked type="radio" name='area_type'
                        value={'Sq. Feet'}
                        onChange={changeHandler} />
                    </span>

                    <span className="d-flex align-items-center">
                      <FormLabel theme={theme}  htmlFor="metre_square" className="form-label mx-1 mb-0">
                        Metre square
                      </FormLabel>
                      <input
                      style={inputStyles} id='metre_square'  type="radio" name='area_type'
                        value={'Metre Sq.'}
                        onChange={changeHandler} />
                    </span>
                    <NumberFormat
                      type="text"
                      className="form-control smaller-input"
                      id="units"
                      style={{...inputStyles, ...{backgroundColor:theme.palette.customColors.bodyBg}}}

                      placeholder="Enter Value"
                      name="units"
                      thousandSeparator={true}
                      value={property.units}
                      onChange={changeHandler}
                    />
                  </div>
                  {propertyList.map((property, index) => (
                    <ShowAdditionalProperty
                      property={property}
                      id={pkValues[index]}
                      setProperty={(updatedProperty) =>
                        updateProperty(updatedProperty, index)
                      }
                      addPropertyCount={index + 1}
                      key={index}
                    />
                  ))}

                  {[...Array(numAdditionalProperties)]?.map((_, index) => (
                    <>
                      {showAddProperty && (
                        <AddProperty
                          id={id}
                          
                          addPropertyCount={index + 1} />
                      )}
                    </>
                  ))}
                  {sessionStorage.getItem("NewPropertyData") ? (
                    <div className="row g-3 mb-3">
                      <h2 className="mb-0 fs-16 fw-6 text-black">
                        Property Info :
                      </h2>
                    </div>
                  ) : null}

                  <span
                    style={{
                      color: themeMode === 'dark' ? theme.palette.common.white : theme.palette.action.dark,
                      cursor: "pointer",
                      marginBottom: "30px",
                    }}
                    onClick={addProperty}
                  >
                    + Add Additinal Property
                  </span>

                  <div class="col-sm-6 col-md-4 ">
                    <div className="mt-3 w-100">
                      <FormLabel theme={theme} for="formFile" class="form-label">
                        OM Document
                      </FormLabel>
                      <FileUploaderMultiple files={document || []} setFiles={setDocument} details={docDetails} setDetails={setDocDetails} handleOpen={handleOpenRenameModal} />
                    </div>
                    {/* <div className="mt-3 w-100">
                      <FormLabel theme={theme} for="formFile" class="form-label">
                        OM Document
                      </FormLabel>
                      {document ? (
                        <div className="border p-1 ps-2 w-100 border-rounded d-flex align-items-center justify-content-between flex-wrap ">
                          <div>{document[0]?.name}</div>
                          <div
                            className="btn btn-sm position-relative"
                            style={{ 
                              backgroundColor: "#2B4479",
                              color: "#FFFFFF",
                            }}
                          >
                            <RxCross1 />
                            <input
                      style={inputStyles}
                              class="form-control p-1 position-absolute top-0"
                              type="file"
                              id="formFile"
                              style={{ opacity: 0.0 }}
                              onChange={(e) => handleDocumentUpload(e, 0)}
                              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                            />
                             
                          </div>
                         

                      ) : (
                        <div>
                          {documentData?.length > 0 ? (
                            <div className="border p-1 ps-2 w-100 border-rounded d-flex align-items-center justify-content-between flex-wrap ">
                              <div >{documentData[0]?.file_name}</div>
                              <div
                                className="btn btn-sm position-relative"
                                style={{
                                  backgroundColor: "#2B4479",
                                  color: "#FFFFFF",
                                }}
                              >
                                Change Document
                                <input
                      style={inputStyles}
                                  class="form-control p-1 position-absolute top-0"
                                  type="file"
                                  id="formFile"
                                  style={{ opacity: 0.0 }}
                                  onChange={(e) => handleDocumentUpload(e, 0)}
                                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                                />
                               
                              </div>
                            </div>
                          ) : (
                            <div className="border p-1 ps-2 w-100 border-rounded d-flex align-items-center justify-content-between flex-wrap ">
                              <div>{document?.name}</div>
                              <div
                                className="btn btn-sm position-relative"
                                style={{
                                  backgroundColor: "#2B4479",
                                  color: "#FFFFFF",
                                }}
                              >
                                Add Document
                                <input
                      style={inputStyles}
                                  class="form-control p-1 position-absolute top-0"
                                  type="file"
                                  id="formFile"
                                  style={{ opacity: 0.0 }}
                                  onChange={(e) => handleDocumentUpload(e, 0)}
                                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {/* <div>
                          {documentData?.length > 0 ? (
                            <div className="border p-1 ps-2 w-100 border-rounded d-flex align-items-center justify-content-between flex-wrap ">
                              <div>{documentData[0]?.file_name}</div>
                              <div
                                className="btn btn-sm position-relative"
                                style={{
                                  backgroundColor: "#2B4479",
                                  color: "#FFFFFF",
                                }}
                              >
                                Change Document
                                <input
                      style={inputStyles}
                                  class="form-control p-1 position-absolute top-0"
                                  type="file"
                                  id="formFile"
                                  style={{ opacity: 0.0 }}
                                  onChange={documentHandler}
                                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="border p-1 ps-2 w-100 border-rounded d-flex align-items-center justify-content-between flex-wrap ">
                              <div>{document?.name}</div>
                              <div
                                className="btn btn-sm position-relative"
                                style={{
                                  backgroundColor: "#2B4479",
                                  color: "#FFFFFF",
                                }}
                              >
                                Add Document
                                <input
                      style={inputStyles}
                                  class="form-control p-1 position-absolute top-0"
                                  type="file"
                                  id="formFile"
                                  style={{ opacity: 0.0 }}
                                  onChange={documentHandler}
                                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                                />
                              </div>
                            </div>
                          )}
                         
                          </div> 
                       {[...Array.from({ length: numAdditionalDocs })].map((_, index) => (
                    <>
                      {showAddDocs && (
                        <AdditionalDocs 
                        key={index + 1}
                        document={document}
                        documentData={documentData}
                    
                        documentHandler={(e) => handleDocumentUpload(e, index)}
                        index={index + 1} />
                       )} 
                    </>
                  ))}
               
                     <span
                    style={{
                      color: "#2b4479",
                      cursor: "pointer",
                      marginBottom: "30px",
                    }}
                    onClick={addDocs}
                  >
                    + Add Additinal Documents
                  </span>
                    </div> */}
                  </div>

                  <div class="col-sm-6 col-md-8 ">
                    <FormLabel theme={theme} for="#" class="form-label">
                      Description
                    </FormLabel>
                    <textarea
                      rows={6}
                      class="form-control"
                      style={inputStyles}
                      id="description"
                      placeholder="Enter Description"
                      name="description"
                      value={property.description}
                      onChange={changeHandler}
                    ></textarea>
                  </div>

                  {/* <div>
                                        <span style={{color:"#2b4479",cursor:"pointer", marginBottom:"30px"}} onClick={handleAddInput}>+ Add Additinal Property</span>
                                        {inputs.map((value, index) => (
                                        <div key={index} style={{display:"flex", width:"50%", marginBottom:"20px"}}>
                                        <input
                      style={inputStyles}
                                            type="text"
                                            value={value}
                                            placeholder="Enter property value"
                                            onChange={(e) => handleChange(index, e.target.value)}
                                            className="form-control"
                                        />
                                        <button className="btn" onClick={() => handleRemoveInput(index)}><BsFillXCircleFill size={20} color={"tomato"} /></button>
                                        </div>
                                    ))}

                                    </div> */}

                  {/* Image section */}
                  <div class="col-sm-12 col-md-8 col-lg-8 p-0">
                    <div
                      class="row h-100 d-flex align-items-center"
                      id="embed-imgs"
                    >
                      <div class="col-sm-2">
                        <FormLabel theme={theme} for="images" class="form-label">
                          Images
                        </FormLabel>
                        <FormLabel theme={theme}
                          for="travel-documents"
                          class="form-label id-label"
                        >
                          <div class="d-flex upload-style align-items-center justify-content-center h-100">
                            <div>
                              <div class="text-center">
                                {/* <img src={uploadSvg} alt="" /> */}
                                <svg
                                  width="25"
                                  height="18"
                                  viewBox="0 0 25 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.9941 10.9854C11.9941 11.3996 12.3299 11.7354 12.7441 11.7354C13.1584 11.7354 13.4941 11.3996 13.4941 10.9854H11.9941ZM13.2745 0.469481C12.9816 0.176588 12.5067 0.176588 12.2138 0.469481L7.44084 5.24245C7.14795 5.53535 7.14795 6.01022 7.44084 6.30311C7.73373 6.59601 8.20861 6.59601 8.5015 6.30311L12.7441 2.06047L16.9868 6.30311C17.2797 6.59601 17.7545 6.59601 18.0474 6.30311C18.3403 6.01022 18.3403 5.53535 18.0474 5.24245L13.2745 0.469481ZM13.4941 10.9854V0.999811H11.9941V10.9854H13.4941Z"
                                    fill="#52575C"
                                    fill-opacity="0.9"
                                  />
                                  <path
                                    d="M1 5.99268V13.3623C1 15.0191 2.34315 16.3623 4 16.3623H21.0002C22.657 16.3623 24.0002 15.0191 24.0002 13.3623V5.99268"
                                    stroke="#52575C"
                                    stroke-opacity="0.9"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </div>
                              <p
                                class="text-muted fs-14 fw-5 mt-2 mb-0"
                                id="upload-id-card"
                              >
                                Upload
                              </p>
                            </div>
                          </div>
                        </FormLabel>
                        <input
                      style={inputStyles}
                          //class="form-control"
                          type="file"
                          id="travel-documents"
                          multiple
                          accept="image/*"
                          onChange={imageHandler}
                        />
                      </div>
                      {images &&
                        Array.from(images).map(
                          (file, index) => (
                            // <div class="col-sm-2" style={{ marginTop: "2rem", marginLeft: ".5rem", marginRight: ".5rem", poniter: "none" }} >
                            // <FormLabel theme={theme} class="form-label id-label">
                            <div
                              className="col-sm-2 mt-4"
                              onClick={() => setMainImage(img?.images)}
                              style={{
                                width: "150px",
                                height: "95px",
                                cursor: "pointer",
                                position: "relative",
                              }}
                            >
                              <div>
                                <img
                                  src={URL.createObjectURL(file)}
                                  style={{
                                    borderRadius: "5px",
                                    width: "140px",
                                    height: "95px",
                                    border: "2px solid blue",
                                  }}
                                />
                              </div>
                              <span
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "128px",
                                }}
                              >
                                <BsFillXCircleFill
                                  size={20}
                                  color={"tomato"}
                                  onClick={() =>
                                    setImages(
                                      Array.from(images).filter(
                                        (_, i) => i !== index
                                      )
                                    )
                                  }
                                />
                              </span>
                            </div>
                          )
                          // </FormLabel>
                        )}

                      {imageData?.length > 0 ? (
                        imageData?.map((img) => (
                          <div
                            className="col-sm-2 mt-4"
                            onClick={() => setMainImage(img?.images)}
                            style={{
                              width: "150px",
                              height: "95px",
                              cursor: "pointer",
                              position: "relative",
                            }}
                          >
                            <img
                              className="img-fluid"
                              style={{
                                borderRadius: "5px",
                                width: "100%",
                                height: "100%",
                                border: "2px solid green",
                              }}
                              src={img?.images}
                              alt=""
                            />
                            <span
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "115px",
                              }}
                              onClick={() => imageDelete(img.id)}
                            >
                              <BsFillXCircleFill size={20} color={"tomato"} />
                            </span>
                          </div>
                        ))
                      ) : (
                        <div></div>
                      )}
                      <div class="col-md-4">
                        <FormLabel theme={theme} for="dealName" class="form-label">
                          Video Link
                        </FormLabel>
                        <input
                          style={inputStyles}
                          type="text"
                          class="form-control"
                          id="video_link"
                          placeholder="Enter Video URL"
                          name="video_link"
                          value={property.video_link}
                          onChange={changeHandler}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  {/* Google Map */}
                  {/* <div className="col-sm-12 col-md-4 col-lg-4 p-0 ">
                                        <div className="google-map-box border rounded">
                                            <GoogleMap
                                                id="map"
                                                mapContainerStyle={mapContainerStyle}
                                                zoom={8}
                                                center={center}
                                                options={options}
                                                // onClick={onMapClick}
                                                onLoad={onMapLoad}
                                            >
                                                <Marker
                                                    key={`${center.lat}-${center.lng}`}
                                                    position={{ lat: center.lat, lng: center.lng }}
                                                    // onClick={() => {
                                                    //     setSelected(marker);
                                                    // }}
                                                    icon={{
                                                        url: `https://img.icons8.com/office/40/000000/marker.png`,
                                                        origin: new window.google.maps.Point(0, 0),
                                                        anchor: new window.google.maps.Point(15, 15),
                                                        scaledSize: new window.google.maps.Size(30, 30),
                                                    }}
                                                />
                                            </GoogleMap>
                                        </div>
                                    </div> */}
                </div>
                {/* <div class="d-flex justify-content-end mt-2 mb-3">
                                    <button class="btn btn-secondary clr-btn" type="reset" onClick={() => setProperty({})}>Clear</button>
                                    {submit ?
                                        <button class="btn btn-primary" type="button" disabled>
                                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            Saving...
                                        </button> :
                                        <input
                      style={inputStyles} class="btn btn-primary ms-2 add-deal" type="submit" value="Add Deal" />}
                                </div> */}
                {id && (
                  <div class="row m-3 mt-5 mb-0 pb-0 align-items-center justify-content-end">
                    <div class="col-auto ">
                      {submit ? (
                        <button
                          class="btn ms-1 save-btn"
                          type="button"
                          disabled
                          style={{ background: "rgb(62,62,61)" }}
                        >
                          <span
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Update...
                        </button>
                      ) : (
                        <button
                          class="btn btn-success save-btn"
                          onClick={() => {
                            updateHandler();
                            refreshUpdate();
                            // reloadPageWithDelay();
                          }}
                          style={{ background: "rgb(62,62,61)" }}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </form>
              <RenameModal
                file={fileObj}
                setFile={setFileObj}
                isOpen={isOpen}
                handleCloseModal={handleCloseRenameModal}

              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyInfo;

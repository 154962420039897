import { Navigate } from "react-router-dom"

const AddDealPermission = ({ user, children }) =>{
    if(user?.user_type==="investor"){
        return <Navigate to='/dashboard/main' />
    }
    else{
        return children
    }
}

export default AddDealPermission
import React, { useEffect, useState } from 'react';
import {
    Button,
    Stepper,
    Step,
    StepLabel,
  } from "@material-ui/core";
  import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
// import RenameFileModal from './RenameModal';
import MetricsPurchase from './MetricsPurchase';
import MetricsBuySell from './MetricsBuySell';
import { useDispatch, useSelector } from 'react-redux';
import { setMetricStep } from '../../store/actions/deals';
import { useTheme } from '@mui/material';




const MetricsStepper = (metrics) => {
const dispatch = useDispatch()
const theme = useTheme()
const State = useSelector((state) => state.deal?.category);
const DealCategory = State?.map(obj => obj.value)
const getSteps = async () => {
  if (DealCategory) {
    setSteps(new Array(DealCategory))
  }
  }

console.log(State, DealCategory)

  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);

  useEffect(() => {
    if(DealCategory) {
    setSteps(DealCategory)
    if(DealCategory?.length > 0) {
    dispatch(setMetricStep(DealCategory[0]))
    }
    }
      // getSteps()
      changeStepperContent()
  }, [])

  // console.log(steps, 'steps')
  const useStyles = makeStyles((theme) => ({
    button: {
      marginRight: theme.spacing(1),
    },
  }));
  const isStepOptional = (step) => {
    return step === 1 || step === 2;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  const getStepContent = (step, setActiveStep) => {
    if (steps[step] === 'Purchase') {
    return <MetricsPurchase metrics={metrics} />
    }
    else if (steps[step] === 'Buy/Sell') {
      return <MetricsBuySell metrics={metrics} />
      }
  }

  const changeStepperContent = () => {
    getStepContent(activeStep, setActiveStep)
   
  }

  const handleChangeStep = (key) => {
    setActiveStep(key)
     dispatch(setMetricStep(steps[key]))
  }

  return (
    <div>
      <Stepper style={{backgroundColor:theme.palette.customColors.bodyBg}} alternativeLabel activeStep={activeStep} 
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: '#2b4479', // circle color (COMPLETED)
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: '#2b4479', // Just text label (COMPLETED)
                },
              '& .MuiStepLabel-root .Mui-active': {
                color: '#2b4479', // circle color (ACTIVE)
              },
              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                {
                  color: 'common.white', // Just text label (ACTIVE)
                },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                fill: '#2b4479', // circle's number (ACTIVE)
              },
            }} 
            >
              {steps.map((step, index) => {
                const labelProps = {};
                const stepProps = {};
                return (
                  // <div>
                  <Step {...stepProps} key={index} onClick={() => handleChangeStep(index)} style={{ cursor: "pointer",  }}>
                    <StepLabel {...labelProps}><p style={{color:theme.palette.text.primary}} className=''>{step}</p></StepLabel>
                  </Step>
                  // </div>
                );
              })}
            </Stepper>
            {getStepContent(activeStep, setActiveStep)}
    </div>
  );
};

export default MetricsStepper;

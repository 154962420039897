import { Navigate } from "react-router-dom"

const PermissionLayout = ({ user, children }) =>{
    if(!user){
        return <Navigate to='/' />
    }
    else{
        return children
    }
}

export default PermissionLayout
import { message } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainClient from "../../utilities/MainClient";
import InvestorTable from "./InvestorTable";
import { DefaultImage } from "../static/DefaultImgUrl";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import ReactPlayer from 'react-player'
import { useTheme } from "@mui/material";
import { FormHeader } from "../newSponsor/FormElements";
const PropertyDetails = ({ property, imageData, propertyList }) => {
  // console.log('imageData', imageData[0].images)
  const [investor, setInvestor] = useState([]);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState();
  const { id } = useParams();
  const theme = useTheme()
  const {palette} = theme
  useEffect(() => {
    const fetchClient = new MainClient(`/investor/deal-invitation-send/${id}/`);
    const response = fetchClient.getAPI();
    response
      .then((res) => {
        console.log("All Investor........", res);
        console.log("investor", res.data.results);
        setInvestor(res.data.results);
      })
      .catch((err) => console.log(err));
  }, [update]);
  const invitationHandler = (name, email) => {
    const body = {
      investor_email: email,
      investor_name: name,
    };
    const fetchClient = new MainClient(
      `/investor/deal-invitation-send/${id}/`,
      body
    );
    const response = fetchClient.postAPI();
    response
      .then((res) => {
        console.log("investorPost", res.data);
        setUpdate(!update);
      })
      .catch((err) => console.log(err));
  };
  const singleSelectedHandler = (e, id) => {
    console.log("singleSelect", e.target.value, id);
    let inv = investor.slice();
    const newInvestor = inv.map((dt) => {
      for (var key in inv) {
        if (dt.email == id) {
          dt["isCheck"] = e.target.checked;
        }
      }
      return dt;
    });
    console.log("newInvestor", newInvestor);
    setInvestor(newInvestor);
  };
  const myInvestor =
    investor?.length > 0
      ? investor.map((dt, index) => (
          <InvestorTable
            dt={dt}
            index={index}
            key={dt.email}
            invitationHandler={invitationHandler}
            singleSelectedHandler={singleSelectedHandler}
          />
        ))
      : "";

  const [mainImage, setMainImage] = useState(imageData ? imageData[0]?.images : []);

  const selectHandler = (e) => {
    console.log("selecthandler", e.target.checked);
    let inv = investor.slice();
    const newDict = inv.map((dt) => {
      for (var key in inv) {
        dt["isCheck"] = e.target.checked;
      }
      return dt;
    });
    setInvestor(newDict);
  };
  console.log("checkInvestor");

  const submitHandler = () => {
    const data = investor.map((dt) =>
      dt.invite_type == "invite" && dt.isCheck == true
        ? { email: dt.email }
        : ""
    );
    const filterData = data.filter((dt) => dt != "");
    console.log("filterData", filterData);
    if (filterData.length > 0) {
      setLoading(true);
      const fetchClient = new MainClient(
        `/investor/send-multiple-invitation/${id}/`,
        filterData
      );
      const response = fetchClient.postAPI();
      response
        .then((res) => {
          console.log("investorPost", res.data);
          setUpdate(!update);
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    }
  };
  return (
    <div
      className="row p-3 pb-4"
      style={{ color: palette.text.primary, height: "1rem auto" }}
    >
      <FormHeader theme={theme}>Property Info : </FormHeader>
      <div className="col-lg-7 mb-0 mt-1 pb-0">
          <div className="col-md-4 col-sm-12 mb-3">
            <div className="pinfo-text-1">Deal Name</div>
            <div className="pinfo-text-2 ">{property.deal_name}</div>
          </div>
          <hr />
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="pinfo-text-1">Property Title</div>
            <div className="pinfo-text-2">{property.property_title}</div>
          </div>
          <div className="col-4">
            <div className="pinfo-text-1">Square ft</div>
            <div className="pinfo-text-2">{property.units}</div>
          </div>
          <div className="col-4">
            <div className="pinfo-text-1">City</div>
            <div className="pinfo-text-2">{property.city}</div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-4">
            <div className="pinfo-text-1">State</div>
            <div className="pinfo-text-2">{property.state}</div>
          </div>
          <div className="col-4">
            <div className="pinfo-text-1">Size</div>
            <div className="pinfo-text-2">{property.size}</div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="pinfo-text-1">Address</div>
          <div className="pinfo-text-2">{property.address}</div>
        </div>
        <hr />

        {propertyList.map((property, index) => (
          <>
            {/* <div className="mb-1 fw-6 fs-6" style={{ marginTop: "10px" }}>
            Additional Property Info : {index + 1}{" "}
            </div> */}

            <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="pinfo-text-1">Property Title</div>
              <div className="pinfo-text-2">{property.property_title}</div>
            </div>
            <div className="col-4">
              <div className="pinfo-text-1">Square ft</div>
              <div className="pinfo-text-2">{property.units}</div>
            </div>
              <div className="col-4">
                <div className="pinfo-text-1">City</div>
                <div className="pinfo-text-2">{property.city}</div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-4">
                <div className="pinfo-text-1">State</div>
                <div className="pinfo-text-2">{property.state}</div>
              </div>
              <div className="col-4">
                <div className="pinfo-text-1">Size</div>
                <div className="pinfo-text-2">{property.size}</div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="pinfo-text-1">Address</div>
              <div className="pinfo-text-2">{property.address}</div>
            </div>

            <hr />
          </>
        ))}

        <div className="row mt-3">
          <div className="pinfo-text-1 mb-1">Descriptions</div>
          <div className="pinfo-text-3">{property.description}</div>
        </div>
        <div class="my-2 d-flex align-items-center justify-content-center">
          {/* <div class="col-auto"> */}
          {/* <button class="btn btn-secondary copy-link">Copy Link</button> */}
          <button
            class="btn invite2-btn text-white border m-0 p-0"
            data-bs-toggle="modal"
            data-bs-target="#InviteModal"
            style={{ background: "rgb(62,62,61)" }}
          >
            Invite Investor
          </button>
          {/* </div> */}
        </div>
      </div>
      <div className="col-lg-5 col-sm-12">
        {/* <div className="d-flex justify-content-center">
        <PhotoProvider>
        <PhotoView src={imageData?.length > 0
                ? mainImage
                : DefaultImage  }>
          <img
            className="img-fluid h-100 property-single-image"
            src={
              imageData?.length > 0
                ? mainImage
                : DefaultImage            }
            alt="Image"
          />
          </PhotoView>
          </PhotoProvider>
        </div> */}
             <div className="col mx-3">
             { property?.video_link && <ReactPlayer
              url={property?.video_link} 
              width="100%"
              className="react-player" />}
             </div>

        <PhotoProvider>
        <div className="row mt-4 d-flex justify-content-center">
          {imageData?.length > 0 ? (
            imageData?.map((img) => (
              <PhotoView src={img?.images}>
              <div
                className="col"
                onClick={() => setMainImage(img?.images)}
                style={{
                  maxWidth: "150px",
                  maxHeight: "100px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="img-fluid"
                  style={{
                    borderRadius: "8px",
                    width: "100%",
                    height: "100%",
                    border: `${
                      mainImage === img?.images ? "2px solid blue" : ""
                    }`,
                  }}
                  src={img?.images}
                  alt=""
                />
              </div>
              </PhotoView>
            ))
          ) : (
            <div></div>
          )}
        </div>
        </PhotoProvider>

      </div>
      <div
        class="modal fade "
        id="InviteModal"
        tabindex="-1"
        aria-labelledby="InviteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <div class="container-fluid">
                <div class="ps-1">
                  <div class="d-flex align-items-center justify-content-between">
                    <h3 class=" mb-0 fs-16 fw-6 text-dark">
                      All Investors ({investor?.length})
                    </h3>
                    {/* <button class="btn btn-primary invite-all" data-bs-toggle="modal"
                                            data-bs-target="#inviteSponser">
                                            Invite All Invester
                                        </button> */}
                    {loading ? (
                      <button
                        class="btn text-white disabled"
                        style={{ background: "rgb(62,62,61)" }}
                        onClick={submitHandler}
                      >
                        Inviting Selected Investor...
                      </button>
                    ) : (
                      <button
                        class="btn text-white"
                        style={{ background: "rgb(62,62,61)" }}
                        onClick={submitHandler}
                      >
                        Invite Selected Investor
                      </button>
                    )}
                    <button
                      class="btn btn-primary invite-reserve"
                      data-bs-toggle="modal"
                      data-bs-target="#inviteSponser"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="container-fluid p-0 ">
                {/* <div className='w-100 d-flex align-items-center justify-content-between'>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" />
                                            <label class="form-check-label" for="flexCheckIndeterminate">
                                                Select All
                                            </label>
                                        </div>
                                        <div>
                                            <button class="btn btn-sm btn-primary invite-all" >
                                                Invite Selected Invester
                                            </button>
                                            <button class="btn btn-primary invite-reserve" data-bs-toggle="modal"
                                                data-bs-target="#inviteSponser">
                                                <i class="fa fa-plus"></i>
                                            </button>
                                        </div>
                                    </div> */}

                <div class="scroller">
                  <div class="row mb-3">
                    <div class="col-12">
                      <div class="card data-card">
                        <div class="card-body">
                          {/* <!-- <div class="divider"></div> --> */}
                          <div class="table-responsive">
                            <table class="table data-table table-striped align-middle table-borderless">
                              <thead class="bg-white">
                                <tr>
                                  <th scope="col" className="text-center">
                                    <div class="d-flex justify-content-start">
                                      <input
                                        className="form-check-input border"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckIndeterminate"
                                        onChange={selectHandler}
                                      />
                                      <label
                                        className="ms-2"
                                        for="flexCheckIndeterminate"
                                      >
                                       
                                      </label>
                                    </div>
                                  </th>
                                  <th scope="col">SR # </th>
                                  <th scope="col" className="text-center">
                                    Investor name{" "}
                                  </th>
                                  <th scope="col" className="text-center">
                                    Phone Number
                                  </th>
                                  <th scope="col" className="text-center">
                                    Email
                                  </th>
                                  <th scope="col" className="text-center">
                                    Market Interest
                                  </th>
                                  <th scope="col" className="text-center">
                                    Country
                                  </th>
                                  {/* <th scope="col" className="text-center">Status</th> */}
                                  <th scope="col" className="text-center">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{myInvestor}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal fade"
              id="inviteSponser"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="text-center">
                      <img src="../assets/images/congrats.svg" alt="" />
                    </div>
                    <h2 class="text-center text-primary mt-3">
                      Send Invite Successfully
                    </h2>
                    <p class="text-center">
                      Lorem ipsum is a placeholder text commonly used to
                      demonstrate the visual form of a document or a typeface
                      without relying on meaningful content.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;

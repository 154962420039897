import '../../assets/css/icons.css'
import '../../assets/css/multi-slider.css'
import '../../assets/css/style1.css'
import SubscribeGraph from './SubscribGraph'
import ClassesShares from './ClassesShares'
import InvestorList from './InvestorList'
import { useEffect, useState } from 'react'
import MainClient from '../../utilities/MainClient'
import { useParams } from 'react-router-dom'
import Dashboard from './Dashboard'


const Main = () => {
    const [data, setData] = useState()
    const [userData, setUserData] = useState()
    const {id} = useParams()
    const [update, setUpdate] = useState(false)

    useEffect(()=>{
        getShares()
        getSubscribeUser()
    },[])
    useEffect(()=>{
        getSubscribeUser()
    }, [update])

    const getShares = () =>{
        const fetchClient = new MainClient(`/deal/share-details-view/${id}/`)
        const response = fetchClient.getAPI()
        response.then(res=>setData(res.data.data[0]))
    }
    const getSubscribeUser = () =>{
        const fetchClient = new MainClient(`/deal/deal-subscribe-users/${id}/`)
        const response = fetchClient.getAPI()
        response.then(res=>setUserData(res.data.data))
    }
    return (
        <div>
            {/* <SubscribeGraph data={data}/>
            <ClassesShares data={data}/>  */}
            <Dashboard update={update}/>
            <InvestorList data={userData} setUpdate={setUpdate} update={update}/>
        </div>
    )
}

export default Main
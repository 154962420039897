import { Modal, Box, Button as MuiButton, IconButton, useTheme } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { FaPlusCircle } from "react-icons/fa";import numberWithOutCommas from "../../../utilities/extrafunction/numberWithoutComma";
import NumberFormat from "react-number-format";
import FeesForm from "./FeesForm";
import { SetFeesInForm, addFeesBoxData, addPurchaseData } from "../../../store/actions/metrics";
import CloseIcon from '@mui/icons-material/Close';
const AddFeesModal = ({ handleSubmit, open, handleClose, data, setData,investment, feesBoxDispatcher }) => {
  const feesboxState = useSelector((state) => state.metrics.feesBox);
  console.log('feesboxState', feesboxState);
  const dispatch = useDispatch();

  const [formList, setFormList] = useState(data || [{ desc: '', cost: '', cost_percent: '' }]);
  const [update, setUpdate] = useState(false)
  const theme = useTheme()
  const {palette} = theme
  const {mode} = palette
  const handleChange = (e, index) => {
    const { name, value } = e.target;

    setFormList((prevFormList) => {
      
      const updatedFormList = [...prevFormList];
      updatedFormList[index] = { ...updatedFormList[index], [name]: name === 'cost' ? numberWithOutCommas(value) : value };
      if (prevFormList !== updatedFormList){
        setUpdate(!update)
      return updatedFormList;
      }
    });
  };

  useEffect(() => {
    const cleanFormList = formList.filter(fee => {
      const Obj = new Object(fee)
      const isEmpty = (Object.values(Obj)).filter(v => v && v !== '')
      
      return !(_.isEmpty(isEmpty))
      
    })?.map((form) => {
      if (form.cost_percent ) {
        const updatedCost = investment * form.cost_percent / 100 
      const updatedForm = {...form, ...{cost : updatedCost}}
      return updatedForm
      } else {
        return form
      }
    })
    console.log('cleanFormList',investment, cleanFormList)
    console.log('feesBoxDispatcher is running...')
    dispatch(feesBoxDispatcher(cleanFormList));
  }, [formList, investment]);
  

  const addForm = () => {
    setFormList([...formList, { desc: '', cost: '', cost_percent: '' }]);
    // dispatch(SetFeesInForm({ desc: '', cost: '', cost_percent: '' }))
  };

  const submitModal = () => {
    handleSubmit();
    // dispatch(feesBoxDispatcher(formList))
    // setFormList([{ desc: '', cost: '', cost_percent: '' }]);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            overflowY:'scroll',
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: '90%',
            width: 900,
            bgcolor: "background.paper",
            border: `0.7px solid ${palette.customColors.trackBg}`,
            boxShadow: 24,
            p: 4,
            '@media (max-width: 992px)': {
              width: '100%',
              overflowY:'auto',
              height:'100%'
            }
          }}
        >
           <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            sx={{ position: 'relative', top: '2%', left: '95%' }}
          >
            <CloseIcon />
          </IconButton>
          <Container>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <FaPlusCircle size={20} type="button" onClick={addForm}  />
             
           <div>

            {/* <Button className="m-1" onClick={() => handleClose()} variant='outlined' >
              Close
            </Button> */}
           </div>
            </Box>
            {formList.map((form, index) => (
              <FeesForm
                key={index}
                formData={form}
                handleChange={(e) => handleChange(e, index)}
                submitModal={submitModal}
                handleClose={handleClose}
                investment={investment}
                update={update}
                setUpdate={setUpdate}
              />
            ))}
          <Button type="submit" className="mt-2 btn-dark"  onClick={submitModal} variant='outlined' >
              Submit
            </Button>
          </Container>
        </Box>
      </Modal>
    </div>
  );
}

export default AddFeesModal;

import { SET_CURRENCY, SET_METRICS_STEP, UPDATE_DEAL_CATEGORY, UPDATE_FILE_DETAILS } from "../../actions/deals"

export const defaultCurrency = {label : 'USD', value: 'USD', sign : '$'}

const initialState = {
  data: null,
  currency : defaultCurrency,
  limit: 0,
  category: '',
  docFileDetails:null,
  metricStep: ''
}

const dealReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DEAL_CATEGORY:
      return {
        ...state,
        category: action.payload
      }
      case UPDATE_FILE_DETAILS:
        return {
          ...state,
          docFileDetails: action.payload
        }
        case SET_METRICS_STEP:
          return {
            ...state,
            metricStep: action.payload
          }  
          
          case SET_CURRENCY:
            return {
              ...state,
              currency: action.payload
            }  
        
    default:
      return state
  }
}

export default dealReducer

import MainClient from "../../utilities/MainClient"
import { userActions } from "../reducers/UserReducer"

export const fetchUser = () => {
    return (async (dispatch) => {
        const getData = async () => {
            const fetchClient = new MainClient(`/user/user-details`)
            const response = await fetchClient.getAPI()
            const data = await response.data
            return data
        }
        try{
            const data = await getData()
            dispatch(userActions.replaceUser(data))
        } catch(error){
            console.log('error', error)
        }
    })
}

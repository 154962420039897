import { ADD_PARTNER, SET_COMPANY } from "../../actions/companies"

const initialState = {
  data: null,
  companyId: 0,
}

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PARTNER:
      return {
        ...state,
        data: action.payload
      }
      case SET_COMPANY:
        return {
          ...state,
          companyId: action.payload
        } 
      
    default:
      return state
  }
}

export default partnerReducer

import { countriesWithCode } from "../../data/formData"

export const getCountryCode = (country) => {
    const getRegex =  new RegExp(`^${country}`, 'i')
    const getCode =  countriesWithCode
    .filter(obj => getRegex.test(obj.country))
    .map(obj => obj.code)[0]

    return getCode

}
import React, { useCallback, useEffect, useState } from 'react';
import {setAddCompany} from '../../store/actions/companies'
import { message, Spin } from "antd";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { useDispatch, useSelector } from "react-redux"
// import AutoAddress from './AutoAddress';
import Search2 from '../newSponsor/Search2';
import AutoAddress from './AutoAddress';
import { FormData } from '../../data/formData';
import { getPhoneFormat } from '../../utilities/extrafunction/getPhoneFormat';

const formCountries = FormData?.countries

const AddForm2 = ({handleAddcompany, data}) => {
  const [center, setCenter] = useState({
    lat: 43.6532,
    lng: -79.3832,
  });
  const panTo = useCallback(({ lat, lan }) => {
    console.log("mapLocations", lat, lan);
    // mapRef.current.panTo({ lat, lng });
    // mapRef.current.setZoom(5);
  }, []);
  const [formData, setFormData] = useState({
    company: data?.company || '',
    email: data?.email || '',
    phone: data?.phone || '',
    administrator: data?.administrator || '',
    opportunities: data?.opportunities || '0' ,
    formed_in: data?.formed_in || '',
    address: data?.address || '',
    initial_filling_date: data?.initial_filling_date || new Date(),
    jurisdiction: data?.jurisdiction || 'United States' ,
    state: data?.state || '',
    city: data?.city || '',
    zip: data?.zip || '',
  });
  const dispatch = useDispatch()
  const handleChange = (e) => {
    const {name, value} = e.target
    if(name === 'phone') {

      setFormData({ 
        ...formData,
        [name]: getPhoneFormat(value), ...{
          user: { email: formData.email, first_name: formData.name, last_name: 'null'}
       },
      })
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
     }
  };
  const getLocation = async (address) => {
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setCenter({
        lat: lat,
        lng: lng,
      });
    } catch (error) {
      console.log("😱 Error: ", error);
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
  
    // return () => {
      dispatch(setAddCompany(formData))
    // }
  }, [formData])
  
  const handleSubmit = (e) => {
    // e.preventDefault();
    console.log('formData',formData)
    if (formData) {
     
     handleAddcompany()
     if (data?.company){
      message.success(`partner ${formData.company} updated successfully !`)
    } else {
      message.success(`partner ${formData.company} added successfully !`)
    }
    } else {
      console.log('no data')
      message.error(`getting error adding companies !`)
    }
    
   
    // Handle form submission logic here
    console.log('Form submitted:');
  };

  return (
    <Container>
      <Form >
        <Row>
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="company">Company Name</Label>
              <Input
                type="text"
                name="company"
                id="company"
                placeholder="Enter your name"
                defaultValue={formData.company}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                defaultValue={formData.email}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
       
        </Row>
        <Row>
       
        <Col md={6} sm={12}>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input
                type="text"
                pattern='\d{3}-\d{3}-\d{4}'
                maxLength={13}
                name="phone"
                id="phone"
                placeholder="Enter your phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="administrator">Administrator</Label>
              <Input
                type="text"
                name="administrator"
                id="administrator"
                placeholder="Enter Administrator"
                defaultValue={formData.administrator}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          {/* <Col md={6}>
            <FormGroup>
              <Label for="opportunities">opportunities</Label>
              <Input
                type="number"
                name="opportunities"
                id="opportunities"
                placeholder="Enter your opportunities"
                defaultValue={formData.opportunities}
                onChange={handleChange}
              />
            </FormGroup>
          </Col> */}
        
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="formed_in">Formed In</Label>
              <Input
                type="text"
                name="formed_in"
                id="formed_in"
                placeholder="Formed In"
                defaultValue={formData.formed_in}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
      
        <Row>
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="jurisdiction">Jurisdiction</Label>
              <Input
                type="select"
                name="jurisdiction"
                id="jurisdiction"
                defaultValue={formData.jurisdiction}
                onChange={handleChange}
              >
                { formCountries.map(country => <option value={country}>{country}</option>)  }
              </Input>
            </FormGroup>
          </Col>
          {/* <Col md={6}>
            <FormGroup>
              <Label for="address">Address</Label>
              <Input
                type="text"
                name="address"
                id="address"
                placeholder="Enter your address"
                defaultValue={formData.address}
                onChange={handleChange}
              />
            </FormGroup>
          </Col> */}
          {/* <Col md={6} sm={12}>
            <FormGroup>
            <Label for="address">Address</Label>
            <AutoAddress formData={formData} setFormData={setFormData} />

            </FormGroup>
          </Col> */}
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="city">City</Label>
              <Input
                type="text"
                name="city"
                id="city"
                placeholder="Enter your city"
                defaultValue={formData.city}
                onChange={handleChange}
              />
               {/* <AutoAddress formData={formData} setFormData={setFormData} /> */}
            </FormGroup>
          </Col>
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="state">State</Label>
              <Input
                type="text"
                name="state"
                id="state"
                placeholder="Enter your state"
                defaultValue={formData.state}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="zip">Zip Code</Label>
              <Input
                type="number"
                name="zip"
                id="zip"
                placeholder="Enter your zip code"
                defaultValue={formData.zip}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
         
        </Row>
        <Row>
         
          
         
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="initial_filling_date">Initial Filling Date</Label>
              <Input
                type="date"
                name="initial_filling_date"
                id="initial_filling_date"
                defaultValue={formData.initial_filling_date}
                onChange={handleChange}
              >
              
              </Input>
            </FormGroup>
          </Col>
          {/* <Col md={6}>
            <FormGroup>
              <Label for="gender">Initial Filling Date</Label>
            <AutoAddress />
            </FormGroup>
          </Col> */}
        </Row>
        <Button onClick={handleSubmit} variant='outlined' >
          Submit
        </Button>
      </Form>
    </Container>
  );
};



export default AddForm2;

import numberWithCommas from "./commaseparate"

const addTotal = (data) =>{
    if(data){
    const value = data.map(dt=>dt.value)
    const add = value.reduce((a,b)=>parseInt(a)+parseInt(b), 0)
        return numberWithCommas(add)
    }
    else{
        return 0
    }
}

export default addTotal
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from './store';
import { SettingsConsumer, SettingsProvider } from '@core/context/settingsContext';
import ThemeComponent from '@core/theme/ThemeComponent';

// import store from './redux/store'


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SettingsProvider
      //  {...(setConfig ? { pageSettings: setConfig() } : {})}
      >
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </ThemeComponent>
            )
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { message } from 'antd';
import React, { useEffect, useState } from "react";
import {
    Typography,
    TextField,
    Button,
    Stepper,
    Step,
    StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import PropertyInfo from './PropertyInfo';
import Metrics from './Metrics';
import CashFlows from './CashFlows';
import StepFour from './StepFour';
import CapitalStack from './CapitalStack';
import WaterFall from './Waterfall';
import MainClient from '../../utilities/MainClient';
import { useNavigate, useParams } from 'react-router-dom';
import FinalResult from './FinalResult';
import Result from './Result';
import CapitalResult from './CapitalResult';
import PropertyDetails from '../DealSummary/PropertyDetails';
import CashflowDetails from '../DealSummary/CashflowDetails';
import MetricsDetails from '../DealSummary/MetricsDetails';
import CapitalStackDetails from '../DealSummary/CapitalStackDetails';
import WaterFallDetails from '../DealSummary/WaterfallDetails';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOpenDeal } from '../../store/actions/DealAction';
import axios from 'axios';
import { addBuySellData, addFeesBoxBuySell, addFeesBoxBuySell2, addFeesBoxPurchase, addFeesBoxPurchase2, addPurchaseData } from '../../store/actions/metrics';
import DocumentDetails from '../DealSummary/DocumentDetails';
import { createSimpleNotification } from '../../store/actions/NotificationActions';
import { setCurrencyData } from '../../store/actions/deals';
import { defaultCurrency } from '../../store/reducers/deals';


const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

const GetStepContent = (step, setActiveStep) => {
    const [update, setUpdate] = useState(false)
    const [capitalResult, setCapitalResult] = useState()
    const [result, setResult] = useState()
    const [submit, setSubmit] = useState(false)
    const [cashFlowId, setCashFlowId] = useState()
    const [capId, setCapId] = useState()
    const [waterfallId, setWaterfallId] = useState()
    const [images, setImages] = useState()
    const [document, setDocument] = useState([])
    const [additionalFileProps, setAdditionalFileProps] = useState([]);
    const [imageData, setImageData] = useState()
    const [documentData, setDocumentData] = useState()
    const [hurdle, setHurdle] = useState('None');
    const [errorMessage, setErrorMessage] = useState()
    const [propertyList, setPropertyList] = useState([]);

    const dispatch = useDispatch()
    const { id } = useParams()
    let navigate = useNavigate()
    const user_id = localStorage.getItem("user_id")
    const getData = () => {
        const fetchClient = new MainClient(`/deal/api/view-add-deal/${id}/`)
        const response = fetchClient.getAPI()
        response.then(res => {
            const data = res.data
            console.log('da2', data)
            const purchase_price = data.purchase_price > 0 ? data.purchase_price : 0
            const closing_cost = data.closing_cost > 0 ? data.closing_cost : 0
            const capex = data.capex > 0 ? data.capex : 0
            const reserves = data.reserves > 0 ? data.reserves : 0
            console.log('pmt', parseInt(purchase_price) + parseInt(closing_cost) + parseInt(capex) + parseInt(reserves))
            const total = parseInt(purchase_price) + parseInt(closing_cost) + parseInt(capex) + parseInt(reserves)
            console.log('logdata8', res.data)
            setDocumentData(data.upload_document)
            setImageData(res.data.image_data)
            setProperty(res.data)
            // get currency data
            dispatch(setCurrencyData(res.data.currency || defaultCurrency))
            // get metrics data
            dispatch(addFeesBoxPurchase(res.data?.purchase_metrics?.fees_box?.data)) // added buysell fees box data into state
            dispatch(addFeesBoxBuySell(res.data?.buysell_metrics?.fees_box?.data)) // added buysell fees box data into state
            setMetrics({ purchase_price: parseInt(data.purchase_price), closing_cost: parseInt(data.closing_cost), capex: parseInt(data.capex), reserves: parseInt(data.reserves), entry_noi: data.entry_noi, stabilized_noi: data.stabilized_noi, pro_noi: data.proForma_noi, investment:data.investment, valuation: data.valuation })
            dispatch(addPurchaseData(res.data?.purchase_metrics))
            dispatch(addBuySellData(res.data?.buysell_metrics))

            console.log('deal_data', res.data?.buysell_metrics)
            // console.log('purchase_metrrics', res.data?.purchase_metrics)
            setEntryNoiSelect(data.noi_data[0])
            setStabilizedNoiSelect(data.noi_data[1])
            setProNoiSelect(data.noi_data[2])
            setTerms(data.investment_term)
            setSeniorDept({
                // sponsor_profit_share: "70",
                ...seniorDept,
                purchase_basis: parseInt(data.purchase_price),
                total_capital_basis: total,
            })
        })
    }

    const getCashflow = () => {
        const fetchClient = new MainClient(`/deal/view-deal-cash-flow/?deal_id=${id}`)
        const response = fetchClient.getAPI()
        response.then(res => {
            console.log('cashFlos', res.data)
            setOpCashFlow(res.data[0].op_cash_flow_years)
            setCpCashFlow(res.data[0].cp_cash_flow_years)
            setCashFlowId(res.data[0].id)
        })
    }
 
    // Additional Property Data
    const getAdditionPropertyInfo = ()=>{

        const fetchClient = new MainClient(`/deal/getpropertyinfodeal/${id}/`)
        const response = fetchClient.getAPI()
        
                // axios
                // .get(`http://127.0.0.1:8000/deal/getpropertyinfodeal/${id}/`)
        response
        .then((response) => {
          const data = response.data;
          const properties = data.map((item) => item.fields);
          setPropertyList(properties);
        })
        .catch((error) => {
          console.log(error);
        });
      }

    const getCapitalStack = () => {
        const fetchClient = new MainClient(`/deal/view-capital_stack/?deal_id=${id}`)
        const response = fetchClient.getAPI()
        response.then(res => {
            const data = res.data[0]
            console.log('myData', res.data)
            setSeniorDept({
                // sponsor_profit_share: "70",
                // ...seniorDept, 
                capital_stack: res.data[0].senior_debt,
                amount: res.data[0].senior_debt_amount,
            })
            setData(data.classesData)
            setDataLen(data.classesData.length)
            setCapId(data.id)
            setHurdle(res.data[0].hurdle)
        })
    }

    const getWaterfall = () => {
        const fetchClient = new MainClient(`/deal/waterfall/${id}/`)
        const response = fetchClient.getAPI()
        response.then(res => {
            console.log('errorResult', res.data)
            setResult(res.data.response)
            setErrorMessage(res.data.warning_message)
        })
    }

    console.log("Result is ",result);
    const getEquityWaterfall = () => {
        const fetchClient = new MainClient(`/deal/equity-waterfall/?deal_id=${id}`)
        const response = fetchClient.getAPI()
        response.then(res => {
            console.log('watarfalls', res.data)
            const data = res.data[0]
            setOpdata(data.op_waterfall)
            setCpdata(data.cp_waterfall)
            setWaterfallId(data.id)
        })
    }


    const getCapitalResult = () => {
        const fetchClient = new MainClient(`/deal/waterfall-capital-event/${id}/`)
        const response = fetchClient.getAPI()
        response.then(res => {
            console.log('result', res.data)
            setCapitalResult(res.data.result1)
        })
    }

    useEffect(() => {
        if (id) {
            getData()
            getAdditionPropertyInfo()
        } else {
            dispatch(addPurchaseData(null))
            dispatch(addBuySellData(null))
            dispatch(addFeesBoxBuySell(null))
            dispatch(addFeesBoxBuySell2(null))
            dispatch(addFeesBoxPurchase(null))
            dispatch(addFeesBoxPurchase2(null))
            dispatch(setCurrencyData(defaultCurrency))

            setImageData('')
            setDocumentData('')
            setDocument('')
            setProperty({
                "deal_name": "",
                "property_title": "",
                "property_type": "",
                "deal_category": "",
                "beds": "",
                "baths": "",
                "garage": "",
                "size": "",
                "address": "",
                "city": "",
                "state": "",
                "country": "",
                "units": '',
                "description": "",
                "square_feet": "",
                "investment": "",
                "valuation": "",
            })
            setMetrics({ purchase_price: '', closing_cost: '', capex: '', reserves: '', entry_noi: '', stabilized_noi: '', pro_noi: '' })
            setEntryNoiSelect({
                purchase_price: true,
                closing_cost: false,
                capex: false,
                reserves: false,
                custom: false,
            })
            setStabilizedNoiSelect({
                purchase_price: true,
                closing_cost: false,
                capex: false,
                reserves: false,
                custom: false,
            })
            setProNoiSelect({
                purchase_price: true,
                closing_cost: false,
                capex: false,
                reserves: false,
                custom: false,
            })
            setTerms()
            setSeniorDept({
                capital_stack: '',
                amount: '',
                purchase_basis: '',
                total_capital_basis: '',
            })
            setOpCashFlow([])
            setCpCashFlow([])
            setCapitalResult([])
            setOpdata([])
            setCpdata([])
            setData([{
                capital_title: 'Class A Equity Shares',
                capital_stack: '',
                equity: 0,
                LP: 0,
                Sponsor: 0,
                amount: '',
                purchase_basis: 0,
                total_capital_basis: 0,
                pref: '',
                min_equity_multiple: "0",
                irr_hurdle: "0",
                profit_share: ''
            }])
            setCapId('')
            setDataLen(1)
            setHurdle('None')
        }
        console.log("repeact")
    }, [id, update])


    // ***** property start ******* //
    const [property, setProperty] = useState({
        "deal_name": "",
        "property_title": "",
        "property_type": "",
        "deal_category": "",
        "beds": "",
        "baths": "",
        "garage": "",
        "size": "",
        "address": "",
        "city": "",
        "state": "",
        "country": "",
        "units": '',
        "description": "",
        "square_feet": "",
        "investment": "",
        "valuation": "",
        "video_link": "",
        "user": 2
    })
    const purchaseState = useSelector((state) => state.metrics.purchase);
    const buysellState = useSelector((state) => state.metrics.buysell);
    const feesboxBuySellState = useSelector((state) => state.metrics.feeBoxBuysell);
    const feesboxPurchaseState = useSelector((state) => state.metrics.feeBoxPurchase);
    const feesboxBuySellState2 = useSelector((state) => state.metrics.feeBoxBuysell2);
    const feesboxPurchaseState2 = useSelector((state) => state.metrics.feeBoxPurchase2);    
    const CurrencyState = useSelector((state) => state.deal.currency)
    const [purchaseM, setPurchaseM] = useState({
      
    })
    const [purchaseFeesBox, setPurchaseFeesBox] = useState({
      
    })
    const [purchaseFeesBox2, setPurchaseFeesBox2] = useState({
      
    })
    const [buySellM, setBuySellM] = useState({
       
    })
    const [buySellFeesbox, setBuySellFeesBox] = useState({
       
    })
    const [buySellFeesbox2, setBuySellFeesBox2] = useState({
       
    })

    useEffect(() => {
        setPurchaseM(purchaseState)
       }, [purchaseState])

    useEffect(() => {
     setBuySellM(buysellState)
    }, [buysellState])

    useEffect(() => {
        setBuySellFeesBox({data : feesboxBuySellState})
       }, [feesboxBuySellState])
    
    useEffect(() => {
    setPurchaseFeesBox({data : feesboxPurchaseState})
    }, [feesboxPurchaseState])


    useEffect(() => {
        setBuySellFeesBox2({data : feesboxBuySellState2})
       }, [feesboxBuySellState2])
    
    useEffect(() => {
    setPurchaseFeesBox2({data : feesboxPurchaseState2})
    }, [feesboxPurchaseState2])

    console.log('purchaseFeesBox', purchaseFeesBox)

    useEffect(() => {
        // getData()
        getCashflow()
        getCapitalStack()
        getEquityWaterfall()
        getWaterfall()
    }, [property.id, update])
    useEffect(() => {
        // getData()
        getCapitalResult()
    }, [result])
    console.log('newSponsor', property)
    // ***** property end ******* //


    // ***** metics start ******* //
    const [metrics, setMetrics] = useState({
        purchase_price: '',
        closing_cost: '',
        capex: '',
        reserves: '',
        entry_noi: '',
        stabilized_noi: '',
        pro_noi: '',
        entry_capbasis: '',
        stabilized_capbasis: '',
        pro_capbasis: '',
        investment: "",
        valuation: "",
    })
    const [entryNoiSelect, setEntryNoiSelect] = useState({
        purchase_price: true,
        closing_cost: false,
        capex: false,
        reserves: false,
        custom: false,
    })
    const [stabilizedNoiSelect, setStabilizedNoiSelect] = useState({
        purchase_price: true,
        closing_cost: false,
        capex: false,
        reserves: false,
        custom: false,
    })
    const [proNoiSelect, setProNoiSelect] = useState({
        purchase_price: true,
        closing_cost: false,
        capex: false,
        reserves: false,
        custom: false,
    })
    console.log('tMetics', metrics)
    const purchase_price = metrics.purchase_price > 0 ? metrics.purchase_price : 0
    const closing_cost = metrics.closing_cost > 0 ? metrics.closing_cost : 0
    const capex = metrics.capex > 0 ? metrics.capex : 0
    const reserves = metrics.reserves > 0 ? metrics.reserves : 0
    // console.log('pmt',parseInt(purchase_price)+parseInt(closing_cost)+parseInt(capex)+parseInt(reserves))
    const total = parseInt(purchase_price) + parseInt(closing_cost) + parseInt(capex) + parseInt(reserves)
    // const total = metrics.purchase_price + metrics.closing_cost + metrics.capex + metrics.reserves
    const entryNoiBasis = [(entryNoiSelect.purchase_price && metrics.purchase_price), (entryNoiSelect.closing_cost && metrics.closing_cost), (entryNoiSelect.capex && metrics.capex), (entryNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)
    const stabilizedNoiBasis = [(stabilizedNoiSelect.purchase_price && metrics.purchase_price), (stabilizedNoiSelect.closing_cost && metrics.closing_cost), (stabilizedNoiSelect.capex && metrics.capex), (stabilizedNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)
    const proNoiBasis = [(proNoiSelect.purchase_price && metrics.purchase_price), (proNoiSelect.closing_cost && metrics.closing_cost), (proNoiSelect.capex && metrics.capex), (proNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)
    // ****** metrics end  ******* //


    // ****** capital stack start  ****** //
    const [seniorDept, setSeniorDept] = useState({
        capital_title: 'seniorDept',
        capital_stack: '',
        equity: 0,
        amount: '0',
        purchase_basis: 0,
        total_capital_basis: 0,
        pref: '0',
        serviceable_pref_return: '0',
        accruable_pref_return: '0',
        min_equity_multiple: "0",
        irr_hurdle: "0",
        profit_share: '0'
    })

    const [data, setData] = useState([{
        capital_title: 'Class A Equity Shares',
        capital_stack: '',
        equity: 0,
        LP: 0,
        Sponsor: 0,
        amount: '',
        purchase_basis: 0,
        total_capital_basis: 0,
        pref: '',
        serviceable_pref_return: '',
        accruable_pref_return: '',
        min_equity_multiple: "0",
        irr_hurdle: "0",
        profit_share: ''
    }])
    console.log('logData', data)
    const [dataLen, setDataLen] = useState(1)
    console.log('dataLen', dataLen)
    const classes = ['Class A', 'Class B', 'Class C', 'Class D', 'Class E', 'Class F', 'Class G', 'Class H', 'Class I', 'CLass J']
    const sliceClasses = classes.slice(0, dataLen)
    const classString = sliceClasses.join(' > ')
    const sponsorProfitShare = data?.length > 0 && (1 - data.map(dt => {
        return parseInt(dt.profit_share)
    }).reduce((a, b) => a + b) / 100) * 100
    // ****** capital-stack end  ****** //


    // **** cashflow start ******** //

    const [opCashFlow, setOpCashFlow] = useState([])
    const [cpCashFlow, setCpCashFlow] = useState([])
    const [terms, setTerms] = useState(1)
    let T = []
    const CP = []
    const CashFlow = opCashFlow.map(dt =>
        dt.value && T.push(parseInt(dt.value))
    )

    const SeconedCashFlow = cpCashFlow.map(dt =>
        dt.value && CP.push(parseInt(dt.value))
    )

    let totalOpCashFlow = T.reduce((a, b) => a + b, 0)
    let totalCpCashFlow = CP.reduce((a, b) => a + b, 0)
    // const seniorDebtAmount = total > 0 && seniorDept.capital_stack > 0 ? ((total * parseInt(seniorDept.capital_stack)) / 100).toFixed() : 0

    //   let totalOpCashFlow = opCashFlow[0].value ? opCashFlow.reduce((a, b) => perseInt(a) + parseInt(b), 0) : 0
    //   let totalCpCashFlow = cpCashFlow[0].value ? cpCashFlow.reduce((a, b) => perseInt(a) + parseInt(b), 0) : 0

    // **** cashflow end ******** //


    // **** waterfall start ******** //

    const [Opdata, setOpdata] = useState([])
    const [Cpdata, setCpdata] = useState([])
    // console.log('opDataTest', Opdata)
    // console.log('cpDataTest', Cpdata)

    // **** waterfall end ******** //


    // ***** post data *******  //
    const buysellFeesData = buySellFeesbox ? {fees_box : buySellFeesbox}  : {}  
    const purchaseFeesData = purchaseFeesBox ? {fees_box : purchaseFeesBox}  : {}  
    const buysellFeesData2 = buySellFeesbox2 ? {fees_box2 : buySellFeesbox2}  : {}  
    const purchaseFeesData2 = purchaseFeesBox2 ? {fees_box2 : purchaseFeesBox2}  : {}
    const deal_data = {
        "total_capitalization": total,
        "deal_name": property.deal_name,
        "property_title": property.property_title,
        "address": property.address,
        "city": property.city,
        "state": property.state,
        "units": property.units,
        "purchase_price": metrics.purchase_price,
        "closing_cost": metrics.closing_cost,
        "capex": metrics.capex,
        "reserves": metrics.reserves,
        "square_feet": property.square_feet,
        "entry_noi": metrics.entry_noi,
        "stabilized_noi": metrics.stabilized_noi,
        "proForma_noi": metrics.pro_noi,
        "description": property.description,
        "investment_term": terms,
        "image_url": null,
        "property_type": property.property_type,
        "deal_category": property.deal_category,
        "beds": property.beds || 0,
        "baths": property.baths || 0,
        "garage": property.garage || 0,
        "size": property.size,
        "country": property.country,
        "investment": metrics.investment ,
        "valuation": metrics.valuation ,
        "user": user_id,
        "video_link" : property.video_link,
        "purchase_metrics":{ ...purchaseM, ...purchaseFeesData, ...purchaseFeesData2 } || {},
        "buysell_metrics": { ...buySellM, ...buysellFeesData, ...buysellFeesData2 } || {},
        "currency": CurrencyState,
        "noi_data": [
            {
                ...entryNoiSelect,
                "noi_name": "entryNOI",
                "cap_basis": entryNoiBasis,
                "cap_rate": (metrics.stabilized_noi) / entryNoiBasis,
            },
            {
                ...stabilizedNoiSelect,
                "noi_name": "stabilizedNOI",
                "cap_basis": stabilizedNoiBasis,
                "cap_rate": (metrics.stabilized_noi) / stabilizedNoiBasis,
            },
            {
                ...proNoiSelect,
                "noi_name": "proformaNOI",
                "cap_basis": proNoiBasis,
                "cap_rate": (metrics.pro_noi) / proNoiBasis
            }
        ]
    }
    // create section
    console.log('resultx',deal_data, result)
    const createCashFlow = (id) => {
        const data = {
            "deal_id": id,
            "op_cash_flow_years": opCashFlow,
            "cp_cash_flow_years": cpCashFlow,
            "op_cash_flow_total": totalOpCashFlow,
            "cp_cash_flow_total": totalCpCashFlow
        }
        console.log('cashFlow data', data)
        const fetchClient = new MainClient(`/deal/add-deal-cash-flow/`, data)
        const response = fetchClient.postAPI()
    }
    const createCapitalStack = (id) => {
        if (data?.length > 0) {
            const newData = Object.create(data)
            const cap = newData.map(dt => {
                dt.serviceable_pref_return = dt.serviceable_pref_return == '' || dt.serviceable_pref_return == null ? "0" : dt.serviceable_pref_return
                return dt
            })
            console.log('cap1', cap)
            console.log('cap2', data)
        }
        const cap_data = {
            "deal_id": id,
            "senior_debt": seniorDept.capital_stack,
            "senior_debt_amount": seniorDept.amount,
            "classesData": data,
            "sponsor_profit_share": sponsorProfitShare,
            "hurdle": hurdle
        }
        console.log("cap_data", cap_data)
        const fetchClient = new MainClient(`/deal/stack-creation/`, cap_data)
        const response = fetchClient.postAPI()
    }
    const createWaterfall = (id) => {
        const data = {
            "deal_id": id,
            "op_waterfall": Opdata,
            "cp_waterfall": Cpdata
        }
        console.log('waterfallData', data)
        const fetchClient = new MainClient(`/deal/equity-waterfall/`, data)
        const response = fetchClient.postAPI()
    }
    const imageUpload = (id, image) => {
        let form_data = new FormData()
        form_data.append('images', image, image.name)
        form_data.append('image_data', id)
        const fetchClient = new MainClient('deal/deal-image/', form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        fetchClient.postAPI()
    }
    const documentUpload = (id, file, file_detailList) => {

        const getdetail = file_detailList?.filter(f => f.path === file.path)
        const detail = getdetail.length > 0 ? getdetail[getdetail.length-1] : {}
        console.log('getdetail', getdetail)
        let form_data = new FormData()
        form_data.append('file', file, file.name)
        form_data.append('file_name', `${detail?.title || file.name}`)
        form_data.append('note', `${file.note}`)
        form_data.append('upload_document', id)
        const fetchClient = new MainClient('deal/upload-document/', form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        fetchClient.postAPI()
            .catch(err => console.log('upload', err))
    }
    const documentUpdate = (id, d_id, file) => {
        let form_data = new FormData()
        form_data.append('file', file, file.name)
        form_data.append('file_name', `${file.name}`)
        form_data.append('upload_document', id)
        const fetchClient = new MainClient(`/deal/upload-document/${d_id}/`, form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        fetchClient.putAPI()
            .catch(err => console.log('upload', err))
    }
    const imageDelete = (id) => {
        const fetchClient = new MainClient(`/deal/deal-image/${id}/`)
        const response = fetchClient.deleteAPI()
        response.then(() => {
            setUpdate(!update)
            message.success("Image successfully deleted")
        }).error(() => {
            message.success("Image can't deleted")
        })
    }
    console.log('imageData', imageData)
    const submitHandler = () => {
        setSubmit(true)
        console.log('deal_data', deal_data)
        const fetchClient = new MainClient('/deal/api/view-add-deal/', deal_data)
        const response = fetchClient.postAPI()
        response.then(res => {
            let id = res.data.id
            console.log("so id is",id);
            if (images) {
                for (let i = 0; i < images.length; i++) {
                    let image = images[i]
                    imageUpload(id, image)
                }
            }
            if (document.length > 0) {
                document.map( (doc, index) => documentUpload(id, doc,additionalFileProps))
                
            }           
        
            createCashFlow(res.data.id)
            createCapitalStack(res.data.id)
            createWaterfall(res.data.id)
            const not_data = {
                to_user : "admin",
                type : "deal_created",
                message : "Deal created Successfully",
                sub_message : 'by admin',
                sender : "admin"
            }
            dispatch(createSimpleNotification(not_data))
            setSubmit(false)

            try {
                const storedData = sessionStorage.getItem("NewPropertyData");        
                // Parse the stored data from a string to a JavaScript object
                const newData = JSON.parse(storedData);
    
                for (let i = 0; i < newData.length; i++) {
                    if (newData[i].deal === null) {
                      newData[i].deal = id;
                    }
                  
                    const dataToSend = newData[i];

                    const fetchClient = new MainClient('/deal/api/additonal_propertyinfo_deal/',dataToSend)
                    const response = fetchClient.postAPI()
                        response.then(response => {
                                console.log(response);
                            })
                            .catch(error => {
                                console.log(error);
                                message.error("There was an error adding your data. Please try again.");
                            });
                  
                    // axios.post("http://127.0.0.1:8000/deal/api/additonal_propertyinfo_deal/", dataToSend)
                    //   .then(response => {
                    //     console.log(response);
                    //   })
                    //   .catch(error => {
                    //     console.log(error);
                    //     message.error("There was an error adding your data. Please try again.");
                    //   });
                  }
                message.success("Your Data Added");
                sessionStorage.removeItem("NewPropertyData")             
            } catch (error) {
                return
            }          
            navigate(`/dashboard/add-new-deal/${res.data.id}`)
            // setActiveStep("4")
            // setUpdate(!update)
            message.success('Deal Saved!')
            setImages('')
            dispatch(fetchOpenDeal())
        })
    }
    const refreshUpdate = () => setUpdate(!update)
    // console.log("Steepr Deal Id is",id);
    // console.log("the id is ID",id);
            
  
        // sessionStorage.setItem("NewPropertyData", JSON.stringify(newData));

    // localStorage.removeItem("NewPropertyData");                


    // update section
    const updateCashFlow = () => {
        // const Id = cashFlow[0].id
        const data = {
            "deal_id": id,
            "op_cash_flow_years": opCashFlow,
            "cp_cash_flow_years": cpCashFlow,
            "op_cash_flow_total": totalOpCashFlow,
            "cp_cash_flow_total": totalCpCashFlow
        }
        console.log('cashFlowId', cashFlowId)
        const fetchClient = new MainClient(`/deal/add-deal-cash-flow/${cashFlowId}/`, data)
        const response = fetchClient.putAPI()
        response
            .then(res => {
                setOpCashFlow(res.data.op_cash_flow_years)
                setCpCashFlow(res.data.cp_cash_flow_years)
                setCashFlowId(res.data.id)
            })
            .catch(err => console.log(err))
    }
    const updateCapitalStack = () => {
        if (data?.length > 0) {
            const newData = Object.create(data)
            const cap = newData.map(dt => {
                dt.serviceable_pref_return = dt.serviceable_pref_return == '' || dt.serviceable_pref_return == null ? "0" : dt.serviceable_pref_return
                return dt
            })
            console.log('cap1', cap)
            console.log('cap2', data)
        }
        const cap_data = {
            "deal_id": id,
            "senior_debt": seniorDept.capital_stack,
            "senior_debt_amount": seniorDept.amount,
            "classesData": data,
            "sponsor_profit_share": sponsorProfitShare,
            "hurdle": hurdle
        }
        console.log("submit_cap_data", cap_data)
        const fetchClient = new MainClient(`/deal/stack-creation/${capId}/`, cap_data)
        const response = fetchClient.putAPI()
    }
    console.log("cap_data", data)
    const updateWaterfall = () => {
        const data = {
            "deal_id": id,
            "op_waterfall": Opdata,
            "cp_waterfall": Cpdata
        }
        console.log('waterfallupdate', data)
        const fetchClient = new MainClient(`/deal/equity-waterfall/${waterfallId}/`, data)
        fetchClient.putAPI()
    }
    const updateDeal = () => {
        const fetchClient = new MainClient(`/deal/api/view-add-deal/${id}/`, deal_data)
        console.log('deal_data', deal_data)
        fetchClient.putAPI()
    }

    // Additional Propert
    const AddAdditionalProperty = () => {
        try {
          const storedData = sessionStorage.getItem("NewPropertyData");
          // Parse the stored data from a string to a JavaScript object
          const newData = JSON.parse(storedData);
      
          for (let i = 0; i < newData.length; i++) {
            if (newData[i].deal === null) {
              newData[i].deal = id;
            }
      
            const dataToSend = newData[i];
      
            const fetchClient = new MainClient(
              '/deal/api/additonal_propertyinfo_deal/',
              dataToSend
            );
            const response = fetchClient.postAPI();
            response
              .then(response => {
                console.log(response);
              })
              .catch(error => {
                console.log(error);
                message.error("There was an error adding your data. Please try again.");
              });
      
            // axios.post("http://127.0.0.1:8000/deal/api/additonal_propertyinfo_deal/", dataToSend)
            //   .then(response => {
            //     console.log(response);
            //   })
            //   .catch(error => {
            //     console.log(error);
            //     message.error("There was an error adding your data. Please try again.");
            //   });
          }
          message.success("Your Data Added");
          sessionStorage.removeItem("NewPropertyData");
        } catch (error) {
          return;
        }
      };

      
    const updateHandler = () => {
        setSubmit(true)
        const fetchClient = new MainClient(`/deal/api/view-add-deal/${id}/`, deal_data)
        console.log('deal_data', deal_data)
        const response = fetchClient.putAPI()
        response.then(res => {
            console.log('updatedata', res.data)
            let id = res.data.id
            if (images) {
                for (let i = 0; i < images.length; i++) {
                    let image = images[i]
                    imageUpload(id, image)
                    

                }
            }
            if (documentData.length > 0 || document) {
                if (documentData.length > 0) {
                    // let d_id = documentData[0].id   
                    // console.log('da2_id', d_id)
                    // if (document.length > 0) {
                    //     document.map(doc => documentUpdate(id, d_id, doc))
                    // }
                    document.map( doc => documentUpload(id, doc,additionalFileProps))
                    
                }
                else {
                    // documentUpload(id, document)
                    document.map( doc => documentUpload(id, doc,additionalFileProps))
                }
            }

           
           
            AddAdditionalProperty()
            updateDeal()
            updateCashFlow()
            updateCapitalStack()
            updateWaterfall()
            setUpdate(!update)
            // message.success('Deal Update!')
            const not_data = {
                to_user : "admin",
                type : "deal_updated",
                message : "Deal updated Successfully",
                sub_message : 'by admin',
                sender : "admin"
            }
            dispatch(createSimpleNotification(not_data))
            setSubmit(false)
        })
    }

    switch (step) {
        case 0:
            return <div className='card-container'><PropertyInfo property={property} setProperty={setProperty} setImages={setImages} images={images} imageData={imageData} refreshUpdate={refreshUpdate}  updateHandler={updateHandler} submit={submit} id={id} imageDelete={imageDelete} setDocument={setDocument} docDetails={additionalFileProps} setDocDetails={setAdditionalFileProps} documentData={documentData} document={document}/></div>
        case 1:
            return <Metrics metrics={metrics} area_type={property?.area_type} setMetrics={setMetrics} units={property?.units} entryNoiSelect={entryNoiSelect} setEntryNoiSelect={setEntryNoiSelect} stabilizedNoiSelect={stabilizedNoiSelect} setStabilizedNoiSelect={setStabilizedNoiSelect} proNoiSelect={proNoiSelect} setProNoiSelect={setProNoiSelect} updateHandler={updateHandler} submit={submit} id={id} total={total} />
        case 2:
            return <div className='card-container'><CashFlows opCashFlow={opCashFlow} setOpCashFlow={setOpCashFlow} cpCashFlow={cpCashFlow} setCpCashFlow={setCpCashFlow} terms={terms} setTerms={setTerms} updateHandler={updateHandler} submit={submit} id={id} /></div>
        case 3:
            return <div>
                <div className='card-container'><CapitalStack totalCapital={total} purchasePrice={metrics.purchase_price} seniorDept={seniorDept} setSeniorDept={setSeniorDept} data={data} setData={setData} setDataLen={setDataLen} hurdle={hurdle} setHurdle={setHurdle} errorMessage={errorMessage} /></div>
                <div className='card-container'><WaterFall classString={classString} Opdata={Opdata} setOpdata={setOpdata} Cpdata={Cpdata} setCpdata={setCpdata} submitHandler={submitHandler} updateHandler={updateHandler} submit={submit} /></div>
                {result && <div className='card-container'><FinalResult data={result} /></div>}
                {/* {capitalResult && <div className='card-container border'><CashflowDetails opCashFlow={opCashFlow} setOpCashFlow={setOpCashFlow} cpCashFlow={cpCashFlow} setCpCashFlow={setCpCashFlow} terms={terms} totalOpCashFlow={totalOpCashFlow} totalCpCashFlow={totalCpCashFlow} /></div>} */}
            </div> 
        case 4:
            return <div>
                {!result && <div className='card-container d-flex justify-content-center' style={{ padding: '1rem' }}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/stackshare-ebcb9.appspot.com/o/404-not-found.png?alt=media&token=beff32fb-13d3-4d7c-9b4f-765e7f6ddddf" class="img-fluid" alt="Responsive image" />
                </div>}
                {result && <div className='card-container'><Result data={result} opData={Opdata} /></div>}
                {result && <div className='card-container'><CapitalResult data={result} cpData={Cpdata} /></div>}
                {result && <div className='card-container'><FinalResult data={result} /></div>}
                {/* {capitalResult && <div className='card-container border'><CashflowDetails opCashFlow={opCashFlow} setOpCashFlow={setOpCashFlow} cpCashFlow={cpCashFlow} setCpCashFlow={setCpCashFlow} terms={terms} totalOpCashFlow={totalOpCashFlow} totalCpCashFlow={totalCpCashFlow} /></div>} */}
            </div>
        case 5: 
            return <div>
                <div className='card-container'>
                    <PropertyDetails property={property} data={data} imageData={imageData} propertyList={propertyList} />
                </div>
                <div className='card-container'>
                    <MetricsDetails metrics={metrics} total={total} />
                </div>
                <div className='card-container'>
                    <CashflowDetails opCashFlow={opCashFlow} setOpCashFlow={setOpCashFlow} cpCashFlow={cpCashFlow} setCpCashFlow={setCpCashFlow} terms={terms} totalOpCashFlow={totalOpCashFlow} totalCpCashFlow={totalCpCashFlow} />
                </div>
                <div className='card-container'>
                    <CapitalStackDetails totalCapital={total} purchasePrice={metrics.purchase_price} seniorDept={seniorDept} setSeniorDept={setSeniorDept} data={data} setData={setData} setDataLen={setDataLen} />
                </div>
                <div className='card-container'>
                    <DocumentDetails  data={documentData} />
                </div>
                <div className='' style={{ fontFamily: 'Poppins' }}>
                    <WaterFallDetails classString={classString} Opdata={Opdata} setOpdata={setOpdata} Cpdata={Cpdata} setCpdata={setCpdata} submitHandler={submitHandler} details={result} />
                </div>

            </div>
        default:
            return "unknown step";
    }
}

export default GetStepContent
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import numberWithCommas from '../../utilities/extrafunction/commaseparate';
import MainClient from '../../utilities/MainClient';
import Graph from './Graph';
import { BsFillXCircleFill } from 'react-icons/bs';
import { InputNumber, message, Switch } from 'antd';
import numberWithOutCommas from '../../utilities/extrafunction/numberWithoutComma';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// ** MUI Components
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import AvatarGroup from '@mui/material/AvatarGroup'
import { DataGrid } from '@mui/x-data-grid'
import LinearProgress from '@mui/material/LinearProgress'
import { styled as makeStyles } from '@mui/system';
import CustomTextField from '@core/components/mui/text-field'
import styled from 'styled-components';
import { DateFormater } from '../../utilities/extrafunction/DateFormater';
import { useTheme } from '@mui/material';




const Select = styled.select`
border: none;
padding: .2rem;
border-radius: 16px;
background: ${props => props.color};
color: #FFFFFF;
cursor:pointer;
`
const Option = styled.option`
background:  #FFFFFF;
color: #000000;
`


const CapitalStack = ({
  totalCapital,
  purchasePrice,
  data,
  setData,
  seniorDept,
  setSeniorDept,
  updateHandler,
  sponsorUpdate,
  setDataLen,
  hurdle,
  setHurdle,
  errorMessage
}) => {
  // const useStyles = makeStyles({
  //   root: {
  //     '& .MuiDataGrid-root .MuiDataGrid-footerContainer': {
  //       display: 'none',
  //     },
  //   },
  // });
  // const classes = useStyles();
  const theme = useTheme()
  const tableInputStyles = { color: theme.palette.text.secondary, textAlign:'center' }
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [stackUpdate, setStackUpdate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [hideClassBtn, sethideClassBtn] = useState(false)
  // redux state
  const State = useSelector((state) => state);
  const CurrencySign = State.deal?.currency?.sign
  // console.log('stackData', data)
  const { id } = useParams();
  // const [seniorDept, setSeniorDept] = useState({
  //     capital_stack: '',
  //     amount: '',
  //     purchase_basis: '',
  //     total_capital_basis: '',
  // })

  // const [data, setData] = useState([{
  //     capital_title: 'Class A Equity Shares',
  //     capital_stack: '',
  //     equity: 0,
  //     amount: 0,
  //     purchase_basis: 0,
  //     total_capital_basis: 0,
  //     pref: '',
  //     min_equity_multiple: '',
  //     profit_share: ''
  // }])
  useEffect(() => {
    setSeniorDept((prev) => {
      const newVal = { ...prev };
      const getVal = seniorDept.amount > 0 ? numberWithCommas(seniorDept.amount) : ''
      newVal.amount = numberWithOutCommas(getVal);
      newVal.capital_stack =
        (numberWithOutCommas(getVal) * 100) / totalCapital;
      return newVal;
    });
    setStackUpdate(true);
  }, [update])

  const seniorDeptChangeHandler = (e) => {
    console.log('senior dept handler working')
    setSeniorDept((prev) => {
      const newVal = { ...prev };
      newVal.amount = numberWithOutCommas(e.target.value);
      newVal.capital_stack =
        (numberWithOutCommas(e.target.value) * 100) / totalCapital;
      return newVal;
    });
    setStackUpdate(true);
  };
  // original handler
  const changeHandler = (e) => {
    const { name, value, id } = e.target;
    const listData = data.slice();
    const newCap = listData.map((cap, index) => {
      for (var key in cap) {
        if (name === 'capital_stack' && cap.capital_title === id) {
          cap['capital_stack'] = value;
          let equity = (
            (parseFloat(value) /
              100 /
              (1 - parseFloat(seniorDept.capital_stack) / 100)) *
            100
          ).toFixed(3);
          cap['equity'] = equity;
          cap['amount'] = ((totalCapital * parseFloat(value)) / 100).toFixed();
          let lp = (cap.profit_share / equity) * 100;
          cap['Sponsor'] = lp;
          cap['LP'] = lp > 0 && 100 - lp;
          // cap["purchase_basis"] = ((parseFloat(value) / 100) + (parseFloat(seniorDept.capital_stack) / 100) * purchasePrice).toFixed(2)
          let sumStack = 0;
          if (data.length > 0) {
            // sumStack = 0
            // console.log('eqData', data)
            // const sliceData = data.slice(0, data.length)
            let l = [];
            listData.map((dt) => {
              l.push(parseFloat(dt.capital_stack));
            });
            // console.log('l', l)
            const sliceData = l.splice(0, index + 1);
            // console.log('spliceData', sliceData)
            // console.log('l', sliceData.reduce((a, b) => a + b, 0))
            sumStack = sliceData.reduce((a, b) => a + b, 0);
          }
          // console.log('sum', sumStack + seniorDept.capital_stack)
          cap['sums'] =
            parseFloat(sumStack) + parseFloat(seniorDept.capital_stack);
          cap['purchase_basis'] = (
            (parseFloat(sumStack) / 100 +
              parseFloat(seniorDept.capital_stack) / 100) *
            purchasePrice
          ).toFixed(0);
          cap['total_capital_basis'] = (
            (parseFloat(sumStack) / 100 +
              parseFloat(seniorDept.capital_stack) / 100) *
            totalCapital
          ).toFixed(0);
        } else if (name === 'profit_share' && cap.capital_title === id) {
          cap[name] = value;
          let lp = (value / cap.equity) * 100;
          cap['LP'] = (value / cap.equity) * 100;
          cap['Sponsor'] = 100 - lp;
        }
        // console.log('capName', key, name)
        else if (key === name && cap.capital_title === id) {
          cap[name] = value;

          // console.log('cap', cap.name)
        }
        // else{
        //     cap[name] = value
        // }
      }
      return cap;
    });
    setData(newCap);
    // setStackUpdate(!stackUpdate)
  };

  // modified
  const changeHandler1 = (e) => {
    const { name, value, id } = e.target;
    const listData = data.slice();
    const newCap = listData.map((cap, index) => {
      for (var key in cap) {
        if (name === 'amount' && cap.capital_title === id) {
          let amount = numberWithOutCommas(value);
          cap['amount'] = amount;
          let capitalStack = (100 * amount) / totalCapital;
          cap['capital_stack'] = capitalStack;
          let equity = (
            (parseFloat(capitalStack) /
              100 /
              (1 - parseFloat(seniorDept.capital_stack) / 100)) *
            100
          ).toFixed(3);

          cap['equity'] = equity;
          // cap["purchase_basis"] = ((parseFloat(value) / 100) + (parseFloat(seniorDept.capital_stack) / 100) * purchasePrice).toFixed(2)
          let sumStack = 0;
          if (data.length > 0) {
            // sumStack = 0
            // console.log('eqData', data)
            // const sliceData = data.slice(0, data.length)
            let l = [];
            listData.map((dt) => {
              l.push(parseFloat(dt.capital_stack));
            });
            // console.log('l', l)
            const sliceData = l.splice(0, index + 1);
            // console.log('spliceData', sliceData)
            // console.log('l', sliceData.reduce((a, b) => a + b, 0))
            sumStack = sliceData.reduce((a, b) => a + b, 0);
          }
          // console.log('sum', sumStack + seniorDept.capital_stack)
          cap['sums'] =
            parseFloat(sumStack) + parseFloat(seniorDept.capital_stack);
          cap['purchase_basis'] = (
            (parseFloat(sumStack) / 100 +
              parseFloat(seniorDept.capital_stack) / 100) *
            purchasePrice
          ).toFixed(0);
          cap['total_capital_basis'] = (
            (parseFloat(sumStack) / 100 +
              parseFloat(seniorDept.capital_stack) / 100) *
            totalCapital
          ).toFixed(0);
        } else if (name === 'profit_share' && cap.capital_title === id) {
          cap[name] = value;
          let lp = (cap.profit_share / cap.equity) * 100;
          cap['LP'] = lp;
          cap['Sponsor'] = lp && 100 - lp;
        }
        // console.log('capName', key, name)
        else if (name == 'min_equity_multiple' && cap.capital_title === id) {
          cap[name] = value;
          cap['irr_hurdle'] = "0.0"
          // console.log('cap', cap.name)
        }
        else if (name == 'irr_hurdle' && cap.capital_title === id) {
          cap[name] = value;
          cap['min_equity_multiple'] = "0.0"
          // console.log('cap', cap.name)
        }
        else if (name == 'pref' && cap.capital_title === id) {
          cap[name] = value;
          if (value > 0) {
            if (cap.serviceable_pref_return > 0) {
              cap['accruable_pref_return'] = value - cap.serviceable_pref_return
            } else {
              cap['accruable_pref_return'] = value
            }
            console.log('accurable',)
          }
          else {
            cap['accruable_pref_return'] = cap.serviceable_pref_return
          }
        }
        else if (name == 'serviceable_pref_return' && cap.capital_title === id) {
          cap[name] = value;
          if (value) {
            if (cap.pref) {
              cap['accruable_pref_return'] = cap.pref - value
            } else {
              cap['accruable_pref_return'] = value
            }
          } else {
            cap['accruable_pref_return'] = cap.pref
          }
        }
        else if (key === name && cap.capital_title === id) {
          cap[name] = value;
        }
      }
      return cap;
    });
    setData(newCap);
    // setStackUpdate(!stackUpdate)
  };
  console.log('newCap', data);

  const sponsorProfitShare =
    data &&
    (1 -
      data
        .map((dt) => {
          return parseFloat(dt.profit_share);
        })
        .reduce((a, b) => a + b) /
      100) *
    100;

  const addHandler = (e) => {
    const mt = [
      { 1: 'A' },
      { 2: 'B' },
      { 3: 'C' },
      { 4: 'D' },
      { 5: 'E' },
      { 6: 'F' },
      { 7: 'G' },
      { 8: 'H' },
      { 9: 'I' },
      { 10: 'J' },
      { 11: 'K' },
      { 12: 'L' },
      { 13: 'M' },
      { 14: 'N' },
      { 15: 'O' },
      { 16: 'P' },
      { 17: 'Q' },
      { 18: 'R' },
      { 19: 'S' },
      { 20: 'T' },
    ];
    const len = data.length;
    console.log("data is", data);
    if (len < 3 && data?.length) {  //Can add only 3 class 
      const value = Object.values(mt)[len];
      const capital_class = Object.values(value)[0];
      // const l = ...data
      const amountBYclass = data?.map(d => d.amount).reduce((a, c) => parseFloat(a) + parseFloat(c))
      const dt = {
        capital_title: `Class ${capital_class} Equity Shares`,
        capital_stack: '',
        equity: 0,
        amount: parseFloat(totalCapital) - (parseFloat(seniorDebtAmount) + parseFloat(amountBYclass)),
        purchase_basis: 0,
        total_capital_basis: 0,
        pref: '',
        min_equity_multiple: "0",
        irr_hurdle: "0",
        profit_share: '',
      }; console.log('dt', seniorDebtAmount, totalCapital, amountBYclass)
      console.log('data  0 amount', parseFloat(totalCapital) - (parseFloat(seniorDebtAmount) + parseFloat(amountBYclass)))
      const arr = [...data, ...[dt]]
      console.log('push data', dt)
      console.log('updated data', arr)

      setData(arr);
      setDataLen(arr.length);
      setUpdate(!update);

    } else {
      sethideClassBtn(true)
      message.warn("You can Add Only 3 Class")
    }

  };

  // let renData = ((parseFloat(dt.capital_stack)/100)/(1-(parseFloat(seniorDept.capital_stack)/100))*100).toFixed(2)

  // useEffect(() => {
  //     if (stackUpdate === true) {
  //         renderCapitalStack()
  //     } else {
  //         renderCapitalStack()
  //     }
  // }, [stackUpdate])

  const removeHandler = () => {
    data.pop();
    setUpdate(!update);
  };
  console.log('data', data);
  let p = [];
  let percent = [];
  let amount = [];
  const value = [
    data.map((dt) => {
      p.push(parseFloat(dt.total_capital_basis));
      amount.push(parseFloat(dt.amount));
    }),
  ];
  const totalCapitalBasis = p.reduce((a, b) => a + b, 0);
  const tatalAmount = amount.reduce((a, b) => a + b, 0);
  const seniorDebtAmount =
    totalCapital && seniorDept.capital_stack
      ? ((totalCapital * parseFloat(seniorDept.capital_stack)) / 100).toFixed()
      : 0;
  const seniorDebtAmount1 =
    totalCapital && seniorDept.amount
      ? (seniorDept.amount * 100) / totalCapital
      : 0;

  console.log('senior dept', seniorDebtAmount1, seniorDept.amount);
  const submitHandler = () => {
    setSubmit(true);
    const dt = {
      deal_id: id,
      senior_debt: seniorDept.capital_stack,
      senior_debt_amount: seniorDept.amount,
      classesData: data,
      sponsor_profit_share: sponsorProfitShare,
    };
    const postData = () => {
      const fetchClient = new MainClient(`/deal/stack-creation/`, dt);
      const response = fetchClient.postAPI();
      response.then((res) => {
        updateHandler(!sponsorUpdate);
        setSubmit(false);
        // navigate(`/dashboard/companies/${id}`)
        // updateHandler(!sponsorUpdate)
      });
    };
    postData();
    // console.log('capPostData', dt)
  };

  const capitalStackList = data.map((dt) =>
    parseFloat(dt.capital_stack > 0 ? dt.capital_stack : 0)
  );
  const equityList = data.map((dt) =>
    parseFloat(dt.equity > 0 ? dt.equity : 0)
  );
  const amountList = data.map((dt) =>
    parseFloat(dt.amount > 0 ? dt.amount : 0)
  );
  const totalCapitalStack =
    capitalStackList.reduce((a, b) => a + b) +
    parseFloat(seniorDept.capital_stack);
  const totalEquity = equityList.reduce((a, b) => a + b);
  const totalAmount =
    amountList.reduce((a, b) => a + b) +
    parseFloat(
      numberWithOutCommas(seniorDebtAmount > 0 ? seniorDebtAmount : '0')
    );

  const profitList = data.map((dt) =>
    parseFloat(dt.profit_share > 0 ? dt.profit_share : 0)
  );
  const sponsorList = data.map((dt) =>
    parseFloat(dt.Sponsor ? dt.Sponsor : 0)
  );
  const LPList = data.map((dt) =>
    parseFloat(dt.LP ? dt.LP : 0)
  );
  const totalProfitShare = profitList.reduce((a, b) => a + b, 0);
  const totalSponsor = sponsorList.reduce((a, b) => a + b);
  const totalLP = LPList.reduce((a, b) => a + b);
  console.log("totalSponsor", totalSponsor, totalLP, sponsorList, LPList)
  useEffect(() => {
    if (totalCapitalStack > 100) {
      message.error('Total capital stack sould be 100%');
    }
  }, [totalCapitalStack]);
  console.log('totalCapital', totalCapitalStack);
  console.log('dataLen', data)

  const columns = [
    {
      flex: 0.1,
      field: 'cap_stack_segment',
      minWidth: 150,
      headerName: (<>
        <p className='m-0'>Capital Stack</p>
        <p className='m-0'> Segment</p>
      </>),
      renderCell: ({ row }) => {

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='p' noWrap={false} sx={{ color: 'text.secondary', fontWeight: 500 }}>
                {row?.capital_title}
              </Typography>
            </Box>
          </Box>
        )
      }
    },
    {
      flex: 0.1,
      minWidth: 105,
      field: 'amount',
      headerName: 'Amount',
      renderCell: ({ row }) =>
        <div className='d-flex align-items-center justify-content-center'>
          {!row.seniorAmountRow ? <Typography variant='p' sx={{ color: 'text.secondary', textAlign: 'center' }}>
            <input
              placeholder='amount'
              className='form-control table-control'
              type='text'
              name='amount'
              id={row.capital_title}
              value={row.amount > 0 ? numberWithCommas(row.amount) : ''}
              onChange={changeHandler1}
              onFocusCapture={changeHandler1}
              style={tableInputStyles}
            />{' '}
          </Typography> :
            <Typography variant='p' sx={{ color: 'text.secondary', textAlign: 'center' }}>
              <input
                placeholder='amount'
                className='form-control table-control'
                type='text'
                name='amount'
                value={seniorDept.amount > 0 ? numberWithCommas(seniorDept.amount) : ''}
                onChange={seniorDeptChangeHandler}
                style={tableInputStyles}
              />
            </Typography>
          }
        </div>


    },
    {
      flex: 0.1,
      field: 'equity',
      minWidth: 100,
      sortable: false,
      headerName: 'Equity %',
      renderCell: ({ row }) => (
        row.equity && <Typography variant='p' sx={{ color: 'text.secondary' }}>
          {row.equity ? parseFloat(row.equity).toFixed(2) : (0).toFixed(2)} %

        </Typography>

      )
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: 'cap_stack_percent',
      headerName: (<>
        <p className='m-0'>Capital </p>
        <p className='m-0'>Stack %</p>
      </>),
      renderCell: ({ row }) => (


        <Typography variant='p' sx={{ color: 'text.secondary' }}>
          {row.capital_stack
            ? parseFloat(row.capital_stack).toFixed(2)
            : (0).toFixed(2)}
          %            </Typography>

      )
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: 'prefferd_percent',
      headerName: (<>
        <p className='m-0'>Preferred</p>
        <p className='m-0'> Return %</p>
      </>),
      renderCell: ({ row }) => (
        !row.seniorAmountRow && <>

          <Typography variant='p' sx={{ color: 'text.secondary' }}>
            <div className='d-flex align-items-center justify-content-center'>
              <input
                placeholder='Enter Return'
                className='form-control table-control'
                type='text'
                name='pref'
                value={row.pref}
                onChange={changeHandler1}
                id={row.capital_title}
                style={tableInputStyles}
              />{' '}
              %
            </div>
          </Typography>
        </>
      )
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: 'serviced_percent',
      headerName: 'Serviced %',
      renderCell: ({ row }) => (
        <>

          {!row.seniorAmountRow && <Typography variant='p' sx={{ color: 'text.secondary' }}>
            <div className='d-flex align-items-center justify-content-center'>
              <input
                placeholder='Enter Serviceable'
                className='form-control table-control'
                type='text'
                name='serviceable_pref_return'
                value={row.serviceable_pref_return}
                onChange={changeHandler1}
                id={row.capital_title}
                style={tableInputStyles}
              />{' '}
              %
            </div>
          </Typography>}
        </>
      )
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: 'accrued_percent',
      headerName: 'Accured %',
      renderCell: ({ row }) => (
        <>

          {!row.seniorAmountRow && <Typography variant='p' sx={{ color: 'text.secondary' }}>
            <div className='d-flex align-items-center justify-content-center'>
              <div>{row.accruable_pref_return > 0 ? row.accruable_pref_return : 0}</div>
              {' '}%
            </div>
          </Typography>}
        </>
      )
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: 'hurdle',
      headerName: (
        <>
          <span>Hurdle</span>
          <Select className="pe-0" name="hurdle" id="hurdle" >
            <Option value="Open" selected={'None'} >None</Option>
            <Option value="Pause" >Min Equity Multiple</Option>
            <Option value="Closed">IRR</Option>
          </Select>
        </>
      ),
      renderCell: ({ row }) => (
        !row.seniorAmountRow && <>
          {hurdle == 'None' &&
            <div className='d-flex align-items-center justify-content-center'>
              <input
                placeholder="Please Select Hurdle"
                className='form-control table-control'
                type='text'
                // name={hurdle === 'None' ? '' : hurdle}
                // value={row.min_equity_multiple}
                // onChange={changeHandler1}
                id={row.capital_title}
                style={tableInputStyles}
              />
            </div>}
          {hurdle == 'min_equity_multiple' &&
            <div className='d-flex align-items-center justify-content-center'>
              <input
                placeholder={hurdle === 'None' ? '' : hurdle}
                className='form-control table-control'
                type='text'
                name={hurdle === 'None' ? '' : hurdle}
                value={row.min_equity_multiple}
                onChange={changeHandler1}
                id={row.capital_title}
                style={tableInputStyles}
              />{' '}
              X
            </div>}
          {hurdle == "irr_hurdle" &&
            <div className='d-flex align-items-center justify-content-center'>
              <input
                placeholder={hurdle === 'None' ? '' : hurdle}
                className='form-control table-control'
                type='text'
                name={hurdle === 'None' ? '' : hurdle}
                value={row.irr_hurdle}
                onChange={changeHandler1}
                id={row.capital_title}
                style={tableInputStyles}
              />{' '}
              %
            </div>}
        </>
      )
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: 'profitshare_percent',
      headerName: (<>
        <p className='m-0'>Profit</p>
        <p className='m-0'>Share %</p>
      </>),
      renderCell: ({ row }) => (
        !row.seniorAmountRow && <>

          <Typography variant='p' sx={{ color: 'text.secondary' }}>
            <div className='d-flex align-items-center justify-content-center'>
              <input
                placeholder='Profit Share'
                className='form-control table-control'
                type='text'
                name='profit_share'
                onChange={changeHandler1}
                value={row.profit_share}
                id={row.capital_title}
                style={tableInputStyles}
              />{' '}
              %
            </div>            </Typography>
        </>
      )
    },

    {
      flex: 0.1,
      minWidth: 160,
      field: 'Profit Class Allocation %',
      headerName: (<>
        <p className='m-0'>Profit Class</p>
        <p className='m-0'> Allocation %</p>
      </>),
      renderCell: ({ row, index }) => (
        !row.seniorAmountRow && <>

          <Typography variant='p' sx={{ color: 'text.secondary' }}>

            {!(data.length - 1 == index && data.length > 1) ?
              <td className='text-center'>
                {row.LP ? row.LP.toFixed(2) : (0).toFixed(2)} % / {row.Sponsor ? row.Sponsor.toFixed(2) : (0).toFixed(2)} %
              </td> :
              <td className='text-center'>
                {row.LP ? row.LP.toFixed(2) : (0).toFixed(2)} %
              </td>}
            {data.length - 1 == index && data.length > 1 && (
              <span style={{ cursor: 'pointer', textAlign: 'center', padding: 0 }}>
                <BsFillXCircleFill
                  size={20}
                  color={'tomato'}
                  className='mb-1'
                  onClick={removeHandler}
                />
              </span>
            )}
          </Typography>
        </>
      )
    },
  ]

  // Function to add custom cells to each row
  const addCustomCellsToRows = (rows) => {
    return rows.map((row) => {
      return {
        ...row,
        customCell1: 'CustomValue1',
        customCell2: 'CustomValue2',
      };
    });
  };

  const newSeniorDept = {
    capital_title: 'Senior Dept',
    capital_stack: '',

    amount: '0',
    seniorAmountRow: true

  }

  return (<>


    {data && <DataGrid
      //  className={classes.root}
      autoHeight
      pagination={false}
      rows={data?.length > 0 && [...[newSeniorDept], ...data]}
      rowHeight={62}
      getRowId={(row) => row?.capital_title}
      columns={columns}
      // checkboxSelection
      pageSizeOptions={[]}
      // disableRowSelectionOnClick
      // paginationModel={paginationModel}
      // onPaginationModelChange={setPaginationModel}
      hideFooter={true}
    />}
    {!hideClassBtn ? (

      <div className='mt-2 mb-4'>
        <button
          onClick={addHandler}
          className='btn add-new-btn p-0 mt-0'
          style={{ fontSize: '.8rem' }}
          id='addittional-className'
        >
          + Add additional class
        </button>
      </div>
    ) : (
      <>

      </>
    )}

  </>)
};

export default CapitalStack;

// Import necessary components from Material-UI
import React, { useEffect, useLayoutEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  Card,
  Button,
  Menu,
  MenuItem,
  Fade,
  Box,
  Select,
  Modal,
  IconButton,
  makeStyles,
  useTheme
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import '../css/investor.css'
import { FaPlusCircle } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { Container, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import "../../assets/css/custom.css";
import { companies } from "./data";
// import AddForm2 from "./AddForm2";
import { useDispatch, useSelector } from "react-redux";
import { setAddCompany, setAddPartner, setCompany, setLimitCompany } from "../../store/actions/companies";
import MainClient from "../../utilities/MainClient";
// import { fetchInvitedSponsors } from "../../store/actions/SponsorAcrions";
import { message } from "antd";
import AddForm1 from "./AddForm1";
import { fetchInvestors } from "../../store/actions/investorActions";
import UpdateForm from "./UpdateForm";
import { MdDelete } from "react-icons/md";
import PromptModal from "../mySponsor/PromptModal";
import SelectCompanyModal from "./SelectCompanyModal";
import { assignCompanies } from "../../store/actions/investors";
import MyInvestor1 from "./MyInvestor1";
import CreateToolTip from "../newSponsor/CreateToolTip";
import { slugify } from "../../utilities/extrafunction/slugifier";
import DealModal from "./DealModal";
import { getInputStyles } from "../newSponsor/FormStyles";

// Create a component for the nested table with accordion



const toolTipsList = ['invite investor', 'delete investor', 'edit investor', 'assign to company',]

const SimpleModal = (props) => {
  return (
    <div>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Open Modal
      </Button> */}
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            '@media (max-width: 992px)': {
              width: '100%',
              overflowY:'auto',
              height:'100%'
            },
            
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={props.handleClose}
            sx={{ position: 'relative', top: '2%', left: '95%' }}
          >
            <CloseIcon />
          </IconButton>
          <AddForm1 handleAddcompany={props.handleAddcompany} handleClose={props.handleClose} />
          {/* <Button onClick={() => props.handleClose()} sx={{ mt: 2 }}>
            Cancel
          </Button> */}
        </Box>
      </Modal>
    </div>
  );
};
const SimpleModal2 = (props) => {
  return (
    <div>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Open Modal
      </Button> */}
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            '@media (max-width: 992px)': {
              width: '100%',
              overflowY:'auto',
              height:'100%'
            }
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={props.handleClose}
            sx={{ position: 'relative', top: '2%', left: '95%' }}
          >
            <CloseIcon />
          </IconButton>
          <UpdateForm data={props.updatePartHistory} handleAddcompany={props.handleAddPartner} handleClose={props.handleClose} />
          {/* <Button onClick={() => props.handleClose()} sx={{ mt: 2 }}>
            Cancel
          </Button> */}
        </Box>
      </Modal>
    </div>
  );
};
const AnimatedDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        style={{ backgroundColor: "#fff", color: "#2b4479" }}
      >
        Open Dropdown
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Fade in={Boolean(anchorEl)}>
          <div>
            <MenuItem onClick={handleClose}>Option 1</MenuItem>
            <MenuItem onClick={handleClose}>Option 2</MenuItem>
            <MenuItem onClick={handleClose}>Option 3</MenuItem>
          </div>
        </Fade>
      </Menu>
    </div>
  );
};


const AnimatedSelect = () => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(10);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLimitCompany(selectedValue));
  }, [selectedValue]);

  return (
    <div>
      <Select
        value={selectedValue}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        MenuComponent={(props) => (
          <Fade {...props} timeout={300} in={open}>
            <div>{props.children}</div>
          </Fade>
        )}
      >
        {" "}
        <MenuItem selected={true} value={10}>
          10
        </MenuItem>
        <MenuItem selected={true} value={25}>
          25
        </MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </div>
  );
};
const InviteSelect = ({ selectedValue, setSelectedValue }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('Not Invited');
  const handleChange = (e) => {
    setSelectedValue(!selectedValue);
    setValue(e.target.value)
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(setLimitCompany(selectedValue));
  // }, [selectedValue]);

  return (
    <div>
      <Select
        className="mx-1"
        value={value}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        MenuComponent={(props) => (
          <Fade {...props} timeout={300} in={open}>
            <div>{props.children}</div>
          </Fade>
        )}
      >
        {" "}
        <MenuItem selected={true} value={'Not Invited'}>
          Not Invited
        </MenuItem>
        <MenuItem selected={true} value={'Invited'}>
          Invited
        </MenuItem>

      </Select>
    </div>
  );
};
const NestedTableAccordion = ({ company, toggleDeleteModal, togglePartnerForm, handleDeletePartner, togglePartUpdate, disabledAccordions, handleAccordion, getinvestorDeals }) => {
  const theme = useTheme()
  const {palette} = theme
  const listHeaderStyle = {
    display: "flex",
    border: "1px #bfbfbf solid",
    background: palette.primary.main,
    // background: 'rgb(62,62,61)',
    // background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)",
    color: palette.primary.dark,
    height: "50px",
    width: '100%'
  };

  return (
    <>
      {toolTipsList.map(target => <CreateToolTip target={target} />)}
      <Accordion expanded={disabledAccordions.includes(company.id)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon onClick={() => handleAccordion(company.id)} />} key={company.name}>
          <List
            component="nav"
            style={{
              display: "flex",
              width: "100%",
              height: "60px",
              fontWeight: "800",
            }}
          >
            <ListItem button onClick={() => handleAccordion(company.id)}>
              <ListItemText style={{ minWidth: '9rem' }} primary={company.name} />
            </ListItem>
            <ListItem button>
              <ListItemText style={{ minWidth: '16rem' }} primary={company.user.email} />
            </ListItem>
            <ListItem button>
              <ListItemText style={{ minWidth: '9rem' }} primary={company.phone} />
            </ListItem>
            <ListItem button>
              <ListItemText style={{ minWidth: '9rem' }} primary={company.country} />
            </ListItem>
            <ListItem button>
              <ListItemText style={{ minWidth: '9rem' }} primary={company?.markets?.selected?.map(m => m.value).toString()} />
            </ListItem>
            <ListItem button>
              <ListItemText primary={company.companies.length} />
            </ListItem>
            <ListItem button onClick={() => getinvestorDeals(company.deal_ids)}>
              <ListItemText primary={company.deals_count} />
            </ListItem>
            <ListItem button>
              <ListItemText primary={company.status} />
            </ListItem>
            <ListItem  >
              <ListItemText style={{ minWidth: '100px' }} >

                <span id={slugify(toolTipsList[0])}>
                  {!company?.invited && <MyInvestor1 key={company.id} userOb={company} />}
                </span>
                <MdDelete id={slugify(toolTipsList[1])} className="mx-1" onClick={() => toggleDeleteModal(company.id)} />
                <FaRegEdit id={slugify(toolTipsList[2])} className="mx-1" onClick={() => togglePartUpdate(company)} />{" "}
                <FaPlusCircle id={slugify(toolTipsList[3])} className="mx-1" onClick={() => togglePartnerForm(company)} primary="Assign To Companies" />

              </ListItemText>
            </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          {company.companies?.length > 0 &&

            <List className="list-header main-header" component="nav" style={listHeaderStyle}>
              <ListItem >
                <ListItemText primary="Name" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Administrator" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Opportunuties" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Partners" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Formed In" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Jurisdiction" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Intial Filling Date" />
              </ListItem>
              <ListItem  >
                <Button className="m-1"
                  style={{ fontSize: '.8rem', color: '#fff', border: '.2px solid #fff' }}
                  variant="outlined"
                  color="primary" onClick={() => togglePartnerForm(company)} primary="Assign Companies" >Assign Companies</Button>
              </ListItem>
            </List>
          }
          {company.companies.map((p) => {
            return (
              <List component="nav" style={{ display: "flex", height: "60px" }}>
                <ListItem button>
                  <ListItemText primary={p.company} />
                </ListItem>
                <ListItem button>
                  <ListItemText primary={p.administrator} />
                </ListItem>
                <ListItem button>
                  <ListItemText primary={p.opportunities} />
                </ListItem>
                <ListItem button>
                  <ListItemText primary={p.partners_list.length} />
                </ListItem>
                <ListItem button>
                  <ListItemText primary={p.formed_in} />
                </ListItem>
                <ListItem button>
                  <ListItemText primary={p.jurisdiction} />
                </ListItem>
                <ListItem button>
                  <ListItemText primary={p.initial_filling_date || '12-8-23'} />
                </ListItem>
                <ListItem >
                  <ListItemText>
                    {" "}
                    {/* <span className="mx-3" onClick={() => handleDeletePartner(p.id)}>delete</span> */}
                    <FaRegEdit id={slugify(toolTipsList[4])} onClick={() => togglePartnerForm(company)} />{" "}
                  </ListItemText>
                </ListItem>
              </List>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const MyInvestor = () => {
  const theme = useTheme()
  const {palette} = theme
  const addBtnStyle = {
    color: palette.text.primary,
    border: '.2px solid #c1bebe'
  }
  
  const listHeaderStyle = {
    display: "flex",
    border: "1px #bfbfbf solid",
    background: palette.primary.main,
    // background: 'rgb(62,62,61)',
    // background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)",
    color: palette.primary.dark,
    height: "50px",
    width: '100%'
  };
  const inputStyles = getInputStyles(theme)
  const companyStateNew = useSelector(
    (state) => state.investors.investorList
  );
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [promptOpen, setPromptOpen] = useState(false);
  const [dealOpen, setDealOpen] = useState(false);


  const [investorDeals, setInvestorDeals] = useState([]);
  const [getCompanies2, setCompanies2] = useState([]);
  const [updatePartHistory, setupdatePartHistory] = useState();


  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCollections, setFilteredCollections] = useState(getCompanies2);

  const companyState = useSelector((state) => state.companies.data);
  const State = useSelector((state) => state);

  const partnerState = useSelector((state) => state.partners.data);
  const companyStateID = useSelector((state) => state.partners.companyId);

  const [selectedValue, setSelectedValue] = useState(false); // for invited investor


  console.log("companyState", companyStateNew);
  console.log("partnerState", partnerState, companyStateID);

  console.log("state", State);
  console.log("getCompanies2", getCompanies2);


  useLayoutEffect(() => {

    setCompanies2(companyStateNew)

  }, [companyStateNew])



  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpen2 = (investor) => {
    setOpen2(true);
    dispatch(setCompany(investor.id))
    const companiesArray = investor?.companies.map(company => company.id)
    console.log('companiesArray', companiesArray)
    dispatch(assignCompanies(companiesArray))

  };
  const handleOpen3 = (company) => {
    setOpen3(true);
    console.log(`handleOpen3 triggered !`)
    setupdatePartHistory(company)
    dispatch(setCompany(company.id))


  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleDealModalClose = () => {
    setDealOpen(false);
  };
  const handleAddcompany = () => {
    if (companyState) {
      addCompanies();

      handleClose();

    }

  };

  const handleAddPartner = () => {
    if (partnerState) {
      // addPartners();
      console.log(partnerState)

      handleClose2();

    }

  };
  const handPromptClose = () => {
    setPromptOpen(false)
  };

  const handPromptOpen = (companyId) => {
    setPromptOpen(true)
    dispatch(setCompany(companyId))
  };
  const addPartners = async () => {
    // if (companyStateID) {

    const fetchClient = new MainClient(`user/my-investors`, partnerState);
    const res = await fetchClient.postAPI().then(resp => {
      message.success(`investor ${partnerState.name} added successfully !`)

      dispatch(fetchInvestors());
    }).catch(err => {
      if (err.response.status === 400) {
        // alert(err.response.data.user?.email)
        console.log('400 is running', err.response)
        message.error(err.response.data.user?.email)
      }
      message.error(`getting error adding investor !`)
    })
    console.log("investor added !");

    // } else {
    //   console.log("company id not found");
    // }
    // const data = await res.data;
    // return data.reverse()
    // setCompanies([...getCompanies, ...newData]);

    setSelectedValue(false) /// go to not invited

    handleClose();
  };

  const addCompanies = async () => {
    const data = {
      // user: 20,
      user: {
        // id: 28,
        first_name: "joe2",
        last_name: "weinberger2",
        email: companyState?.email
      },
      company: companyState?.companyName,
      administrator: companyState?.admin,
      opportunities: 7,
      partners: 3,
      phone_number: companyState?.phone,
      formed_in: companyState?.formedIn,
      jurisdiction: companyState?.jurisdiction,
      // initial_filling_date: "10-02-2023",
    };
    const fetchClient = new MainClient("/investor/my-investors", data);
    const res = await fetchClient.postAPI().then(resp => {
      dispatch(fetchInvestors());
    })
    console.log(res, 'response from post company')
    // const data = await res.data;
    // return data.reverse()
    // setCompanies([...getCompanies, ...newData]);
    console.log("investor aded !");
  };
  const handleDeleteInvestor = async (company) => {

    const fetchClient = new MainClient(`user/investor-profile/delete/${companyStateID}/`);
    const res = await fetchClient.deleteAPI().then(resp => {
      message.success('investor deleted')
      dispatch(fetchInvestors());
    }).catch(err => {
      message.error('error deleting investor')
    })
    handPromptClose()

  }

  const getinvestorDeals = async (deal_ids) => {
    const fetchClient = new MainClient(`user/investor-deals`, { deal_ids: deal_ids });
    const res = await fetchClient.postAPI().then(resp => {
      if (resp.status === 200) {
        console.log(resp.data)
        setInvestorDeals(resp.data.deals)
      }
    }).catch(err => {
      message.error('error getting investor deals')
    })
    setDealOpen(true)
  }



  const handleUpdatePartner = async (id) => {

    const fetchClient = new MainClient(`user/investor-profile/${companyStateID}/`, partnerState);
    const res = await fetchClient.putAPI().then(resp => {
      message.success('investor updated')
      dispatch(fetchInvestors());
    }).catch(err => {
      message.error('error updating investor')
    })
    handleClose3()

  }
  const handleDeletePartner = async (id) => {

    const fetchClient = new MainClient(`user/investor-profile/delete/${id}/`);
    const res = await fetchClient.deleteAPI().then(resp => {
      message.success('investor deleted')
      dispatch(fetchInvestors());
    }).catch(err => {
      message.error('error deleting investor')
    })

  }
  const handleAssignCompanies = async (values) => {
    let companies = { companies: values }
    const fetchClient = new MainClient(`user/investor-profile/assign-companies/${companyStateID}/`, companies);
    const res = await fetchClient.putAPI().then(resp => {
      message.success('companies assigned')
      dispatch(fetchInvestors());
    }).catch(err => {
      message.error('error assigning companies')
    })
    handleClose2()
  }
  const companyLimit = useSelector((state) => state.companies.limit);
  useEffect(() => {
    // Function to filter collections based on searchQuery
    const filterCollections = () => {
      const filtered = getCompanies2?.filter((collection) =>
        collection?.name?.toLowerCase().includes(searchQuery.toLowerCase()) && collection?.invited === selectedValue
      );
      const filterbynumber = filtered?.slice(0, companyLimit);
      console.log(
        companyLimit,
        "filterbynumber",
        companyLimit > 0 ? filterbynumber : filtered
      );
      setFilteredCollections(filtered);
    };

    // if(displayedCollections.length === 0 && selectedValue === true) { // if invited 
    //   setSelectedValue(false)
    // }

    filterCollections();
  }, [searchQuery, getCompanies2, companyLimit, selectedValue]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [displayedCollections, setDisplayedCollections] = useState([]);
  const itemsPerPage = companyLimit;
  useEffect(() => {
    // Function to update displayed collections based on the current page
    const updateDisplayedCollections = () => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const displayed = filteredCollections?.slice(startIndex, endIndex);
      setDisplayedCollections(displayed);
    };

    // Update total pages when the collections or itemsPerPage change
    setTotalPages(Math.ceil(filteredCollections?.length / itemsPerPage));

    updateDisplayedCollections();
  }, [currentPage, filteredCollections, getCompanies2, itemsPerPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  console.log(
    "currentPage",
    currentPage,
    "totalPages",
    totalPages,
    itemsPerPage,
    displayedCollections?.length
  );
  console.log('filteredCollections', filteredCollections)
  console.log('selectedValue', selectedValue)
  const [disabledAccordions, setDisabledAccordions] = useState([]);

  const handleAccordion = (accordionId) => {
    setDisabledAccordions((prevDisabledAccordions) => {
      if (prevDisabledAccordions.includes(accordionId)) {
        return prevDisabledAccordions.filter((id) => id !== accordionId);
      } else {
        return [...prevDisabledAccordions, accordionId];
      }
    });
  };

  return (
    <div className="myinvestors">
      {/* Add other components or content as needed */}
      <Card style={{ minHeight: "4rem", padding: "0.7rem 0.7rem" }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexWrap={'wrap'}
        >
          <Col lg='3' sm='12' className="d-flex">
            <AnimatedSelect setData={filteredCollections} />
            <InviteSelect selectedValue={selectedValue} setSelectedValue={setSelectedValue} />

          </Col>
          <Col lg='9' sm='12' className="d-flex my-1">
            <Box display={"flex"}
              justifyContent={"stretch"}
              width={'100%'}
            >
              <Input
                defaultValue={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                style={{...inputStyles, ...{ padding: '15px' }}}
                className="shadow-sm w-100   mx-2 portfolio-search-btn"
                type="text"
                name="enddate"
                id="exampleSearch"
                placeholder="Search here..."
              />
            </Box>


            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>

              <Button
                onClick={handleOpen}
                className="mx-1"
                variant="outlined"
                color="primary"
                style={addBtnStyle}
              >
                Add Investors
              </Button>
            </Box>
          </Col>
        </Box>
      </Card>

      <div className="listTable mt-2">
        <Card>

          <List
            component="nav"
            className="main-header"
            style={{
              display: "flex",
              padding: "0 18px",
              fontWeight: "bolder",
              ...listHeaderStyle,
            }}
          >
            <ListItem button>
              <ListItemText style={{ minWidth: '9rem' }} primary="Name" />
            </ListItem>
            <ListItem button>
              <ListItemText style={{ minWidth: '16rem' }} primary="Email" />
            </ListItem>
            <ListItem button>
              <ListItemText style={{ minWidth: '9rem' }} primary="Phone" />
            </ListItem>
            <ListItem button>
              <ListItemText style={{ minWidth: '9rem' }} primary="Country" />
            </ListItem>
            <ListItem button>
              <ListItemText style={{ minWidth: '9rem' }} primary="Market" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Companies" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Deals" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Status" />
            </ListItem>
            <ListItem button>
              <ListItemText style={{ minWidth: '100px' }} className="mx-3" primary="Action" />

            </ListItem>
          </List>

        </Card>

        {displayedCollections?.map((company) => (
          <NestedTableAccordion
            disabledAccordions={disabledAccordions}
            handleAccordion={handleAccordion}
            togglePartnerForm={handleOpen2}
            togglePartUpdate={handleOpen3}
            toggleDeleteModal={handPromptOpen}
            getinvestorDeals={getinvestorDeals}
            company={company}
            // handleDeleteComapny={handleDeleteInvestor}
            handleAddPartner={addPartners}
            handleDeletePartner={handleDeletePartner}
          // handleUpdatePartner={handleUpdatePartner}
          />
        ))}
      </div>
      <div>
        {/* Pagination controls */}
        {displayedCollections?.length > 0 ? <div className="my-1 py-1">
          <Button
            variant="outlined"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="mx-1">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            variant="outlined"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div> : <h2 className="text-center mt-5 text-secondary">No Investors Yet ...</h2>}
      </div>

      <SimpleModal
        open={open}
        handleClose={handleClose}
        handleAddcompany={addPartners}
      />
      <SelectCompanyModal
        open={open2}
        handleClose={handleClose2}
        handleAssignCompanies={handleAssignCompanies}
      />
      <SimpleModal2
        open={open3}
        updatePartHistory={updatePartHistory}
        handleClose={handleClose3}
        handleAddPartner={handleUpdatePartner}
      />

      <PromptModal
        open={promptOpen}
        handleClose={handPromptClose}
        handleConfirm={handleDeleteInvestor}
        modalContent={'are you sure to delete this investot ?'}
      />
      <DealModal open={dealOpen} handleClose={handleDealModalClose} data={investorDeals} />

      {/* Add more accordions or components as needed */}
    </div>

  );
};

export default MyInvestor;

// ** React Imports
import { useState } from 'react'

// ** Next Imports
// import Link from 'next/link'

// ** MUI Components
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import InputAdornment from '@mui/material/InputAdornment'
import MuiFormControlLabel from '@mui/material/FormControlLabel'

// ** Custom Component Import
import CustomTextField from 'src/@core/components/mui/text-field'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
// import { yupResolver } from '@hookform/resolvers/yup'

// ** Hooks
// import { useAuth } from 'src/hooks/useAuth'
import useBgColor from '@core/hooks/useBgColor'
import { useSettings } from '@core/hooks/useSettings'

// ** Configs
import themeConfig from 'src/configs/themeConfig'

// ** Layout Import
import BlankLayout from '@core/layouts/BlankLayout'
import { Link } from '@mui/material'

import BuildignImages from '../../components/authentication/img/side-img2.svg'
import Logo from '../../assets/logos//gulfstream2.png'
import MblueLogo from '../../assets/logos/gulfstream.jpeg';

// ** Demo Imports
import FooterIllustrationsV2 from './FooterIllustration'
import Common from '../authentication/Common'
import AuthClient from '../../utilities/AuthClient'
import { useNavigate, useParams } from 'react-router-dom'

// ** Styled Components
const LoginIllustration = styled('img')(({ theme }) => ({
  zIndex: 2,
  maxHeight: 480,
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(12),
  [theme.breakpoints.down(1540)]: {
    maxHeight: 450
  },
  [theme.breakpoints.down('lg')]: {
    maxHeight: 400
  }
}))

const RightWrapper = styled(Box)(({ theme }) => ({
  margin : theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: 320
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 700
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 950
  }
}))

const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: `${theme.palette.primary.main} !important`
}))

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    color: theme.palette.text.secondary
  }
}))

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(5).required()
})

const defaultValues = {
  password: '',
  email: ''
}

const ResetPassword = () => {
  const [rememberMe, setRememberMe] = useState(true)
  const [showPassword, setShowPassword] = useState(false)

  // ** Hooks
  const auth = {}
  const theme = useTheme()
  const bgColors = useBgColor()
  const { settings } = useSettings()
  const hidden = useMediaQuery(theme.breakpoints.down('md'))

  // ** Vars
  const { skin } = settings

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    // resolver: yupResolver(schema)
  })

  const { uid, token } = useParams()
    const navigate = useNavigate()
  const onSubmit = async (data) => {

        // e.preventDefault()
        console.log(data)
        const dt = {
    		"password": data.password,
    		"token": token,
    		"uidb64": uid
	   }
        const fetchClient = new AuthClient('/user/password-reset-complete', dt)
        await fetchClient.patchAPI()
            .then(res => {
                navigate('/login')
            })
            .catch(err => {
                console.log("err",(JSON.parse(err.request.response)).error[0])
            })
          }


  const imageSource = skin === 'bordered' ? 'auth-v2-login-illustration-bordered' : 'auth-v2-login-illustration'

  return (
    <div className="row vh-height-100">

    <Box className='content-right' sx={{ backgroundColor: 'background.paper', display:'flex' }}>
      {!hidden ? (
        // <Box
        //   sx={{
        //     flex: 1,
        //     // display: 'flex',
        //     position: 'relative',
        //     alignItems: 'center',
        //     borderRadius: '20px',
        //     justifyContent: 'center',
        //     backgroundColor: 'customColors.bodyBg',
        //     width : '100%',
        //     // margin: theme => theme.spacing(8, 0, 8, 8)
        //   }}
        // >
        <Common />

        
      ) : null}
      <RightWrapper>
        <Box
          sx={{
            p: [7, 10],
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box sx={{ width: '100%', maxWidth: 620 }}>
          {/* <img src={MblueLogo} style={{ maxWidth: "40px", borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} /> */}

            
            <Box sx={{ my: 4 }}>
              <Typography variant={hidden ? 'h4' : 'h3'} sx={{ mb: 1.5 }}>
              {themeConfig.templateName}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Please create your new password !
              </Typography>
            </Box>
            {/* <Alert icon={false} sx={{ py: 3, mb: 6, ...bgColors.primaryLight, '& .MuiAlert-message': { p: 0 } }}>
              <Typography variant='body2' sx={{ mb: 2, color: 'primary.main' }}>
                Admin: <strong>admin@vuexy.com</strong> / Pass: <strong>admin</strong>
              </Typography>
              <Typography variant='body2' sx={{ color: 'primary.main' }}>
                Client: <strong>client@vuexy.com</strong> / Pass: <strong>client</strong>
              </Typography>
            </Alert> */}
            <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mb: 4 }}>
                {/* <Controller
                  name='email'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <CustomTextField
                      fullWidth
                      autoFocus
                      label='Email'
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder='Enter your login email'
                      error={Boolean(errors.email)}
                      {...(errors.email && { helperText: errors.email.message })}
                    />
                  )}
                /> */}
              </Box>
              <Box sx={{ mb: 1.5 }}>
                <Controller
                  name='password'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <CustomTextField
                      fullWidth
                      value={value}
                      onBlur={onBlur}
                      label='New Password'
                      placeholder='Enter your password'
                      onChange={onChange}
                      id='auth-login-v2-password'
                      error={Boolean(errors.password)}
                      {...(errors.password && { helperText: errors.password.message })}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              edge='end'
                              onMouseDown={e => e.preventDefault()}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <Icon fontSize='1.25rem' icon={showPassword ? 'tabler:eye' : 'tabler:eye-off'} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  mb: 1.75,
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >

              </Box>
              <Button fullWidth type='submit' variant='contained' sx={{ mb: 4 }}>
                Submit
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                <Typography sx={{ color: 'text.secondary', mr: 2 }}>New on our platform?</Typography>
                <Typography href='/register' component={LinkStyled}>
                  Create an account
                </Typography>
              </Box>
              
            </form>
          </Box>
        </Box>
      </RightWrapper>
    </Box>
    </div>
  )
}
ResetPassword.getLayout = page => <BlankLayout>{page}</BlankLayout>
ResetPassword.guestGuard = true

export default ResetPassword

export const UPDATE_DEAL_CATEGORY = 'UPDATE_DEAL_CATEGORY'
export const SET_METRICS_STEP = 'SET_METRICS_STEP'

export const UPDATE_FILE_DETAILS = 'UPDATE_FILE_DETAILS'
export const SET_CURRENCY = 'SET_CURRENCY'


export const updateDealCategory = (category) => ({
    type: UPDATE_DEAL_CATEGORY,
    payload: category
  })

  export const setMetricStep = (category) => ({
    type: SET_METRICS_STEP,
    payload: category
  })  

  export const updateFileDetails = (payload) => ({
    type: UPDATE_FILE_DETAILS,
    payload: payload
  })  
  export const setCurrencyData = (payload) => ({
    type: SET_CURRENCY,
    payload:payload
  })
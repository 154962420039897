import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import MainClient from '../../utilities/MainClient'
import ProfileDetails from './ProfileDetails'
import ProfileImage from './ProfileImage'
import ProtfolioDetails from './ProtfolioDetails'

const SponsorProfileDetails = () => {
  const [update, setUpdate] = useState(false)
  const { id } = useParams()
  const [company, setCompany] = useState()
  const [data, setData] = useState({
    "user": {
      "first_name": "",
      "last_name": "",
      "email": ""
    },
    "company": "",
    "company_address": "",
    "country": "",
    "sponsor_image": "",
    "phone_number": ""
  })
  const users = useSelector(state => state.user.userData)
  useEffect(() => {
    const getCompany = (userId) => {
      const fetchClient = new MainClient(`/user/get-company-profile-data/${userId}`)
      const response = fetchClient.getAPI()
      response.then(res => {
        console.log('company', res.data.data[0])
        setCompany(res.data.data[0])
      })
    }
    const getData = () => {
      const fetchClient = new MainClient(`/investor/sponsor-profile/${id}/`)
      const response = fetchClient.getAPI()
      response.then(res => {
        console.log('responseUser', res.data.user)
        getCompany(res.data.user.id)
        setData(res.data)
      })
    }
    getData()
  }, [users])


  console.log('sposorData', users)
  return (
    <div class="mt-3">
      <ProfileImage data={data}  />
      <ProtfolioDetails id={data?.user?.id}/>
      <ProfileDetails data={data} company={company}/>
    </div>

  )
}

export default SponsorProfileDetails
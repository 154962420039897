import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsArrowDown, BsFillXCircleFill } from "react-icons/bs";
import { useTheme } from '@mui/material';
import { FormLabel } from './FormElements';
import { getInputStyles } from './FormStyles';

const WaterFall = ({ updateHandler, sponsorUpdate, classString, Opdata, setOpdata, Cpdata, setCpdata, submitHandler, submit }) => {
    // const OpTier = ['Pref Return', 'Return of Outstanding Capital', 'Pro Rata : Initial Equity %', 'Pro Rata : Initial Equity %', 'Pro Rata Split :  Pro Rata Split : Profit Share Equity %']
    const [selectedOpData, setSelectedOpData] = useState()
    const [update, setUpdate] = useState(false)
    const [selectedCpData, setSelectedCpData] = useState()

    const { id } = useParams()
    const theme = useTheme()
    const inputStyles = getInputStyles(theme)
    const matchOpPrefandCapital = Opdata.filter(dt => Object.values(dt).includes('Preferred Return & Return of Capital'))
    const matchOpPref = Opdata.filter(dt => Object.values(dt).includes('Preferred Return'))
    const matchOPCapital = Opdata.filter(dt => Object.values(dt).includes('Return of Capital'))
    const matchOpPro = Opdata.filter(dt => Object.values(dt).includes('Initial Equity %'))
    const matchOpProfit = Opdata.filter(dt => Object.values(dt).includes('Profit Share %'))

    const matchCpPreferredandCapital = Cpdata.filter(dt => Object.values(dt).includes('Preferred Return & Return of Capital'))
    const matchCpPreferred = Cpdata.filter(dt => Object.values(dt).includes('Preferred Return'))
    const matchCpCapital = Cpdata.filter(dt => Object.values(dt).includes('Return of Capital'))
    const matchCpHurdle = Cpdata.filter(dt => Object.values(dt).includes('EM/IRR Hurdle'))
    const matchCpPariInitial = Cpdata.filter(dt => Object.values(dt).includes('Initial Equity %'))
    const matchCpPariProfit = Cpdata.filter(dt => Object.values(dt).includes('Profit Share %'))

    const opChangeHandler = (e) => {
        const { name, value } = e.target
        setSelectedOpData({ ...selectedOpData, [name]: value })
    }
    const opAddHandler = (e) => {
        if (selectedOpData.tier == 'Profit Share %' || selectedOpData.tier == 'Initial Equity %') {
            Opdata.push({ classes: '', tier: selectedOpData.tier })
        } else {
            Opdata.push(selectedOpData)
        }
        // setSelectedOpData({ classes: classString, tier: OpTier[Opdata.length] })    
        setUpdate(!update)

    }
    console.log('opData', Opdata)
    console.log('classString', classString)
    console.log('selectedOpData', selectedOpData)

    const opRemoveHandler = () => {
        Opdata.pop()
        setUpdate(!update)
    }

    const cpChangeHandler = (e) => {
        const { name, value } = e.target
        setSelectedCpData({ ...selectedCpData, [name]: value })
    }
    const cpAddHandler = (e) => {
        if (selectedCpData.tier == 'Profit Share %' || selectedCpData.tier == 'Initial Equity %') {
            Cpdata.push({ classes: '', tier: selectedCpData.tier })
        } else {
            Cpdata.push(selectedCpData)
        }
        setUpdate(!update)
    }
    const cpRemoveHandler = () => {
        Cpdata.pop()
        setUpdate(!update)
        console.log('rmc', Cpdata)
    }

    const operatingCashFlow = () => Opdata ? Opdata.map((dt, index) => {
        return (
            <div >
                <div id="operatingCashFlow">
                    <div class='opeClass'>
                        <div class="cash-div">
                            {dt.classes != '' ?
                                <h4 class="fs-14 fw-4 text-black">{dt.classes}</h4>
                                : <h4 className="fs-14 fw-4" style={{ opacity: 0.0 }}>empty class</h4>}
                            <h5 class="fs-12 text-muted">{dt.tier}</h5>
                        </div>
                        {Opdata.length === index + 1 ? <div></div> :
                            <div class="text-center my-2">
                                <BsArrowDown size={22} className="text-muted" />
                                {/* <img src={ArrowDown} alt="" /> */}

                            </div>}
                    </div>
                </div>
                {Opdata.length === index + 1 ?
                    <button class='btn cross-btn' id="removeCashFlow" onClick={opRemoveHandler}>
                        {/* <img src={Cross} alt="" /> */}
                        {/* <AiOutlineCloseCircle className='bg-danger text-white rounded-circle' /> */}
                        <BsFillXCircleFill size={17} color={"tomato"} />
                    </button> : <div></div>}
            </div>
        )
    }) : []

    const capitalCashFlow = () => Cpdata ? Cpdata.map((dt, index) => {
        console.log('classes', Cpdata)
        return (
            <div style={{ position: 'relative' }}>
                <div id="operatingCashFlow">
                    <div className='opeClass'>
                        <div className="cash-div">
                            {dt.classes != '' ? <h4 className="fs-14 fw-4 text-black">{dt.classes}</h4> :
                                <h4 className="fs-14 fw-4" style={{ opacity: 0.0 }}>empty class</h4>}
                            <h5 className="fs-12 text-muted">{dt.tier}</h5>
                        </div>
                        {Cpdata.length === index + 1 ? <div></div> :
                            <div className="text-center my-2">
                                {/* <img src={ArrowDown} alt="" /> */}
                                <BsArrowDown size={22} className="text-muted" />
                            </div>}
                    </div>
                </div>
                <div style={{ position: "absolute", right: "-1.4rem" }}>
                    {Cpdata.length === index + 1 ?
                        <button class='btn cross-btn' id="removeCashFlow" onClick={cpRemoveHandler}>
                            {/* <AiOutlineCloseCircle className='bg-danger text-white rounded-circle' /> */}
                            <BsFillXCircleFill size={17} color={"tomato"} />
                        </button> : <div></div>}
                </div>
            </div>
        )
    }) : []

    useEffect(() => {
        operatingCashFlow()
        capitalCashFlow()
    }, [update])

    return (
        <div>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="">
                        <div class="card-body">
                            <div class='Opdata-header'>
                                <FormLabel theme={theme}>Waterfall</FormLabel>
                            </div>
                            <div class="Opdata-body mt-2">
                                <div class="row mb-3">
                                    <div class="col-sm-6">
                                        <div class="row g-3">
                                            <div class="col-12">
                                                <label class="form-label" for="classA">Operating Cash Flow
                                                </label>
                                                <select style={inputStyles} class="form-select" id="classA" name="classes" onChange={opChangeHandler} >
                                                    <option selected value=''>Select Classes</option>
                                                    <option value={classString}>{classString}</option>
                                                    <option value="Pari Passu / All Classes">Pari Passu / All Classes</option>
                                                </select>
                                            </div>
                                            <div class="col-12">
                                                <select style={inputStyles} class="form-select" id="classAA" name="tier" onChange={opChangeHandler}>
                                                    <option selected>Select Tier</option>
                                                    {matchOpPrefandCapital.length === 0 && matchOpPref.length == 0 && matchOPCapital.length == 0 && matchOpProfit.length == 0 && matchOpPro.length == 0 && <option value="Preferred Return & Return of Capital">Preferred Return & Return of Capital</option>}
                                                    {/* {matchOpPref.length == 0 && matchOpPrefandCapital.length === 0 && matchOpProfit.length == 0 && matchOpPro.length == 0 && <option value="Preferred Return">Preferred Return
                                                    </option>} */}
                                                    {/* {matchOPCapital.length == 0 && matchOpPrefandCapital.length === 0 && matchOpProfit.length == 0 && matchOpPro.length == 0 && <option value="Return of Capital">Return of Capital</option>} */}
                                                    {matchOpProfit.length == 0 && matchOpPro.length == 0 && <option value="Initial Equity %">Initial Equity %</option>}
                                                    {matchOpProfit.length == 0 && matchOpPro.length == 0 && <option value="Profit Share %">Profit Share %</option>}
                                                </select>
                                            </div>
                                            <div class="col-12 add_newdeal">
                                                <span class="badge rounded-pill bg-primary" style={{ fontSize: "1rem", marginLeft: ".4rem", cursor: "pointer" }} onClick={opAddHandler}>+ Add Waterfall Tier</span>
                                                {/* <button class="btn add-new-btn mt-0 p-0" id="addClass1" onClick={opAddHandler}>+ Add Waterfall Tier</button> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row g-3">
                                            <div class="col-12">
                                                <label class="form-label" for="capitalEvents">Capital Events

                                                </label>
                                                <select style={inputStyles} class="form-select" id="capitalEvents" name="classes" onChange={cpChangeHandler}>
                                                    <option selected value=''>Select Classes</option>
                                                    <option value={classString}>{classString}</option>
                                                    <option>Pari Passu / All Classes</option>
                                                </select>
                                            </div>
                                            <div class="col-12">
                                                <select style={inputStyles} class="form-select" id="classBB" name="tier" onChange={cpChangeHandler}>
                                                    <option selected>Select Tier</option>
                                                    {/* {matchCpPref.length === 0 && matchCpPreferredandCapital.length === 0 && matchCpHurdle.length === 0 &&
                                                        <option value="Return of Outstanding Pref and Capital">Return of Outstanding Pref and Capital
                                                        </option>} */}
                                                    {matchCpPreferredandCapital.length === 0 && matchCpPreferred.length === 0 && matchCpHurdle.length === 0 && matchCpCapital.length === 0 && matchCpPariInitial.length === 0 && matchCpPariProfit.length === 0 &&
                                                        <option value="Preferred Return & Return of Capital">Preferred Return & Return of Capital
                                                        </option>}
                                                    {/* {matchCpPreferred.length === 0 && matchCpPreferredandCapital.length === 0 && matchCpHurdle.length === 0 && matchCpPariInitial.length === 0 && matchCpPariProfit.length === 0 && <option value="Preferred Return">Preferred Return</option>}
                                                    {matchCpCapital.length === 0 && matchCpPreferredandCapital.length === 0 && matchCpHurdle.length === 0 && matchCpPariInitial.length === 0 && matchCpPariProfit.length === 0 && <option value="Return of Capital">Return of Capital</option>} */}
                                                    {matchCpHurdle.length === 0 && matchCpPariInitial.length === 0 && matchCpPariProfit.length === 0 && <option value="EM/IRR Hurdle">EM/IRR Hurdle</option>}
                                                    {/* {matchCpHurdle.length === 0 && matchCpPariProfit.length === 0 && matchCpPariInitial.length === 0 &&
                                                        (matchCpPreferred.length > 0 && matchCpCapital.length > 0 || matchCpPreferredandCapital.length > 0 ?
                                                            <option value="EM/IRR Hurdle">EM/IRR Hurdle
                                                            </option> :
                                                            <option value="EM/IRR Hurdle" disabled>EM/IRR Hurdle
                                                            </option>
                                                        )} */}
                                                    {matchCpPariInitial.length === 0 && matchCpPariProfit.length === 0 && <option value="Initial Equity %">Initial Equity %
                                                    </option>}
                                                    {matchCpPariProfit.length === 0 && matchCpPariInitial.length === 0 && <option value="Profit Share %">Profit Share %</option>}
                                                </select>
                                            </div>
                                            <div className="col-12">
                                                <span class="badge rounded-pill bg-primary" style={{ fontSize: "1rem", marginLeft: ".4rem", cursor: "pointer" }} onClick={cpAddHandler}>+ Add Waterfall Tier</span>
                                                {/* <button className="btn add-new-btn mt-0 p-0 font-weight-bold" id='addwater' onClick={cpAddHandler}>+ Add Waterfall Tier</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider"></div>
                                <div class="row gx-5 mt-4 mb-3">
                                    <div class="col-sm-6 position-relative">
                                        <FormLabel theme={theme}>1:- Operating Cash Flow</FormLabel>
                                        {operatingCashFlow()}
                                    </div>
                                    <div class="col-sm-6">
                                        <FormLabel theme={theme}>2:- Capital Event Cash Flow</FormLabel>
                                        <div id="capitalCashFlow">
                                            {capitalCashFlow()}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row m-3 align-items-center justify-content-end">
                            {!id ?
                                <div class="col-auto ">
                                    {submit ?
                                        <button class="fw-6 ms-1 save-btn" type="button" disabled style={{ background: "rgb(62,62,61)" }}>
                                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            Saving...
                                        </button> :
                                        <button class="fs-5 save-btn rounded" onClick={submitHandler} style={{ background: "rgb(62,62,61)" }}>Save</button>}
                                </div> :
                                <div class="col-auto ">
                                    {submit ?
                                        <button class="btn ms-1 save-btn" type="button" disabled style={{ background: "rgb(62,62,61)" }}>
                                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            Update...
                                        </button> :
                                        <button class="btn btn-success save-btn" onClick={updateHandler} style={{ background: "rgb(62,62,61)" }}>Update</button>}
                                </div>

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WaterFall
import { Spin, message } from "antd"
import { useState } from "react"
import { fetchNotification } from "../../store/actions/NotificationActions"
import { useDispatch } from "react-redux"
import { inviteInvestor } from "../../store/actions/investorActions"

const InvestorTable = ({ dt, index, invitationHandler, singleSelectedHandler }) => {
    const dispatch = useDispatch()
    const [submit, setSubmit] = useState(false)
    const [submit1, setSubmit1] = useState(false)


    const inviteInvestertoVerify = (investor) => {
        const data = {
            investor_name: investor?.investor_name,
            email: investor?.email 
        }
        if (data.email) {
            // dispatch(inviteInvestor(data))
            setSubmit1(true)
            invitationHandler(data.investor_name, data.email)
            message.success('Success - request sent to investor via email')
        } else {
            message.error('Error - investor email not found')
        }
    }
    const submitHandler = (user_id,name, email,deal_id) => {
        setSubmit(true)
        invitationHandler(name, email)
        const dataToSend = {
            from_user: 'admin',
            to_user:email,
            type: 'new_offerings'
        };
        dispatch(fetchNotification(dataToSend, user_id, 'investor'))
        setTimeout(() => { setSubmit(false) }, 2000);
    }
    return (
        <tr>
            <td className="text-center">
                {dt.invite_type == 'invite' &&
                    <div class="form-check">
                        {dt.isCheck ?
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" checked onChange={(e) => singleSelectedHandler(e, dt.email)} /> :
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" onChange={(e) => singleSelectedHandler(e, dt.email)} />}
                    </div>}
            </td>
            <td>{index + 1}</td>
            <td className="text-center">{dt.investor_name}</td>
            <td className="text-center">{dt.phone_number}</td>
            <td className="text-center">{dt.email}</td>
            <td className="text-center">{dt.markets?.selected?.map(m => m.value)?.toString()}</td>
            <td className="text-center">{dt.country}</td>
            {/* <td className="text-center">{dt.verified ? <span className="text-success fw-700">verified</span> : 
            submit1 ? <button className="invite-btn text-capitalize text-mute ">requested</button> : <button onClick={() => inviteInvestertoVerify(dt)} className="invite-btn text-capitalize text-mute ">request</button> 
            }</td> */}

            <td className="text-center">
                {submit ? <button className="invite-btn text-capitalize text-mute">inviting..</button> :
                    dt.invite_type == "invited" ?
                        <button className="invite-btn bg-success text-capitalize">invited</button> :
                        <button className="invite-btn text-capitalize" onClick={() => submitHandler(dt.user_id,dt.investor_name, dt.email, dt.id)}>Invite</button>}
            </td>
        </tr>
    )
}

export default InvestorTable
export const ASSIGN_COMPANIES = 'ASSIGN_COMPANIES'
export const GET_INVESTOR_DEAL = 'GET_INVESTOR_DEAL'

export const assignCompanies = (companies) => ({
    type: ASSIGN_COMPANIES,
    payload: companies
  })

export const getInvestorDealsById = (deal_idList) => ({
    type: GET_INVESTOR_DEAL,
    payload: deal_idList
  })

  
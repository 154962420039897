import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { getInputStyles } from '../FormStyles';
import { useTheme } from '@mui/material';

function FeesForm({ formData, handleChange, investment }) {
  const [localFormData, setLocalFormData] = useState(formData);
  const theme = useTheme()
  const inputStyles = getInputStyles(theme)
  const error = {
    cost_percent: 'Cost(%) should be lower or equal to 100%',
  }
  useEffect(() => {
    const calculatedCost = investment * localFormData?.cost_percent;
    if (!isNaN(calculatedCost)) {
      setLocalFormData((prevFormData) => ({
        ...prevFormData,
        cost: (calculatedCost.toFixed(2) / 100),
      }));
    }
  }, [investment, localFormData?.cost_percent]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    handleChange(e); // Call the parent handleChange function to update the main state
  };
  const handleBlurChange = (e) => {

    const calculatedCostPercent = localFormData?.cost * 100 / investment

    if (calculatedCostPercent !== localFormData?.cost_percent && calculatedCostPercent || !isNaN(calculatedCostPercent)) {
      const newPercent = calculatedCostPercent.toFixed(2)
      setLocalFormData({
        ...localFormData,
        cost_percent: parseFloat(newPercent).toFixed(2),
      });
      handleChange(e);

    }
  }
  return (
    <>
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="name">Description</Label>
              <Input
                style={inputStyles}
                type="text"
                name="desc"
                id="name"
                placeholder="Enter Fees Description"
                value={localFormData?.desc}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="cost">Cost</Label>
              <NumberFormat
                style={inputStyles}
                type="text"
                className="form-control smaller-input"
                name="cost"
                id="cost"
                placeholder="Enter Cost"
                value={localFormData?.cost == 0 ? '' : parseFloat(localFormData?.cost).toFixed(2)}
                thousandSeparator={true}
                // onBlur={handleBlurChange}
                onValueChange={(values) =>
                  handleInputChange({
                    target: { name: 'cost', value: values.value },
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="phone">Cost %</Label>
              <NumberFormat
                style={inputStyles}
                type="text"
                className="form-control smaller-input"
                name="cost_percent"
                id="phone"
                placeholder="Enter Cost%"
                value={parseFloat(localFormData?.cost_percent).toFixed(2)}
                onFocus={handleBlurChange}
                onValueChange={(values) =>
                  handleInputChange({
                    target: { name: 'cost_percent', value: values.value },
                  })
                }
              />

            </FormGroup>
            {+localFormData?.cost_percent > 100 && <p className='mt-50 text-danger'>{error.cost_percent}</p>}

          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FeesForm;

import { BsFillChatDotsFill } from "react-icons/bs";
import logo from '../../assets/images/logo.png'
import { Sender, micSvg, documentSvg } from "../../assets/icons/Icons";
import { capitalize, Input } from '@material-ui/core'
import numberWithCommas from "../../utilities/extrafunction/commaseparate";
import NumberFormat from "react-number-format";
import MainClient from "../../utilities/MainClient";
import { useState } from "react";
import numberWithOutCommas from "../../utilities/extrafunction/numberWithoutComma";
import { useEffect } from "react";
import { message, Spin, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom'
import InvestorTable from '../DealSummary/InvestorTable'
import { useDispatch } from "react-redux";
import { fetchNotification } from "../../store/actions/NotificationActions";

const InvestorList = ({ data, setUpdate, update }) => {
    const [amount, setAmount] = useState(0)
    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(false)
    const [check, setCheck] = useState(false)
    const [filter, setFilter] = useState(false)
    const [investor, setInvestor] = useState([])
    const [updates, setUpdates] = useState(false)
    const { id } = useParams()
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchClient = new MainClient(`/investor/deal-invitation-send/${id}/`)
        const response = fetchClient.getAPI()
        response.then(res => {
            console.log("investor", res.data.results)
            setInvestor(res.data.results)
        }
        ).catch(err => console.log(err))
    }, [updates])
    const invitationHandler = (name, email) => {
        const body = {
            "investor_email": email,
            "investor_name": name
        }
        const fetchClient = new MainClient(`/investor/deal-invitation-send/${id}/`, body)
        const response = fetchClient.postAPI()
        response.then(res => {
            console.log("investorPost", res.data)
            setUpdates(!updates)
        }
        ).catch(err => console.log(err))
    }
    const singleSelectedHandler = (e, id) => {
        console.log('singleSelect', e.target.value, id)
        let inv = investor.slice()
        const newInvestor = inv.map(dt => {
            for (var key in inv) {
                if (dt.email == id) {
                    dt["isCheck"] = e.target.checked
                }
            }
            return dt
        })
        console.log("newInvestor", newInvestor)
        setInvestor(newInvestor)
    }
    const myInvestor = investor.length > 0 ? investor.map((dt, index) =>
        <InvestorTable  dt={dt} index={index} key={dt.email} invitationHandler={invitationHandler} singleSelectedHandler={singleSelectedHandler} />
    ) : ''

    // const [mainImage, setMainImage] = useState(imageData[0]?.images)

    const selectHandler = (e) => {
        console.log("selecthandler", e.target.checked)
        let inv = investor.slice()
        const newDict = inv.map(dt => {
            for (var key in inv) {
                dt["isCheck"] = e.target.checked
            }
            return dt
        })
        setInvestor(newDict)
    }
    console.log('checkInvestor')

    const invitationSubmitHandler = () => {
        const data = investor.map(dt => dt.invite_type == 'invite' && dt.isCheck == true ? ({ 'email': dt.email }) : '')
        const filterData = data.filter(dt => dt != '')
        console.log('filterData', filterData)
        if (filterData.length > 0) {
            setLoading(true)
            const fetchClient = new MainClient(`/investor/send-multiple-invitation/${id}/`, filterData)
            const response = fetchClient.postAPI()
            response.then(res => {
                console.log("investorPost", res.data)
                setUpdates(!updates)
                setLoading(false)
            }).catch(err => setLoading(false))
        }
    }

    useEffect(() => {
        setDataList(data)
    }, [])

    const changeHandler = (e) => {
        const { id, name, value } = e.target
        console.log('id', id)
        const newList = data.slice()
        const newData = newList.map(data => {
            data.sub_amount.map(am => {
                if (parseInt(am.id) === parseInt(id)) {
                    am["amount_accepted"] = numberWithOutCommas(value)
                }
            })
        })
        setDataList(newData)
    }
    const submitHandler = (deal_id, user_id, subscription_id, amount_accepted) => {
        console.log("acceptSubmit", deal_id, user_id, subscription_id)
        const data = {
            user_id: user_id,
            subscription_id: subscription_id,
            amount_accepted: amount_accepted
        }
        const fetchClient = new MainClient(`deal/accept-subscription/${deal_id}/`, data)
        const response = fetchClient.postAPI()
        response.then(res => {
            setUpdate(!update)
            message.success(res.data.message)
            const dataToSend = {
                from_user: 'admin',
                to_user_id: user_id,
                type: 'accept_subscription'
            };
            dispatch(fetchNotification(dataToSend, user_id))
        }).catch(err => {
            console.log('error', err)
            message.error("Subscription class is already filled")
        })
        // response.then(res => setUpdate(!update))
    }
    const declinedHandler = (deal_id, user_id, subscription_id) => {
        const data = {
            user_id: user_id,
            subscription_id: subscription_id,
        }
        const fetchClient = new MainClient(`deal/decline-subscription/${deal_id}/`, data)
        const response = fetchClient.postAPI()
        response.then(res => {
            console.log('declineData', res.data)
            setUpdate(!update)
            
            const dataToSend = {
                from_user: 'admin',
                to_user_id: user_id,
                type: 'decline_subscription'
            };
            dispatch(fetchNotification(dataToSend, user_id))

            message.success(res.data.message)
        }).catch(err => {
            console.log('error', err)
            // message.error(err)
        })
    }
    const switchHandler = (checked) => {
        setCheck(checked)
    }
    console.log('dData', check)
    const investors = data ? data.map((dt, index) =>
        dt.sub_amount.map(am =>
            <tr class="bg-white">
                <td className="col-1">DS{dt.deal_id}</td>
                <td className="text-center col-1">{dt.investor_name}</td>
                <td className="text-center col-1">{capitalize(am.class_name)} Shares</td>
                <td className="text-center col-1">{dt.subscribed_date}</td>
                <td className="text-center col-1">${numberWithCommas(am.amount)}</td>
                <td className="text-center col-2">
                    {am.is_declined ?
                        <span>$0</span> :
                        <div>
                            {am.is_subscribed ?
                                <div>${numberWithCommas(parseInt(am.amount_accepted))}</div> :
                                <NumberFormat class="form-control" type="text" placeholder="Enter Investment amonut"
                                    id={am.id}
                                    name="amount" value={am.amount_accepted} thousandSeparator={true} onChange={changeHandler}
                                    max=''
                                />}
                        </div>}
                </td>
                <td class="text-center col-2">
                    {am.is_declined ?
                        <span onClick={() => declinedHandler(dt.deal_id, dt.user, am.id)}>
                            <Switch checkedChildren="Declined" unCheckedChildren="Reverted" defaultChecked className="bg-danger" />
                        </span> :
                        <div>
                            {am.is_subscribed ?
                                <span onClick={() => submitHandler(dt.deal_id, dt.user, am.id, am.amount_accepted)}>
                                    <Switch checkedChildren="Accepted" unCheckedChildren="Reverted" defaultChecked onChange={switchHandler} className="bg-success" />
                                </span> :
                                // <span class="badge table-badge rounded-pill bg-green fw-4" style={{ cursor: 'pointer' }} onClick={() => submitHandler(dt.deal_id, dt.user, am.id, am.amount_accepted)}>Accepted</span> :
                                <div>
                                    <span class="badge table-badge rounded-pill bg-green fw-4" style={{ cursor: 'pointer' }} onClick={() => submitHandler(dt.deal_id, dt.user, am.id, am.amount_accepted)}>Accept</span>
                                    <span style={{ margin: ".5rem" }}>or</span>
                                    <span class="badge table-badge rounded-pill bg-danger fw-4" style={{ cursor: 'pointer' }} onClick={() => declinedHandler(dt.deal_id, dt.user, am.id)}>Decline</span>
                                </div>}
                        </div>}
                </td>
                <td className="text-center col-1">
                    <span class="badge table-badge rounded-pill bg-info fw-4" style={{ cursor: 'pointer' }}>Generate</span>
                </td>
                <td class="text-center col-1">
                    <a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                        aria-controls="offcanvasExample">
                        <BsFillChatDotsFill />
                    </a>
                </td>
            </tr >
        )) : ''
    return (
        <div>
            <div class="row mb-3">
                <h2 class="fs-20 fw-6 text-dark mb-1 mt-3">Investor</h2>
                <div class="col-sm-12">
                    <ul class="nav nav-pills mb-3 stack-ul data-deal-role position-relative" id="pills-tab" role="tablist" >
                        <li class="nav-item investors-tabs" role="presentation" onClick={() => setFilter(false)}>
                            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                data-bs-target="#sub-investor" type="button" role="tab" aria-controls="sub-investor"
                                aria-selected="true">Subscribed Investors</button>
                        </li>
                        <li class="nav-item investors-tabs ms-4" role="presentation" onClick={() => setFilter(true)}>
                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#invited"
                                type="button" role="tab" aria-controls="invited" aria-selected="false">My Investors</button>
                        </li>
                    </ul>
                    {filter ?
                        <div className="">
                            <div class="container-fluid p-0">
                                <div class="pb-2 border-bottom">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h3 class=" mb-0 fs-16 fw-6 text-dark">All Investors ({investor.length})</h3>
                                        {/* <button class="btn btn-primary invite-all" data-bs-toggle="modal"
                                data-bs-target="#inviteSponser">
                                Invite All Invester
                            </button> */}
                                        {loading ? <button class="btn text-white disabled" style={{ background: "#3D61AE" }}>
                                            Inviting Selected Investor...
                                        </button> :
                                            <button class="btn text-white invite-buttom " style={{ background: "#3D61AE" }} onClick={invitationSubmitHandler}>
                                                Invite Selected Investor
                                            </button>}
                                        {/* <button class="btn btn-primary invite-reserve" data-bs-toggle="modal"
                                            data-bs-target="#inviteSponser">
                                            <i class="fa fa-plus"></i>
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                            <div style={{overflowX:'scroll'}} className="table-responsive card table-card">

                                <table class="table data-table table-striped align-middle table-borderless table-responsive">
                                    <thead class="bg-white">
                                        <tr>
                                            <th scope="col" className="text-center">
                                                <div class="d-flex justify-content-start">
                                                    <input className="form-check-input border" type="checkbox" value="" id="flexCheckIndeterminate" onChange={selectHandler}/>
                                                    <label className="ms-2" for="flexCheckIndeterminate">
                                                        Select
                                                    </label>
                                                </div>
                                            </th>
                                            <th scope="col">SR # </th>
                                            <th scope="col" className="text-center">Investor name </th>
                                            <th scope="col" className="text-center">Phone Number</th>
                                            <th scope="col" className="text-center">Email</th>
                                            <th scope="col" className="text-center">Market Interested</th>
                                            <th scope="col" className="text-center">Country</th>
                                            {/* <!-- <th scope="col">Status</th> --> */}
                                            {/* <th scope="col" className="text-center">Status</th> */}
                                            <th scope="col" className="text-center">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {myInvestor}
                                    </tbody>
                                </table>
                            </div>
                        </div> :
                        <div>
                            <div class="filters">
                                <div class="col-auto me-4">
                                    <span>Active Filters:</span>
                                </div>
                                <div class="filter-bar">
                                    <div class="col-auto">
                                        {/* <input type="radio" class="btn-check" name="filters" id="success-outlined"
                                    autocomplete="off" checked autofocus /> */}
                                        <label class="btn fs-10" for="success-outlined">All</label>
                                    </div>

                                    <div class="col-auto">
                                        {/* <input type="radio" class="btn-check" name="filters" id="danger-outlined"
                                    autocomplete="off" /> */}
                                        <label class="btn  fs-10" for="danger-outlined">Class A</label>
                                    </div>

                                    <div class="col-auto">
                                        {/* <input type="radio" class="btn-check" name="filters" id="prmary-outlined"
                                    autocomplete="off" /> */}
                                        <label class="btn fs-10 " for="prmary-outlined">Class B</label>
                                    </div>


                                    <div class="col-auto">
                                        {/* <input type="radio" class="btn-check" name="filters" id="secondary-outlined"
                                    autocomplete="off" /> */}
                                        <label class="btn fs-10 " for="secondary-outlined">Class C</label>
                                    </div>
                                </div>

                            </div>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="sub-investor" role="tabpanel"
                                    aria-labelledby="pills-home-tab">
                                    <div class="table-responsive">
                                        <div class="card table-card">
                                            <table class="table table-striped table-hover align-middle">
                                                <thead>
                                                    <tr>
                                                        <th className="col-1">Deal No.</th>
                                                        <th className="text-center col-1">Investor Name</th>
                                                        <th className="text-center col-1">Subscription Class</th>
                                                        <th className="text-center col-1">Date</th>
                                                        <th className="text-center col-1">Amount Requested</th>
                                                        <th className="text-center col-1">Amount Accepted</th>
                                                        <th className="text-center col-1">Subscription</th>
                                                        <th className="text-center col-1">Subscription Doc</th>
                                                        <th className="text-center col-1">Message</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {investors}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>}
                </div>
            </div>
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div class="offcanvas-header">
                    <div class="chat-header">
                        <div class="sender-name">
                            <p class="fs-16 fw-5 mb-0">Anthony Hopkins</p>
                            <p class="user-status fs-11 fw-5 mb-0">Anthony Hopkins</p>
                        </div>
                    </div>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="chat-box">
                                <div class="main-chat-div">
                                    <div class="reciever-div">
                                        <div class="reciever-img">
                                            <img src={logo} alt="user-icon" />
                                        </div>
                                        <div class="receiver-msg">
                                            <p class="mb-0">I took some excellent images yesterday.</p>
                                        </div>
                                    </div>
                                    <p class="reciever-time mb-0 fs-11">11.54 am</p>

                                    <div class="sender-div">
                                        <div class="sender-msg">
                                            <p class="mb-0">Give me the images.</p>
                                        </div>
                                    </div>
                                    <div class="text-end">
                                        {Sender}
                                    </div>

                                    <div class="reciever-div">
                                        <div class="reciever-img">
                                            <img src={logo} alt="user-icon" />
                                        </div>
                                        <div class="receiver-msg">
                                            <p class="mb-0">I took some excellent images yesterday.</p>
                                        </div>
                                    </div>
                                    <p class="reciever-time mb-0 fs-11">11.54 am</p>

                                    <div class="sender-div">
                                        <div class="sender-msg">
                                            <p class="mb-0">i wll send you the legel documents.</p>
                                        </div>
                                    </div>
                                    <div class="text-end">
                                        {Sender}
                                    </div>
                                </div>
                                <div class="chat-footer d-flex align-items-center">
                                    <div class="message-pad d-flex align-items-center">
                                        <input type="text" placeholder="Type message here ...." />
                                    </div>
                                    <div class="pad-icons h-100 d-flex justify-content-around">
                                        {micSvg}
                                        {documentSvg}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default InvestorList
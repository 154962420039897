import { useEffect } from "react"
import numberWithCommas from "../../utilities/extrafunction/commaseparate"
import { useSelector } from "react-redux"
import { useTheme } from "@mui/material"
import { FormHeader } from "../newSponsor/FormElements"

const CashflowDetails = ({ opCashFlow, setOpCashFlow, cpCashFlow, setCpCashFlow, terms, totalOpCashFlow, totalCpCashFlow }) => {
    const theme = useTheme()
    const {palette} = theme
    useEffect(() => {
        let op = []
        let cp = []
        for (let i = 1; i <= terms; i++) {
            op.push({ year: i, value: '' },)
            cp.push({ year: i, value: '' },)
        }
        console.log('opCashFlow', opCashFlow)
        if (opCashFlow.length > 0 && terms > opCashFlow.length) {
            let opf = [...opCashFlow]
            let cpf = [...cpCashFlow]
            for (let i = opf.length + 1; i <= terms; i++) {
                opf.push({ year: i, value: '' },)
                cpf.push({ year: i, value: '' },)
            }
            setOpCashFlow(opf)
            setCpCashFlow(cpf)
        }
        else if (opCashFlow.length > terms) {
            let opf = [...opCashFlow].slice(0, terms)
            let cpf = [...cpCashFlow].slice(0, terms)
            setOpCashFlow(opf)
            setCpCashFlow(cpf)
        }
        else if (opCashFlow.length > 0 || cpCashFlow.length > 0) {
            setOpCashFlow(opCashFlow)
            setCpCashFlow(cpCashFlow)
        }
        else {
            setOpCashFlow(op)
            setCpCashFlow(cp)
        }
    }, [terms])

    const op = opCashFlow.map(dt =>
        <div className="col">
            <div className="cashflow-years">
                {dt.year} Year
            </div>
            <div className="pb-3">
                {numberWithCommas(dt.value)}
            </div>
        </div>
    )

    const cp = cpCashFlow.map(dt =>
        <div className="col">
            <div className="cashflow-years">
                {dt.year} Year
            </div>
            <div className="pb-3">
                {numberWithCommas(dt.value)}
            </div>
        </div>
    )

      // redux state
  const State = useSelector((state) => state);
  const CurrencySign = State.deal?.currency?.sign

    return (
        <div className="row p-3 pb-4" 
        style={{ color: palette.text.primary, height: "1rem auto" }}
        >
            <div class='mb-4'>
                <div class='d-flex justify-content-between align-content-center'>
                <FormHeader theme={theme}>Cash FLows</FormHeader>
                <FormHeader theme={theme}>Investment Terms :
                        <span class='fw-bold'> {terms} years</span>
                        {/* <span class='fw-bold text-black'> ${numberWithCommas(total)}</span> */}
                        {/* <span class='fw-bold text-black'>${capital ? numberWithCommas(capital) : 0}</span> */}
                    </FormHeader>
                </div>
            </div>
            <div className="opDheader">
                <div class='d-flex justify-content-between align-content-center mb-2'>
                <FormHeader theme={theme}>Operating Cash Flows</FormHeader>
                <FormHeader theme={theme}>Total :
                        <span class='fw-bold '> {CurrencySign}{numberWithCommas(totalOpCashFlow)}</span>
                        {/* <span class='fw-bold text-black'> ${numberWithCommas(total)}</span> */}
                        {/* <span class='fw-bold text-black'>${capital ? numberWithCommas(capital) : 0}</span> */}
                    </FormHeader>
                </div>
            </div>
            <div className="row px-4">
                {op}
            </div>
            <div className="opDheader mt-4">
                <div class='d-flex justify-content-between align-content-center mb-2'>
                <FormHeader theme={theme}>Capital Event Cash Flows</FormHeader>
                <FormHeader theme={theme}>Total :
                        <span class='fw-bold'> {CurrencySign}{numberWithCommas(totalCpCashFlow)}</span>
                        {/* <span class='fw-bold text-black'> ${numberWithCommas(total)}</span> */}
                        {/* <span class='fw-bold text-black'>${capital ? numberWithCommas(capital) : 0}</span> */}
                    </FormHeader>
                </div>
            </div>
            <div className="row px-4">
                {cp}
            </div>
        </div>
    )
}

export default CashflowDetails
import { useEffect, useState } from "react"
import { Link, Navigate, useParams } from "react-router-dom"
import AuthClient from "../../utilities/AuthClient"
import Common from "./Common"
import InputPasswordToggle from "./elements/InputPasswordToggle"

const Register = () => {
    const {signuptoken} = useParams()
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
    })
    const [message, setMessage] = useState()

    const changeHandler = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    useEffect(()=>{
        const fetchClient = new AuthClient(`/deal/investor-info/${signuptoken}`)
        const response = fetchClient.getAPI()
        response.then(res=>setData({...data, email: res.data.email}))
    },[])
    const submitHandler = (e) => {
        e.preventDefault()
        console.log('data', data)
        const fetchClient = new AuthClient('/user/register/', data)
        const response = fetchClient.postAPI()
        response.then(() => {
            setMessage("Register Successfull !! click here to login")
            setData({
                first_name: "",
                last_name: "",
                email: "",
                password: "",
            })

        }).catch(err => {
            setMessage("Email already Register !!")
        })
    }
    return (
        <div className="row vh-height-100">
            <Common />
            <div className="col-sm-7 ps-0 pe-0 bg-white form-align-center">
                <div className="d-flex align-center justify-content-end mt-2">
                    {/* <div className='col-2'>
                        <Link replace to="/" style={{ color: "#2B4479" }}>Login</Link>
                    </div> */}
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="col-sm-10">
                        <div className="signup-col-2">
                            {message &&
                                <Link to="/">
                                    <div class="alert alert-success" role="alert">
                                        <Link to="/" class="alert-link">{message}</Link>
                                    </div>
                                </Link>}
                            <h2 className="text-center fs-28 fw-5 form-header" style={{ "margin": 0, "paddding": 0 }}>Register to GulfStream Investments</h2>

                            <div className="sign-up-form">
                                <form className="needs-validation" novalidate onSubmit={submitHandler}>
                                    <div className="mb-3">
                                        <label for="first_name" className="form-label signup-label">First Name</label>
                                        <input type="first_name" className="form-control sign-up-control"
                                            name="first_name"
                                            value={data.first_name}
                                            onChange={changeHandler}
                                            placeholder="Please enter your first name"
                                            required />
                                    </div>
                                    <div className="mb-3">
                                        <label for="last_name" className="form-label signup-label">Last Name</label>
                                        <input type="last_name" className="form-control sign-up-control"
                                            name="last_name"
                                            value={data.last_name}
                                            onChange={changeHandler}
                                            placeholder="Please enter your last name"
                                            required />
                                    </div>
                                    <div className="mb-3">
                                        <label for="email" className="form-label signup-label">Email address</label>
                                        <input type="email" className="form-control sign-up-control"
                                            aria-describedby="email"
                                            name="email" value={data.email}
                                            onChange={changeHandler}
                                            placeholder="Please enter your email"
                                            required />
                                        <div className="invalid-feedback">
                                            Please enter your email.
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label for="password" className="form-label signup-label">Password</label>
                                        {/* <input type="password" className="form-control sign-up-control"
                                            name="password" value={data.password}
                                            onChange={changeHandler}
                                            required /> */}
                                    <InputPasswordToggle
                                        value={data.password}
                                        id='password'
                                        name='password'
                                        className=''
                                        onChange={changeHandler}
                                        required />    
                                    </div>
                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn button_color mb-3 btn-lg">Register</button>
                                    </div>
                                    <div className="grid float-end">
                                        <Link to="/">
                                            <p className="fs-14 fw-4" style={{ color: "#2B4479" }}>Have an account? Login</p>
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Register
import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const SelectCompanyModal = (props) => {
  const assignedCompanies = useSelector(
    (state) => state.assignCompanies
  );
  const dispatch = useDispatch()  
  const [checkedValues, setCheckedValues] = useState([]);
  const companyStateNew = useSelector(
    (state) => state.sponsors.invitedSponsors
  );

  useLayoutEffect(() => {
   
    setCheckedValues(assignedCompanies.companies)
    
  }, [assignedCompanies])
  
  const submit = async (values) => {
    // if (checkedValues.length > 0) {
    await  props.handleAssignCompanies(values)
    // setCheckedValues([])
    // } else {
    //     message.error('select any company to assign !') 
    // }
  }
  const unassignAll = async () => {
    await  props.handleAssignCompanies([])
  }
  const handleCheckboxChange = (value) => {
    // If the checkbox is checked, add the value to the state
    // If the checkbox is unchecked, remove the value from the state
    setCheckedValues((prevValues) =>
      prevValues.includes(value)
        ? prevValues.filter((v) => v !== value)
        : [...prevValues, value]
    );
  };
  console.log("companyStateNew from assign modal", assignedCompanies.companies);
  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        
        <Box
          sx={{
            position: "absolute",
            overflowY: "scroll",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            height: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            '@media (max-width: 992px)': {
              width: '100%',
              overflowY:'auto',
              height:'100%'
            }
            
          }}
        >
 <IconButton
            edge="end"
            color="inherit"
            onClick={props.handleClose}
            sx={{ position: 'relative', top: '2%', left: '95%' }}
          >
            <CloseIcon />
          </IconButton>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>Name</th>
                <th>Admin</th>
                <th>Jurisdiction</th>
              </tr>
            </thead>
            <tbody>
              {companyStateNew?.map((company) => (
                <tr>
                    <td> <input
              type="checkbox"
              value={company.id}
              checked={checkedValues.includes(company.id)}
              onChange={() => handleCheckboxChange(company.id)}
            /></td>
                  <td>{company.id}</td>
                  <td>{company.company}</td>
                  <td>{company.administrator}</td>
                  <td>{company.jurisdiction}</td>
                </tr>
              ))}
              
            </tbody>
          </Table>
          {/* <p>all checks {checkedValues} </p> */}
          <Button 
          // disabled={checkedValues.length === 0}
          className="btn-primary mx-1" onClick={() => submit(checkedValues)}>Save</Button>
          
          {/* <Button
          className="btn-primary mx-1"
           onClick={() => unassignAll()} sx={{ mt: 2 }}>
            Remove All
          </Button> */}
          <Button onClick={() => props.handleClose()} sx={{ mt: 2 }}>
            Cancel
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default SelectCompanyModal;

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'

// ** Custom Components Imports
import Icon from '@core/components/icon'
import OptionsMenu from '@core/components/option-menu'
import CustomAvatar from '@core/components/mui/avatar'
import Chart from 'react-apexcharts'
// ** Util Import
import { hexToRGBA } from '@core/utils/hex-to-rgba'
import Classes from './Classes'
import { useSelector } from 'react-redux'



const KPIAnalysis = ({kpiData}) => {
  const totalOpenDeals = useSelector(state => state?.deals?.openDealData?.length)
  const totalClosedDeals = useSelector(state => state?.deals?.closeDealData?.length)
  const newInvestorPercent = (kpiData?.investors?.new_investors_this_month / kpiData?.investors?.total) * 100
  const totalCloseDealPercent = totalClosedDeals / (totalOpenDeals + totalClosedDeals) * 100
  console.log('totalOpenDeals redux state', totalCloseDealPercent?.toFixed(2))
  const data = [
    {
      subtitle: kpiData?.investors?.total,
      title: 'Investors',
      avatarIcon: 'tabler:ticket'
    },
    {
      subtitle: kpiData?.companies?.total,
      title: 'Companies',
      avatarIcon: 'tabler:ticket'
    },
    {
      subtitle: totalOpenDeals,
      // avatarColor: 'info',
      title: 'Open Deals',
      avatarIcon: 'tabler:circle-check'
    },
    {
      subtitle: totalClosedDeals,
      title: 'Closed Deals',
      // avatarColor: 'warning',
      avatarIcon: 'tabler:clock'
    }
  ]

  const statsData = [
    {
      title: 'New Investors',
      amount: kpiData?.investors?.new_investors_this_month,
      icon: 'tabler:ticket',
      trendNumber: `${newInvestorPercent?.toFixed(1)}%`,
      // avatarColor: 'success'
    },
   
  ]
  const stateRightBox = <CardContent sx={{pt:1}}>
  {statsData.map((item, index) => {
    return (
      <Box
        key={index}
        sx={{
          display: ['none', null, null, 'flex'],
          alignItems: 'center',
          // flexWrap: 'wrap',
          pt: 5,
          mb : [data.length - 1 ? [6.5, 6.5, 7.5, 6.5] : undefined, -5]
          // mb: index !== data.length - 1 ? [6.5, 6.5, 7.5, 6.5] : undefined
        }}
      >
        <CustomAvatar
          skin='light'
          variant='rounded'
          color={item.avatarColor}
          sx={{ mr: 4, width: 34, height: 34 }}
        >
          <Icon icon={item.icon} />
        </CustomAvatar>
        <Box
          sx={{
            rowGap: 1,
            columnGap: 4,
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography sx={{mr: 7,}} variant='h6'>{item.title}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mr: 7, fontWeight: 500, color: 'text.secondary' }}>{item.amount}+</Typography>
            <Typography sx={{ mr: 7, color: `${item.trend === 'negative' ? 'error' : 'success'}.main` }}>
              {item.trendNumber}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  })}
</CardContent>
  // ** Hook
  const theme = useTheme()
  const {mode} = theme.palette
  const options = {
    chart: {
      sparkline: { enabled: true }
    },
    stroke: { dashArray: 10 },
    labels: ['Closed Deals'],
    colors: [hexToRGBA(theme.palette.primary.main, 1)],
    states: {
      hover: {
        filter: { type: 'none' }
      },
      active: {
        filter: { type: 'none' }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        opacityTo: 0.5,
        opacityFrom: 1,
        shadeIntensity: 0.5,
        stops: [30, 70, 100],
        inverseColors: false,
        gradientToColors: [theme.palette.primary.main]
      }
    },
    plotOptions: {
      radialBar: {
        endAngle: 130,
        startAngle: -140,
        hollow: { size: '60%' },
        track: { background: mode == "light" ? '#ede4e424' :'#ede4e403' },
        dataLabels: {
          name: {
            offsetY: -15,
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body1.fontSize
          },
          value: {
            offsetY: 15,
            fontWeight: 500,
            formatter: value => `${value}%`,
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h3.fontSize
          }
        }
      }
    },
    grid: {
      padding: {
        top: -30,
        bottom: 12
      }
    },
    responsive: [
      {
        breakpoint: 1300,
        options: {
          grid: {
            padding: {
              left: 22
            }
          }
        }
      },
      {
        breakpoint: theme.breakpoints.values.md,
        options: {
          grid: {
            padding: {
              left: 0
            }
          }
        }
      }
    ]
  }

  return (
    <Card className='h-100' style={{boxShadow:'0px 2px 6px 0px rgba(47, 43, 61, 0.14)', background : theme.palette.customColors.bodyBg}}>
      <CardHeader
        title='Stats'
        subheader='Last Month'
        action={
          // <OptionsMenu
          //   options={['Refresh', 'Edit', 'Share']}
          //   iconButtonProps={{ size: 'small', sx: { color: 'text.disabled' } }}
          // />
          <>
          {stateRightBox}
          </>
        }
      />
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={5}>
            {/* <Classes /> */}
            {/* <Typography variant='h3'>{kpiData?.investors?.total}</Typography>
            <Typography sx={{ mb: 6, color: 'text.secondary' }}>New Investors</Typography> */}
            {data.map((item, index) => (
              <Box
                key={index}
                sx={{ display: 'flex', alignItems: 'center', mb: index !== data.length - 1 ? 4 : undefined }}
              >
                <CustomAvatar
                  skin='light'
                  variant='rounded'
                  color={item.avatarColor}
                  sx={{ mr: 4, width: 34, height: 34 }}
                >
                  <Icon icon={item.avatarIcon} />
                </CustomAvatar>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant='h6'>{item.title}</Typography>
                  <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} sm={7} sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center', mt : -10 }}>
            <Chart type='radialBar' height={325} options={options} series={[totalCloseDealPercent?.toFixed(2)]} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default KPIAnalysis

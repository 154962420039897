import { useTheme } from "@mui/material";
import styled from "styled-components";

export const FormLabel = styled.label`
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 0.5rem;
`;

export const FormHeader = styled.h2`
  font-weight: 600;
  font-size: 1rem;
  color: ${(props) => props.theme.palette.text.primary};
  margin: 0;
`;


export const FormInput = ({onChange, ...props}) => {
    const theme = useTheme()
    const StyledInput = styled.input`
 
 display: block;
 width: 100%;
 padding: .375rem .75rem;
 font-size: 1rem;
 font-weight: 400;
 line-height: 1.5;
 color: ${theme.palette.text.primary};
 background-color: ${theme.palette.customColors.bodyBg};
 background-clip: padding-box;
 border: 1px solid ${theme.palette.customColors.avatarBg};
 -webkit-appearance: none;
 -moz-appearance: none;
 appearance: none;
 border-radius: .25rem;
 transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
 &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
 `
 const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };



    return <StyledInput  theme={theme} {...props} />;
};

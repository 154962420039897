import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import numberWithCommas from '../../utilities/extrafunction/commaseparate';
import numFormatter from '../../utilities/extrafunction/numberConveter';
import percentage from '../../utilities/extrafunction/Persentage';
import MainClient from '../../utilities/MainClient';
import DoughnutComponent from './DoughnutComponent';

const Wrapper = styled.div`
  background: #fbfafb;
  border: 1px solid #e9ebee;
  padding: 1rem 2rem 2rem 2rem;
  margin-bottom: 2rem;
`;
const UpperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BoxOne = styled.div`
  background: #ffffff;
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.15),
    2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 0.7rem;
  @media (max-width: 1250px) {
    // font-size: 14px;
    padding: 0.4rem 0.1rem;
  }
`;
const TextOne = styled.div`
  // font-family: 'san-serif, verdana';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #767676;
  text-align: center;
  @media (max-width: 1250px) {
    font-size: 14px;
    line-height: 18px;
    // padding: .6rem;
  }
`;
const TextPrice = styled.div`
  // font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  margin-top: 0.5rem;
  text-align: center;
  @media (max-width: 1250px) {
    font-size: 16px;
    margin-top: 0.2rem;
    line-height: 20px;
    // padding: .6rem;
  }
`;

const GraphCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GraphSectionWrapper = styled.div`
  flex: 0.5;
  display: flex;
  align-items: ${(props) => (props.share > 1 ? 'center' : 'normal')};
  justify-content: space-between;
  flex-direction: ${(props) => (props.share > 1 ? 'row' : 'column')};
  @media(max-width: 570px){
    flex-direction: row;
    width: 100%;
  }
`;

const GraphSection = styled.div`
  flex: 1;
  background: #ffffff;
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.15),
    2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 16px 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
`;

const DotWrapperConatiner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: ${(props) => (props.share > 1 ? 'column' : 'row')};
  width: ${(props) => (props.share > 1 ? '120px' : '50%')};
  height: 40px;
  margin-left: 25px;
  @media (min-width: 1250px) {
    width: ${(props) => (props.share > 1 ? '150px' : '50%')};
    height: 40px;
  }
`;
const DotWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.share > 1 ? 'space-between' : 'space-evenly'};
  align-items: center;
  width: 100%;
`;

const Dot1 = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1d2e52;
`;
const Dot2 = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #3d61ae;
`;

const DotText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-align: center;
  @media (max-width: 1250px) {
    font-size: 12px;
    line-height: 14px;
    // padding: .6rem;
  }
`;

const GraphComitted = styled.div`
  background: #1d2e52;
  border-radius: ${(props) =>
    props.share > 99.9 ? '5px 5px 5px 5px' : '5px 0px 0px 5px'};
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: ${(props) => (props.share > 0 ? props.share + '%' : '0%')};
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  transition: 0.9s;
  text-indent: ${(props) => (props.share > 10 ? 0 : '-9999px')};
  /* :hover {
    text-indent: 0;
    transition: 0.9s ease-in ease-out;
    padding: 0.6rem;
    width: ${(props) => (props.share > 40 ? props.share + '%' : '40%')};
  } */
  @media (max-width: 1250px) {
    font-size: 14px;
    border-radius: ${(props) =>
      props.share > 99.9 ? '5px 5px 5px 5px' : '5px 0px 0px 5px'};
    width: ${(props) => (props.share > 0 ? props.share + '%' : '0%')};
    transition: 0s;
    /* :hover {
      text-indent: 0;
      transition: 0.2s ease-in ease-out;
      width: 100%;
    } */
  }
`;
const GraphRemaing = styled.div`
  background: #3d61ae;
  border-radius: ${(props) =>
    props.share > 99.9 ? '5px 5px 5px 5px' : '0px 5px 5px 0px'};
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: ${(props) => (props.share > 0 ? props.share + '%' : '0%')};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  transition-duration: inherit;
  text-indent: ${(props) => (props.share > 10 ? 0 : '-9999px')};
  /* :hover {
    text-indent: 0;
    transition: 0.9s ease-in ease-out;
    padding: 0.6rem;
    width: ${(props) => (props.share > 40 ? props.share + '%' : '40%')};
  } */
  @media (max-width: 1250px) {
    font-size: 14px;
    border-radius: ${(props) =>
      props.share > 99.9 ? '5px 5px 5px 5px' : '0px 5px 5px 0px'};
    width: ${(props) => (props.share > 0 ? props.share + '%' : '0%')};
    transition: 0s;
    /* :hover {
      text-indent: 0;
      transition: 0.2s ease-in ease-out;
      width: 100%;
    } */
  }
`;
const ClassSections = styled.div`
  flex: 0.5;
  margin-left: ${(props) => (props.share > 1 ? 0 : '35px')};
  @media(max-width:570px){
    margin-left:0;
    width:100%;
  }
`;

const ClassBox = styled.div`
  background: #ffffff;
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.15),
    2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

const ClassText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #767676;
  padding: 10px 0px 0px 10px;
  @media (max-width: 1250px) {
    font-size: 14px;
  }
`;

const CardContentWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: ${(props) => (props.share > 2 ? 'stretch' : 'center')};
  flex-direction: ${(props) => (props.share > 2 ? 'column' : 'row')};
  @media (max-width: 570px){
    flex-direction: column
  }
`;

const GraphWapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
`;
const GraphBox = styled.div`
  width: 120px;
  @media (max-width: 1250px) {
    width: 100px;
  }
`;
const BottomWapper = styled.div`
  width: ${(props) => (props.share <= 2 ? '50%' : '100%')};
  margin: ${(props) =>
    props.share <= 2 ? '0.7rem 1.5rem 0.5rem 1.5rem' : '0'};
  /* padding: 0.7rem 1.5rem 0.5rem 1.5rem; */
  @media (max-width: 1250px) {
    /* padding: 1rem 0 0 0; */
    margin: ${(props) => (props.share <= 2 ? '1rem 0 0 0' : '1rem 0 0 0')};
  }
  @media (max-width: 570px) {
    /* padding: 1rem 0 0 0; */
    margin: .3rem 0 .2rem 0;
    width:100%
  }
`;
const TextWapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${props=>props.share > 2 ? '2px 25px 0px 25px' : '10px 25px 5px 25px'};
  @media (max-width: 570px) {
    margin: 10px 25px 5px 25px
  }
`;

const SingleClassContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  @media(max-width:570px){
    flex-direction: column;
    width:100%;
  }
`
const SingleGraphSection = styled.div`
  flex: 1;
  background: #ffffff;
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.15),
    2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 0px 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  @media (max-width: 570px) {
    margin: 0px -10px 16px 0;
  }
`;

const SingleDotWrapperConatiner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: ${(props) => (props.share > 1 ? 'column' : 'row')};
  width: ${(props) => (props.share > 1 ? '120px' : '50%')};
  height: 40px;
  margin-left: 25px;
  @media (min-width: 1250px) {
    width: ${(props) => (props.share > 1 ? '150px' : '50%')};
    height: 40px;
  }
  @media (max-width: 570px) {
    flex-direction: column;
    width: 120px;
  }
`;
const SingleGraphSectionWrapper = styled.div`
  flex: .5;
  display: flex;
  align-items: ${(props) => (props.share > 1 ? 'center' : 'normal')};
  justify-content: space-between;
  flex-direction: ${(props) => (props.share > 1 ? 'row' : 'column')};
  @media(max-width: 570px){
    flex-direction: row;
    width: 100%;
  }
`;

const Dashboard = ({ update }) => {
  const [data, setData] = useState();
  const { id } = useParams();
  console.log('id', id);
  useEffect(() => {
    const getData = () => {
      const fetchClient = new MainClient(`/deal/share-details-view/${id}/`);
      const response = fetchClient.getAPI();
      response
        .then((res) => {
          console.log('resData', res.data.data[0]);
          setData(res.data.data[0]);
        })
        .catch((err) => console.log(err));
    };
    getData();
  }, [update]);
  const fillColor = [
    '#2B4479',
    '#3D61AE',
    '#4F7CDA',
    '#5D91FF',
    '#8BB0FF',
    '#B2CBFF',
  ];
  const percentages = ['85', '70', '60'];
  const committedPercentage = percentage(
    parseInt(data?.committed_shares),
    parseInt(data?.committed_shares) + parseInt(data?.remaining_shares)
  ).toFixed(2);
  const len = data?.each_share?.length;
  const classShares = data?.each_share.map((dt, index) => {
    const pt = percentage(
      parseInt(dt.committed_share),
      parseInt(dt.committed_share) + parseInt(dt.remaining_share)
    );
    console.log('pt', pt);
    return (
      <div className={`col-lg-${len > 0 ? 12 / len : 12} col-sm-12 mb-1`} >
        <ClassBox className='p-1 card-margin pb-3'>
          <ClassText className='text-capitalize'>{dt.class_name}</ClassText>
          <CardContentWrapper share={len}>
            <GraphWapper>
              <GraphBox>
                <DoughnutComponent
                  percentage={pt ? pt.toFixed(2) : 0}
                  fillColor={fillColor[index]}
                  emptyColor='#D9D9D9'
                  color='#D9D9D9'
                />
              </GraphBox>
            </GraphWapper>
            <BottomWapper share={len}>
              <TextWapper share={len}>
                <div>Shares</div>
                <div>
                  ${dt.total_share ? numberWithCommas(dt.total_share) : 0.0}
                </div>
              </TextWapper>
              <TextWapper share={len}>
                <div>Subscribed</div>
                <div>
                  $
                  {dt.subscribed_share
                    ? numberWithCommas(dt.subscribed_share)
                    : 0.0}
                </div>
              </TextWapper>
              <TextWapper share={len}>
                <div>Committed</div>
                <div>
                  $
                  {dt.committed_share
                    ? numberWithCommas(dt.committed_share)
                    : 0.0}
                </div>
              </TextWapper>
              <TextWapper share={len}>
                <div>Remaining Shares</div>
                <div>
                  $
                  {dt.remaining_share
                    ? numberWithCommas(dt.remaining_share)
                    : 0.0}
                </div>
              </TextWapper>
            </BottomWapper>
          </CardContentWrapper>
        </ClassBox>
      </div>
    );
  });
  return (
    <>
      {/* <div class="container overflow-hidden"> */}
      <div class='row'>
        <div class='col-lg-3 col-md-6 col-sm-12 pt-1 card-margin'>
          <BoxOne>
            <TextOne>Total Shares</TextOne>
            <TextPrice>
              $ {data?.total_shares ? numberWithCommas(data?.total_shares) : 0}
            </TextPrice>
          </BoxOne>
        </div>
        <div class='col-lg-3 col-md-6 col-sm-12 pt-1 card-margin'>
          <BoxOne>
            <TextOne>Subscriptions</TextOne>
            <TextPrice>
              ${' '}
              {data?.subscribed_shares
                ? numberWithCommas(data?.subscribed_shares)
                : 0}
            </TextPrice>
          </BoxOne>
        </div>
        <div class='col-lg-3 col-md-6 col-sm-12 pt-1 card-margin'>
          <BoxOne>
            <TextOne>Committed Shares</TextOne>
            <TextPrice>
              ${' '}
              {data?.committed_shares
                ? numberWithCommas(data?.committed_shares)
                : 0}
            </TextPrice>
          </BoxOne>
        </div>
        <div class='col-lg-3 col-md-6 col-sm-12 pt-1 card-margin'>
          <BoxOne>
            <TextOne>Remaining Shares</TextOne>
            <TextPrice>
              ${' '}
              {data?.remaining_shares
                ? numberWithCommas(data?.remaining_shares)
                : 0}
            </TextPrice>
          </BoxOne>
        </div>
      </div>
      {len === 1 && (
        <SingleClassContainer className='pt-3'>
          <SingleGraphSectionWrapper share={len} className="">
            <SingleGraphSection>
              <GraphComitted share={committedPercentage}>
                 $
                {data?.committed_shares
                  ? numFormatter(data?.committed_shares)
                  : 0}
              </GraphComitted>
              <GraphRemaing share={100 - committedPercentage}>
                 ${numFormatter(data?.remaining_shares)}
              </GraphRemaing>
            </SingleGraphSection>
            <SingleDotWrapperConatiner share={len}>
              <DotWrapper share={len}>
                <Dot1 />
                <DotText>Committed Shares</DotText>
              </DotWrapper>
              <DotWrapper share={len}>
                <Dot2 />
                <DotText> Remaining Shares</DotText>
              </DotWrapper>
            </SingleDotWrapperConatiner>
          </SingleGraphSectionWrapper>

          <ClassSections share={len}>
            <div className='row'>{classShares}</div>
          </ClassSections>
        </SingleClassContainer>
      )}
      {len > 1 && (
        <>
          <GraphSectionWrapper share={len}>
            <GraphSection>
              <GraphComitted share={committedPercentage}>
                $
                {data?.committed_shares
                  ? numFormatter(data?.committed_shares)
                  : 0}
              </GraphComitted>
              <GraphRemaing share={100 - committedPercentage}>
                ${numFormatter(data?.remaining_shares)}
              </GraphRemaing>
            </GraphSection>
            <DotWrapperConatiner share={len}>
              <DotWrapper share={len}>
                <Dot1 />
                <DotText>Committed Shares</DotText>
              </DotWrapper>
              <DotWrapper share={len}>
                <Dot2 />
                <DotText> Remaining Shares</DotText>
              </DotWrapper>
            </DotWrapperConatiner>
          </GraphSectionWrapper>

          <ClassSections share={len}>
            <div className='row'>{classShares}</div>
          </ClassSections>
        </>
      )}
      {/* </div> */}
      {/* <UpperContainer>
                <BoxOne >kjsbjd</BoxOne>
                <BoxOne >kjsbjd</BoxOne>
                <BoxOne >kjsbjd</BoxOne>
                <BoxOne >kjsbjd</BoxOne>
            </UpperContainer> */}
    </>
  );
};

export default Dashboard;

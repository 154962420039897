export const getInputStyles = (theme) => {
  const {mode} = theme.palette
  const border = mode === "dark" ? theme.palette.text.primary : theme.palette.customColors.avatarBg
    return {
    color : theme.palette.text.primary,
    backgroundColor : theme.palette.customColors.bodyBg,
    border : `1px solid ${border}`,
    outline: 'none',
    ':focus': {
      backgroundColor : theme.palette.customColors.bodyBg,
      borderColor: `.5px ${theme.palette.primary.main}`, // Change the border color on focus
      boxShadow: `0 0 0 0.1rem ${theme.palette.primary.main}`,
    },
    // ':-webkit-autofill': {
    //   backgroundColor : 'red'
    // }
    }

}

export const getBasicInputStyles = (theme) => {
    return {
    color : theme.palette.text.primary,
    backgroundColor : theme.palette.customColors.bodyBg,
    border : `1px solid ${theme.palette.customColors.avatarBg}`,
    
    }


}
export const getTextInputStyles = (theme) => {
  return {
  color : theme.palette.text.primary,
  backgroundColor : theme.palette.customColors.bodyBg,
  
  }


}

export const getCapBasisStyles = (theme, active) => {
  if (active) {
  return {
  color : theme.palette.primary.main,
  backgroundColor : theme.palette.customColors.bodyBg,
  border : `.5px solid ${theme.palette.primary.main}`,
  fontSize : '0.7rem'
  
  }
} else {
  return {
    color : theme.palette.text.primary,
    backgroundColor : theme.palette.customColors.bodyBg,
    border : `.5px solid ${theme.palette.customColors.avatarBg}`,
    fontSize : '0.7rem'
    }
}

}
import { Input, InputNumber, Space, message } from 'antd'
import { Table, Collapse, Button } from 'reactstrap'
import React, { memo, useEffect, useMemo, useState } from 'react'
import NumberFormat from 'react-number-format'
import numberWithCommas from '../../utilities/extrafunction/commaseparate'
import { useDispatch, useSelector } from 'react-redux'
import { addFeesBoxPurchase, addFeesBoxPurchase2, addPurchaseData } from '../../store/actions/metrics'
import numberWithOutCommas from '../../utilities/extrafunction/numberWithoutComma'
import { BsFillXCircleFill } from 'react-icons/bs'
import AddFeesModal from './feesModal/AddFeesModal'
import { Box, IconButton, Modal, useTheme } from '@mui/material'
import commaseparateOnlyNum from '../../utilities/extrafunction/commaseparateOnlyNum'
import CloseIcon from '@mui/icons-material/Close';
import { getInputStyles } from './FormStyles'
import { FormHeader } from './FormElements'

function MetricsPurchase({ total, metrics, setMetrics, entryNoiSelect, setEntryNoiSelect, stabilizedNoiSelect, setStabilizedNoiSelect, proNoiSelect, setProNoiSelect, updateHandler, id, submit, squareFeet }) {
  const feesboxPurchaseState = useSelector((state) => state.metrics.feeBoxPurchase);
  const feesboxPurchaseState2 = useSelector((state) => state.metrics.feeBoxPurchase2);
  const theme = useTheme()
  const {mode} = theme.palette
  const inputStyles = getInputStyles(theme)
  const feesboxState = useSelector((state) => state.metrics.feesBox);
  const purchaseState = useSelector((state) => state.metrics.purchase);
  console.log('feesboxPurchaseState', feesboxPurchaseState)
  console.log('purchaseState', purchaseState)
  console.log('feesboxState', feesboxState)
  const [purchaseM, setPurchaseM] = useState({})
  const [feeBox, setFeesBox] = useState([])
  const dispatch = useDispatch()
  const error = {
    loanTovalue: 'Loan to value(%) should be lower or equal to 100%',
    roi: 'ROI(%) should be lower or equal to 100%',
    taxes: 'Taxes(%) should be lower or equal to 100%'
  }
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openBoxModal, setOpenBoxModal] = useState(false);
  const handleCollapse = () => setIsOpen(!isOpen)
  const removeFees = (payload) => {
    const newArray = feesboxPurchaseState?.filter(f => f !== payload)
    dispatch(addFeesBoxPurchase(newArray))
  }
  const [isOpen2, setIsOpen2] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openBoxModal2, setOpenBoxModal2] = useState(false);
  const handleCollapse2 = () => setIsOpen2(!isOpen2)
  const removeFees2 = (payload) => {
    const newArray = feesboxPurchaseState2?.filter(f => f !== payload)
    dispatch(addFeesBoxPurchase2(newArray))
  }

  const loanTovalue = useMemo(() => {
    return purchaseState?.investment / purchaseState?.valuation * 100;
  }, [purchaseState?.investment, purchaseState?.valuation]);

  const ROI = useMemo(() => {
    return purchaseState?.roi_percent * purchaseState?.investment;
  }, [purchaseState?.roi_percent, purchaseState?.investment]);

  const ROIPerc = useMemo(() => {
    return (purchaseState?.roi / purchaseState?.investment) * 100;
  }, [purchaseState?.roi, purchaseState?.investment]);

  const taxes_amount = useMemo(() => {
    // if (purchaseState?.sell_price) {
    return purchaseState?.taxes_percent * purchaseState?.sell_price / 100;
    // }
    // return 0; // Return 0 or any default value when sell_price is falsy
  }, [purchaseState?.sell_price, purchaseState?.taxes_percent]);
  // const taxes_amount_perc = useMemo(() => {
  //   return (purchaseState?.taxes / purchaseState?.sell_price) * 100;
  // }, [ purchaseState?.sell_price]);
  console.log('taxes_amount', taxes_amount)
  const profit = useMemo(() => {
    return purchaseState?.sell_price - purchaseState?.investment - (purchaseState?.sell_fees || 0) - (taxes_amount || 0);
  }, [purchaseState?.sell_price, purchaseState?.investment, purchaseState?.sell_fees, purchaseState?.taxes]);

  const profit_perc = useMemo(() => {
    return (purchaseState?.net_profit / purchaseState?.investment) * 100;
  }, [purchaseState?.net_profit, purchaseState?.investment]);

  useEffect(() => {
    dispatch(addPurchaseData({ ...purchaseState, ...{ loan_tovalue: loanTovalue.toFixed(2) } }));
    console.log('useEffect 1 is running.....');
  }, [loanTovalue]);

  useEffect(() => {
    dispatch(addPurchaseData({ ...purchaseState, ...{ roi: ROI.toFixed(2).toString() / 100 } }));
    console.log('useEffect 2 is running.....');
  }, [ROI]);

  useEffect(() => {
    dispatch(addPurchaseData({ ...purchaseState, ...{ roi_percent: ROIPerc.toFixed(2).toString() } }));
    console.log('useEffect 3 is running.....');
  }, [ROIPerc]);

  useEffect(() => {
    const amount = (taxes_amount).toFixed(2).toString(); // Multiply by 100 to get the percentage
    dispatch(addPurchaseData({ ...purchaseState, ...{ taxes: amount } }));
    console.log('useEffect 4 is running.....', { taxes: amount });
  }, [taxes_amount]);

  // useEffect(() => {
  //   dispatch(addPurchaseData({ ...purchaseState, ...{ taxes_percent: taxes_amount_perc } }));
  //   console.log('useEffect 5 is running.....');
  // }, [taxes_amount_perc]);

  useEffect(() => {
    dispatch(addPurchaseData({ ...purchaseState, ...{ net_profit: profit.toFixed(2).toString() } }));
    console.log('useEffect 6 is running.....');
  }, [profit]);

  useEffect(() => {
    dispatch(addPurchaseData({ ...purchaseState, ...{ net_profit_percent: profit_perc.toFixed(2).toString() } }));
    console.log('useEffect 7 is running.....');
  }, [profit_perc]);


  // const calculateLoanTovalue = () => {
  //   const loanTovalue = purchaseState?.investment / purchaseState?.valuation * 100
  //   dispatch(addPurchaseData({ ...purchaseState, ...{ loan_tovalue: loanTovalue.toFixed(2) } }))
  // }

  // const calculateROI = () => {
  //   const ROI = (purchaseState?.roi_percent * purchaseState?.investment)
  //   dispatch(addPurchaseData({ ...purchaseState, ...{ roi: ROI.toFixed(2).toString() / 100 } }))
  // }

  // const calculateROIPerc = () => {
  //   const ROIPerc = (purchaseState?.roi / purchaseState?.investment) * 100
  //   dispatch(addPurchaseData({ ...purchaseState, ...{ roi_percent: ROIPerc.toFixed(2).toString() } }))
  // }

  // const calculateTax = () => {
  //   const taxes_amount = purchaseState?.taxes_percent * purchaseState?.sell_price / 100
  //   dispatch(addPurchaseData({ ...purchaseState, ...{ taxes: taxes_amount.toFixed(2) } }))

  // }
  // const calculateTaxPerc = () => {

  //   const taxes_amount_perc = (purchaseState?.taxes / purchaseState?.sell_price) * 100

  //   dispatch(addPurchaseData({ ...purchaseState, ...{ taxes_percent: taxes_amount_perc } }))

  //   console.log('taxes_amount', taxes_amount_perc)
  // }

  // const calculateProfit = () => {
  //   const profit = purchaseState?.sell_price - purchaseState?.investment - (purchaseState?.sell_fees || 0) - purchaseState?.taxes
  //   console.log('profit...........', profit)
  //   dispatch(addPurchaseData({ ...purchaseState, ...{ net_profit: profit.toFixed(2).toString() } }))

  // }
  // const calculateProfitPerc = () => {
  //   const profit_perc = purchaseState?.net_profit / purchaseState?.investment * 100
  //   console.log('profit...........', profit_perc)
  //   dispatch(addPurchaseData({ ...purchaseState, ...{ net_profit_percent: profit_perc.toFixed(2).toString() } }))

  // }

  // useEffect(() => {
  //   calculateLoanTovalue()

  //   console.log('useffect is runnng.....')
  // }, [purchaseState?.investment, purchaseState?.valuation])

  // useEffect(() => {
  //   calculateROI()
  //   console.log('useffect 2 is runnng.....')
  // }, [purchaseState?.investment, purchaseState?.roi_percent])

  // useEffect(() => {
  //   calculateROIPerc()
  //   console.log('useffect 2 perc is runnng.....')
  // }, [purchaseState?.investment, purchaseState?.roi])

  // useEffect(() => {
  //   calculateTax()
  //   console.log('useffect 3 is runnng.....')
  // }, [purchaseState?.sell_price, purchaseState?.taxes_percent])

  // useEffect(() => {
  //   calculateTaxPerc()
  //   console.log('useffect 3 perc is runnng.....')
  // }, [purchaseState?.taxes])
  // useEffect(() => {
  //   calculateProfit()
  //   console.log('useffect 4 is runnng.....')
  // }, [purchaseState?.investment, purchaseState?.taxes, purchaseState?.sell_price])

  // useEffect(() => {
  //   calculateProfitPerc()
  //   console.log('useffect 5 is runnng.....')
  // }, [purchaseState?.net_profit])

  const changeHandler = (e) => {
    const { name, value } = e.target
    //   setPurchaseM({  
    //   ...purchaseM,
    //   [name]: numberWithOutCommas(value)
    // });
    console.log('{name, value}', name, value)
    const newValue = numberWithOutCommas(value)
    // if (purchaseState[name] !== newValue) {
    dispatch(addPurchaseData({
      ...purchaseState,
      [name]: newValue
    }))
    // }

  };
  const State = useSelector((state) => state);
  const CurrencySign = State.deal?.currency?.sign

  // console.log('purchaseM', purchaseM)
  const totalFeesCost = feesboxPurchaseState?.map(f => f.cost).reduce((a, c) => parseFloat(a) + parseFloat(c), 0)
  const totalFeesPercentCost = feesboxPurchaseState?.map(f => f.cost_percent).reduce((a, c) => parseFloat(a) + parseFloat(c), 0)

  const totalFeesCost2 = feesboxPurchaseState2?.map(f => f.cost).reduce((a, c) => parseFloat(a) + parseFloat(c), 0)
  const totalFeesPercentCost2 = feesboxPurchaseState2?.map(f => f.cost_percent).reduce((a, c) => parseFloat(a) + parseFloat(c), 0)

  const [updateDispatch, setupdateDispatch] = useState(false)
  const addFeesData = () => {
    if (feesboxPurchaseState) {
      // setFeesBox([...feeBox, ...[feesboxState]])
      message.success('New Fees Added Successfully !')
    } else {
      message.error('fees data not found !')
    }
    handleCloseModal()
  }
  const handleCloseFeesBox = () => {
    setOpenBoxModal(false);
  };
  const handleOpenFeesBox = () => {
    setOpenBoxModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false)
    dispatch(addPurchaseData({
      ...purchaseState,
      fees: totalFeesCost
    }))

    console.log(' hanle close is working')
  }
  const handleOpenModal = () => {

    setOpenModal(true)
  }

  const addFeesData2 = () => {
    if (feesboxPurchaseState2) {
      // setFeesBox([...feeBox, ...[feesboxState]])
      message.success('New Fees Added Successfully !')
    } else {
      message.error('fees data not found !')
    }
    handleCloseModal2()
  }
  const handleCloseFeesBox2 = () => {
    setOpenBoxModal2(false);
  };
  const handleOpenFeesBox2 = () => {
    setOpenBoxModal2(true);
  };
  const handleCloseModal2 = () => {
    setOpenModal2(false)
    dispatch(addPurchaseData({
      ...purchaseState,
      sell_fees: totalFeesCost2
    }))

    console.log(' hanle close is working')
  }
  const handleOpenModal2 = () => {

    setOpenModal2(true)
  }


  // useEffect(() => {

  //     // return () => {
  //       dispatch(addPurchaseData(purchaseM))
  //     // }
  //   }, [purchaseM])

  // useEffect(() => {
  //   dispatch(addFeesBoxPurchase(feeBox));
  //   setPurchaseM({ ...purchaseM, ...{ fees: totalFeesCost } }) // save fees in records 
  // }, [feeBox]);
  useEffect(() => {
    console.log('only investment useffect running...')
    if (purchaseState?.fees || purchaseState?.sell_fees) {
      const updatedFees = purchaseState?.investment * totalFeesPercentCost / 100
      const updatedFees2 = purchaseState?.investment * totalFeesPercentCost2 / 100

      dispatch(addPurchaseData({
        ...purchaseState,
        ...{
          fees: updatedFees,
          sell_fees: updatedFees2
        }
      }))
      console.log('updatedFees', updatedFees, updatedFees2)
      // setupdateDispatch(!updateDispatch)
    }

    // if (purchaseState?.sell_fees){
    //   const updatedFees = purchaseState?.investment * totalFeesPercentCost2 / 100
    //   dispatch(addPurchaseData({
    //     ...purchaseState,
    //     sell_fees: updatedFees
    //   }))
    //   console.log('updatedFees', updatedFees)
    //   // setupdateDispatch(!updateDispatch)
    // }

  }, [purchaseState?.investment])
  useEffect(() => {
    setFeesBox(purchaseState?.fees_box?.data || feesboxPurchaseState)

    // setPurchaseM(purchaseState)

  }, [])



  return (
    <div>
      <div class='col-12'>
        <div class=''>
          <div className="p-2">
            <div class='mb-4 mt-2'>
              <div class='d-flex justify-content-between align-content-center'>
                <FormHeader theme={theme}>Metrics and Capitalization :</FormHeader>
                {/* <h2 class='mb-0 fs-16 fw-5' style={{ color: "#A2ACBD", letterSpacing: '0.2px', }}>Total
                </h2> */}
              </div>
            </div>
            <div className=''>
              <form action="" id="deal-form" >
                <div class="row g-3">
                  <div className="col-lg-3 col-md-2 col-sm-4">
                    <label htmlFor="squareFeet" className="form-label">
                      Investment
                    </label>
                    <NumberFormat
                      style={inputStyles}
                      type="text"
                      className="form-control smaller-input"
                      id="squareFeet"
                      placeholder="Enter Investment"
                      name="investment"
                      value={(purchaseState?.investment)}
                      onChange={changeHandler}
                      thousandSeparator={true}
                    />
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-4">
                    <label htmlFor="squareFeet" className="form-label">
                      Valuation
                    </label>
                    <input
                      style={inputStyles}
                      type="text"
                      className="form-control smaller-input"
                      id="squareFeet"
                      placeholder="Enter Valuation"
                      name="valuation"
                      thousandSeparator={true}
                      value={(purchaseState?.valuation)}
                      onChange={changeHandler}
                    />
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="purchasePrice" class="form-label">Fees</label>
                    <Space onClick={handleOpenFeesBox} direction="vertical" style={{ width: '100%' }}>
                      <input
                        style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="fees"
                        placeholder="Enter Fees"
                        name="fees"
                        thousandSeparator={true}
                        value={numberWithCommas(purchaseState?.fees) || numberWithCommas(totalFeesCost)}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="closingCost" class="form-label">Loan to Value %</label>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <NumberFormat
                        style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="loan_tovalue"
                        placeholder="Enter Loan to value"
                        name="loan_tovalue"
                        // thousandSeparator={true}
                        value={(purchaseState?.loan_tovalue)}
                        onChange={changeHandler}
                      />
                      {purchaseState?.loan_tovalue > 100 && <p className='text-danger'>{error.loanTovalue}</p>}

                    </Space>

                    {/* <input type="number" class="form-control" id="closingCost"
                                        placeholder='Enter Closing Costs'
                                        name="closing_cost"
                                        value={property.closing_cost.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        onChange={changeHandler}
                                        required
                                    /> */}
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">ROI %</label>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <NumberFormat
                        style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="roi_percent"
                        placeholder="Enter ROI%"
                        name="roi_percent"
                        // thousandSeparator={true}
                        value={purchaseState?.roi_percent}
                        onChange={changeHandler}
                      />
                      {purchaseState?.roi_percent > 100 && <p className='text-danger'>{error.roi}</p>}

                    </Space>

                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">ROI</label>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <NumberFormat
                        style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="roi"
                        placeholder="Enter ROI"
                        name="roi"
                        thousandSeparator={true}
                        value={purchaseState?.roi}
                        onChange={changeHandler}
                      />
                    </Space>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
      <div class='col-12 matrix-card'>
        <div class=''>
          <div className="p-2">
            <div class='mb-4 mt-2'>
              <div class='d-flex justify-content-between align-content-center'>
                <FormHeader theme={theme}>Sell Scenarios :</FormHeader>
              </div>
            </div>
            <div className=''>
              <form action="" id="deal-form" >
                <div class="row g-3">

                  <div class="col-sm-6 col-md-3 ">
                    <label for="purchasePrice" class="form-label">Sell Price</label>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <NumberFormat
                        style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="sell_price"
                        placeholder="Enter Sell Price"
                        name="sell_price"
                        thousandSeparator={true}
                        value={purchaseState?.sell_price}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="closingCost" class="form-label">Other Fees</label>
                    <Space onClick={handleOpenFeesBox2} direction="vertical" style={{ width: '100%' }}>
                      <NumberFormat
                        style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="sell_fees"
                        placeholder="Enter Fees"
                        name="sell_fees"
                        thousandSeparator={true}
                        value={purchaseState?.sell_fees || numberWithCommas(totalFeesCost2)} onChange={changeHandler}
                      />

                    </Space>

                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">Taxes %</label>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <NumberFormat
                        style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="taxes_percent"
                        placeholder="Enter Taxes Percent"
                        name="taxes_percent"
                        // thousandSeparator={true}
                        value={purchaseState?.taxes_percent}
                        onChange={changeHandler}
                      />
                      {purchaseState?.taxes_percent > 100 && <p className='text-danger'>{error.taxes}</p>}

                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">Taxes</label>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <NumberFormat
                        style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="taxes"
                        placeholder="Enter Taxes"
                        name="taxes"
                        thousandSeparator={true}
                        value={purchaseState?.taxes}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>

                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">Potential Profit</label>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <NumberFormat
                        style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="net_profit"
                        placeholder="Enter Profit"
                        name="net_profit"
                        thousandSeparator={true}
                        value={purchaseState?.net_profit}
                        onChange={changeHandler}
                      />
                    </Space>

                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">Profit %</label>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <NumberFormat
                        style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="net_profit_percent"
                        placeholder="Enter Profit %"
                        name="net_profit_percent"
                        // thousandSeparator={true}
                        value={purchaseState?.net_profit_percent}
                        onChange={changeHandler}
                      />
                    </Space>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >

      <Modal open={openBoxModal} onClose={() => handleCloseFeesBox()}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            '@media (max-width: 992px)': {
              width: '100%',
              overflowY: 'auto',
              height: '100%'
            }
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseFeesBox}
            sx={{ position: 'relative', top: '2%', left: '95%' }}
          >
            <CloseIcon />
          </IconButton>
          {/* <div class="col-lg-7 col-sm-12 matrix-card"> */}
          <div class="mb-4 mt-2">
            <div class="d-flex justify-content-between align-content-center">
            <FormHeader theme={theme}>Fees Box</FormHeader>
              <div> <Button onClick={handleOpenModal} className="btn-dark m-1 py-25">
                Add Fees
              </Button>
                {/* <Button onClick={handleCloseFeesBox} className="m-1 py-25">
                  Close
                </Button> */}
              </div>
            </div>
          </div>
          <Table responsive className="m-0 fees-box"></Table>
          {/* <Collapse isOpen={isOpen}> */}

          <Table responsive className="m-0">
            <thead onClick={handleCollapse}>
              <tr
                style={{
                  color: '#fff',
                  background: theme.palette.primary.main,
                  // background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)", color: "#fff"
                }}
              >
                <th>Description</th>
                <th>Cost</th>
                <th>Cost %</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {feesboxPurchaseState?.length > 0 &&
                feesboxPurchaseState?.map((fees) => {
                  return (
                    <tr
                    style={{
                      color:  mode === 'dark' ?  '#fff' : "#000000",
                    }}>
                      <td>{fees.desc}</td>
                      <td>{CurrencySign}{numberWithCommas(fees.cost)}</td>
                      <td>{fees.cost_percent}%</td>

                      <td>
                        <span>
                          {" "}
                          <BsFillXCircleFill
                            onClick={() => removeFees(fees)}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tbody
              style={{
                color: '#fff',
                background: theme.palette.primary.main,
                // background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)", color: "#fff"
              }}
            >
              <tr className="text-right">
                <td>Total</td>
                <td>{CurrencySign}{numberWithCommas(totalFeesCost) || "0"}</td>
                <td>{totalFeesPercentCost || "0"}%</td>
                <td></td>
              </tr>
            </tbody>
          </Table>

          {/* </Collapse> */}
          <Table responsive className="m-0"></Table>

          {/* </div> */}

          <AddFeesModal
            open={openModal}
            handleSubmit={addFeesData}
            setOpen={setIsOpen}
            handleClose={handleCloseModal}
            data={feesboxPurchaseState}
            feesBoxDispatcher={addFeesBoxPurchase}
            investment={purchaseState?.investment}

          />
        </Box>
      </Modal>


      <Modal open={openBoxModal2} onClose={() => handleCloseFeesBox2()}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            '@media (max-width: 992px)': {
              width: '100%',
              overflowY: 'auto',
              height: '100%'
            }
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseFeesBox2}
            sx={{ position: 'relative', top: '2%', left: '95%' }}
          >
            <CloseIcon />
          </IconButton>
          <div class="mb-4 mt-2">
            <div class="d-flex justify-content-between align-content-center">
            <FormHeader theme={theme}>Fees Box</FormHeader>
              <div> <Button onClick={handleOpenModal2} className="m-1 py-25">
                Add Fees
              </Button>
                {/* <Button onClick={handleCloseFeesBox2} className="m-1 py-25">
                  Close
                </Button> */}
              </div>
            </div>
          </div>
          <Table responsive className="m-0 fees-box"></Table>
          {/* <Collapse isOpen={isOpen}> */}

          <Table responsive className="m-0">
            <thead onClick={handleCollapse2}>
              <tr
                style={{
                  color: '#fff',
                  background: theme.palette.primary.main,
                  // background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)", color: "#fff"
                }}
              >
                <th>Description</th>
                <th>Cost</th>
                <th>Cost %</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {feesboxPurchaseState2?.length > 0 &&
                feesboxPurchaseState2?.map((fees) => {
                  return (
                    <tr
                    style={{
                      color:  mode === 'dark' ?  '#fff' : "#000000",
                    }}>
                      <td>{fees.desc}</td>
                      <td>{CurrencySign}{numberWithCommas(fees.cost)}</td>
                      <td>{fees.cost_percent}%</td>

                      <td>
                        <span>
                          {" "}
                          <BsFillXCircleFill
                            onClick={() => removeFees2(fees)}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tbody
              style={{
                color: '#fff',
                background: theme.palette.primary.main,
                // background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)", color: "#fff"
              }}
            >
              <tr className="text-right">
                <td>Total</td>
                <td>{CurrencySign}{numberWithCommas(totalFeesCost2) || "0"}</td>
                <td>{totalFeesPercentCost2 || "0"}%</td>
                <td></td>
              </tr>
            </tbody>
          </Table>

          {/* </Collapse> */}
          <Table responsive className="m-0"></Table>

          <AddFeesModal
            open={openModal2}
            handleSubmit={addFeesData2}
            setOpen={setIsOpen2}
            data={feesboxPurchaseState2}
            handleClose={handleCloseModal2}
            feesBoxDispatcher={addFeesBoxPurchase2}
            investment={purchaseState?.investment}
          />

          {/* </div> */}
        </Box>
      </Modal>
    </div>
  )
}

export default (MetricsPurchase)
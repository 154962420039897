import { ADD_BUYSELL, ADD_FEESBOX_BUYSELL, ADD_FEESBOX_BUYSELL2, ADD_FEESBOX_DATA, ADD_FEESBOX_PURCHASE, ADD_FEESBOX_PURCHASE2, ADD_PURCHASE, SET_FEES } from "../../actions/metrics"


const initialState = {
  purchase: null,
  buysell: null,
  feesBox : null,
  feeBoxBuysell: [],
  feeBoxPurchase: [],
  feeBoxBuysell2: [],
  feeBoxPurchase2: [],
  fees:null

//   companyId: 0,
}

const metricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PURCHASE:
      return {
        ...state,
        purchase: action.payload
      }
      case ADD_BUYSELL:
      return {
        ...state,
        buysell: action.payload
      }
      case ADD_FEESBOX_DATA:
      return {
        ...state,
        feesBox: action.payload
      }
      case ADD_FEESBOX_BUYSELL:
        return {
          ...state,
          feeBoxBuysell: action.payload
        }
        case ADD_FEESBOX_PURCHASE:
        return {
          ...state,
          feeBoxPurchase: action.payload
        }
        case ADD_FEESBOX_BUYSELL2:
          return {
            ...state,
            feeBoxBuysell2: action.payload
          }
          case ADD_FEESBOX_PURCHASE2:
          return {
            ...state,
            feeBoxPurchase2: action.payload
          }

          case SET_FEES:
          return {
            ...state,
            fees : action.payload
          }
      
    default:
      return state
  }
}

export default metricsReducer

import { useTheme } from "@mui/material"
import numberWithCommas from "../../utilities/extrafunction/commaseparate"
import numberWithOutCommas from "../../utilities/extrafunction/numberWithoutComma"

const InvestorResult = ({ data, CurrencySign }) => {
    const theme = useTheme()
    const {palette}  = theme
    const {mode} = palette
    const cellStyles = {color : palette.text.primary }
    const cellHeadStyles = {color : mode === 'light' ? palette.primary.dark : palette.primary.light, }
// console.log('investor result table', data)

    return (
        <div className="">
            {/* {data.tier && <div className="pb-2 fs-14 mt-1">{data.tier}</div>} */}
            <div className="table-responsive">
                <table className="table data-table align-middle table-borderless sponser-index-table"
                    id="sponser-table">
                    <thead className="table-white border-bottom" style={{ 'fontSize': '14px', 'fontWeight': '400' }}>
                        <tr style={cellHeadStyles}>
                            <th scope="col-1" className="align-middle">Cash Flow Summary</th>
                            <th scope="col-1" className="align-middle" >IRR</th>
                             <th scope="col-1" className="align-middle" >Investment</th>
                                {!(data.class_A_year).length > 0 ? ((data.class_B_year).length > 0 ? data.class_B_year : data.class_C_year) : data.class_A_year}
                             <th scope="col-1" className="text-center">Total</th>
                        </tr> 
                    </thead>
                    <tbody>
                        {data?.investment_class_A > 0 &&
                            <tr style={cellStyles}>
                                <td>Class A</td>
                                {/* {data.tier==='% Capital Outstanding' ? data.class_A+'%': data.class_A} */}
                                {data.irr_class_A && <td>{isNaN(data.irr_class_A)? 0 : ((data.irr_class_A)*100).toFixed(2)} %</td>}
                                {data.investment_class_A && <td>{CurrencySign}{numberWithCommas((data.investment_class_A))}</td>}
                                {data.class_A}
                                {data.totalCashFlowClassA && <td className="text-center">{CurrencySign}{numberWithCommas((data.totalCashFlowClassA).toFixed())}</td>}
                            </tr>}
                        {data?.investment_class_B > 0 &&
                            <tr style={cellStyles} >
                                <td>Class B</td>
                                {data.irr_class_B && <td>{isNaN(data.irr_class_B)? 0 : ((data.irr_class_B)*100).toFixed(2)} %</td>}
                                {data.investment_class_B && <td>{CurrencySign}{numberWithCommas(data.investment_class_B)}</td>}
                                {data.class_B}
                                {data.totalCashFlowClassB && <td className="text-center">{CurrencySign}{numberWithCommas((data.totalCashFlowClassB).toFixed())}</td>}

                            </tr>}
                        {data?.investment_class_C > 0  &&
                            <tr style={cellStyles} >
                                <td>Class C</td>
                                {data.irr_class_C && <td>{isNaN(data.irr_class_C)? 0 : ((data.irr_class_C)*100).toFixed(2)} %</td>}
                                {data.investment_class_C && <td>{CurrencySign}{numberWithCommas(data.investment_class_C)}</td>}
                                {data.class_C}
                                {data.totalCashFlowClassC && <td className="text-center">{CurrencySign}{numberWithCommas((data.totalCashFlowClassC).toFixed())}</td>}
                            </tr>}

                        {(data.total).length > 0 &&
                            <tr style={cellStyles} className="border-top">
                                <td className="fw-6">Total</td>
                                {data.totalInvetment > 0 ?
                                <td className="fw-5">{isNaN(data.totalIRR) ? 0 : ((data.totalIRR)*100).toFixed(2)} %</td>
                                :<td>0.00 %</td>}
                                <td className="align-middle fw-5">{CurrencySign}{numberWithCommas((data.totalInvetment).toFixed(0))}</td>
                                {data.total}
                                <td className="text-center fw-5">{CurrencySign}{numberWithCommas((data.projected_return).toFixed(0))}</td>
                            </tr>}
                            {(data.total_cash_yield).length > 0 &&
                            <tr style={cellStyles} className="border-top">
                                <td className="fw-5">Cash Yield on Investment</td>
                                <td></td>
                                <td className="align-middle"></td>
                                {data.total_cash_yield}
                                <td className="text-center fw-5">{data.totalInvetment ? (((data.projected_return/data.totalInvetment)*100).toFixed(2)) : '0.00' } %</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default InvestorResult 

import { Box, Modal } from '@material-ui/core'
import React from 'react'
import { Button } from 'react-bootstrap'
import { CardText, Row } from 'reactstrap'


const PromptModal = ({open, handleClose, modalContent, handleConfirm}) => {
  return (
    <div>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Open Modal
      </Button> */}
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
          }}
        >
         <CardText>{modalContent}</CardText> 
         <div className='justify-content-between'>
         <Button className='btn btn-danger mx-2' onClick={() => handleConfirm()} sx={{ mt: 2 }}>
            Yes
          </Button>
          <Button className='mx-2' style={{backgroundColor:'#2b4479'}} onClick={() => handleClose()} sx={{ mt: 2 }}>
            Cancel
          </Button>
         </div>
        </Box>
      </Modal>
    </div>
  )
}

export default PromptModal

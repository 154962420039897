import { useEffect, useState } from "react"
import addTotal from "../../utilities/extrafunction/addTotal"
import numberWithCommas from "../../utilities/extrafunction/commaseparate"
import MainClient from "../../utilities/MainClient"
import CapitalResultTable from "../sponsor/CapitalResultTable"
import { useTheme } from "@mui/material"
import { FormHeader, FormLabel } from "./FormElements"
// import CapitalResultTable from "./CapitalResultTable"
// import ResultTable from "./ResultTable"

const CapitalResult = ({ data, cpData }) => {
    const [len, setLen] = useState(1)
    // console.log('cpData', cpData[1].tier)
    const theme = useTheme()
    const { palette } = theme
    const { mode } = palette
    const cellStyles = { color: palette.text.primary }
    const cellHeadStyles = {
        color: mode === 'light' ? palette.primary.dark : palette.primary.light,
        
    }
    useEffect(() => {
        let dataLen = data.tier_1_distribution_cp[0].data.length
        if (dataLen < 5) {
            setLen(3)
        }
        else if ((dataLen < 8)) {
            setLen(2)
        }
        else {
            setLen(1)
        }
    }, []);

    const tier1 = cpData[0]?.tier
    // pref return
    const pref_ret_class_A_year = data.tier_1_distribution_cp[0].data.length > 0 ? data.tier_1_distribution_cp[0].data.map((dt) =>
        <th className={`text-center col-1`}>Year {dt.year}</th>) : ''
    const class_A_tier_1 = data.tier_1_distribution_cp[0].data.length > 0 ? data.tier_1_distribution_cp[0].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const class_B_tier_1 = data.tier_1_distribution_cp[1].data.length > 0 ? data.tier_1_distribution_cp[1].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const class_C_tier_1 = data.tier_1_distribution_cp[2].data.length > 0 ? data.tier_1_distribution_cp[2].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const total_sum_tier_1 = data.total_sum_tier_1_cp.length > 0 ? data.total_sum_tier_1_cp.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const cashflow_remaining_Tier_1 = data.cashflow_remaining_tier_1_cp.length > 0 ? data.cashflow_remaining_tier_1_cp.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const tier_1_data = {
        "tier": tier1,
        "class_A_year": pref_ret_class_A_year,
        "class_A": class_A_tier_1,
        "class_B": class_B_tier_1,
        "class_C": class_C_tier_1,
        "total": total_sum_tier_1,
        "rem": cashflow_remaining_Tier_1,
        "sponsor": '',
        'class_A_Total': addTotal(data.tier_1_distribution[0].data),
        'class_B_Total': addTotal(data.tier_1_distribution[1].data),
        'class_C_Total': addTotal(data.tier_1_distribution[2].data),
        'tTotal': addTotal(data.total_sum_tier_1_cp),
        'remTotal': addTotal(data.cashflow_remaining_tier_1_cp)
    }

    // tier 2

    const tier2 = cpData[1]?.tier
    const class_A_tier_2 = data.tier_2_distribution_cp[0].data.length > 0 ? data.tier_2_distribution_cp[0].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const class_B_tier_2 = data.tier_2_distribution_cp[1].data.length > 0 ? data.tier_2_distribution_cp[1].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const class_C_tier_2 = data.tier_2_distribution_cp[2].data.length > 0 ? data.tier_2_distribution_cp[2].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const equity_percentage_class_A = data.equity_percentage_class_A.length > 0 ? data.equity_percentage_class_A[0].value : ''
    const equity_percentage_class_B = data.equity_percentage_class_B.length > 0 ? data.equity_percentage_class_B[0].value : ''
    const equity_percentage_class_C = data.equity_percentage_class_C.length > 0 ? data.equity_percentage_class_C[0].value : ''
    const total_sum_tier_2 = data.total_sum_tier_2_cp.length > 0 ? data.total_sum_tier_2_cp.map((dt) =>
    <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const tire_2__sponsor = data.sponsor_tier_2_cp ? data.sponsor_tier_2_cp.map((dt) =>
        <td className="text-center">{numberWithCommas(dt.value)}</td>) : ''

    const tier_2_data = {
        "tier": tier2,
        "class_A_year": pref_ret_class_A_year,
        "class_A": class_A_tier_2,
        "class_B": class_B_tier_2,
        "class_C": class_C_tier_2,
        "class_A_equity": equity_percentage_class_A,
        "class_B_equity": equity_percentage_class_B,
        "class_C_equity": equity_percentage_class_C,
        "total": total_sum_tier_2,
        "sponsor" : tire_2__sponsor,
        'class_A_Total': addTotal(data.tier_2_distribution_cp[0].data),
        'class_B_Total': addTotal(data.tier_2_distribution_cp[1].data),
        'class_C_Total': addTotal(data.tier_2_distribution_cp[2].data),
        'tTotal': addTotal(data.total_sum_tier_2_cp),
        'sponsorTotal': data.sponsor_tier_2_cp ? addTotal(data.sponsor_tier_2_cp) : 0
    }
    
    // tier 3

    const tier3 = cpData[2]?.tier
    const class_A_tier_3 = data.tier_3_distribution_cp ? data.tier_3_distribution_cp[0].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const class_B_tier_3 = data.tier_3_distribution_cp ? data.tier_3_distribution_cp[1].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const class_C_tier_3 = data.tier_3_distribution_cp ? data.tier_3_distribution_cp[2].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    // const equity_percentage_class_A = data.equity_percentage_class_A.length > 0 ? data.equity_percentage_class_A[0].value : ''
    // const equity_percentage_class_B = data.equity_percentage_class_B.length > 0 ? data.equity_percentage_class_B[0].value : ''
    // const equity_percentage_class_C = data.equity_percentage_class_C.length > 0 ? data.equity_percentage_class_C[0].value : ''
    const total_sum_tier_3 = data.total_sum_tier_3_cp ? data.total_sum_tier_3_cp.map((dt) =>
    <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const tire_3__sponsor = data.sponsor_tier_3_cp ? data.sponsor_tier_3_cp.map((dt) =>
        <td className="text-center">{numberWithCommas(dt.value)}</td>) : ''

    const tier_3_data = {
        "tier": tier3,
        "class_A_year": pref_ret_class_A_year,
        "class_A": class_A_tier_3,
        "class_B": class_B_tier_3,
        "class_C": class_C_tier_3,
        "class_A_equity": equity_percentage_class_A,
        "class_B_equity": equity_percentage_class_B,
        "class_C_equity": equity_percentage_class_C,
        "total": total_sum_tier_3,
        "sponsor" : tire_3__sponsor,
        'class_A_Total': data.tier_3_distribution_cp ? addTotal(data.tier_3_distribution_cp[0].data) : '',
        'class_B_Total': data.tier_3_distribution_cp ? addTotal(data.tier_3_distribution_cp[1].data) : '',
        'class_C_Total': data.tier_3_distribution_cp ? addTotal(data.tier_3_distribution_cp[2].data) : '',
        'tTotal': addTotal(data.total_sum_tier_3_cp),
        'sponsorTotal': data.sponsor_tier_3_cp ? addTotal(data.sponsor_tier_3_cp) : 0
    }
    // total distributions
    // const tier2 = waterfall[1].tier
    const total_distribution_case_class_A = data.total_dist_case_cp[0].data.length > 0 ? data.total_dist_case_cp[0].data.map((dt) =>
    <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const total_distribution_case_class_B = data.total_dist_case_cp[1].data.length > 0 ? data.total_dist_case_cp[1].data.map((dt) =>
    <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const total_distribution_case_class_C = data.total_dist_case_cp[2].data.length > 0 ? data.total_dist_case_cp[2].data.map((dt) =>
    <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const total_distribution_case_sponsor = data.total_dist_case_sponsor_cp.length > 0 ? data.total_dist_case_sponsor_cp.map((dt) =>
    <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const sum_total_distrinution_case_all_class = data.sum_total_dist_case_cp.length > 0 ? data.sum_total_dist_case_cp.map((dt) =>
    <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const total_distribution = {
        "tier": "Total Distribution For The Case",
        "class_A_year": pref_ret_class_A_year,
        "class_A": total_distribution_case_class_A,
        "class_B": total_distribution_case_class_B,
        "class_C": total_distribution_case_class_C,
        "total": sum_total_distrinution_case_all_class,
        "sponsor": total_distribution_case_sponsor,
        'class_A_Total': addTotal(data.total_dist_case_cp[0].data),
        'class_B_Total': addTotal(data.total_dist_case_cp[1].data),
        'class_C_Total': addTotal(data.total_dist_case_cp[2].data),
        'tTotal': addTotal(data.total_dist_case_sponsor_cp),
        'sponsorTotal': addTotal(data.sum_total_dist_case_cp)
    }

    return (
        <div className="mb-3" >
            <div className="col-12">
                <div  className="card">
                    <div style={{backgroundColor: palette.customColors.bodyBg}}  className="card-body">
                        <div className='data-header'>
                            <div className="d-flex justify-content-between align-items-center">
                                <FormHeader theme={theme}>Capital Event Cash Flow</FormHeader>
                                {/* <h4 className="mb-0 fs-14 ">Total Capitalization : <span
                                    className="text-black fw-bold">${totalCapital ? numberWithCommas(totalCapital) : 0}</span> </h4> */}

                            </div>
                        </div>
                        <div className="data-body sponser-data-body mt-3">
                            {/* <div className="pb-2 fs-16 fw-6">Pref Return</div> */}
                            <CapitalResultTable data={tier_1_data} len={len} />
                            <CapitalResultTable data={tier_2_data} len={len} tierTwo=''/>
                            {data.tier_3_distribution_cp &&
                            <CapitalResultTable data={tier_3_data} len={len} tierTwo='' /> }
                            <CapitalResultTable data={total_distribution} len={len} />
                            {/* {toString(data.result1)} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CapitalResult
function numberWithCommas(x, isInteger) {
    const out = (parseFloat(x)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    const outInteger = (parseInt(x)?.toFixed(0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))

    if (out || !isNaN(out)){
        return isInteger ? outInteger : out
    } else {
        return ''
    }
    
}

export default numberWithCommas
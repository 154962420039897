import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import MainClient from "../../utilities/MainClient";
import PublicUserContext from "../../pages/PublicUserContext";
import { message } from "antd";

function SubscribeDialog({ showDialog, setShowDialog }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [csrfToken, setCsrfToken] = useState(null);

  const [isFormValid, setFormValid] = useState(true);

  const { dealUser, dealIid } = useContext(PublicUserContext);

  console.log("DealUser is", dealUser);
  console.log("DealUserID is is", dealIid);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleContactChange = (event) => {
    setContact(event.target.value);
  };

  const handelEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // const fetchClient = new MainClient('/deal/api/sharetoken/');
  // const fullURL = fetchClient.getFullURL();
  // console.log('Full URL:', fullURL);

  const handleSendEmail = async () => {
    try {
      const emailData = {
        name: name, //Name of Public user
        email: email, //Email of Public User
        contact: contact, //contact number of Public user
        deal_user_email: dealUser, //admin or sponsor Email
        deal_user_Id: dealIid, //Deal Id
      };

      console.log("Email Data is", emailData);

      const fetchClient_cookie = new MainClient("/deal/csrf_cookie/");
      const fullURL_csrf = fetchClient_cookie.getFullURL();

      const response = await axios.get(fullURL_csrf);
      const csrfToken = response.data.csrfToken;
      console.log("Csrf Token", csrfToken);

      const fetchClient_emai = new MainClient("/deal/send_email/");
      const fullURL_email = fetchClient_emai.getFullURL();

      await axios.post(fullURL_email, emailData, {
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
      });

      console.log("Email sent successfully.");
      message.success("Subscription Request Sent.");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!name || !email || !contact) {
      setFormValid(false);
      return;
    } else {
      handleSendEmail();

      console.log("Name:", name);
      console.log("Contact:", contact);
      // Reset the form inputs
      setName("");
      setContact("");
      setEmail("");
      console.log("CSRF TOEKN IS ", csrfToken);
      // Hide the dialog box
      setShowDialog(false);
    }
  };

  return (
    <Modal show={showDialog} onHide={() => setShowDialog(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Subscribe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isFormValid && (
          <div style={{ zIndex: 99999 }}>
            <div
              style={{
                backgroundColor: "#ffffcc",
                padding: "10px",
                margin: "10px 0",
                border: "1px solid #ccc",
                borderRadius: "4px",
                color: "#333",
                fontWeight: "bold",
              }}
            >
              Warning: Please fill out all the fields in the form.
            </div>
          </div>
        )}

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={handleNameChange}
            />
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={handelEmailChange}
            />
          </Form.Group>

          <Form.Group controlId="contact">
            <Form.Label>Contact</Form.Label>
            <Form.Control
              type="number"
              value={contact}
              onChange={handleContactChange}
            />
          </Form.Group>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              variant="primary"
              type="submit"
              style={{ backgroundColor: "#15223c", borderColor: "#15223c" }}
            >
              Subscribe
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default SubscribeDialog;

import { useTheme } from '@mui/material';
import React from 'react';
import Select from 'react-select';

const MultiSelectComponent = ({options, value, setValue, defaultName, placeholder}) => {
 
  const theme = useTheme()
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.customColors.bodyBg,
      color: theme.palette.text.primary
      // Add multiselect styles here
    }),
    // control: (provided, state) => ({
    //   ...provided,
    //   backgroundColor: state.isFocused ? '#444' : '#333', // Adjust background color for focus
    //   borderColor: state.isFocused ? '#666' : '#555', // Adjust border color for focus
    //   color: '#fff', // Text color
    // }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: theme.palette.customColors.bodyBg ,
      color: theme.palette.text.primary, // Adjust text color for selected
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.customColors.bodyBg, // Menu background color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.palette.text.primary, // Text color for the selected value
    }),
  };
  const handleChange = (selectedOptions) => {
    // const selectedValues = selectedOptions.map(op => op.label)?.toString()
    console.log('selectedValues', selectedOptions)
    setValue({...value, ...{ [defaultName]: {selected : selectedOptions}}})
  }

  const defaultValue = value[defaultName]?.selected
  console.log('defaultValue', defaultValue)

  return (
    <Select
    styles={customStyles}
    menuPlacement='auto'
    value={defaultValue}
    placeholder={placeholder}
      isMulti
      options={options}
      onChange={(selectedOptions) => handleChange(selectedOptions)}
    />
  );
};

export default MultiSelectComponent;
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import DashboardHeader from '../components/Dashboard/DashboardHeaderTwo';
import InvestorDashboard from '../components/Dashboard/InvestorDashboard';
import RecipeReviewCard from '../components/Dashboard/MuCard';
import { DefaultImage } from '../components/static/DefaultImgUrl';
import { dealCardData } from '../data/dealCard';
import MainClient from '../utilities/MainClient';
import NewDashboard from '../components/newDashboard/NewDashboard';
import { SettingsConsumer, SettingsProvider } from '@core/context/settingsContext';
import ThemeComponent from '@core/theme/ThemeComponent';
import { Card, CardContent, CardMedia, Typography, useTheme } from '@mui/material';
import PropertyCard from '../components/newDashboard/PropertyCard';

const MainDashboard = ({ userType }) => {

  const theme = useTheme()

  const [search, setSearch] = useState();
  const [dealData, setDealData] = useState();
  const ImageUrl = [
    'https://stackshares.io/media/deal/2022-11-01_12_11_25-GODWIN_TERRACE_3045_-_PICTURE_BOOK.pdf.png',
    'https://stackshares.io/media/deal/Multifamily-Apartment-Property-Management-Basics_lvHmafc.jpg',
    'https://stackshares.io/media/deal/DJI_0883_pfhCczU.jpg',
    // 'https://firebasestorage.googleapis.com/v0/b/stackshare-ebcb9.appspot.com/o/pexels-pixabay-280222.jpg?alt=media&token=ba4d8cd5-fe06-46fb-876e-e60addc030dc',
    'https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/dealImages%2FdealImage1.jpeg?alt=media&token=eaf272a4-6ef7-4b1d-aabe-82424a9b9f38',
    'https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/dealImages%2FdealImage2.jpeg?alt=media&token=990ba777-4cef-41d2-a315-90ac39afb285',
    'https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/dealImages%2FdealImage3.jpg?alt=media&token=907fcf95-5b6c-4050-93b5-e5f6f84f32af',
    'https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/dealImages%2FdealImage4.jpeg?alt=media&token=936c9a4d-950c-420d-a569-311709ed68e8',
    'https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/dealImages%2FdealImage5.jpg?alt=media&token=0e4a4723-ee4e-4b15-8d7b-0f0666da299a',
    'https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/dealImages%2FdealImage6.jpg?alt=media&token=9b09f737-5906-4c7d-a943-84ae21c8ea37',
    'https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/dealImages%2FdealImage7.jpg?alt=media&token=786468a2-e21c-47ae-94d0-4caa012e7307',
    'https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/dealImages%2FdealImage8.jpg?alt=media&token=a9d7a0bb-49a7-435d-bae4-138b97fde3f4',
    'https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/dealImages%2FdealImage9.jpeg?alt=media&token=9f04161c-9b14-4caf-b6f0-478163790d0f',
    'https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/dealImages%2FdealImage10.jpg?alt=media&token=fd754a55-8a97-4f33-add7-ff0032ac6cf6',
  ];
  // const data = styled.div`
  // display-flex justify-content-start align-items-start flex-wrap
  // `
  console.log('userType', userType)
  useEffect(() => {
    const dynamicSearch = userType && userType === "admin" ? "Open Deals" : search || 'Offerings'

    setSearch(dynamicSearch)
    const getData = () => {
      const fetchClient = new MainClient(
        // `/investor/invited-deal?get_type=${search}`
        `/deal/get-deals?deal_status=${search}`

      );

      const response = fetchClient.getAPI();
      response
        .then((res) => {
          console.log('response', res.data);
          if (search === 'Offerings') {
            setDealData(res.data.results.reverse());
          } else {
            setDealData(res.data.result.reverse());
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
    const getInvestorData = () => {
      const fetchClient = new MainClient(
        `/investor/invited-deal?get_type=${search}`
        // `/deal/get-deals?deal_status=${search}`

      );

      const response = fetchClient.getAPI();
      response
        .then((res) => {
          console.log('response', res.data);
          if (search === 'Offerings') {
            setDealData(res.data.results);
          } else {
            setDealData(res.data.result);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
    if (userType == "admin") {
      // setDealData(dealCardData);
      getData()
      // }else if (userType === "investor"){
    } else {
      getInvestorData()
    }
    // getData()
  }, [search, userType]);

  //   const title = styled.div`
  //     font-size: 14px;
  //     font-color: yellow;
  //   `;
  const card = dealData?.reverse()?.map((dt, index) => {

    // console.log("dt", dt);
    // console.log("TOTAL IMAGES", dt.image_data);


    let imgUrl = DefaultImage;
    let subPath = userType == "admin" ? '/media/' : userType === "investor" && search === 'Subscriptions' ? "/media/" : ""
    if (dt.image_data?.length > 0) {
      imgUrl = `http://my.gulfstreaminvestments.io${subPath}${dt.image_data[0]?.images}`;
      // imgUrl = `http://localhost:8000${subPath}${dt.image_data[0]?.images}`;

    }
    console.log('imgUrl', imgUrl)
    
    return (<div className='pe-1 col-md-6 col-sm-12'>
      {/* <RecipeReviewCard data={dt} image={imgUrl} search={search} userType={userType} /> */}
      <PropertyCard data={dt} image={imgUrl} search={search} userType={userType} />

    </div>);
  });

  console.log('cardData', userType);
  return (
    <div style={{backgroundColor:theme.palette.background.paper}}>
      {/* <DashboardHeader /> */}
      { userType === 'investor' && <InvestorDashboard userType={userType} />}
   
     { userType === 'admin' && <NewDashboard userType={userType} />}
              

      <div class='row mb-3'>
        {/* <h2 class="fs-20 fw-6 text-dark mb-3">Dashboard</h2> */}
        <div class='col-sm-12'>
          <ul
            class='nav nav-pills mb-3 stack-ul data-deal-role'
            id='pills-tab'
            role='tablist'
          >
            <li
              class='nav-item investors-tabs'
              role='presentation'
              onClick={() => setSearch(userType && userType === "admin" ? "Open Deals" : 'Offerings')}
            >
              <button
                class='nav-link active'
                id='pills-home-tab'
                data-bs-toggle='pill'
                data-bs-target='#sub-investor'
                type='button'
                role='tab'
                aria-controls='sub-investor'
                aria-selected='true'
              >
                Offerings
              </button>
            </li>
            {/* <li
              class='nav-item investors-tabs'
              role='presentation'
              onClick={() => setSearch('Offerings')}
            >
              <button
                class='nav-link active'
                id='pills-home-tab'
                data-bs-toggle='pill'
                data-bs-target='#sub-investor'
                type='button'
                role='tab'
                aria-controls='sub-investor'
                aria-selected='true'
              >
                Offerings
              </button>
            </li> */}
            <li
              class='nav-item investors-tabs ms-4'
              role='presentation'
              onClick={() => setSearch('Subscriptions')}
            >
              <button
                class='nav-link'
                id='pills-profile-tab'
                data-bs-toggle='pill'
                data-bs-target='#invited'
                type='button'
                role='tab'
                aria-controls='invited'
                aria-selected='false'
              >
                Subscriptions
              </button>
            </li>
          </ul>
        </div>
      </div>
      {dealData?.length > 0 ? (
        <div className={`d-flex ${dealData?.length > 1 ? 'justify-content-around' : 'justify-content-start'} align-items-start flex-wrap`}>
          {card}
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center flex-wrap'>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/icon%2Ficons8-file.svg?alt=media&token=7dc87d3d-a246-46a7-b6f2-741064a23799'
            style={{ width: '300px' }}
          />
        </div>
      )}
    </div>
  );
};

export default MainDashboard;

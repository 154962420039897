import { useState } from "react"
import { Outlet } from "react-router-dom"
import MainSideNav from "../components/static/MainSideNav"
import NewHeader from "../components/static/NewHeader"
import NewSidebar from "../components/static/NewSidebar"
import { useTheme } from "@mui/material"

const DashboardLayout = ({ user }) => {
    const [tg, setTg] = useState(true)
    const tgHandler = () => {
        console.log('tgHnadler', tg)
        setTg(!tg)
    }
    const theme = useTheme()
    return (
        <div className={tg && 'xp-toggle-menu'} style={{ background: theme.palette.background.paper, width: "auto", position: "relative", minHeight: "100vh" }}>
            <MainSideNav user={user} tg={tg} tgHandler={tgHandler} />
            <div class="xp-rightbar" style={{ width: "auto" }} >
                <div class="xp-contentbar">
                    <NewHeader user={user} />
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
export default DashboardLayout
export const ADD_COMPANY = 'ADD_COMPANY'
export const ADD_PARTNER = 'ADD_PARTNER'
export const LIMIT_COMPANY = 'LIMIT_COMPANY'
export const SET_COMPANY = 'SET_COMPANY'



export const setAddCompany = (company) => ({
  type: ADD_COMPANY,
  payload: company
})
export const setLimitCompany = (limit) => ({
  type: LIMIT_COMPANY,
  payload: limit
})
export const setAddPartner = (partner) => ({
  type: ADD_PARTNER,
  payload: partner
})

export const setCompany = (companyId) => ({
  type: SET_COMPANY,
  payload: companyId
})

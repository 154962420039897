import { InputNumber, Space, message } from "antd";
import { Table, Collapse, Button } from "reactstrap";
import React, { useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import numberWithCommas from "../../utilities/extrafunction/commaseparate";
import { useDispatch, useSelector } from "react-redux";
import numberWithOutCommas from "../../utilities/extrafunction/numberWithoutComma";
import { addBuySellData, addFeesBoxBuySell, addFeesBoxBuySell2 } from "../../store/actions/metrics";
import AddFeesModal from "./feesModal/AddFeesModal";
import { BsFillXCircleFill } from "react-icons/bs";
import { Box, IconButton, Modal, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { getInputStyles } from "./FormStyles";
import { FormHeader } from "./FormElements";
function MetricsBuySell({
  total,
  metrics,
  setMetrics,
  entryNoiSelect,
  setEntryNoiSelect,
  stabilizedNoiSelect,
  setStabilizedNoiSelect,
  proNoiSelect,
  setProNoiSelect,
  updateHandler,
  id,
  submit,
  squareFeet,
}) {
  const feesboxState = useSelector((state) => state.metrics.feesBox);
  const feesboxBuySellState = useSelector(
    (state) => state.metrics.feeBoxBuysell
  );
  const feesboxBuySellState2 = useSelector(
    (state) => state.metrics.feeBoxBuysell2
  );

  const theme = useTheme()
  const {mode} = theme.palette
  const inputStyles = getInputStyles(theme) // get input style from import

  console.log("feesboxBuySellState", feesboxBuySellState);
  console.log("feesboxState", feesboxState);
  const buysellState = useSelector((state) => state.metrics.buysell);
  console.log("buysellState", buysellState);
  const [buysellM, setBuySellM] = useState({});

  const [feeBox, setFeesBox] = useState([]);
  const error = {
    loanTovalue: 'Loan to value(%) should be lower or equal to 100%',
    roi: 'ROI(%) should be lower or equal to 100%',
    taxes: 'Taxes(%) should be lower or equal to 100%',
    market: 'Market(%) should be lower or equal to 100%'
  }
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openBoxModal, setOpenBoxModal] = useState(false);

  const [isOpen2, setIsOpen2] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openBoxModal2, setOpenBoxModal2] = useState(false);
  const dispatch = useDispatch();
  const handleCollapse = () => setIsOpen(!isOpen2);

  const removeFees = (payload) => {
    const newArray = feesboxBuySellState?.filter((f) => f !== payload);
    dispatch(addFeesBoxBuySell(newArray))
  };
  const handleCollapse2 = () => setIsOpen2(!isOpen);
  const removeFees2 = (payload) => {
    const newArray = feesboxBuySellState2?.filter((f) => f !== payload);
    dispatch(addFeesBoxBuySell2(newArray))
  };
  const changeHandler = (e) => {
    const { name, value } = e.target
    //   setPurchaseM({  
    //   ...purchaseM,
    //   [name]: numberWithOutCommas(value)
    // });
    console.log('{name, value}', name, value)
    // if (name && value && !isNaN(value)) {
    dispatch(addBuySellData({
      ...buysellState,
      [name]: numberWithOutCommas(value)
    }))
    // }

  };

  const calculateLoanTovalue = useMemo(() => {
    const loanTovalue = buysellState?.investment / buysellState?.valuation * 100;
    return { ...buysellState, loan_tovalue: loanTovalue.toFixed(2) };
  }, [buysellState?.investment, buysellState?.valuation]);

  const calculateROI = useMemo(() => {
    const ROI = buysellState?.roi_percent * buysellState?.investment;
    return { ...buysellState, roi: (ROI.toFixed(2) / 100).toString() };
  }, [buysellState?.investment, buysellState?.roi_percent]);

  const calculateTax = useMemo(() => {
    const taxes_amount = buysellState?.taxes_percent * buysellState?.sell_price;
    return { ...buysellState, taxes: (taxes_amount.toFixed(2) / 100).toString() };
  }, [buysellState?.taxes_percent, buysellState?.sell_price]);

  const calculateProfit = useMemo(() => {
    const profit = buysellState?.sell_price - buysellState?.investment - (buysellState?.fees || 0) - (calculateTax?.taxes || 0)
    return { ...buysellState, net_profit: profit.toFixed(2) };
  }, [buysellState?.taxes, buysellState?.sell_price, buysellState?.investment, buysellState?.fees]);

  const calculateProfitPerc = useMemo(() => {
    const profit_perc = buysellState?.net_profit / buysellState?.investment * 100;
    return { ...buysellState, net_profit_percent: profit_perc.toFixed(2) };
  }, [buysellState?.net_profit, buysellState?.investment]);

  // Default buysell calculations
  const calculateDefaultSellPrice = useMemo(() => {
    const amount = buysellState?.default_market_value * buysellState?.valuation;
    return { ...buysellState, default_sellprice: (amount.toFixed(2) / 100).toString() };
  }, [buysellState?.default_market_value]);

  const calculateDefaultTax = useMemo(() => {
    const taxes_amount = buysellState?.default_taxes_percent * buysellState?.default_sellprice;
    return { ...buysellState, default_taxes: (taxes_amount.toFixed(2) / 100).toString() };
  }, [buysellState?.default_taxes_percent, buysellState?.default_sellprice]);

  const calculateDefaultProfit = useMemo(() => {
    const profit = buysellState?.default_sellprice - buysellState?.investment - (buysellState?.default_fees || 0) - (calculateDefaultTax?.default_taxes || 0); // added updated profit
    return { ...buysellState, default_netprofit: profit.toFixed(2) };
  }, [buysellState?.default_taxes, buysellState?.default_sellprice, buysellState?.investment, buysellState?.default_fees]);

  const calculateDefaultProfitPerc = useMemo(() => {
    const profit_perc = buysellState?.default_netprofit / buysellState?.investment * 100;
    return { ...buysellState, default_netprofit_percent: profit_perc.toFixed(2) };
  }, [buysellState?.default_netprofit, buysellState?.investment]);

  // Dispatch the results in useEffect hooks
  useEffect(() => {
    dispatch(addBuySellData(calculateLoanTovalue));
    console.log('useEffect 1 is running.....');
  }, [calculateLoanTovalue]);

  useEffect(() => {
    dispatch(addBuySellData(calculateROI));
    console.log('useEffect 2 is running.....');
  }, [calculateROI]);

  useEffect(() => {
    dispatch(addBuySellData(calculateTax));
    console.log('useEffect 3 is running.....');
  }, [calculateTax])

  useEffect(() => {
    dispatch(addBuySellData(calculateProfit));
    console.log('useEffect 4 is running.....');
  }, [calculateProfit])

  useEffect(() => {
    dispatch(addBuySellData(calculateProfitPerc));
    console.log('useEffect 5 is running.....');
  }, [calculateProfitPerc])

  useEffect(() => {
    dispatch(addBuySellData(calculateDefaultSellPrice));
    console.log('useEffect 6 is running.....');
  }, [calculateDefaultSellPrice])

  useEffect(() => {
    dispatch(addBuySellData(calculateDefaultProfit));
    console.log('useEffect 7 is running.....');
  }, [calculateDefaultProfit])

  useEffect(() => {
    dispatch(addBuySellData(calculateDefaultProfitPerc));
    console.log('useEffect 8 is running.....');
  }, [calculateDefaultProfitPerc])

  useEffect(() => {
    dispatch(addBuySellData(calculateDefaultTax));
    console.log('useEffect 9 is running.....');
  }, [calculateDefaultTax])

  const State = useSelector((state) => state);
  const CurrencySign = State.deal?.currency?.sign

  const totalFeesCost = feesboxBuySellState
    ?.map((f) => f.cost)
    .reduce((a, c) => parseFloat(a) + parseFloat(c), 0)?.toFixed(2);
  const totalFeesPercentCost = feesboxBuySellState
    ?.map((f) => f.cost_percent)
    .reduce((a, c) => parseFloat(a) + parseFloat(c), 0)?.toFixed(2);

  const totalFeesCost2 = feesboxBuySellState2
    ?.map((f) => f.cost)
    .reduce((a, c) => parseFloat(a) + parseFloat(c), 0)?.toFixed(2);
  const totalFeesPercentCost2 = feesboxBuySellState2
    ?.map((f) => f.cost_percent)
    .reduce((a, c) => parseFloat(a) + parseFloat(c), 0)?.toFixed(2);

  const addFeesData = () => {
    if (feesboxBuySellState) {
      // setFeesBox([...feeBox, ...[feesboxState]]);
      message.success("New Fees Added Successfully !");
    } else {
      message.error("fees data not found !");
    }
    handleCloseModal();
  };

  const addFeesData2 = () => {
    if (feesboxBuySellState) {
      // setFeesBox([...feeBox, ...[feesboxState]]);
      message.success("New Fees Added Successfully !");
    } else {
      message.error("fees data not found !");
    }
    handleCloseModal2();
  };

  const handleCloseFeesBox = () => {
    setOpenBoxModal(false);
  };
  const handleOpenFeesBox = () => {
    setOpenBoxModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(addBuySellData({
      ...buysellState,
      fees: totalFeesCost
    }))
    console.log(" hanle close is working");
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseFeesBox2 = () => {
    setOpenBoxModal2(false);
  };
  const handleOpenFeesBox2 = () => {
    setOpenBoxModal2(true);
  };
  const handleCloseModal2 = () => {
    setOpenModal2(false);
    dispatch(addBuySellData({
      ...buysellState,
      default_fees: totalFeesCost2
    }))
    console.log(" hanle close is working");
  };
  const handleOpenModal2 = () => {
    setOpenModal2(true);
  };


  // console.log('feeBox',feeBox.length)
  // useEffect(() => {
  //   // return () => {
  //   dispatch(addBuySellData(buysellM));
  //   // }
  // }, [buysellM]);

  // useEffect(() => {
  //   dispatch(addFeesBoxBuySell(feeBox));
  //   setBuySellM({...buysellM, ...{fees : totalFeesCost}}) // save fees in records 

  // }, [feeBox]);
  // useEffect(() => {
  //   console.log('only investment useffect running...')
  //   if (buysellState?.fees || buysellState?.default_fees) {
  //     const updatedFees = buysellState?.investment * totalFeesPercentCost / 100
  //     const updatedFees2 = buysellState?.investment * totalFeesPercentCost2 / 100

  //     dispatch(addBuySellData({
  //       ...buysellState,
  //       ...{
  //         fees: updatedFees,
  //         default_fees: updatedFees2
  //       }
  //     }))
  //     console.log('updatedFees', updatedFees, updatedFees2)
  //   }
  //     // setupdateDispatch(!updateDispatch)
  //   })
  // useEffect(() => {
  //   setBuySellM(buysellState);
  //   // setFeesBox(buysellState?.fees_box?.data || feesboxBuySellState);
  // }, []);

  return (
    <div>
      <div class="col-12 matrix-card">
        <div class="">
          <div className="p-2">
            <div class="mb-4 mt-2">
              <div class="d-flex justify-content-between align-content-center">
              <FormHeader theme={theme}>Metrics and Capitalization :</FormHeader>
                {/* <h2
                  class="mb-0 fs-16 fw-5"
                  style={{ color: "#A2ACBD", letterSpacing: "0.2px" }}
                >
                  Total
 </h2> */}
              </div>
            </div>
            <div className="">
              <form action="" id="deal-form">
                <div class="row g-3">
                  <div className="col-lg-3 col-md-2 col-sm-4">
                    <label htmlFor="squareFeet" className="form-label">
                      Investment
                    </label>
                    <NumberFormat
                      type="text"
                      style={inputStyles}
                      className="form-control smaller-input"
                      id="squareFeet"
                      placeholder="Enter Investment"
                      name="investment"
                      thousandSeparator={true}
                      value={buysellState?.investment}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-4">
                    <label htmlFor="squareFeet" className="form-label">
                      Valuation
                    </label>
                    <NumberFormat
                      type="text"
                      style={inputStyles}
                      className="form-control smaller-input"
                      id="squareFeet"
                      placeholder="Enter Valuation"
                      name="valuation"
                      thousandSeparator={true}
                      value={buysellState?.valuation}
                      onChange={changeHandler}
                    />
                  </div>

                  <div class="col-sm-6 col-md-3 ">
                    <label for="closingCost" class="form-label">
                      Loan to Value %
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="loan_tovalue"
                        placeholder="Enter Loan to value"
                        name="loan_tovalue"
                        thousandSeparator={true}
                        value={buysellState?.loan_tovalue}
                        onChange={changeHandler}
                      />
                      {buysellState?.loan_tovalue > 100 && <p className='text-danger'>{error.loanTovalue}</p>}

                    </Space>
                    {/* <input type="number" class="form-control" id="closingCost"
                                        placeholder='Enter Closing Costs'
                                        name="closing_cost"
                                        value={property.closing_cost.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        onChange={changeHandler}
                                        required
                                    /> */}
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">
                      Terms(Months)
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="terms"
                        placeholder="Enter Terms"
                        name="terms"
                        thousandSeparator={true}
                        value={buysellState?.terms}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">
                      ROI %
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="roi_percent"
                        placeholder="Enter ROI%"
                        name="roi_percent"
                        // thousandSeparator={true}
                        value={buysellState?.roi_percent}
                        onChange={changeHandler}
                      />
                      {buysellState?.roi_percent > 100 && <p className='text-danger'>{error.roi}</p>}

                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">
                      ROI
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="roi"
                        placeholder="Enter ROI"
                        name="roi"
                        thousandSeparator={true}
                        value={buysellState?.roi}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 matrix-card">
        <div class="">
          <div className="p-2">
            <div class="mb-4 mt-2">
              <div class="d-flex justify-content-between align-content-center">
              <FormHeader theme={theme}>Buy/Sell Scenarios :</FormHeader>
              </div>
            </div>
            <div className="">
              <form action="" id="deal-form">
                <div class="row g-3">
                  <div class="col-sm-6 col-md-3 ">
                    <label for="purchasePrice" class="form-label">
                      Sell Price
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="sell_price"
                        placeholder="Enter Sell Price"
                        name="sell_price"
                        thousandSeparator={true}
                        value={buysellState?.sell_price}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="purchasePrice" class="form-label">
                      Fees
                    </label>
                    <Space onClick={handleOpenFeesBox} direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="fees"
                        placeholder="Enter Fees"
                        name="fees"
                        thousandSeparator={true}
                        value={buysellState?.fees || numberWithCommas(totalFeesCost) || ''}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">Taxes %</label>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="taxes_percent"
                        placeholder="Enter Taxes Percent"
                        name="taxes_percent"
                        thousandSeparator={true}
                        value={buysellState?.taxes_percent}
                        onChange={changeHandler}
                      />
                      {buysellState?.taxes_percent > 100 && <p className='text-danger'>{error.taxes}</p>}

                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">
                      Taxes
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="taxes"
                        placeholder="Enter Taxes"
                        name="taxes"
                        thousandSeparator={true}
                        value={calculateTax?.taxes}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>

                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">
                      Potential Profit
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="net_profit"
                        placeholder="Enter Profit"
                        name="net_profit"
                        thousandSeparator={true}
                        value={buysellState?.net_profit}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">
                      Profit %
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="net_profit_percent"
                        placeholder="Enter Profit %"
                        name="net_profit_percent"
                        // thousandSeparator={true}
                        value={buysellState?.net_profit_percent}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 matrix-card">
        <div class="">
          <div className="p-2">
            <div class="mb-4 mt-2">
              <div class="d-flex justify-content-between align-content-center">
              <FormHeader theme={theme}>Default Scenarios :</FormHeader>
              </div>
            </div>
            <div className="">
              <form action="" id="deal-form">
                <div class="row g-3">
                  <div class="col-sm-6 col-md-3 ">
                    <label for="purchasePrice" class="form-label">
                      Market Value %
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="default_market_value"
                        placeholder="Enter Market value %"
                        name="default_market_value"
                        thousandSeparator={true}
                        value={buysellState?.default_market_value}
                        onChange={changeHandler}
                      />
                    </Space>
                    {buysellState?.default_market_value > 100 && <p className='text-danger'>{error.market}</p>}

                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="closingCost" class="form-label">
                      Sell Price
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="default_sellprice"
                        placeholder="Enter Sell Price %"
                        name="default_sellprice"
                        thousandSeparator={true}
                        value={buysellState?.default_sellprice}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">
                      Other Fees
                    </label>
                    <Space onClick={handleOpenFeesBox2} direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="default_fees"
                        placeholder="Enter Fees"
                        name="default_fees"
                        thousandSeparator={true}
                        value={buysellState?.default_fees || numberWithCommas(totalFeesCost2)}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">Taxes %</label>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="default_taxes_percent"
                        placeholder="Enter Taxes Percent"
                        name="default_taxes_percent"
                        thousandSeparator={true}
                        value={buysellState?.default_taxes_percent}
                        onChange={changeHandler}
                      />
                      {buysellState?.default_taxes_percent > 100 && <p className='text-danger'>{error.taxes}</p>}

                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">
                      Taxes
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="default_taxes"
                        placeholder="Enter Taxes"
                        name="default_taxes"
                        thousandSeparator={true}
                        value={calculateDefaultTax?.default_taxes}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">
                      Potential Profit
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="default_netprofit"
                        placeholder="Enter Profit"
                        name="default_netprofit"
                        thousandSeparator={true}
                        value={buysellState?.default_netprofit}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                  <div class="col-sm-6 col-md-3 ">
                    <label for="reserves" class="form-label">
                      Profit %
                    </label>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <NumberFormat
                      style={inputStyles}
                        type="text"
                        className="form-control smaller-input"
                        id="default_netprofit_percent"
                        placeholder="Enter Profit"
                        name="default_netprofit_percent"
                        thousandSeparator={true}
                        value={buysellState?.default_netprofit_percent}
                        onChange={changeHandler}
                      />
                    </Space>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal open={openBoxModal} onClose={() => handleCloseFeesBox()}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            '@media (max-width: 992px)': {
              width: '100%',
              overflowY: 'auto',
              height: '100%'
            }
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseFeesBox}
            sx={{ position: 'relative', top: '2%', left: '95%' }}
          >
            <CloseIcon />
          </IconButton>
          <div class="mb-4 mt-2">
            <div class="d-flex justify-content-between align-content-center">
            <FormHeader theme={theme}>Fees Box</FormHeader>
              <div> <Button onClick={handleOpenModal} className="m-1 py-25 btn-dark">
                Add Fees
              </Button>
                {/* <Button onClick={handleCloseFeesBox} className="m-1 py-25">
                  Close
                </Button> */}
              </div>
            </div>
          </div>
          <Table responsive className="m-0 fees-box"></Table>
          {/* <Collapse isOpen={isOpen}> */}

          <Table responsive className="m-0">
            <thead onClick={handleCollapse}>
              <tr
                style={{
                  color: '#fff',
                  background: theme.palette.primary.main,
                  // background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)", color: "#fff"
                }}          >
                <th>Description</th>
                <th>Cost</th>
                <th>Cost %</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {feesboxBuySellState?.length > 0 &&
                feesboxBuySellState?.map((fees) => {
                  return (
                    <tr style={{
                      color:  mode === 'dark' ?  '#fff' : "#000000",
                    }}>
                      <td>{fees.desc}</td>
                      <td>{CurrencySign}{numberWithCommas(fees.cost)}</td>
                      <td>{fees.cost_percent}%</td>

                      <td>
                        <span>
                          {" "}
                          <BsFillXCircleFill
                            onClick={() => removeFees(fees)}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tbody
              style={{
                background: 'rgb(62,62,61)',
                background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)", color: "#fff"
              }}
            >
              <tr className="text-right">
                <td>Total</td>
                <td>{CurrencySign}{numberWithCommas(totalFeesCost) || "0"}</td>
                <td>{totalFeesPercentCost || "0"}%</td>
                <td></td>
              </tr>
            </tbody>
          </Table>

          {/* </Collapse> */}
          <Table responsive className="m-0"></Table>

          <AddFeesModal
            open={openModal}
            handleSubmit={addFeesData}
            setOpen={setIsOpen}
            data={feesboxBuySellState}
            handleClose={handleCloseModal}
            feesBoxDispatcher={addFeesBoxBuySell}
            investment={buysellState?.investment}
          />

          {/* </div> */}
        </Box>
      </Modal>
      <Modal open={openBoxModal2} onClose={() => handleCloseFeesBox2()}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            '@media (max-width: 992px)': {
              width: '100%',
              overflowY: 'auto',
              height: '100%'
            }
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseFeesBox2}
            sx={{ position: 'relative', top: '2%', left: '95%' }}
          >
            <CloseIcon />
          </IconButton>
          <div class="mb-4 mt-2">
            <div class="d-flex justify-content-between align-content-center">
              <FormHeader theme={theme}>Fees Box</FormHeader>
              <div> <Button onClick={handleOpenModal2} className="m-1 py-25">
                Add Fees
              </Button>
                {/* <Button onClick={handleCloseFeesBox2} className="m-1 py-25">
                  Close
                </Button> */}
              </div>
            </div>
          </div>
          <Table responsive className="m-0 fees-box"></Table>
          {/* <Collapse isOpen={isOpen}> */}

          <Table responsive className="m-0">
            <thead onClick={handleCollapse2}>
              <tr
                style={{
                  color: '#fff',
                  background: theme.palette.primary.main,
                  // background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)", color: "#fff"
                }}              >
                <th>Description</th>
                <th>Cost</th>
                <th>Cost %</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {feesboxBuySellState2?.length > 0 &&
                feesboxBuySellState2?.map((fees) => {
                  return (
                  <tr
                    style={{
                      color:  mode === 'dark' ?  '#fff' : "#000000",
                    }}>
                      <td>{fees.desc}</td>
                      <td>{CurrencySign}{numberWithCommas(fees.cost)}</td>
                      <td>{fees.cost_percent}%</td>

                      <td>
                        <span>
                          {" "}
                          <BsFillXCircleFill
                            onClick={() => removeFees2(fees)}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tbody
              style={{
                background: 'rgb(62,62,61)',
                background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)", color: "#fff"
              }}
            >
              <tr className="text-right">
                <td>Total</td>
                <td>{CurrencySign}{numberWithCommas(totalFeesCost2) || "0"}</td>
                <td>{totalFeesPercentCost2 || "0"}%</td>
                <td></td>
              </tr>
            </tbody>
          </Table>

          {/* </Collapse> */}
          <Table responsive className="m-0"></Table>

          <AddFeesModal
            open={openModal2}
            handleSubmit={addFeesData2}
            setOpen={setIsOpen2}
            data={feesboxBuySellState2}
            handleClose={handleCloseModal2}
            feesBoxDispatcher={addFeesBoxBuySell2}
            investment={buysellState?.investment}
          />

          {/* </div> */}
        </Box>
      </Modal>
    </div>
  );
}

export default MetricsBuySell;

import axios from "axios";

class MainClient {
  constructor(url, data, header, signal) {
    this.url = url
    this.data = data
    this.header = header
    this.signal = signal

    this.instance = axios.create({
      baseURL: 'https://my.gulfstreaminvestments.io',
      // baseURL: 'http://localhost:8000',
      signal : signal,
      // baseURL: location.origin,
    });
  }

  getHeader() {
    // console.log(localStorage.getItem('token'))
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  }


  getNewHeader(){
    return{
      headers:{
        Authorization: `Bearer ${localStorage.getItem('temp_token')}`
      }
    }
  }
  
  getFullURL() {
    return this.instance.defaults.baseURL + this.url;
  }
  
  postAPI() {
    return this.instance.post(this.url, this.data, this.getHeader())
  }
  getAPI() {
    return this.instance.get(this.url, this.getHeader())
  }


// This is Only Use For Public URL
  getNewPublicUrl(){
    return this.instance.get(this.url)
  }
  
  getPublicURL(){
    return this.instance.get(this.url,this.getNewHeader())
  }

  postPublicUrl(){
    return this.instance.post(this.url,this.getNewHeader())
  }



  deleteAPI() {
    return this.instance.delete(this.url, this.data, this.getNewHeader())
  }
  putAPI() {
    return this.instance.put(this.url, this.data, this.getHeader())
  }
  patchAPI() {
    return this.instance.patch(this.url, this.data, this.getHeader())
  }
}
export default MainClient

import { configureStore } from '@reduxjs/toolkit'
import dealSlice from './reducers/DealReducers'
import investorSlice from './reducers/InvestorReducer'
import sponsorSlice from './reducers/SponsorReducer'
import userSlice from './reducers/UserReducer'
import companyReducer from './reducers/Companies'
import partnerReducer from './reducers/Partners'
import investorReducer from './reducers/investors'
import dealReducer from './reducers/deals'
import metricsReducer from './reducers/metrics'
import notificationSlice from './reducers/NotificationReducer'



const store = configureStore({
    reducer:{
        user: userSlice.reducer,
        deals: dealSlice.reducer,
        deal:dealReducer,
        investors: investorSlice.reducer,
        sponsors: sponsorSlice.reducer,
        companies: companyReducer,
        partners: partnerReducer,
        assignCompanies: investorReducer,
        metrics:metricsReducer,
        notifications:notificationSlice.reducer
    }
})

export default store
// import './home.css'
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Footer from "../components/static/Footer"

const Wapper = styled.div`
    font-family: verdana, sans-serif;
    // background: url("https://i.vimeocdn.com/video/1334230813-ad7193d1e06f5a6f53de69b673ca4880195c4499025fadee7937ba956a13896a-d_1920x1080?r=pad");
    color: #fff;
    background-attachment: fill;
    background-repeat: no-repeat;
}`

const Nav = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 4rem 1rem 1rem;
    border-bottom: 1px solid #fff;
    position: fixed;
    width:100%;
    @media(max-width: 800px){
        padding: 1.5rem 2rem .5rem .3rem;
    }
`

const Logo = styled.div`
    font-size: 1.5rem;
    width: 15rem;
    cursor: pointer;
    @media(max-width: 800px){
        font-size: 1.5rem;
        width:9rem;
    }
`
const Side = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const Li = styled.a`
    margin: 0 1rem;
    cursor: pointer;
    font-size: 1.2rem;
    color: white;
    &:hover{
        color: black
    }
    @media(max-width: 800px){
        font-size: .6rem;
        margin: 0 .4rem;

    }
`
const Content = styled.div`
    position: abolute;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20%;
    z-index: 1000;
    width: 100%;
    heigth:100%;
    @media(max-width: 800px){
        margin-top: 65%;
    }
`
const Box = styled.div`
    text-align: center;
    margin-top: 6rem;
    z-index: 1000;
`
const Text = styled.div`
    text-align:'center';
    font-size: 3.5rem;
    font-weight: 600;
    @media(max-width: 800px){
        font-size: 1.8rem;
    }

`
const Image = styled.img`
    width: 100%;
    opacity: 10
`
const VideoFrame = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
    margin-top: -15px;
    margin-bottom: -13px;
    @media(max-width: 800px){
        height: 100vh;
    }
`
const Iframe = styled.iframe`
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 177.77777778vh;
`

const NavSection = styled.div`
 position: fixed;
 top:0;
 z-index: 1000;
 width: 100%;
 padding: 0 1.2rem;
`

const P = styled.div`
    font-size : 1rem
`

const Home = ({ accessToken, logOut }) => {
    const [count, setCount] = useState(0)
    const [url, setUrl] = useState([])

    useEffect(() => {
        setUrl([
            'https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/newUpdatedLogo%2Fmain%20logo%20final%20done%20wite%20s2.png?alt=media&token=c1fed041-c11f-4689-a140-d47478642f75',
        ])
    }, [])

    const changeHandler = () => {
        if (count + 1 < url.length) {
            setCount(count + 1)
        }
        else {
            setCount(0)
        }
    }

    const logout = () => {
        logOut()
    }
    return (
        <div>
            <Wapper>
                <VideoFrame >
                    <Iframe data-ux="Embed" id="embed-iframe-10534" src="https://player.vimeo.com/video/660235662?h=59afe41909&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;muted=1&amp;title=0&amp;byline=0&amp;portrait=0&amp;controls=0" frameBorder="0" allow="autoplay; fullscreen" allowfullscreen loading="lazy" data-aid="HEADER_VIDEO_EMBED"></Iframe>
                    <Content>
                        <Box>
                            <Text>Reimagine Your Real </Text>
                            <Text> Estate  </Text>
                            <Text> Equity Raise </Text>
                        </Box>
                    </Content>
                </VideoFrame>
                <NavSection>
                    <Nav>
                        <Logo onClick={changeHandler}>
                            <Image src={url[count]}></Image>
                        </Logo>
                        <Side>
                            <Link to="/"><Li>Home</Li></Link>
                            {accessToken && <Link to="/dashboard/main"><Li>Dashboard</Li></Link>}
                            {accessToken && <Li onClick={logout}>Logout</Li>}
                            {!accessToken && <Link to="/login"><Li>Login</Li></Link>}
                        </Side>
                    </Nav>
                </NavSection>
                {/* <iframe data-ux="Embed" id="embed-iframe-10534" src="https://player.vimeo.com/video/660235662?h=59afe41909&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;muted=1&amp;title=0&amp;byline=0&amp;portrait=0&amp;controls=0" frameBorder="0" allow="autoplay; fullscreen" allowfullscreen="" loading="lazy" data-aid="HEADER_VIDEO_EMBED"></iframe> */}
            </Wapper >
            <p class="m-0 text-center p-4  bg-dark text-white fs-6" style={{ marginTop: '-50px' }}>Copyright © 2022 StackShares - All Rights Reserved.</p>
            {/* <video className="border m-3" autoPlay loop muted>
                <source src="https://player.vimeo.com/video/660235662?h=59afe41909&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;muted=1&amp;title=0&amp;byline=0&amp;portrait=0&amp;controls=0" />
            </video> */}
        </div >
    )
}

export default Home
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowDown from "../../assets/images/arrowdown.svg";
import Cross from "../../assets/images/cross_btn.svg";
import MainClient from "../../utilities/MainClient";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsArrowDown, BsFillXCircleFill } from "react-icons/bs";
import { useTheme } from "@mui/material";
import { FormHeader } from "../newSponsor/FormElements";

const WaterFall = ({ Opdata, Cpdata }) => {
  // const OpTier = ['Pref Return', 'Return of Outstanding Capital', 'Pro Rata : Initial Equity %', 'Pro Rata : Initial Equity %', 'Pro Rata Split :  Pro Rata Split : Profit Share Equity %']
  console.log("ops", Opdata);
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme()
  const {palette} = theme
  const operatingCashFlow = () =>
    Opdata
      ? Opdata.map((dt, index) => {
          return (
            <div style={{ position: "relative", width:"100%"}}>
              <div className="class_flow" style={{border: `1px solid ${palette.primary.main}`}}>
                <h4 style={{color:palette.text.primary}} class="fs-12 fw-4">{dt.classes}</h4>
                <h5 style={{color:palette.text.primary}} class="fs-10">{dt.tier}</h5>
              </div>

              {Opdata.length === index + 1 ? (
                <div></div>
              ) : (
                <div class="text-center">
                  <BsArrowDown size={22}  />
                </div>
              )}

              {/* <div id="operatingCashFlow">
                <div class="opeClass">
                  <div class="cash-div">
                    <h4 class="fs-14 fw-4 text-black">{dt.classes}</h4>
                    <h5 class="fs-12 text-muted">{dt.tier}</h5>
                  </div>
                  {Opdata.length === index + 1 ? (
                    <div></div>
                  ) : (
                    <div class="text-center my-2">
                      <BsArrowDown size={22} className="text-muted" />
                      
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          );
        })
      : [];

  const capitalCashFlow = () =>
    Cpdata
      ? Cpdata.map((dt, index) => {
          return (
            <div style={{ position: "relative", width:"100%" }}>
              <div className="class_flow" style={{border: `1px solid ${palette.primary.main}`}}>
                <h4 style={{color:palette.text.primary}}  class="fs-12 fw-4">{dt.classes}</h4>
                <h5 style={{color:palette.text.primary}} class="fs-10 ">{dt.tier}</h5>
              </div>

              {Cpdata.length === index + 1 ? (
                <div></div>
              ) : (
                <div class="text-center">
                  <BsArrowDown size={22} />
                </div>
              )}

              {/* <div id="operatingCashFlow">
                <div className="opeClass">
                  <div className="cash-div">
                    <h4 className="fs-14 fw-4 text-black">{dt.classes}</h4>
                    <h5 className="fs-12 text-muted">{dt.tier}</h5>
                  </div>
                  {Cpdata.length === index + 1 ? (
                    <div></div>
                  ) : (
                    <div className="text-center my-2">
                      <BsArrowDown size={22} className="text-muted" />                      
                    </div>
                  )}
                </div>
              </div> */}
              {/* <div style={{ position: "absolute", right: "-1.4rem" }}>
                    {Cpdata.length === index + 1 ?
                        <button class='btn cross-btn' id="removeCashFlow" onClick={cpRemoveHandler}>
                            <AiOutlineCloseCircle className='bg-danger text-white rounded-circle' />
                        </button> : <div></div>}
                </div> */}
            </div>
          );
        })
      : [];

  return (
    <>
      {/* <div class="row mb-3">
            <div className="col-12">
                <div className="card data-card">
                    <div className="card-body">
                            <div class='Opdata-header mb-3'>
                                <h2 class='mb-0 fs-18 fw-6 heading'>Waterfall</h2>
                            </div>
                            <div class="Opdata-body mt-2">
                                <div class="row gx-5 mt-4 mb-3">
                                    <div class="col-sm-6 position-relative">
                                        <h2 class="fs-14 text-dark fw-bold mb-3">1:- Operating Cash Flow</h2>
                                        {operatingCashFlow()}
                                    </div>
                                    <div class="col-sm-6">
                                        <h2 class="fs-14 text-dark fw-bold mb-3 ce-cash-flow">2:- Capital Event Cash Flow</h2>
                                        <div id="capitalCashFlow">
                                            {capitalCashFlow()}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        
                    </div>
                </div>
            </div> */}

      <div className="cash_flow">
        <FormHeader theme={theme}>Operating Cash Flow</FormHeader>
        {operatingCashFlow()}
      </div>
      <div className="cash_flow mtt_10">
        <FormHeader theme={theme}>Capital Event Cash Flow </FormHeader>
        {capitalCashFlow()}
      </div>
    </>
  );
};

export default WaterFall;

import React from 'react'
import ProfileImg from '../../assets/images/user-profile.png'
import { Button, Spin, Upload } from 'antd';
import { GrUpdate } from "react-icons/gr"
import profileBackground from '../../assets/images/card-bg.png'

const ProfileImage = ({ data }) => {
  console.log('data', data)
  return (
    <div class="row mb-3">
      <div class="col-sm-12">
        <div class="profile-card">
          <div id="card-bg"></div>
        </div>
        <div className="company-profile-data position-relative" >
          <div class="company-img align-items-center justify-content-between">
            <div className='d-flex align-items-start justify-content-center flex-column '>
              <div className=''>
                {data?.sponsor_image ?
                  <img class="company-user-img" src={data?.sponsor_image} alt="pleae add image" /> : <img className="company-user-img bg-white" />
                  // <img class="company-user-img" src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/profile%20imahe%2Fempty%20profile%20image.jpg?alt=media&token=d386df41-478a-4497-a252-f22e3ef051c5" alt="" />
                }
              </div>
              <h1 class="company-title">{data?.company ? data.company : 'Company Name'}</h1>
              <div className=' text-muted compnay-mail '>{data.user.email}</div>
            </div>
           
            {/* </input> */}
          </div>
          {/* <input type="file" onChange={imageUpload} value=""/> */}
          {/* <label for="inputTag" className='profile_update' >
          <GrUpdate size={"30px"} color="blue"></GrUpdate><div>Update</div>
            <input id="inputTag" type="file" style={{ display: "none" }} onChange={imageUpload}/>
          </label> */}
        </div>
      </div>
    </div>
  )
}

export default ProfileImage
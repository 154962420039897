import { message } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import numberWithCommas from "../../utilities/extrafunction/commaseparate";
import numberWithOutCommas from "../../utilities/extrafunction/numberWithoutComma";
import MainClient from "../../utilities/MainClient";
import { IRR } from "../newSponsor/utils";
import InvestorResult from "./InvestorResult";
import SubscribeDialog from "./SubscribeDialog";
import { Container } from "react-bootstrap";
import "../../assets/css/subscriptioncol.css";


import TestSubScribtion from "./TestSubScribtion";

const SubscriptionColumn = ({
  shares,
  data,
  result,
  terms,
  totals,
  targeted_irr,
  totalCashflow,
  capAmount,
  property,
  propertyList,
  seniorDept,
  Opdata,
  Cpdata,
}) => {
  const { id } = useParams();

  // For Subscription Dialog For Public Url
  const user_id = localStorage.getItem("user_id");
  const [showDialog, setShowDialog] = useState(false);

  console.log("result", result);
  // const [result, setResult] = useState([])
  const [amount, setAmount] = useState({
    classA: 0,
    classB: 0,
    classC: 0,
  });
  const [share, setShare] = useState({
    classA: 0,
    classB: 0,
    classC: 0,
  });

  const [showButton, setShowButton] = useState(true);
  // console.log('data1', data[0]?.amount)
  const [disClassA, setDisClassA] = useState([]);
  const [disClassB, setDisClassB] = useState([]);
  const [disClassC, setDisClassC] = useState([]);
  const classA = data[0];
  const classB = data[1];
  const classC = data[2];

  // useEffect(()=>{
  //     setShowButton(true)
  // },[result])

  // const distributionClassA = []
  // const distributionClassB = []
  // const distributionClassC = []
  const getDisClassA = disClassA?.map((dt) => dt.value);
  const getDisClassB = disClassB?.map((dt) => dt.value);
  const getDisClassC = disClassC?.map((dt) => dt.value);
  const totalDisClassA = getDisClassA?.reduce((a, b) => a + b, 0);
  const totalDisClassB = getDisClassB?.reduce((a, b) => a + b, 0);
  const totalDisClassC = getDisClassC?.reduce((a, b) => a + b, 0);
  const projected_return = totalDisClassA + totalDisClassB + totalDisClassC;

  const investment_class_A = result.investment_class_A
    ? result.investment_class_A[0]?.value
    : 0;
  const investment_class_B = result.investment_class_B
    ? result.investment_class_B[0]?.value
    : 0;
  const investment_class_C = result.investment_class_C
    ? result.investment_class_C[0]?.value
    : 0;
  // const getClassAInvesment = ((amount.classA) * (share.classA))/100
  // console.log('getInvst', getClassAInvesment)
  let totalInvetment =
    (isNaN(parseInt(amount.classA)) ? 0 : parseInt(amount.classA)) +
    (isNaN(parseInt(amount.classB)) ? 0 : parseInt(amount.classB)) +
    (isNaN(parseInt(amount.classC)) ? 0 : parseInt(amount.classC));
  const cash_yield_class_A = totalDisClassA / totalInvetment;
  const cash_yield_class_B = totalDisClassB / totalInvetment;
  const cash_yield_class_C = totalDisClassC / totalInvetment;

  const totalCashFlowClassA = totalDisClassA + investment_class_A;
  const totalCashFlowClassB = totalDisClassB + investment_class_B;
  const totalCashFlowClassC = totalDisClassC + investment_class_C;

  const irrA = [-1 * amount.classA, ...getDisClassA];
  const irrB = [-1 * amount.classB, ...getDisClassB];
  const irrC = [-1 * amount.classC, ...getDisClassC];
  const getIrrClassA = IRR(irrA);
  const getIrrClassB = IRR(irrB);
  const getIrrClassC = IRR(irrC);
  const getTotalIrr = IRR(irrC);
  const [subscribeTotal, setSubscribeTotal] = useState([]);
  const [totalYeild, setTotalYeild] = useState([]);

  // console.log('distributionClassA', distributionClassA)
  // console.log('classAShare', share.classA)
  // console.log('classAIrr', getIrrClassA)
  // console.log('TIrr', totalIRR)
  // console.log('classAInVestment', amount.classA)
  // console.log('classAIrr', getIrrClassA, getClassAInvesment, share.classA, getDisClassA )
  const value = Object.values(amount);
  const vl = value.map((vl) => parseInt(vl));
  const dl = vl.filter((vl) => vl > 0);
  const total = dl.reduce((a, b) => a + b, 0);
  const stotal = () => {
    for (let i = 0; i < disClassA.length; i++) {
      console.log("classA", disClassA[i]?.value);
      console.log("classB", disClassB[i]?.value);
      console.log("classC", disClassC[i]?.value);
      // console.log("stotal", disClassA[i]?.value + disClassB[i]?.value + disClassC[i]?.value)
      if (disClassA[i]?.value && disClassB[i]?.value && disClassC[i]?.value) {
        subscribeTotal[i] =
          disClassA[i]?.value + disClassB[i]?.value + disClassC[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else if (disClassA[i]?.value && disClassB[i]?.value) {
        subscribeTotal[i] = disClassA[i]?.value + disClassB[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else if (disClassA[i]?.value && disClassC[i]?.value) {
        subscribeTotal[i] = disClassA[i]?.value + disClassC[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else if (disClassB[i]?.value && disClassC[i]?.value) {
        subscribeTotal[i] = disClassB[i]?.value + disClassC[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else if (disClassA[i]?.value) {
        subscribeTotal[i] = disClassA[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else if (disClassB[i]?.value) {
        subscribeTotal[i] = disClassB[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else if (disClassC[i]?.value) {
        subscribeTotal[i] = disClassC[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else {
        subscribeTotal[i] = 0;
        totalYeild[i] = 0;
      }
    }
  };
  stotal();
  const irrTotal = [-1 * totalInvetment, ...subscribeTotal];
  const totalIRR = IRR(irrTotal);
  const dis_class_A_year = disClassA?.map((dt) => (
    <th className="text-center">Year {dt.year}</th>
  ));

  const dis_class_B_year = disClassB?.map((dt) => (
    <th className="text-center">Year {dt.year}</th>
  ));

  const dis_class_C_year = disClassC?.map((dt) => (
    <th className="text-center">Year {dt.year}</th>
  ));

  const dis_class_A_value = disClassA.map((dt) => (
    <td className="text-center">${numberWithCommas(dt.value.toFixed())}</td>
  ));

  const dis_class_B_value = disClassB.map((dt) => (
    <td className="text-center">${numberWithCommas(dt.value.toFixed())}</td>
  ));

  const dis_class_C_value = disClassC.map((dt) => (
    <td className="text-center">${numberWithCommas(dt.value.toFixed())}</td>
  ));
  // console.log('subTotal3', subscribeTotal)

  const dis_class_total = subscribeTotal.map((dt) => (
    <td className="text-center fw-5">${numberWithCommas(dt.toFixed())}</td>
  ));

  const total_cash_yield = totalYeild.map((dt) => (
    <td className="text-center fw-5">{numberWithCommas(dt.toFixed(2))} %</td>
  ));

  const Data = {
    class_A_year: dis_class_A_year,
    class_B_year: dis_class_B_year,
    class_C_year: dis_class_C_year,
    class_A: dis_class_A_value,
    class_B: dis_class_B_value,
    class_C: dis_class_C_value,
    investment_class_A: amount.classA,
    investment_class_B: amount.classB,
    investment_class_C: amount.classC,
    totalCashFlowClassA: totalDisClassA,
    totalCashFlowClassB: totalDisClassB,
    totalCashFlowClassC: totalDisClassC,
    irr_class_A: getIrrClassA,
    irr_class_B: getIrrClassB,
    irr_class_C: getIrrClassC,
    total: dis_class_total,
    totalInvetment: totalInvetment,
    totalIRR: totalIRR,
    projected_return: projected_return,
    total_cash_yield: total_cash_yield,
  };

  const submitHandler = () => {
    if (user_id) {
      const data = {
        deal_id: id,
        amount: amount,
        share: share,
        projected_return: projected_return,
        projected_irr: totalIRR,
      };
      const fetchClient = new MainClient(
        `/deal/investor-subscription/${id}/`,
        data
      );
      const response = fetchClient.postAPI();
      response
        .then((res) => {
          setShowButton(false);
          message.success("Subscription Successfully !!");
        })
        .catch((err) => message.error("Deal already subscribe"));
      // console.log('submit data', response)
    } else {
      setShowDialog(true);
    }
  };

  // console.log('total', totalDisClassA+totalDisClassB+totalDisClassC)
  // const newDistClassA =

  // console.log('result', results)

  // const dtClassA = () => {
  //     let l = []
  //     // for(key in distributionClassA)
  //     for(let i=0; i<distributionClassA.length; i++){
  //         l.push(distributionClassA[i].value * share.classA)
  //     }
  //     console.log('l', l)
  // }
  // console.log('dtClassA', dtClassA)

  // console.log('shares', share[0])
  // dtClassA()
  // const addClass = {...disClassA, ...disClassB, ...disClassC}
  // console.log('addClass', addClass)
  useEffect(() => {
    const getData = () => {
      const fetchClient = new MainClient(`/deal/investor-subscription/${id}/`);
      const response = fetchClient.getAPI();
      response.then((res) => {
        console.log("getData", res.data.data[0].sub_amount);
        const data = res.data.data[0].sub_amount;
        console.log(
          "getDataclassAmount",
          data.filter((dt) => dt.class_name == "class A")[0]?.amount
        );
        const amountClassA = data?.filter((dt) => dt.class_name == "class A")[0]
          ?.amount;
        const amountClassB = data?.filter((dt) => dt.class_name == "class B")[0]
          ?.amount;
        const amountClassC = data?.filter((dt) => dt.class_name == "class C")[0]
          ?.amount;
        const classA_amount = amountClassA ? amountClassA : 0;
        const classB_amount = amountClassB ? amountClassB : 0;
        const classC_amount = amountClassC ? amountClassC : 0;
        const subscription_shareClassA = data?.filter(
          (dt) => dt.class_name == "class A"
        )[0]?.subscription_share;
        const subscription_shareClassB = data?.filter(
          (dt) => dt.class_name == "class B"
        )[0]?.subscription_share;
        const subscription_shareClassC = data?.filter(
          (dt) => dt.class_name == "class C"
        )[0]?.subscription_share;
        const classA_share = subscription_shareClassA
          ? subscription_shareClassA
          : 0;
        const classB_share = subscription_shareClassB
          ? subscription_shareClassB
          : 0;
        const classC_share = subscription_shareClassC
          ? subscription_shareClassC
          : 0;
        console.log("amount", classA_share);
        setAmount({
          classA: classA_amount,
          classB: classB_amount,
          classC: classC_amount,
        });
        setShare({
          classA: classA_share,
          classB: classB_share,
          classC: classC_share,
        });
        setShowButton(false);
      });
    };
    getData();
  }, []);
  console.log("share", share);
  console.log("distribution", disClassA, disClassB);
  useEffect(() => {
    const distributionClassA = result
      ? JSON.parse(JSON.stringify(result.final_dist_summary[0].data))
      : [];
    const distributionClassB = result
      ? JSON.parse(JSON.stringify(result.final_dist_summary[1].data))
      : [];
    const distributionClassC = result
      ? JSON.parse(JSON.stringify(result.final_dist_summary[2].data))
      : [];
    const tds = distributionClassA?.map((dt, index) => {
      for (let key in dt) {
        // console.log('key', dt.year, index + 1, dt.value)
        if (key === "value" && dt.year == index + 1) {
          dt[key] = dt.value * (share.classA / 100);
        }
      }
      return dt;
    });
    setDisClassA(tds);
    // stotal()

    const tds1 = distributionClassB?.map((dt, index) => {
      for (let key in dt) {
        // console.log('key', dt.year, index + 1, dt.value)
        if (key === "value" && dt.year == index + 1) {
          dt[key] = dt.value * (share.classB / 100);
        }
      }
      return dt;
    });
    setDisClassB(tds1);
    const tds2 = distributionClassC?.map((dt, index) => {
      for (let key in dt) {
        // console.log('key', dt.year, index + 1, dt.value)
        if (key === "value" && dt.year == index + 1) {
          dt[key] = dt.value * (share.classC / 100);
        }
      }
      return dt;
    });
    setDisClassC(tds2);
  }, [share.classA, share.classB, share.classC, result]);

  const ChangeHandler = (event) => {
    const { name, value } = event.target;
    // console.log('name, vakue', name, value)
    if (name === "classA") {
      if (numberWithOutCommas(value) >= parseInt(data[0]?.amount)) {
        setAmount({ ...amount, [name]: 0 });
        setShare({ ...share, [name]: 0 });
        message.error("Subscription amount is higher than ClassA total amount");
      } else {
        setAmount({ ...amount, [name]: numberWithOutCommas(value) });
        setShare({
          ...share,
          [name]:
            numberWithOutCommas(value) > 0
              ? (numberWithOutCommas(value) / classA.amount) * 100
              : 0,
        });
      }
    } else if (name === "classB") {
      if (numberWithOutCommas(value) >= parseInt(data[0]?.amount)) {
        setAmount({ ...amount, [name]: 0 });
        setShare({ ...share, [name]: 0 });
        message.error("Subscription amount is higher than ClassB total amount");
      } else {
        setAmount({ ...amount, [name]: numberWithOutCommas(value) });
        setShare({
          ...share,
          [name]:
            numberWithOutCommas(value) > 0
              ? (numberWithOutCommas(value) / classB.amount) * 100
              : 0,
        });
      }
      // setShare({ ...share, [name]: numberWithOutCommas(value) > 0 ? numberWithOutCommas(value) / (classB.amount) * 100 : 0 })
    } else if (name === "classC") {
      if (numberWithOutCommas(value) >= parseInt(data[0]?.amount)) {
        setAmount({ ...amount, [name]: 0 });
        setShare({ ...share, [name]: 0 });
        message.error("Subscription amount is higher than ClassC total amount");
      } else {
        setAmount({ ...amount, [name]: numberWithOutCommas(value) });
        setShare({
          ...share,
          [name]:
            numberWithOutCommas(value) > 0
              ? (numberWithOutCommas(value) / classC.amount) * 100
              : 0,
        });
      }
      // setShare({ ...share, [name]: numberWithOutCommas(value) > 0 ? numberWithOutCommas(value) / (classC.amount) * 100 : 0 })
    } else {
      message.error("Subscription amount is higher than Class amount");
    }
  };

  console.log("sharew", share);
  const price_per_unit = shares ? shares.price_per_unit : "";
  const price_per_sf = shares ? shares.price_per_sf : "";
  const stabilized_noi = shares ? shares.stabilized_noi : "";
  const proforma_noi = shares ? shares.proforma_noi : "";
  // const profit_share = share ? share.projected_profit_share : ''
  // const avg_annual_return = share ? share.projected_avg_annual_returns : ''
  // const projected_irr = share ? share.projected_irr  : ''
  // const projected_equity_mutiple = share ? share.projected_equity_mutiple : ''
  const profit_share = shares ? share.projected_profit_share : "";
  const avg_annual_return = shares ? shares.projected_avg_annual_returns : "";
  const projected_irr = shares ? shares.projected_irr : "";
  // const projected_equity_mutiple = result ? result.projected_equity_mutiple : ''
  const preferred_return = shares ? shares.preferred_interest : "";
  const equity = shares ? shares.equity : "";
  const profit_share_1 = shares ? shares.profit_share : "";
  const classA_stack = data[0] ? data[0].capital_stack : "";
  const classB_stack = data[1] ? data[1].capital_stack : "";
  const classC_stack = data[2] ? data[2].capital_stack : "";
  const classA_amount = data[0] ? data[0].amount : "";
  const classB_amount = data[1] ? data[1].amount : "";
  const classC_amount = data[2] ? data[2].amount : "";
  const entry_cap_rate = shares ? shares.entry_cap_rate : "";

  console.log("price_per_unit", classC_stack);
  const total_cashflows_class_A =
    result?.total_cashflow_class_A.length > 0
      ? result.total_cashflow_class_A[0].value
      : "";
  const total_cashflows_class_B =
    result?.total_cashflow_class_B.length > 0
      ? result.total_cashflow_class_B[0].value
      : "";
  const total_cashflows_class_C =
    result?.total_cashflow_class_C.length > 0
      ? result.total_cashflow_class_C[0].value
      : "";
  const totalLength = result?.tier_2_distribution[0].data.length;

  const avg_return_class_A = (
    (total_cashflows_class_A / totalLength / classA_amount) *
    100
  ).toFixed(1);
  const avg_return_class_B = (
    (total_cashflows_class_B / totalLength / classB_amount) *
    100
  ).toFixed(1);
  const avg_return_class_C = (
    (total_cashflows_class_C / totalLength / classC_amount) *
    100
  ).toFixed(1);
  console.log("newShares", shares);
  const LpClassA = shares?.lp[0]
    ? parseFloat(shares?.lp[0]?.data[0]?.value).toFixed(1)
    : 0;
  const LpClassB = shares?.lp[1]
    ? parseFloat(shares?.lp[1]?.data[0]?.value).toFixed(1)
    : 0;
  const LpClassC = shares?.lp[2]
    ? parseFloat(shares?.lp[2]?.data[0]?.value).toFixed(1)
    : 0;
  const sponsorClassA = shares?.sponsor[0]
    ? parseFloat(shares?.sponsor[0]?.data[0]?.value).toFixed(1)
    : 0;
  const sponsorClassB = shares?.sponsor[0]
    ? parseFloat(shares?.sponsor[1]?.data[0]?.value).toFixed(1)
    : 0;
  const sponsorClassC = shares?.sponsor[0]
    ? parseFloat(shares?.sponsor[2]?.data[0]?.value).toFixed(1)
    : 0;
  const servicedClassA = shares?.serviced[0]
    ? parseFloat(shares?.serviced[0]?.data[0]?.value).toFixed(1)
    : 0;
  const servicedClassB = shares?.serviced[1]
    ? parseFloat(shares?.serviced[1]?.data[0]?.value).toFixed(1)
    : 0;
  const servicedClassC = shares?.serviced[2]
    ? parseFloat(shares?.serviced[2]?.data[0]?.value).toFixed(1)
    : 0;
  const accurdClassA = shares?.accrued[0]
    ? parseFloat(shares?.accrued[0]?.data[0]?.value).toFixed(1)
    : 0;
  const accurdClassB = shares?.accrued[1]
    ? parseFloat(shares?.accrued[1]?.data[0]?.value).toFixed(1)
    : 0;
  const accurdClassC = shares?.accrued[2]
    ? parseFloat(shares?.accrued[2]?.data[0]?.value).toFixed(1)
    : 0;

  console.log("log", shares);
  console.log("log1", accurdClassA); 
  return (
    <TestSubScribtion
      shares={shares}
      data={data}
      result={result}
      terms={terms}
      totals={totals}
      targeted_irr={targeted_irr}
      totalCashflow={totalCashflow}
      capAmount={capAmount}
      property={property}
      propertyList={propertyList}
      seniorDept={seniorDept}
      Opdata={Opdata}
      Cpdata={Cpdata}
    />
    // <div className="mb-3 mt-3 p-0">
    //   <div className="col-12">
    //     <div className="card data-card">
    //       <div className="card-body p-2 m-0">
    //         <div
    //           className="table-responsive m-0"
    //           style={{borderRadius: "10px", padding:"15px" }}
    //         >
    //           <table
    //             className="table data-table invester-index-table align-middle table-borderless p-0 m-0"
    //             id="metrics-table"
    //           >
    //             <thead
    //               className="table-white"
    //               style={{ boxShadow: "rgb(234 230 230) -5px 6px 8px 0px, rgb(243 240 240) 6px -6px 8px 0px", backgroundColor:"rgb(251 250 255)" }}
    //             >
    //               <tr>
    //                 <th></th>
    //                 {classA_stack && (
    //                   <th
    //                     className="text-center mb-3 pt-3 border-top-0 border-bottom-0"
    //                     // style={{
    //                     //   border: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     <div className="custom-badge mb-2">
    //                       <span className="fs-12 fw-5">
    //                         {parseFloat(classA_stack).toFixed()}%
    //                       </span>
    //                     </div>
    //                     <p className="fs-18 text-blue mb-0">Class A Shares</p>
    //                     <p className="fw-6 mb-0 mt-2">
    //                       ${numberWithCommas(classA_amount)}
    //                     </p>
    //                   </th>
    //                 )}
    //                 {classB_stack && (
    //                   <th
    //                     className="text-center border-top-0 border-bottom-0"
    //                     // style={{
    //                     //   border: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     <div className="custom-badge mb-2">
    //                       <span className="fs-12 fw-5">
    //                         {parseFloat(classB_stack).toFixed()}%
    //                       </span>
    //                     </div>
    //                     <p className="fs-18 mb-0 text-green">Class B Shares</p>
    //                     <p className="fw-6 mb-0 mt-2">
    //                       ${numberWithCommas(classB_amount)}
    //                     </p>
    //                   </th>
    //                 )}
    //                 {classC_stack && (
    //                   <th
    //                     className="text-center border-top-0 border-bottom-0"
    //                     // style={{
    //                     //   border: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     <div className="custom-badge mb-2">
    //                       <span className="fs-12 fw-5">
    //                         {parseFloat(classC_stack).toFixed()}%
    //                       </span>
    //                     </div>
    //                     <p className="fs-18 mb-0 text-red">Class C Shares</p>
    //                     <p className="fw-6 mb-0 mt-2">
    //                       ${numberWithCommas(classC_amount)}
    //                     </p>
    //                   </th>
    //                 )}
    //               </tr>
    //             </thead>
    //             <div className="add_gap"></div>

    //             <tbody className="shadow_body shadow_section">
    //               <tr className="">
    //                 <th scope="col" className="fs-18">
    //                   Investment Metrics
    //                 </th>
    //                 {classA_stack && (
    //                   <th
    //                     scope="col"
    //                     className="fs-18 text-center border-bottom-0 border-top-0"
    //                     // style={{
    //                     //   border: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     Class A
    //                   </th>
    //                 )}
    //                 {classB_stack && (
    //                   <th
    //                     scope="col"
    //                     className="fs-18 text-center border-bottom-0 border-top-0"
    //                     // style={{
    //                     //   border: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     Class B
    //                   </th>
    //                 )}
    //                 {classC_stack && (
    //                   <th
    //                     scope="col"
    //                     className="fs-18 text-center border-bottom-0 border-top-0"
    //                     // style={{
    //                     //   border: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     Class C
    //                   </th>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>

    //               {/* <div className="test">    */}
    //               <tr className="">
    //                 <td>Price Per Unit</td>
    //                 {price_per_unit[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     $
    //                     {price_per_unit[0] &&
    //                       numberWithCommas(price_per_unit[0].data[0].value)}
    //                   </td>
    //                 )}
    //                 {price_per_unit[1] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     $
    //                     {price_per_unit[1] &&
    //                       numberWithCommas(price_per_unit[1].data[0].value)}
    //                   </td>
    //                 )}
    //                 {price_per_unit[2] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     $
    //                     {price_per_unit[2] &&
    //                       numberWithCommas(price_per_unit[2].data[0].value)}
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td>Price per SF</td>
    //                 {price_per_sf[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     $
    //                     {price_per_sf[0] &&
    //                       numberWithCommas(price_per_sf[0].data[0].value)}
    //                   </td>
    //                 )}
    //                 {price_per_sf[1] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     $
    //                     {price_per_sf[1] &&
    //                       numberWithCommas(price_per_sf[1].data[0].value)}
    //                   </td>
    //                 )}
    //                 {price_per_sf[2] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     $
    //                     {price_per_sf[2] &&
    //                       numberWithCommas(price_per_sf[2].data[0].value)}
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td>Entry Cap Rate</td>
    //                 {entry_cap_rate[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {entry_cap_rate[0] &&
    //                       numberWithCommas(
    //                         entry_cap_rate[0].data[0].value
    //                       )}{" "}
    //                     %
    //                   </td>
    //                 )}
    //                 {entry_cap_rate[1] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {entry_cap_rate[1] &&
    //                       numberWithCommas(
    //                         entry_cap_rate[1].data[0].value
    //                       )}{" "}
    //                     %
    //                   </td>
    //                 )}
    //                 {entry_cap_rate[2] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {entry_cap_rate[2] &&
    //                       numberWithCommas(
    //                         entry_cap_rate[2].data[0].value
    //                       )}{" "}
    //                     %
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td>Stabilized Cap Rate</td>
    //                 {stabilized_noi[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {stabilized_noi[0] &&
    //                       numberWithCommas(
    //                         stabilized_noi[0].data[0].value
    //                       )}{" "}
    //                     %
    //                   </td>
    //                 )}
    //                 {stabilized_noi[1] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {stabilized_noi[1] &&
    //                       numberWithCommas(
    //                         stabilized_noi[1].data[0].value
    //                       )}{" "}
    //                     %
    //                   </td>
    //                 )}
    //                 {stabilized_noi[2] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {stabilized_noi[2] &&
    //                       numberWithCommas(
    //                         stabilized_noi[2].data[0].value
    //                       )}{" "}
    //                     %
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td>Pro Forma Cap Rate</td>
    //                 {proforma_noi[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {proforma_noi[0] &&
    //                       numberWithCommas(proforma_noi[0].data[0].value)}{" "}
    //                     %
    //                   </td>
    //                 )}
    //                 {proforma_noi[1] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {proforma_noi[1] &&
    //                       numberWithCommas(proforma_noi[1].data[0].value)}{" "}
    //                     %
    //                   </td>
    //                 )}
    //                 {proforma_noi[2] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {proforma_noi[2] &&
    //                       numberWithCommas(proforma_noi[2].data[0].value)}{" "}
    //                     %
    //                   </td>
    //                 )}
    //               </tr>
    //             </tbody>
    //             <div className="add_gap"></div>

    //             <tbody className="shadow_body shadow_section">
    //               {/* </div> */}
    //               {/* <tr>
    //                                     <td>Liquidation Value</td>
    //                                     <td className="text-center">22,500,000</td>
    //                                     <td className="text-center">23,750,000</td>
    //                                     <td className="text-center">25,000,000</td>
    //                                 </tr> */}

    //               {/* <div className="test">                 */}
    //               <tr>
    //                 <th className="pt-3 fs-18">Investment Terms</th>
    //                 {classA_stack && (
    //                   <th
    //                     scope="col"
    //                     className="fs-18 text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >Class A</th>
    //                 )}
    //                 {classB_stack && (
    //                   <th
    //                     scope="col"
    //                     className="fs-18 text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >Class B</th>
    //                 )}
    //                 {classC_stack && (
    //                   <th
    //                     scope="col"
    //                     className="fs-18 text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >Class C</th>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td>Preferred Return</td>
    //                 {preferred_return[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {preferred_return[0] &&
    //                       numberWithCommas(preferred_return[0].data[0].value)}
    //                     %
    //                   </td>
    //                 )}
    //                 {preferred_return[1] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {preferred_return[1] &&
    //                       numberWithCommas(preferred_return[1].data[0].value)}
    //                     %
    //                   </td>
    //                 )}
    //                 {preferred_return[2] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {preferred_return[2] &&
    //                       numberWithCommas(preferred_return[2].data[0].value)}
    //                     %
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td>Serviced / Accrued</td>
    //                 {preferred_return[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {`${servicedClassA}% / ${accurdClassA}%`}
    //                   </td>
    //                 )}
    //                 {preferred_return[1] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {`${servicedClassB}% / ${accurdClassB}%`}
    //                   </td>
    //                 )}
    //                 {preferred_return[2] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {`${servicedClassC}% / ${accurdClassC}%`}
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td>Hurdle</td>
    //                 {preferred_return[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   ></td>
    //                 )}
    //                 {preferred_return[1] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   ></td>
    //                 )}
    //                 {preferred_return[2] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   ></td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td>Equity stack %</td>
    //                 {equity[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {equity[0] &&
    //                       parseFloat(equity[0].data[0].value).toFixed(1)}
    //                     %
    //                   </td>
    //                 )}
    //                 {equity[1] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {equity[1] &&
    //                       parseFloat(equity[1].data[0].value).toFixed(1)}
    //                     %
    //                   </td>
    //                 )}
    //                 {equity[2] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {equity[2] &&
    //                       parseFloat(equity[2].data[0].value).toFixed(1)}
    //                     %
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td className="">Profit Share</td>
    //                 {profit_share_1[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {profit_share_1[0] &&
    //                       numberWithCommas(profit_share_1[0].data[0].value)}
    //                     %
    //                   </td>
    //                 )}
    //                 {profit_share_1[1] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {profit_share_1[1] &&
    //                       numberWithCommas(profit_share_1[1].data[0].value)}
    //                     %
    //                   </td>
    //                 )}
    //                 {profit_share_1[2] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {profit_share_1[2] &&
    //                       numberWithCommas(profit_share_1[2].data[0].value)}
    //                     %
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td className="">Profit Class Allocation %</td>
    //                 {classA_stack && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {LpClassA + "%" + " " + "/" + " " + sponsorClassA + "%"}
    //                   </td>
    //                 )}
    //                 {classB_stack && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {/* {classC_stack ?
    //                       LpClassB+"%" +" "+ "/" +" " + sponsorClassB+"%" : LpClassB + "%"
    //                   } */}
    //                     {classC_stack
    //                       ? LpClassB +
    //                         "%" +
    //                         " " +
    //                         "/" +
    //                         " " +
    //                         sponsorClassB +
    //                         "%"
    //                       : LpClassB + "%"}
    //                   </td>
    //                 )}
    //                 {classC_stack && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {LpClassC + "%"}
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //             </tbody>

    //             <div className="add_gap"></div>

    //             <tbody className="shadow_body shadow_section">
    //               <tr>
    //                 <th className="pt-3 fs-18">Projected Returns</th>
    //                 {classA_stack && (
    //                   <th
    //                     scope="col"
    //                     className="fs-18 text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >Class A</th>
    //                 )}
    //                 {classB_stack && (
    //                   <th
    //                     scope="col"
    //                     className="fs-18 text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >Class B</th>
    //                 )}
    //                 {classC_stack && (
    //                   <th
    //                     scope="col"
    //                     className="fs-18 text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >Class C</th>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td>Profit Distribution</td>
    //                 {result?.total_cashflow_class_A[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     $
    //                     {numberWithCommas(
    //                       parseInt(
    //                         result?.total_cashflow_class_A[0].value
    //                       ).toFixed()
    //                     )}
    //                   </td>
    //                 )}
    //                 {result?.total_cashflow_class_B[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     $
    //                     {numberWithCommas(
    //                       parseInt(
    //                         result?.total_cashflow_class_B[0].value
    //                       ).toFixed()
    //                     )}
    //                   </td>
    //                 )}
    //                 {result?.total_cashflow_class_C[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     $
    //                     {numberWithCommas(
    //                       parseInt(
    //                         result?.total_cashflow_class_C[0].value
    //                       ).toFixed()
    //                     )}
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td>Average Annual Return</td>
    //                 {total_cashflows_class_A && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {avg_return_class_A} %
    //                   </td>
    //                 )}
    //                 {total_cashflows_class_B && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {avg_return_class_B} %
    //                   </td>
    //                 )}
    //                 {total_cashflows_class_C && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {avg_return_class_C} %
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td>Projected IRR</td>
    //                 {result?.irr_class_A[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {result.irr_class_A[0].value} %
    //                   </td>
    //                 )}
    //                 {result?.irr_class_B[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {result?.irr_class_B[0].value} %
    //                   </td>
    //                 )}
    //                 {result?.irr_class_C[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {result.irr_class_C[0].value} %
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //               <tr>
    //                 <td>Equity Multiple</td>
    //                 {result?.equity_multiple_class_A[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {result?.equity_multiple_class_A[0].value} X
    //                   </td>
    //                 )}
    //                 {result?.equity_multiple_class_B[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {result?.equity_multiple_class_B[0].value} X
    //                   </td>
    //                 )}
    //                 {result?.equity_multiple_class_C[0] && (
    //                   <td
    //                     className="text-center"
    //                      // style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     //   borderRadius: "0px",
    //                     // }}
    //                   >
    //                     {result?.equity_multiple_class_C[0].value} X
    //                   </td>
    //                 )}
    //               </tr>
    //               <div className="border_line"></div>
    //             </tbody>
    //             <div className="add_gap"></div>

    //             <tbody className="shadow_body_bottom shadow_section">
    //               <div className="border_line"></div>

                  // {data.length == 1 && (
                  //   <tr className=" mt-5">
                  //     <th className="pt-4 fs-18">Subscription Amount</th>
                  //     {classA_stack && (
                  //       <th
                  //         scope="col"
                  //         className="fs-18 text-center pt-3 pb-4"
                  //         // style={{
                  //         //   borderLeft: "1px solid #767676",
                  //         //   borderRight: "1px solid #767676",
                  //         // }}
                  //       >
                  //         <div className="d-flex flex-column align-items-center justify-content-center">
                  //           <label
                  //             class="form-label text-blue"
                  //             for="investmentAmount"
                  //           >
                  //             <b>Class A Investment Amount</b>
                  //           </label>
                  //           <NumberFormat
                  //             className="form-control w-50"
                  //             type="text"
                  //             placeholder="Enter Investment amonut"
                  //             id="investmentAmount"
                  //             name="classA"
                  //             value={amount.classA > 0 && amount.classA}
                  //             thousandSeparator={true}
                  //             onChange={ChangeHandler}
                  //           />
                  //         </div>
                  //       </th>
                  //     )}
                  //   </tr>
                  // )}
    //               {data.length > 1 && (
    //                 <tr className=" mt-5">
    //                   <th className="pt-4 fs-18">Subscription Amount</th>
    //                   {classA_stack && (
    //                     <th
    //                       scope="col"
    //                       className="fs-18 text-center pt-3 pb-4"
    //                       // style={{
    //                       //   borderLeft: "1px solid #767676",
    //                       //   borderRight: "1px solid #767676",
    //                       // }}
    //                     >
    //                       <label
    //                         class="form-label text-blue"
    //                         for="investmentAmount"
    //                       >
    //                         <b>Class A Investment Amount</b>
    //                       </label>
    //                       <NumberFormat
    //                         class="form-control"
    //                         type="text"
    //                         placeholder="Enter Investment amonut"
    //                         id="investmentAmount"
    //                         name="classA"
    //                         value={amount.classA > 0 && amount.classA}
    //                         thousandSeparator={true}
    //                         onChange={ChangeHandler}
    //                       />
    //                     </th>
    //                   )}
                      // {classB_stack && (
                      //   <th
                      //     scope="col"
                      //     className="fs-18 text-center pt-3 pb-4"
                      //     // style={{
                      //     //   borderLeft: "1px solid #767676",
                      //     //   borderRight: "1px solid #767676",
                      //     // }}
                      //   >
                      //     <label
                      //       class="form-label text-green fw-6"
                      //       for="investmentAmount1"
                      //     >
                      //       <b>Class B Investment Amount</b>
                      //     </label>
                      //     <NumberFormat
                      //       class="form-control"
                      //       type="text"
                      //       placeholder="Enter Investment amonut"
                      //       id="investmentAmount1"
                      //       name="classB"
                      //       value={amount.classB > 0 && amount.classB}
                      //       thousandSeparator={true}
                      //       onChange={ChangeHandler}
                      //     />
                      //   </th>
                      // )}
                      // {classC_stack && (
                      //   <th
                      //     scope="col"
                      //     className="fs-18 text-center pt-3 pb-4"
                      //     // style={{
                      //     //   borderLeft: "1px solid #767676",
                      //     //   borderRight: "1px solid #767676",
                      //     // }}
                      //   >
                      //     <label
                      //       class="form-label text-red fw-6"
                      //       for="investmentAmount2"
                      //     >
                      //       <b>Class C Investment Amount</b>
                      //     </label>
                          // <NumberFormat
                          //   class="form-control"
                          //   type="text"
                          //   placeholder="Enter Investment amonut"
                          //   id="investmentAmount2"
                          //   name="classC"
                          //   value={amount.classC > 0 && amount.classC}
                          //   thousandSeparator={true}
                          //   onChange={ChangeHandler}
                          //   max=""
                          // />
                      //   </th>
                      // )}
                    // </tr>
    //               )}

    //               <div className="border_line"></div>
    //               <tr className="pb-2 pt-2 mt-5">
    //                 <th className="pt-2 fs-18">Subscription Shares</th>
    //                 {classA_stack && (
    //                   <th
    //                     scope="col"
    //                     className="col fs-18 text-center"
    //                     //style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     // }}
    //                   >
    //                     <h1
    //                       class="text-black fs-14 fw-4 mb-0"
    //                       style={{ marginLeft: ".5rem" }}
    //                     >
    //                       {parseFloat(share.classA).toFixed(2)} Class A Shares
    //                     </h1>
    //                   </th>
    //                 )}
    //                 {classB_stack && (
    //                   <th
    //                     scope="col"
    //                     className="fs-18 text-center"
    //                     //style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     // }}
    //                   >
    //                     <h1
    //                       class="text-black fs-14 fw-4 mb-0"
    //                       style={{ marginLeft: ".5rem" }}
    //                     >
    //                       {parseFloat(share.classB).toFixed(2)} Class B Shares
    //                     </h1>
    //                   </th>
    //                 )}
    //                 {classC_stack && (
    //                   <th
    //                     scope="col"
    //                     className="fs-18 text-center"
    //                     //style={{
    //                     //   borderLeft: "1px solid #767676",
    //                     //   borderRight: "1px solid #767676",
    //                     // }}
    //                   >
    //                     <h1
    //                       class="text-black fs-14 fw-4 mb-0"
    //                       style={{ marginLeft: ".5rem" }}
    //                     >
    //                       {parseFloat(share.classC).toFixed(2)} Class C Shares
    //                     </h1>
    //                   </th>
    //                 )}
    //               </tr>
    //               {/* {data.length == 3 && (
    //                 <tr className='fs-18 fw-7'>
    //                   <th className='pt-3 pb-2 col-3'>
    //                     <span className='text-black mb-0 '>
    //                       Total Subscription:{' '}
    //                     </span>
    //                     <span class='text-black  ms-2 mb-0'>
    //                       ${numberWithCommas(total ? total : 0)}
    //                     </span>
    //                   </th>

    //                   <th
    //                     scope='col'
    //                     className='col-3 text-center border-bottom-0 border-top-0'
    //                     style={{
    //                       border: '1px solid #767676',
    //                     }}
    //                   >
    //                     <span className='text-black  mb-0'>
    //                       Projected Return:{' '}
    //                     </span>
    //                     <span className='text-black ms-2 mb-0'>
    //                       ${numberWithCommas(projected_return.toFixed())}
    //                     </span>
    //                   </th>

    //                   <th
    //                     scope='col'
    //                     className='col-3 text-center border-bottom-0 border-top-0'
    //                     style={{
    //                       border: '1px solid #767676',
    //                     }}
    //                   >
    //                     <span className='text-black mb-0 '>
    //                       Projected IRR:{' '}
    //                     </span>
    //                     {totalInvetment > 0 ? (
    //                       <span className='text-black ms-2 mb-0'>
    //                         {isNaN(totalIRR) ? 0 : (totalIRR * 100).toFixed(2)}{' '}
    //                         %
    //                       </span>
    //                     ) : (
    //                       <span className='text-black ms-2 mb-0'>0.00 %</span>
    //                     )}
    //                   </th>
    //                   {classC_stack && (
    //                     <th
    //                       scope='col'
    //                       className='col-3 text-center border-bottom-0 border-top-0'
    //                       style={{
    //                         border : '1px solid #767676',
    //                       }}
    //                     >
    //                       {showButton ? (
    //                         <button
    //                           className='btn btm-md button_color'
    //                           onClick={submitHandler}
    //                         >
    //                           Subscribe Now
    //                         </button>
    //                       ) : (
    //                         <div className='text-success fs-6'>
    //                           <AiOutlineCheckCircle color='green' size={25} />{' '}
    //                           Subscribed
    //                         </div>
    //                       )}
    //                     </th>
    //                   )}
    //                 </tr>
    //               )}
    //               {data.length == 2 && (
    //                 <tr className='fs-18 fw-7'>
    //                   <th className='pt-3 pb-2 col-4 '>
    //                     <span className='text-black mb-0 '>
    //                       Total Subscription:{' '}
    //                     </span>
    //                     <span class='text-black  ms-2 mb-0'>
    //                       ${numberWithCommas(total ? total : 0)}
    //                     </span>
    //                   </th>

    //                   <th
    //                     scope='col'
    //                     className='col-4 text-center border-bottom-0 border-top-0'
    //                     style={{
    //                       border: '1px solid #767676',
    //                     }}
    //                   >
    //                     <span className='text-black  mb-0'>
    //                       Projected Return:{' '}
    //                     </span>
    //                     <span className='text-black ms-2 mb-0'>
    //                       ${numberWithCommas(projected_return.toFixed())}
    //                     </span>
    //                   </th>

    //                   <th
    //                     scope='col'
    //                     className='col-4 text-center border-bottom-0 border-top-0'
    //                     style={{
    //                       border: '1px solid #767676',
    //                     }}
    //                   >
    //                     <span className='text-black mb-0 '>
    //                       Projected IRR:{' '}
    //                     </span>
    //                     {totalInvetment > 0 ? (
    //                       <span className='text-black ms-2 mb-0'>
    //                         {isNaN(totalIRR) ? 0 : (totalIRR * 100).toFixed(2)}{' '}
    //                         %
    //                       </span>
    //                     ) : (
    //                       <span className='text-black ms-2 mb-0'>0.00 %</span>
    //                     )}
    //                   </th>
    //                 </tr>
    //               )}
    //               {data.length == 1 && (
    //                 <tr className='fs-18 fw-7'>
    //                   <th className='py-0 pb-2 col-4'>
    //                     <span className='text-black mb-0 '>
    //                       Total Subscription:{' '}
    //                     </span>
    //                     <span class='text-black  ms-2 mb-0'>
    //                       ${numberWithCommas(total ? total : 0)}
    //                     </span>
    //                   </th>

    //                   <th
    //                     scope='col'
    //                     className='text-center m-0 py-0 border-bottom-0 border-top-0'
    //                     style={{
    //                       border: '1px solid #767676',
    //                     }}
    //                   >
    //                     <div className='d-flex align-items-center justify-content-around m-0'>
    //                       <div
    //                         className='w-100 py-3 px-1 border-bottom-0'
    //                         // style={{ borderRight: '1px solid #767676' }}
    //                       >
    //                         <span className='text-black  mb-0'>
    //                           Projected Return:{' '}
    //                         </span>
    //                         <span className='text-black ms-2 mb-0'>
    //                           ${numberWithCommas(projected_return.toFixed())}
    //                         </span>
    //                       </div>
    //                       <div className='w-100 px-1'>
    //                         <span className='text-black mb-0 border-bottom-0'>
    //                           Projected IRR:{' '}
    //                         </span>
    //                         {totalInvetment > 0 ? (
    //                           <span className='text-black ms-2 mb-0'>
    //                             {isNaN(totalIRR)
    //                               ? 0
    //                               : (totalIRR * 100).toFixed(2)}{' '}
    //                             %
    //                           </span>
    //                         ) : (
    //                           <span className='text-black ms-2 mb-0'>
    //                             0.00 %
    //                           </span>
    //                         )}
    //                       </div>
    //                     </div>
    //                   </th>
    //                 </tr>
    //               )} */}
    //             </tbody>

    //             <div className="add_gap"></div>
    //           </table>
    //         </div>
    //         {/* {data.length < 3 && (
    //           <div class='row g-3 align-items-center justify-content-end mx-1 mt-1 mb-2'>
    //             <div className='col-sm-6 col-md-3'>
    //               {showButton ? (
    //                 <button
    //                   className='btn button_color float-end'
    //                   onClick={submitHandler}
    //                 >
    //                   Subscribe Now
    //                 </button>
    //               ) : (
    //                 <div className='float-end text-success fs-6'>
    //                   <AiOutlineCheckCircle color='green' size={25} />{' '}
    //                   Subscribed
    //                 </div>
    //               )}
    //             </div>
    //           </div>
    //         )} */}
    //         <div class="row g-3 align-items-center mx-2 mt-1 mb-3">
    //           <div className="col-sm-6 col-md-3">
    //             <span className="text-black fs-18 fw-bold mb-0">
    //               Total Subscription:{" "}
    //             </span>
    //             <span class="fw-6 text-black fs-18 fw-bold mb-0">
    //               ${numberWithCommas(total ? total : 0)}
    //             </span>
    //           </div>
    //           <div className="col-sm-6 col-md-3">
    //             <span className="text-black fs-18 fw-bold mb-0">
    //               Projected Return:{" "}
    //             </span>
    //             <span className="text-black fs-18 fw-bold mb-0">
    //               ${numberWithCommas(projected_return.toFixed())}
    //             </span>
    //           </div>
    //           <div className="col-sm-6 col-md-3">
    //             <span className="text-black fs-18 fw-bold mb-0 ">
    //               Projected IRR:{" "}
    //             </span>
    //             {totalInvetment > 0 ? (
    //               <span className="fw-6 text-black fs-18 fw-bold mb-0">
    //                 {isNaN(totalIRR) ? 0 : (totalIRR * 100).toFixed(2)} %
    //               </span>
    //             ) : (
    //               <span className="fw-6 text-black fs-18 fw-bold mb-0">
    //                 0.00 %
    //               </span>
    //             )}
    //           </div>
    //           <div className="col-sm-6 col-md-3">
    //             {showButton ? (
    //               <button
    //                 className="btn button_color float-end text-bold"
    //                 onClick={submitHandler}
    //               >
    //                 Subscribe Now
    //               </button>
    //             ) : (
    //               <div className="float-end text-success fs-5">
    //                 <AiOutlineCheckCircle color="green" size={25} /> Subscribed
    //               </div>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="card data-card mt-3 p-1">
    //     <InvestorResult data={Data} />
    //   </div>
    // </div>
  );
};

export default SubscriptionColumn;

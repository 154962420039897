import { useEffect, useState } from 'react';
import { NavLink, useParams, matchRoutes, useLocation } from 'react-router-dom';
import MenuIcon from '../../assets/images/menu.svg';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import DashboardWhite from '../../assets/icons/dashboardWhite.png';
import DashboardBlack from '../../assets/icons/dashboardBlack.png';
import CreateDealWhite from '../../assets/icons/craeteDealWhite.png';
import CreateDealBlack from '../../assets/icons/createDealBlack.png';
import InvestorWhite from '../../assets/icons/investorIconWhite.png';
import InvestorBlack from '../../assets/icons/investorIconBlack.png';
import DealWhite from '../../assets/icons/dealIconWhite.png';
import DealBlack from '../../assets/icons/dealIconBlack.png';
import SponsorWhite from '../../assets/images/SponsorWhite.png';
import SponsorBlack from '../../assets/images/SponsorBlack.png';
import MblueLogo from '../../assets/logos/gulfstream.jpeg';
import { RiDashboardFill } from 'react-icons/ri';
import GridViewIcon from '@mui/icons-material/GridView';
import styled from 'styled-components';
import { useTheme } from '@mui/material';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
// background: rgb(62,62,61);
// background: linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%);





const NewSidebar = ({ tg, user, tgHandler }) => {

  const theme = useTheme()
  const {mode} = theme.palette
  const Nav = styled.div`
  position: fixed;
  width: 90px;
  height: 99vh;
  left: 18px;
  top: 5px;
  background: ${mode == 'light' ? theme.palette.customColors.lightPaperBg : theme.palette.customColors.bodyBg};
  border: .6px solid ${theme.palette.customColors.avatarBg};
  border-radius: 29px;
  transition: 0.5s;
  z-index: 2;
  .nav-title{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }
  .toggle-span {
    color: ${theme.palette.mode === 'dark' ? '#fffff' : theme.palette.text.primary} !imporatnt;
  }
  &:hover{
      width: 220px;
      .toggle-span {
        display: block;
        color: ${theme.palette.mode === 'dark' ? '#fffff' : "#fffff"} !imporatnt;
        font-size: 18px;
      }
      // .toggle-span {
      //   color: ${theme.palette.mode === 'dark' ? '#fffff' : theme.palette.text.primary};
      // }
      .li-hover{
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
      }
      .nav-title{
        visibility: visible;
        opacity: 1;
        color: ${theme.palette.mode === 'dark' ? '#fffff' : theme.palette.text.primary};

      }
      .image-logo{
        margin-left: 20px;
        margin-right: 12px;
      }
  }
  .white-icon{
    display: none;
  }
`;
  const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
  const Ul = styled.ul`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  padding-right: 0.2rem;
  margin-top: 20px;
`;
  const Li = styled.li`
  list-style-type: none;
`;

  const StyleNavLink = styled(NavLink)`
    margin-top: 2rem;
    color:${theme.palette.mode === 'light' ? '#fff' : '#fff'} !important;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // cursor: pointer;
    margin-top: 0.8rem;
    transition:.5s;
    border: 'none';
    cursor:pointer;
    height: 50px;
    &:hover {
      color:  ${(props) => (props.active ? "white" : '#fff')} !important;
    background: ${(props) => (props.active ? theme.palette.customColors.avatarBg : theme.palette.customColors.avatarBg)};
    border:  ${(props) => (props.active ? 'none' : '1px solid #FFFFFF')};
    border-left: none;
    border-radius: 0px 34px 34px 0px;
    }
    &.active{
      color: ${'#fffff'};
      background: ${theme.palette.primary.main};
      border-radius: 0px 34px 34px 0px;
      // border-left: none;
      width:20px;
      .toggle-span {
        color: ${'#fffff'};
      }

    }

    .toggle-span {
      color: ${theme.palette.mode === 'dark' ? '#fffff' : '#fffff'};
    }
  `
  const [toggle, setToggle] = useState(true);
  const [active, setActive] = useState({
    mainDashboard: false,
    addNewDeal: false,
    myInvestor: false,
    deals: false,
  })
  const dashboardActive = () => {
    setActive({
      mainDashboard: true,
      addNewDeal: false,
      myInvestor: false,
      deals: false,
    })
  }
  const addNewdealActive = () => {
    setActive({
      mainDashboard: false,
      addNewDeal: true,
      myInvestor: false,
      deals: false,
    })
  }
  const myInvestorActive = () => {
    setActive({
      mainDashboard: false,
      addNewDeal: false,
      myInvestor: true,
      deals: false,
    })
  }
  const dealsActive = () => {
    setActive({
      mainDashboard: false,
      addNewDeal: false,
      myInvestor: false,
      deals: true,
    })
  }
  console.log(useParams())
  return (
    <div>
      <Nav>
        <Logo>
          <NavLink to='/'>
            <div class='xp-logo mt-3'>
              {/* {tg ? ( */}
              <img src={MblueLogo} style={{ maxWidth: "50px", borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} />
              {/* <svg
                  width='35'
                  height='49'
                  viewBox='0 0 48 49'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M42.875 32.022L23.4254 43.5198L4.62059 32.3443L0.107422 35.0307L23.4254 49.0001L47.2807 34.7084L42.875 32.022Z'
                    fill='white'
                  />
                  <path
                    d='M28.5834 2.90132L23.7478 0L0 14.3991L14.1842 22.8882L32.9891 11.7127L36.965 14.1842L18.2676 25.4671L23.318 28.6908L47.6031 14.1842L32.9891 5.58773L14.1842 16.9781L9.88598 14.3991L28.5834 2.90132Z'
                    fill='white'
                  />
                  <path
                    d='M42.6602 21.3838L23.318 32.8816L4.62062 21.7062L0 24.5L23.318 38.5768L47.3882 24.1777L42.6602 21.3838Z'
                    fill='white'
                  />
                </svg> */}
              {/* )  */}
              {/* // : (
              //   <div style={{ maxWidth: '30px' }}>
              //     <img */}
              {/* //       src='https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/selectedIcons%2FdashboardLogo-removebg.png?alt=media&token=e0e2242c-a55e-4bd6-bef9-8ff85d8cd897'
              //       style={{ maxWidth: '30px', minWidth: '170px' }}
              //     />
              //   </div>
              // )
              // } */}
              {/* "ST"} */}
              {/* <span class="toggle-span"><sup>TM</sup></span> */}
              {/* <span class="fs-32 text-dark">St</span> */}
              {/* <span class="toggle-span">ackShares <sup>TM</sup></span> */}
            </div>
          </NavLink>
          <div>
          </div>
        </Logo>
        <Ul>
          {/* <div style={{ height:"100px" }}> */}
          {(user?.user_type === "investor" || user?.user_type == "admin") && (
            <StyleNavLink to='/dashboard/main' className='w-100' >
              <Li className="li-hover">
                <AutoAwesomeMosaicIcon sx={
                  { color: theme.palette.grey.custom, }
                } className='mx-3' />
                {/* <div className='white-icon image-logo'>
                  <img src={DashboardBlack} width='25px' />
                </div>
                <div className='black-icon image-logo'>
                  <img src={DashboardWhite} width='25px' />
                </div> */}
                {/* </span> */}
                {/* <Li> */}
                {/* {active.mainDashboard ? (
                <img src={DashboardBlack} width='28px' />
              ) : (
                <img src={DashboardWhite} width='28px' />
              )} */}
                <div className='nav-title'>
                  <span class='toggle-span'>Dashboard</span>
                </div>
              </Li>
            </StyleNavLink>)}
          {/* </div> */}
          {(user?.user_type === "sponsor" || user?.user_type == "admin") && (
            <StyleNavLink to='/dashboard/add-new-deal' className='w-100'>
              <Li className="li-hover">
              <FactCheckIcon sx={
                  { color: theme.palette.grey.custom }
                } className='mx-3' />
                {/* <div className='white-icon image-logo'>
                  <img src={CreateDealBlack} width='28px' />
                </div>
                <div className='black-icon image-logo'>
                  <img src={CreateDealWhite} width='28px' />
                </div> */}
                {/* {active.addNewDeal ? (
                  <img src={CreateDealBlack} width='28px' />
                ) : (
                  <img src={CreateDealWhite} width='28px' />
                )} */}
                {/* <div> */}
                <div className='nav-title d-flex align-items-center justify-content-center'>
                  <span class='toggle-span'>Add</span>
                  <span class='toggle-span' style={{ marginRight: ".2rem", marginLeft: ".2rem" }}>New </span>
                  <span class='toggle-span'>Deal </span>
                </div>
                {/* </div> */}
              </Li>
            </StyleNavLink>
          )}
          {(user?.user_type === "sponsor" || user?.user_type == "admin") && (
            <StyleNavLink to='/dashboard/my-investors' className='w-100'>
              <Li className="li-hover">
                {/* <div className='white-icon image-logo'>
                  <img src={InvestorBlack} width='25px' />
                </div>
                <div className='black-icon image-logo'>
                  <img src={InvestorWhite} width='25px' />
                </div> */}
                <AttachMoneyIcon sx={
                  { color: theme.palette.text.primary }
                } className='mx-3' />
                <div className='nav-title'>
                  <span class='toggle-span'></span>
                  <span class='toggle-span'>Investors</span>
                </div>
              </Li>
              {/* <Li >
                {active.myInvestor ? (
                  <img src={InvestorBlack} width='28px' />
                ) : (
                  <img src={InvestorWhite} width='28px' />
                )}
                <span class='toggle-span'>My Investors</span>
              </Li> */}
            </StyleNavLink>
          )}
          {(user?.user_type == "admin") && (
            <StyleNavLink to='/dashboard/companies' className='w-100'>
              <Li className="li-hover">
                {/* <div className='black-icon image-logo'>
                  <img src={SponsorWhite} width='28px' />
                </div>
                <div className='white-icon image-logo'>
                  <img src={SponsorBlack} width='28px' />
                </div> */}
                <BusinessIcon sx={
                  { color: theme.palette.text.primary }
                } className='mx-3' />
                <div className='nav-title'>
                  <span class='toggle-span'>Companies</span>
                </div>
              </Li>
              {/* <Li>
                {active.deals ? (
                  <img src={DealBlack} width='28px' />
                ) : (
                  <img src={DealWhite} width='28px' />
                )}
                <span class='toggle-span ms-3'>Deals</span>
              </Li> */}
            </StyleNavLink>
          )}
        </Ul>
        {(user?.user_type === "sponsor" || user?.user_type == "admin") && (
          <StyleNavLink to='/dashboard/deals' className='w-100'>
            <Li className="li-hover">
              {/* <div className='black-icon image-logo'>
                <img src={DealWhite} width='25px' />
              </div>
              <div className='white-icon image-logo'>
                <img src={DealBlack} width='25px' />
              </div> */}
              <WorkIcon sx={
                  { color: theme.palette.text.primary }
                } className='mx-3' />
              <div className='nav-title'>
                <span class='toggle-span'>Deals</span>
              </div>
            </Li>
            {/* <Li>
                {active.deals ? (
                  <img src={DealBlack} width='28px' />
                ) : (
                  <img src={DealWhite} width='28px' />
                )}
                <span class='toggle-span ms-3'>Deals</span>
              </Li> */}
          </StyleNavLink>
        )}

      </Nav>
    </div>
  );
};

export default NewSidebar;

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Custom Components Imports
import CustomAvatar from 'src/@core/components/mui/avatar'
import OptionsMenu from 'src/@core/components/option-menu'
import { useTheme } from '@mui/material'
import numberWithCommas from '../../utilities/extrafunction/commaseparate'



const Classes = ({classA, classB, totalShares}) => {
  const totalAsharesPercent = ((classA / totalShares) * 100)?.toFixed(2) 
  const totalBsharesPercent = ((classB / totalShares) * 100)?.toFixed(2) 

  const data = [
    {
      title: 'Class A',
      amount: classA ? (numberWithCommas(classA, true)) : 0,
      icon: 'tabler:mail',
      trendNumber: totalAsharesPercent,
      // avatarColor: 'success'
    },
    {
      title: 'Class B',
      amount: classB ? numberWithCommas(classB, true) : 0,
      trendNumber: totalBsharesPercent,
      // avatarColor: 'info',
      icon: 'tabler:link'
    },
   
  ]

  const theme = useTheme()
  return (
    <Card className='h-100' style={{boxShadow:'0px 2px 6px 0px rgba(47, 43, 61, 0.14)', background : theme.palette.customColors.bodyBg}}>
      <CardHeader
        title='Commited Shares By Class'
        subheader='Class A, Class B'
        // action={
        //   <OptionsMenu
        //     options={['Last Month', 'Last 6 Months', 'Last Year']}
        //     iconButtonProps={{ size: 'small', sx: { color: 'text.disabled' } }}
        //   />
        // }
      />
      <CardContent>
        {data.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: index !== data.length - 1 ? [6.5, 6.5, 7.5, 6.5] : undefined
              }}
            >
              <CustomAvatar
                skin='light'
                variant='rounded'
                color={item.avatarColor}
                sx={{ mr: 4, width: 34, height: 34 }}
              >
                <Icon icon={item.icon} />
              </CustomAvatar>
              <Box
                sx={{
                  rowGap: 1,
                  columnGap: 4,
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Typography variant='h6'>{item.title}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ mr: 4, fontWeight: 500, color: 'text.secondary' }}>${item.amount}</Typography>
                  <Typography sx={{ color: `${item.trend === 'negative' ? 'error' : 'success'}.main` }}>
                    {item.trendNumber}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          )
        })}
      </CardContent>
    </Card>
  )
}

export default Classes

const MyInvestorData = [
    {
        "investor_name": "Stephane",
        "email": "Stephane@gmail.com",
        "status": "Accepted",
        "address": "356 OLD STEESE HWY",
        "country": "JUNEAU",
        "phone_number": "564564654"
    },
    {
        "investor_name": "George rieo",
        "email": "george@gmail.com",
        "status": "Accepted",
        "address": "35553 KENAI SPUR HIGHWAY",
        "country": "KENAI PENINSULA",
        "phone_number": "3423424324"
    },
    {
        "investor_name": "Harry cooper",
        "email": "harrycooper@gmail.com",
        "status": "Accepted",
        "address": "oknknd",
        "country": "COLBERT",
        "phone_number": "7278993253"
    },
    {
        "investor_name": "Jack test",
        "email": "jack_test@gmail.com",
        "status": "Accepted",
        "address": "2528 W 28TH ST",
        "country": "COLBERT",
        "phone_number": "897886786"
    },
    {
        "investor_name": "arun singh",
        "email": "avisingh10.as@gmail.com",
        "status": "Accepted",
        "address": "safdarjung enclave",
        "country": "India",
        "phone_number": "123456789"
    },
    {
        "investor_name": "muklasar rahaman",
        "email": "muklasar@gmakl.com",
        "status": "pending",
        "address": "kolkata",
        "country": "India",
        "phone_number": "8787878788"
    },
    {
        "investor_name": "Thomas",
        "email": "thomas@gmail.com",
        "status": "pending",
        "address": "6145 E BROADWAY BLVD",
        "country": "KETCHIKAN",
        "phone_number": "2832732323"
    }
]
export default MyInvestorData
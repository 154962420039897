// Import necessary components from Material-UI
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  Card,
  Button,
  Menu,
  MenuItem,
  Fade,
  Box,
  Select,
  Modal,
  IconButton,
  useTheme
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../css/sponsor.css'
import { FaRegEdit, FaDumpster } from "react-icons/fa";
import { MdDelete, MdDeleteForever } from "react-icons/md";
import { Container, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import "../../assets/css/custom.css";
import { companies } from "./data";
import AddForm from "./AddForm";
import AddForm2 from "./AddForm2";
import { useDispatch, useSelector } from "react-redux";
import { setAddCompany, setCompany, setLimitCompany } from "../../store/actions/companies";
import MainClient from "../../utilities/MainClient";
import { fetchInvitedSponsors } from "../../store/actions/SponsorAcrions";
import { message } from "antd";
import AddForm1 from "./AddForm1";
import PromptModal from "./PromptModal";
import { makeStyles } from '@material-ui/core/styles';
import { currenTimezone, formatDateInTimezone } from "../../utilities/extrafunction/dates";
import numberWithCommas from "../../utilities/extrafunction/commaseparate";
import { slugify } from "../../utilities/extrafunction/slugifier";
import CreateToolTip from "../newSponsor/CreateToolTip";
import { getInputStyles } from "../newSponsor/FormStyles";
const useStyles = makeStyles((theme) => ({
  customButton: {
    backgroundColor: 'red', // Add your custom styles here
    color: 'white',
    // ... other styles
  },
}));
// Create a component for the nested table with accordion


const addBtnStyle = {
  color: 'rgb(62,62,61)',
  border: '.2px solid #c1bebe'
}

const toolTipsList = [ 'delete company', 'edit company', 'delete partner', 'edit partner']


const currentDate = new Date();
const timezone = 'America/New_York';

const formattedDate = formatDateInTimezone(currentDate, timezone);
console.log(formattedDate);

const SimpleModal = (props) => {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            '@media (max-width: 992px)': {
              width: '100%',
              overflowY:'auto',
              height:'100%'
            }
          }}
        >
      <IconButton
          edge="end"
          color="inherit"
          onClick={props.handleClose}
          sx={{ position: 'relative', top: '2%', left: '95%' }}
        >
          <CloseIcon />
        </IconButton>
          <AddForm2 data={props.updateCompHistory} handleAddcompany={props.handleAddcompany} />
          {/* <Button onClick={() => props.handleClose()} sx={{ mt: 2 }}>
            Cancel
          </Button> */}
        </Box>
      </Modal>
    </div>
  );
};
const SimpleModal2 = (props) => {
  const {open, handleClose, updatePartHistory, handleAddPartner} = props
  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            '@media (max-width: 992px)': {
              width: '100%',
              overflowY:'auto',
              height:'100%'
            }
          }}
        >
        <IconButton
          edge="end"
          color="inherit"
          onClick={props.handleClose}
          sx={{ position: 'relative', top: '2%', left: '95%' }}
        >
          <CloseIcon />
        </IconButton>
          <AddForm1 data={updatePartHistory} handleAddcompany={() => handleAddPartner()} />
          {/* <Button onClick={() => handleClose()} sx={{ mt: 2 }}>
            Cancel
          </Button> */}
        </Box>
      </Modal>
    </div>
  );
};
const AnimatedDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        style={{ backgroundColor: "#fff", color: "#2b4479" }}
      >
        Open Dropdown
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Fade in={Boolean(anchorEl)}>
          <div>
            <MenuItem onClick={handleClose}>Option 1</MenuItem>
            <MenuItem onClick={handleClose}>Option 2</MenuItem>
            <MenuItem onClick={handleClose}>Option 3</MenuItem>
          </div>
        </Fade>
      </Menu>
    </div>
  );
};

// useEffect(() => {     
//   dispatch(fetchInvitedSponsors())
//  }, [])

const AnimatedSelect = () => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(10);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLimitCompany(selectedValue));
  }, [selectedValue]);

  return (
    <div>
      <Select
        value={selectedValue}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        MenuComponent={(props) => (
          <Fade {...props} timeout={300} in={open}>
            <div>{props.children}</div>
          </Fade>
        )}
      >
        {" "}
        <MenuItem selected={true} value={10}>
          10
        </MenuItem>
        <MenuItem selected={true} value={25}>
          25
        </MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </div>
  );
};
export const NestedTableAccordion = ({ company, toggleDeleteModal, toggleDeleteModal2, togglePartnerForm, handleDeletePartner, togglePartUpdate, toggleUpdateCompany, disabledAccordions, handleAccordion }) => {
  const [search, setSearch] = useState("");
  
  const theme = useTheme()
  const {palette} = theme
  const listHeaderStyle = {
    display: "flex",
    border: "1px #bfbfbf solid",
    background: palette.primary.main,
    // background: 'rgb(62,62,61)',
    // background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)",
    color: "#fff",
    height: "50px",
    width: '100%'
  };
  
  return (
    <>
        {toolTipsList.map(target => <CreateToolTip target={target} />)}

    <Accordion expanded={disabledAccordions.includes(company.id)}>
    <AccordionSummary
     expandIcon={
     <ExpandMoreIcon onClick={() => handleAccordion(company.id)} />} key={company.name}>
        <List
          component="nav"
          style={{
            display: "flex",
            width: "100%",
            height: "60px",
            fontWeight: "800",
          }}
        >
          <ListItem button onClick={() => handleAccordion(company.id)}>
            <ListItemText style={{minWidth:'150px'}} primary={company.company} />
          </ListItem>
          <ListItem button>
            <ListItemText style={{minWidth:'250px'}} primary={company.administrator} />
          </ListItem>
          <ListItem button>
            <ListItemText primary={company.deals_count_opportunities} />
          </ListItem>
          <ListItem button>
            <ListItemText primary={company.related_partners?.length} />
          </ListItem>
          <ListItem button>
            <ListItemText style={{minWidth:'100px'}} primary={company.formed_in} />
          </ListItem>
          <ListItem button>
            <ListItemText style={{minWidth:'100px'}} primary={company.jurisdiction} />
          </ListItem>
          <ListItem button>
            <ListItemText style={{minWidth:'100px'}} primary={company.initial_filling_date ? formatDateInTimezone(company.initial_filling_date, currenTimezone) : ('-')} />
          </ListItem>
          <ListItem >
            <ListItemText style={{minWidth:'100px'}}>
              {" "}
              <MdDelete id={slugify(toolTipsList[0])} className="mx-3" onClick={() => toggleDeleteModal(company.id)} />
              {/* <MdDeleteForever />{" "} */}
              <FaRegEdit id={slugify(toolTipsList[1])} onClick={() => toggleUpdateCompany(company)} />{" "}
            </ListItemText>
          </ListItem>
        </List>
      </AccordionSummary>
      <AccordionDetails>
      { company.related_partners?.length > 0 && 
       <List className="list-header main-header" component="nav" style={listHeaderStyle}>
          <ListItem button>
            <ListItemText style={{minWidth:'150px'}} primary="Name" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Phone" />
          </ListItem>
          <ListItem button>
            <ListItemText style={{minWidth:'250px'}} primary="Email" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="City/State" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Country" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Shares" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Deals" />
          </ListItem>
          {/* <ListItem button>
            <ListItemText primary="Action" />
          </ListItem> */}
          <ListItem>
          <ListItemText className="mx-3" primary="Action" />
            {/* <Button
              onClick={() => togglePartnerForm(company.id)}
              className="mx-1"
              variant="outlined"
              color="primary"
              style={{ color: '#00', border: '.2px solid #fff', backgroundColor: 'white' }}
            >
              Add Partner
            </Button> */}
          </ListItem>
        </List>}
        {company.related_partners?.map((p) => {
          return (
            <List component="nav" style={{ display: "flex", height: "60px" }}>
              <ListItem button>
                <ListItemText style={{minWidth:'150px'}} primary={p.name} />
              </ListItem>
              <ListItem button>
                <ListItemText primary={p.phone} />
              </ListItem>
              <ListItem button>
                <ListItemText style={{minWidth:'250px'}} primary={p.email} />
              </ListItem>
              <ListItem button>
                <ListItemText primary={p.city} />
              </ListItem>
              <ListItem button>
                <ListItemText primary={p.country} />
              </ListItem>
              <ListItem button>
                <ListItemText primary={numberWithCommas(p.shares_data.shares )|| 0} />
              </ListItem>
              <ListItem button>
                <ListItemText primary={p.deal_count || 0} />
              </ListItem>
              <ListItem >
                <ListItemText>
                  {" "}
                  <MdDelete id={slugify(toolTipsList[2])} className="mx-3" onClick={() => toggleDeleteModal2(p.id)} />
                  <FaRegEdit id={slugify(toolTipsList[3])} onClick={() => togglePartUpdate(p)} />{" "}
                </ListItemText>
              </ListItem>
            </List>
          );
        })}
      </AccordionDetails>
    </Accordion>
    </>
  );
};

// Your main component where you use the NestedTableAccordion
const MySponsor = () => {
  const classes = useStyles();
  
  const theme = useTheme()
  const {palette} = theme
  const addBtnStyle = {
    color: palette.text.primary,
    border: '.2px solid #c1bebe'
  }
  const listHeaderStyle = {
    display: "flex",
    border: "1px #bfbfbf solid",
    background: palette.primary.main,
    // background: 'rgb(62,62,61)',
    // background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)",
    color: "#fff",
    height: "50px",
    width: '100%'
  };
  const inputStyles = getInputStyles(theme)
  const companyStateNew = useSelector(
    (state) => state.sponsors.invitedSponsors
  );
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [promptOpen, setPromptOpen] = useState(false);
  const [promptOpen2, setPromptOpen2] = useState(false);


  const [getCompanies, setCompanies] = useState([]);
  const [getCompanies2, setCompanies2] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateCompHistory, setupdateCompHistory] = useState();
  const [updatePartHistory, setupdatePartHistory] = useState();

  const companyState = useSelector((state) => state.companies.data);
  const partnerState = useSelector((state) => state.partners.data);
  const companyStateID = useSelector((state) => state.partners.companyId);



  console.log("companyState", companyState);
  console.log("partnerState", partnerState, companyStateID);

  console.log("getCompanies2", getCompanies2);

  useLayoutEffect(() => {

    dispatch(fetchInvitedSponsors());

  }, [])
  useLayoutEffect(() => {

    setCompanies2(companyStateNew)

  }, [companyStateNew])


  const [filteredCollections, setFilteredCollections] = useState(getCompanies2);
  console.log('filteredCollections', filteredCollections, getCompanies2)
  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpen1 = (company) => {
    console.log("handleOpen1 data", company)
    setupdateCompHistory(company)
    setOpen1(true);
    dispatch(setCompany(company.id))
  };
  const handleOpen2 = (companyId) => {
    setOpen2(true);
    dispatch(setCompany(companyId))

  };
  const handleOpen3 = (partner) => {
    setOpen3(true);
    setupdatePartHistory(partner)
    dispatch(setCompany(partner.id))

  };
  const handPromptOpen = (companyId) => {
    setPromptOpen(true)
    dispatch(setCompany(companyId))
  };
  const handPromptOpen2 = (companyId) => {
    setPromptOpen2(true)
    dispatch(setCompany(companyId))
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };

  const handPromptClose = () => {
    setPromptOpen(false)
  };

  const handPromptClose2 = () => {
    setPromptOpen2(false)
  };
  const handleAddcompany = () => {
    if (companyState) {
      addCompanies();

      handleClose();

    }

  };

  const handleAddPartner = () => {
    if (partnerState) {
      // addPartners();
      console.log(partnerState)

      handleClose2();

    }

  };

  const addPartners = async () => {
    if (companyStateID) {
      const data = {
        partners_list: [partnerState]
      }
      const fetchClient = new MainClient(`user/sponsor-profile/${companyStateID}/`, data);
      const res = await fetchClient.patchAPI().then(resp => {
        dispatch(fetchInvitedSponsors());
      })
      console.log(res, 'response from post company')
      console.log("company aded !");

    } else {
      console.log("company id not found");
    }
    // const data = await res.data;
    // return data.reverse()
    // setCompanies([...getCompanies, ...newData]);

    handleClose2();
  };

  const addCompanies = async () => {

    const fetchClient = new MainClient("/investor/my-sponsors", companyState);
    const res = await fetchClient.postAPI().then(resp => {
      dispatch(fetchInvitedSponsors());
      message.success(`Company ${companyState.company} added successfully !`)
    })

    // const data = await res.data;
    // return data.reverse()
    // setCompanies([...getCompanies, ...newData]);
    console.log("company aded !");
  };
  const handleDeleteComapny = async (company) => {

    const fetchClient = new MainClient(`user/sponsor-profile/delete/${companyStateID}/`);
    const res = await fetchClient.deleteAPI().then(resp => {
      message.success('company deleted')
      dispatch(fetchInvitedSponsors());
    }).catch(err => {
      message.error('error deleting company')
    })
    handPromptClose()
  }

  // const handleUpdatePartner = async (id) => {

  //   const fetchClient = new MainClient(`user/partner-profile/${companyStateID}/`, partnerState);
  //   const res = await fetchClient.putAPI().then(resp => {
  //     message.success('partner updated')
  //     dispatch(fetchInvitedSponsors());
  //   }).catch(err => {
  //     message.error('error updating partner')
  //   })
  //   handleClose3()

  // }
  const handleUpdatePartner = async (id) => {

    const fetchClient = new MainClient(`user/investor-profile/${companyStateID}/`, partnerState);
    const res = await fetchClient.putAPI().then( resp => {
      message.success('investor updated')
      dispatch(fetchInvitedSponsors());
    }).catch(err => {
      message.error('error updating investor')
    })

    if (partnerState?.shares_data) {
      const fetchClient2 = new MainClient(`user/investor-shares-update/${partnerState.shares_data.id}/`, partnerState.shares_data);
    const res2 = await fetchClient2.patchAPI().then( resp => {
      message.success('investor updated')
      dispatch(fetchInvitedSponsors());
    }).catch(err => {
      message.error('error updating investor')
    })
    } else {
      message.error('no partners shares found')
    }

    handleClose3()


   
  }
  const handleUpdatecompany = async (id) => {

    const fetchClient = new MainClient(`user/sponsor-profile/${companyStateID}/`, companyState);
    const res = await fetchClient.putAPI().then(resp => {
      message.success('company updated')
      dispatch(fetchInvitedSponsors());
    }).catch(err => {
      message.error('error updating company')
    })
    handleClose1()

  }
  // const handleDeletePartner = async (id) => {

  //   const fetchClient = new MainClient(`user/partner-profile/delete/${companyStateID}/`);
  //   const res = await fetchClient.deleteAPI().then(resp => {
  //     message.success('partner deleted')
  //     dispatch(fetchInvitedSponsors());
  //   }).catch(err => {
  //     message.error('error deleting partner')
  //   })
  //   handPromptClose2()
  // }
  const handleDeletePartner = async (company) => {
    
    const fetchClient = new MainClient(`user/investor-profile/delete/${companyStateID}/`);
    const res = await fetchClient.deleteAPI().then( resp => {
      if (resp.status === 200) {
        message.success('investor deleted')
      dispatch(fetchInvitedSponsors());
      handPromptClose2()
      }
    })
    
   
  }

  const companyLimit = useSelector((state) => state.companies.limit);
  useEffect(() => {
    // Function to filter collections based on searchQuery
    const filterCollections = () => {
      const filtered = getCompanies2?.filter((collection) =>
        collection.company?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      const filterbynumber = filtered?.slice(0, companyLimit);
      console.log(
        companyLimit,
        "filterbynumber",
        companyLimit > 0 ? filterbynumber : filtered
      );
      setFilteredCollections(filtered);
    };

    filterCollections();
  }, [searchQuery, getCompanies2, companyLimit]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [displayedCollections, setDisplayedCollections] = useState([]);
  const itemsPerPage = companyLimit;
  useEffect(() => {
    // Function to update displayed collections based on the current page
    const updateDisplayedCollections = () => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      console.log('filteredCollections', filteredCollections)
      const displayed = filteredCollections?.slice(startIndex, endIndex);
      setDisplayedCollections(displayed);
    };

    // Update total pages when the collections or itemsPerPage change
    setTotalPages(Math.ceil(filteredCollections?.length / itemsPerPage));

    updateDisplayedCollections();
  }, [currentPage, filteredCollections, getCompanies2, itemsPerPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  console.log(
    "currentPage",
    currentPage,
    "totalPages",
    totalPages,
    itemsPerPage,
    displayedCollections?.length
  );
  const [disabledAccordions, setDisabledAccordions] = useState([]);

  const handleAccordion = (accordionId) => {
    setDisabledAccordions((prevDisabledAccordions) => {
      if (prevDisabledAccordions.includes(accordionId)) {
        return prevDisabledAccordions.filter((id) => id !== accordionId);
      } else {
        return [...prevDisabledAccordions, accordionId];
      }
    });
  };

  return (
    <div className="myinvestors">
      {/* Add other components or content as needed */}
      <Card style={{ minHeight: "4rem", padding: "0.7rem 0.7rem" }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AnimatedSelect setData={filteredCollections} />
          <Box display={"flex"}
            justifyContent={"stretch"}
            width={'100%'}
          >
            <Input
              defaultValue={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              style={{...inputStyles, ...{ padding: '15px' }}}
              className="shadow-sm w-100   mx-2 portfolio-search-btn"
              type="text"
              name="enddate"
              id="exampleSearch"
              placeholder="Search here..."
            />
          </Box>


          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}>

            <Button
              onClick={handleOpen}
              className="mx-1"
              variant="outlined"
              color="primary"
              style={addBtnStyle}
            >
              Add Companies
            </Button>
          </Box>
        </Box>
      </Card>
      <div className="listTable mt-2 ">
        <Card>
          <List
          className="main-header"
            component="nav"
            style={{
              display: "flex",
              padding: "0 18px",
              fontWeight: "bolder",
              ...listHeaderStyle,
            }}
          >
            <ListItem button>
              <ListItemText style={{minWidth:'150px'}} primary="Name" />
            </ListItem>
            <ListItem button>
              <ListItemText style={{minWidth:'250px'}} primary="Administrator" />
            </ListItem>
            <ListItem button>
              <ListItemText  primary="Opportunities" />
            </ListItem>
            <ListItem button>
              <ListItemText  primary="Partners" />
            </ListItem>
            <ListItem button>
              <ListItemText style={{minWidth:'100px'}} primary="Formed In" />
            </ListItem>
            <ListItem button>
              <ListItemText style={{minWidth:'100px'}} primary="Jurisdiction" />
            </ListItem>
            <ListItem button>
              <ListItemText style={{minWidth:'80px'}} primary="Filling Date" />
            </ListItem>
            <ListItem button>
              <ListItemText style={{minWidth:'100px'}} className="mx-3" primary="Action" />
            </ListItem>
          </List>
        </Card>
        {displayedCollections?.map((company) => (
          <NestedTableAccordion
            disabledAccordions={disabledAccordions}
            handleAccordion={handleAccordion}
            togglePartnerForm={handleOpen2}
            togglePartUpdate={handleOpen3}
            toggleDeleteModal={handPromptOpen}
            toggleDeleteModal2={handPromptOpen2}

            company={company}
            handleAddPartner={addPartners}
            toggleUpdateCompany={handleOpen1}
            handleDeleteComapny={handleDeleteComapny}
            handleDeletePartner={handleDeletePartner}
          // handleUpdatePartner={handleUpdatePartner}
          />
        ))}
      </div>
      <div>
        {/* Pagination controls */}
        {getCompanies2?.length > 0 ? <div className="my-1 py-1">
          <Button
            variant="outlined"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="mx-1">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            variant="outlined"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div> : <h2 className="text-center mt-5 text-secondary">No Companies Yet ...</h2>}
      </div>

      <SimpleModal
        open={open}
        handleClose={handleClose}
        handleAddcompany={handleAddcompany}
      />
      <SimpleModal
        open={open1}
        handleClose={handleClose1}
        updateCompHistory={updateCompHistory}
        handleAddcompany={handleUpdatecompany}
      />
      <SimpleModal2
        open={open2}

        handleClose={handleClose2}
        handleAddPartner={addPartners}
      />
      <SimpleModal2
        open={open3}
        updatePartHistory={updatePartHistory}
        handleClose={handleClose3}
        handleAddPartner={handleUpdatePartner}
      />
      <PromptModal
        open={promptOpen}
        handleClose={handPromptClose}
        handleConfirm={handleDeleteComapny}
        modalContent={'are you sure to delete this company ?'}
      />
      <PromptModal
        open={promptOpen2}
        handleClose={handPromptClose2}
        handleConfirm={handleDeletePartner}
        modalContent={'are you sure to delete this partner ?'}
      />
      {/* Add more accordions or components as needed */}
    </div>
  );
};

export default MySponsor;

import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { slugify } from '../../utilities/extrafunction/slugifier'

function CreateToolTip({ target }) {
  return (
    <>{
    target &&  <UncontrolledTooltip placement="right" target={slugify(target)} >
        {target}
      </UncontrolledTooltip>
    }</>
  )
}

export default CreateToolTip
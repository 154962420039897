import { notificationActions } from "../reducers/NotificationReducer";
import MainClient from "../../utilities/MainClient";
import { notification } from "antd";

export const fetchNotification = (payload,room_id,user_type) => {
  let newSocket
  if (user_type === 'admin'){
  // newSocket =  new WebSocket(`ws://localhost:8000/ws/notifications/admin/`)
  newSocket =  new WebSocket(`wss://my.gulfstreaminvestments.io/ws/notifications/admin/`)

   } else if (room_id) {
    // newSocket = new WebSocket(`ws://localhost:8000/ws/notifications/${room_id}/`)
    newSocket = new WebSocket(`wss://my.gulfstreaminvestments.io/ws/notifications/${room_id}/`)

    // 
    }
    return (async (dispatch) => {

      if (newSocket) {
        // Event handler for when the connection is opened
        newSocket.onopen = () => {
          console.log('WebSocket connection opened', room_id);
          dispatch(notificationActions.socketStatus('open'))
        };
    
        // Event handler for receiving messages
        newSocket.onmessage = (event) => {
          const receivedMessage = JSON.parse(event.data);
          console.log('Received message:', receivedMessage);
          dispatch(notificationActions.connect(receivedMessage))
          dispatch(notificationActions.setIsCustom(false))
          if (receivedMessage.message && receivedMessage.type){
            dispatch(DbNotifications(receivedMessage))  // save to db  

          }
          
          if (payload && newSocket && newSocket.readyState === WebSocket.OPEN) {

          newSocket.send(JSON.stringify(payload));
          newSocket.close()
          newSocket.onmessage = (event) => {
            const receivedMessage = JSON.parse(event.data);
            console.log('Received message after new payload:', receivedMessage);
            dispatch(notificationActions.getNotify(receivedMessage))
          };
        } else {
          console.log('socket is not ready')
        }
        };
    
        // Event handler for when the connection is closed
        newSocket.onclose = (event) => {
          console.log('WebSocket connection closed:', event);
          dispatch(notificationActions.socketStatus('close'))

        };
       
      } 
    })
}

export const createSimpleNotification = (payload) => {
  return (async (dispatch) => {
  if(payload) {
    dispatch(enableCustomNotification())
    const openNotification = (data) => {
        notification.open({
            message: data?.message,
            description: data?.sub_message,
            className: 'custom-notification', // Add your custom class for styling
            duration: 3, // Duration in seconds

        });
    };
    openNotification(payload)
    dispatch(notificationActions.connect(payload))// update in notification tab
    if (payload.message && payload.type){
      dispatch(DbNotifications(payload))  // save to db  

    }
  }
})
}

export const DbNotifications = (payload) => {
  return (async (dispatch) => {
  if(payload) {
    const newPayload = {...payload, ...{sub_message:'notification'}}
    const postData = async () => {
      const fetchClient = new MainClient('/investor/all-notifications', newPayload)
      const response = await fetchClient.postAPI()
      const data = await response.data
      return data
  }
  try {
      const data = await postData().then(() =>
        console.log('new notification created !', payload)
      )

      dispatch(notificationActions.getNotify(data))
  } catch (error) {
      dispatch(notificationActions.getError(error.messages))
  }

  } else {

    const getData = async () => {
      const fetchClient = new MainClient('/investor/all-notifications')
      const response = await fetchClient.getAPI()
      const data = await response.data
      return data
  }
  try {
      const data = await getData()
      dispatch(notificationActions.getNoticationsList(data))
  } catch (error) {
      dispatch(notificationActions.getError(error.messages))
  }
  }
})
}

export const enableCustomNotification = () => {
  return (async (dispatch) => { 
    dispatch(notificationActions.setIsCustom(true))
  })
}

export const togglePopNotification = (payload) => {
  return (async (dispatch) => { 
    dispatch(notificationActions.setIsPopup(payload))
  })
}


   


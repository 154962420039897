import React, { useCallback, useEffect, useState } from 'react';
import {setAddCompany, setAddPartner} from '../../store/actions/companies'
import { message, Spin } from "antd";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { useDispatch, useSelector } from "react-redux"
// import AutoAddress from './AutoAddress';
import Search2 from '../newSponsor/Search2';
import AutoAddress from './AutoAddress';
import MultiSelectComponent from '../newSponsor/Multiselect';
import { FormData } from '../../data/formData';
import MainClient from '../../utilities/MainClient';
import { getPhoneFormat } from '../../utilities/extrafunction/getPhoneFormat';

const formCountries = FormData?.countries
const optionsList = [
  {  label: "Americas",
  options: [
    { value: 'USA', label: 'USA' },
    { value: 'Canada', label: 'Canada' },
  ],
  },
  { label: "UAE" ,
  options: [
    { value: 'Oman', label: 'Oman' },
    { value: 'Abu Dhabi', label: 'Abu Dhabi' },
  ],
  },
  { label: "Europe", 
  options: [
    { value: 'Spain ', label: 'Spain ' },
    { value: 'Portugal', label: 'Portugal' },
  ],
  } ]



const AddForm1 = ({handleAddcompany}) => {
  const [center, setCenter] = useState({
    lat: 43.6532,
    lng: -79.3832,
  });

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    state: '',
    city: '',
    country: '',
    markets: 'United States',
    // companies: [],
    deals: 0,
    status:'Active'
  });
  const [error, setError] = useState({})
  const dispatch = useDispatch()
  const handleChange = (e) => {
    if(e.target.name === 'phone') {

      setFormData({ 
        ...formData,
        [e.target.name]: getPhoneFormat(e.target.value), ...{
          user: { email: formData.email, first_name: formData.name.split(' ')[0], last_name: formData.name.split(' ')[1], user_type:'investor'}
       },
      });
    } else {
      setFormData({ 
        ...formData,
        [e.target.name]: e.target.value, ...{
          user: { email: formData.email, first_name: formData.name.split(' ')[0], last_name: formData.name.split(' ')[1], user_type:'investor'}
       },
      });
    }
   
  };
  console.log('formData?.phone',formData?.phone)

  const checkEmailExists = async () => {
    const data = {email : formData.email}
    const fetchClient = new MainClient(`user/check-user/`, data);
    const res = await fetchClient.postAPI().then(resp => {
      
      if(resp.data.message !== 'OK') {
        setError({message: resp.data.message})
      }

    }).catch(err => message.error('Something went wrong checking user'))

   } 

  const getLocation = async (address) => {
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setCenter({
        lat: lat,
        lng: lng,
      });
    } catch (error) {
      console.log("😱 Error: ", error);
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
  
    // return () => {
      dispatch(setAddPartner(formData))
    // }
  }, [formData])
  
  const handleSubmit = (e) => {
    // e.preventDefault();
    console.log('formData',formData)
    if (formData) {
     
     handleAddcompany()
    //  message.success(`investor ${formData.name} added successfully !`)
    } else {
      console.log('no data')
      message.error(`getting error adding investor from here !`)
    }
    
   
    // Handle form submission logic here
    console.log('Form submitted:');
  };

  return (
    <Container>
      <Form >
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Enter  name"
                defaultValue={formData.name}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter  email"
                defaultValue={formData.email}
                onChange={handleChange}
                onBlur={checkEmailExists}
              />
            { error?.message && <p className='text-danger'>{error?.message}</p>}
            </FormGroup>
          </Col>
       
        </Row>
        <Row>
        
        <Col md={6}>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input
                type="text"
                pattern='\d{3}-\d{3}-\d{4}'
                maxLength={13}
                name="phone"
                id="phone"
                placeholder="Enter  phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          {/* <Col md={6}>
            <FormGroup>
              <Label for="admin">Companies</Label>
              <Input
                type="number"
                name="shares"
                id="admin"
                placeholder="Enter Shares"
                defaultValue={formData.companies}
                onChange={handleChange}
              />
            </FormGroup>
          </Col> */}
          
      </Row>
        <Row>
         
        <Col md={6}>
            <FormGroup>
            <Label for="address">Address</Label>
            <AutoAddress formData={formData} setFormData={setFormData} />

            </FormGroup>
          </Col>
          
          <Col md={6}>
            <FormGroup>
              <Label for="city">City</Label>
              <Input
                type="text"
                name="city"
                id="city"
                placeholder="Enter city"
                defaultValue={formData.city}
                onChange={handleChange}
              />
               {/* <AutoAddress formData={formData} setFormData={setFormData} /> */}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="state">State</Label>
              <Input
                type="text"
                name="state"
                id="state"
                placeholder="Enter  state"
                defaultValue={formData.state}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="country">Country</Label>
              <Input
                type="select"
                placeholder=''
                name="country"
                id="country"
                defaultValue={formData.country}
                onChange={handleChange}
              >
                <option value="">Select country</option>
                { formCountries.map(country => <option value={country}>{country}</option>)  }
                {/* Add more country options as needed */}
              </Input>
            </FormGroup>
          </Col>
          <Col md={6}>
          <FormGroup>
              <Label for="country">Market</Label>
              {/* <Input
                type="select"
                name="markets"
                id="country"
                defaultValue={formData.markets}
                onChange={handleChange}
              >
                <option value="">Select your country</option>
                <option value="usa">United States</option>
                <option value="canada">Canada</option>
              
              </Input> */}
              <MultiSelectComponent
                defaultName='markets'
                value={formData}
                setValue= {setFormData}
                placeholder={'Select Markets'}
               options={optionsList} />
            </FormGroup>
          </Col>
        </Row>
        
        <Button onClick={handleSubmit} variant='outlined' >
          Submit
        </Button>
      </Form>
    </Container>
  );
};



export default AddForm1;


export const getMinutesAgo = (date) => {
    const myDate = new Date(date)
    const currentdate = new Date()
    // console.log('cdate', myDate)
    const diffInMilliseconds = Math.abs(currentdate - myDate);
    const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

  // Format the result as a string
  const resultString = `${hours} ${hours === 1 ? 'hour' : 'hours'} and ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;

  return resultString;

}

export const isToday = (date) => {
    const currentdate = new Date()
    const myDate = new Date(date)
    const diffInMilliseconds = Math.abs(currentdate - myDate);
    const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    // console.log('hours', hours)
    return hours <= 24

}
// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { styled, useTheme } from '@mui/material/styles'
import LinearProgress from '@mui/material/LinearProgress'

// ** Custom Components Imports
import Icon from '@core/components/icon'
import CustomChip from '@core/components/mui/chip'
import OptionsMenu from '@core/components/option-menu'
import CustomAvatar from '@core/components/mui/avatar'
import Chart from 'react-apexcharts'
import { hexToRGBA } from '../../utilities/extrafunction/hexToRGA'
import numFormatter from '../../utilities/extrafunction/numberConveter'


const series = [{ data: [37, 76, 65, 41, 99, 53, 70] }]



const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    paddingTop: '0 !important'
  }
}))

const MonthlyReport = ({DashboardData}) => {
// data
const total_subscription = 0
const data = [
  {
    progress: 59,
    title: 'Committed',
    stats: `${numFormatter(DashboardData?.totalCommitedShares)}`,
    // avatarColor: 'info',
    // progressColor: 'info',
    avatarIcon: 'tabler:chart-pie-2'
  },
  {
    progress: 22,
    stats: `${numFormatter(DashboardData?.remainingShares)}`,
    title: 'Remainings',
    // avatarColor: 'error',
    // progressColor: 'error',
    avatarIcon: 'tabler:brand-paypal'
  },
  {
    progress: 64,
    stats: `${numFormatter(DashboardData?.totalShares)}`,
    title: 'Total Subscriptions',
    avatarIcon: 'tabler:currency-dollar'
  }
]

const subPercent = DashboardData?.sharesPercent && !isNaN(DashboardData?.sharesPercent) ?  DashboardData?.sharesPercent : 0
  // ** Hook
  const theme = useTheme()
  // date variables
  const month = new Date().getMonth()
  const monthIndex = month < 2 ? month : month - 2
  const monthsArray = month < 5 ? ['jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul'] : ['Jun', 'Jul', 'Aug','Sep','Oct','Nov', 'Dec']
  // const newMonthsArray = monthsArray.slice(0, month + 5)
  // console.log("newMonthsArray", newMonthsArray, month)
  const palettesArray = monthsArray.map((d, index) => {
    const hexInvert = hexToRGBA(theme.palette.primary.invert, 1)
    const hexMain = hexToRGBA(theme.palette.primary.main, 1)
    if (month < 5) {
      return index === month ? hexMain : hexInvert
    } else {
      const newmonth = month - 5 // month after june should match index
      return index === newmonth ? hexMain : hexInvert
    }
  })
  // const total_subs = total_subscription?.toString()?.split('.')[0]
  const options = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false }
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        distributed: true,
        columnWidth: '42%',
        endingShape: 'rounded',
        startingShape: 'rounded'
      }
    },
    legend: { show: false },
    tooltip: { enabled: false },
    dataLabels: { enabled: false },
    colors: palettesArray,
    states: {
      hover: {
        filter: { type: 'none' }
      },
      active: {
        filter: { type: 'none' }
      }
    },
    grid: {
      show: false,
      padding: {
        top: -28,
        left: -9,
        right: -10,
        bottom: -12
      }
    },
    xaxis: {
      axisTicks: { show: false },
      axisBorder: { show: false },
      categories: monthsArray,
      labels: {
        style: {
          colors: theme.palette.text.disabled,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.body2.fontSize
        }
      }
    },
    yaxis: { show: false }
  }

  return (
    <Card className='h-100' style={{boxShadow:'0px 2px 6px 0px rgba(47, 43, 61, 0.14)', background : theme.palette.customColors.bodyBg}} >
      <CardHeader
        sx={{ pb: 0 }}
        title='Subscriptions Reports'
        subheader='Subscriptions'
        // action={
        //   <OptionsMenu
        //     options={['Last Week', 'Last Month', 'Last Year']}
        //     iconButtonProps={{ size: 'small', sx: { color: 'text.disabled' } }}
        //   />
        // }
      />
      <CardContent>
        <Grid container spacing={6}>
          <StyledGrid
            item
            sm={5}
            xs={12}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end' }}
          >
            <Box sx={{ mb: 3, rowGap: 1, columnGap: 2.5, display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <Typography variant='h4'>${numFormatter(DashboardData?.totalSubscribedShares)}</Typography>
              <CustomChip rounded size='small' skin='light' color='success' label={`${subPercent}%`} />
            </Box>
            <Typography variant='body2'>You informed of this month compared to last month</Typography>
          </StyledGrid>
          <StyledGrid item xs={12} sm={7}>
            <Chart type='bar' height={163} series={series} options={options} />
          </StyledGrid>
        </Grid>
        <Box sx={{ mt: 6, borderRadius: 1, p: theme.spacing(4, 5), border: `1px solid ${theme.palette.divider}` }}>
          <Grid container spacing={6}>
            {data.map((item, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Box sx={{ mb: 2.5, display: 'flex', alignItems: 'center' }}>
                  <CustomAvatar
                    skin='light'
                    variant='rounded'
                    color={item.avatarColor}
                    sx={{ mr: 2, width: 26, height: 26 }}
                  >
                    <Icon fontSize='1.125rem' icon={item.avatarIcon} />
                  </CustomAvatar>
                  <Typography variant='h6'>{item.title}</Typography>
                </Box>
                <Typography variant='h5' sx={{ mb: 2.5 }}>
                  {item.stats}
                </Typography>
                <LinearProgress
                  variant='determinate'
                  value={item.progress}
                  color={item.progressColor}
                  sx={{ height: 4 }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}

export default MonthlyReport

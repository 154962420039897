import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import GoogleMapKey from "../Keys/apiKeys";
import { Input, InputGroup } from "reactstrap";

const AutoAddress = ({ formData, setFormData }) => {
  const [address, setAddress] = useState("");

  const handleChange = (newAddress) => {
    setAddress(newAddress);
    // setFormData({ ...formData, ...{ address: newAddress } });
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      console.log("Selected Address:", selectedAddress);
      // console.log("Latitude and Longitude:", latLng);
      setFormData({ ...formData, ...{ address: selectedAddress } });
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  return (
    <div>
      <PlacesAutocomplete
        googleCallbackName="initGooglePlaces"
        googleOptions={{
          apiKey: GoogleMapKey,
          libraries: ["places"],
        }}
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    key={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <li className="list-group-item">
                      {suggestion.description}
                    </li>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AutoAddress;

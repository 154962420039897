import React from 'react'
import ProfileImg from '../../assets/images/user-profile.png'
import { Button, Spin, Upload } from 'antd';
import { GrUpdate } from "react-icons/gr"
import profileBackground from '../../assets/images/card-bg.png'

const ProfileImage = ({ data, update, imageUpload }) => {
  return (
    <div class="row mb-3">
      <div class="col-sm-12">
        <div class="profile-card ">
          <div id="card-bg"></div>
        </div>
        <div className="company-profile-data position-relative border-rounded" >
          <div class="company-img align-items-center justify-content-between">
            <div className='d-flex align-items-start justify-content-center flex-column '>
              {update ?
                <div class="company-user-img d-flex align-items-center justify-content-center mt-1"><Spin /></div> :
                <div className=''>
                  {data?.sponsor_image ?
                    <img class="company-user-img bg-white border-rounded" src={data?.sponsor_image} alt="please add image" /> : <img className="company-user-img bg-white" />
                    // <img class="company-user-img" src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/profile%20imahe%2Fempty%20profile%20image.jpg?alt=media&token=d386df41-478a-4497-a252-f22e3ef051c5" alt="" />
                  }
                </div>}
              <h1 class="company-title">{data?.company ? data.company : 'Company Name'}</h1>
              <div className=' text-muted compnay-mail '>{data.user.email}</div>
            </div>
            <div>
              <input id="inputTag" type="file" style={{ display: "none" }} onChange={imageUpload}/>
              <label for="inputTag">
                <div className='btn button_color mt-4 update-logo' id="submit-btn">Update Logo</div>
              </label>
            </div>
            {/* </input> */}
          </div>
          {/* <input type="file" onChange={imageUpload} value=""/> */}
          {/* <label for="inputTag" className='profile_update' >
          <GrUpdate size={"30px"} color="blue"></GrUpdate><div>Update</div>
            <input id="inputTag" type="file" style={{ display: "none" }} onChange={imageUpload}/>
          </label> */}
        </div>
      </div>
    </div>
  )
}

export default ProfileImage
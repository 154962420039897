import numberWithCommas from '../../utilities/extrafunction/commaseparate';
import { Carousel, Icon } from 'antd';
import { useEffect, useRef, useState } from 'react';
import KeyboardArrowLeftSharpIcon from '@mui/icons-material/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import { Button, IconButton } from '@mui/material';
import styled from 'styled-components';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { useNavigate, useParams } from 'react-router-dom';
import MainClient from '../../utilities/MainClient';
import moment from 'moment';

const Price = styled.div`
font-weight: 600;
font-size: 24px;
line-height: 32px;
letter-spacing: 0.05em;
color: #2b4479;
cursor: pointer;
/* margin-top: 0.4rem; */
@media (min-width: 1200px) and (max-width: 1440px) {
  font-size: 20px;
}
@media (min-width: 768px) and (max-width: 1200px) {
  font-size: 16px;
}
@media (min-width: 200px) and (max-width: 560px) {
  font-size: 14px;
}
`;

const libraries = ["places"];

const ProtfolioDetails = ({ id }) => {
    const [data, setData] = useState()
    const CarouselRef = useRef();
    const CarouselMediaRef = useRef();

    const next = () => {
        CarouselRef.current.next();
    };
    const previous = () => {
        CarouselRef.current.prev();
    };
    useEffect(() => {
        const getData = async () => {
            const fetchData = new MainClient(`/deal/get-property-portfolio/${id}`)
            const response = await fetchData.getAPI()
            console.log('fetchData', response.data)
            setData(response.data.data[0])
        }
        getData()
    }, [id])
    console.log('data1', data)
    console.log('data2', data)
    return (
        <div>
            <div className='row'>
                <div className='col-md-7'>
                    <div className='card data-card h-100'>
                        <div className='card-body'>
                            <h3 class="data-title fw-bold">Portfolio</h3>
                            <div class="divider"></div>
                            <div className='pb-4 pt-1'>
                                <div className='row '>
                                    <div className='col-sm-12 col-lg-3'>
                                        <p className='fs-15 pb-1 mb-0 fw-5'>
                                            Property Name
                                        </p>
                                        <p className='fs-16'>{data?.property_name}</p>
                                    </div>
                                    <div className='col-sm-12 col-lg-3'>
                                        <p className='fs-15 pb-1 mb-0 fw-5'>Address</p>
                                        <p className='fs-16'>{data?.property_address}</p>
                                    </div>
                                    <div className='col-sm-12 col-lg-3'>
                                        <p className='fs-15 pb-1 mb-0 fw-5'>City</p>
                                        <p className='fs-16'>{data?.property_city}</p>
                                    </div>
                                    <div className='col-sm-12 col-lg-3'>
                                        <p className='fs-15 pb-1 mb-0 fw-5'>State</p>
                                        <p className='fs-16'>{data?.property_state}</p>
                                    </div>
                                    <div className='col-sm-12 col-lg-3'>
                                        <p className='fs-15 pb-1 mb-0 fw-5'>Units</p>
                                        <p className='fs-16'>{data?.units ? numberWithCommas(data?.units) : ''}</p>
                                    </div>
                                    <div className='col-sm-12 col-lg-3'>
                                        <p className='fs-15 pb-1 mb-0 fw-5'>Square Feet</p>
                                        <p className='fs-16'>{data?.sf ? numberWithCommas(data?.sf) : ''}</p>
                                    </div>
                                    <div className='col-sm-12 col-lg-3'>
                                        <p className='fs-15 pb-1 mb-0 fw-5'>Price</p>
                                        <p className='fs-16'>{data?.price ? numberWithCommas(data?.price) : ''}</p>
                                    </div>
                                    <div className='col-sm-12 col-lg-3'>
                                        <p className='fs-15 pb-1 mb-0 fw-5'>Average Returns</p>
                                        <p className='fs-16'>{data?.average_returns ? numberWithCommas(data?.average_returns) : '' }</p>
                                    </div>
                                    <div className='col-sm-12 col-lg-3'>
                                        <p className='fs-15 pb-1 mb-0 fw-5'>IRR</p>
                                        <p className='fs-16'>{data?.irr ? numberWithCommas(data?.irr) : ''}</p>
                                    </div>
                                    <div className='col-sm-12 col-lg-3'>
                                        <p className='fs-15 pb-1 mb-0 fw-5'>Acquisition Date</p>
                                        <p className='fs-16'>{data?.acquisition_date ? moment(data.acquisition_date).format("MM-DD-YYYY") : ''}</p>
                                    </div>
                                </div>

                            </div>
                            <div className='row p-0 '>

                                <div className='col'>
                                    {data?.image_data?.length > 0 && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <IconButton onClick={previous}>
                                                <KeyboardArrowLeftSharpIcon />
                                            </IconButton>
                                            <div
                                                className='single-item'
                                                style={{
                                                    display: 'block',
                                                    width: 100,
                                                    flex: 1,
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <Carousel
                                                    ref={(carousel) => (CarouselRef.current = carousel)}
                                                    asNavFor={CarouselMediaRef.current}
                                                    autoplay
                                                    dots={false}
                                                    slidesToShow={4}
                                                    centerMode={true}
                                                    //   swipeToSlide={true}
                                                    touchThreshold={50}
                                                    focusOnSelect={true}
                                                    draggable={true}
                                                    infinite={data?.image_data?.length > 5}

                                                // afterChange={Index}
                                                >
                                                    {data?.image_data?.map((img, index) => (
                                                        <div key={index}>
                                                            <img
                                                                className='img-fluid'
                                                                src={`https://stackshares.io${img.images}`}
                                                                alt=''
                                                                style={{
                                                                    minHeight: '90px',
                                                                    height: '90px',
                                                                    objectFit: 'cover',
                                                                    width: '90%',
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </Carousel>
                                            </div>
                                            <IconButton onClick={next}>
                                                <KeyboardArrowRightSharpIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='col-md-5 investor-image-div'>
                    <div>
                        {data?.image_data?.length > 0 && (
                            <div className='single-item'>
                                <Carousel
                                    ref={(carousel) => (CarouselMediaRef.current = carousel)}
                                    autoplay
                                    dots={false}
                                    asNavFor={CarouselRef.current}
                                // afterChange={Index}
                                >
                                    {data?.image_data?.map((img, index) => (
                                        <div key={index}>
                                            <img
                                                className='img-fluid w-100 investor-deal-image'
                                                src={`https://stackshares.io${img.images}`}
                                                alt=''
                                                style={{
                                                    // minHeight: '400px',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                        )}
                    </div>

                </div>
            </div>

        </div>
    );
};

export default ProtfolioDetails;

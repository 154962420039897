import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import MainClient from '../../utilities/MainClient'
import ProfileDetails from './ProfileDetails'
import ProfileImage from './ProfileImage'

const AdminProfile = ({ UserUpdateHandler }) => {
  const [update, setUpdate] = useState(false)
  const [data, setData] = useState({
    "user": {
      "first_name": "",
      "last_name": "",
      "email": ""
    },
    "company": "",
    "company_address": "",
    "country": "",
    "sponsor_image": "",
    "phone_number": ""
  })

  useEffect(() => {
    const getData = (id) => {
      const fetchClient = new MainClient(`/investor/admin-profile/${id}/`)
      const response = fetchClient.getAPI()
      response.then(res => {
        console.log('responseUser', res.data)
        // setData((res.data.results).reverse())
        setData(res.data)
      })
    }
    const getUserData = () => {
      const fetchClient = new MainClient(`/user/user-details`)
      const response = fetchClient.getAPI()
      response.then(res => {
        console.log('responseUser', res.data.profile_id)
        getData(res.data.profile_id)
      })
    }
    // getData()
    getUserData()
  }, [])

  const changeHandler = (e) => {
    const { name, value } = e.target
    if (name === "first_name" || name === "last_name") {
      let user = {
        ...data.user, [name]: value
      }
      setData({ ...data, user: user })
    }
    else {
      setData({ ...data, [name]: value })
    }
  }
  const submitHandler = (event) => {
    event.preventDefault();
    const submitData = delete data["admin_image"]
    console.log('submitData', data)
    const updateData = () => {
      const fetchClient = new MainClient(`/investor/admin-profile/${data.id}/`, data)
      const response = fetchClient.putAPI()
      response.then((res)=>{
        message.success('Your profile successfully updated')
      }).catch((err)=>{
        message.error('Profile update failed')
      })
    }
    updateData()
  }
  const imageUpload = (e) => {
    console.log('image upload')
    setUpdate(true)
    let form_data = new FormData()
    form_data.append('admin_image', e.target.files[0], e.target.files[0].name)
    const fetchClient = new MainClient(`/investor/admin-profile-image/${data.id}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    const response = fetchClient.putAPI()
    response.then(res => {
        setData({ ...data, admin_image: res.data.admin_image })
        setUpdate(false)
        message.success("Image Upload successfully")
        UserUpdateHandler()
      })
  }
  console.log('sposorData', data)
  return (
    <div class="mt-3">
      <ProfileImage data={data} imageUpload={imageUpload} update={update} />
      <ProfileDetails changeHandler={changeHandler} data={data} submitHandler={submitHandler} />
    </div>

  )
}

export default AdminProfile
import React from 'react'
import { Col, Row } from 'reactstrap'
import MonthlyReport from './MonthlyReport'
import KPIAnalysis from './KPIAnalysis'
import './card.scss'
import InvestorsKPI from './InvestorsKPI'
import Classes from './Classes'
import { useEffect, useState } from 'react'
import numberWithCommas from 'src/utilities/extrafunction/commaseparate'
import MainClient from '../../utilities/MainClient'
import ModeToggler from './ModeToggler'
import { useSettings } from '@core/hooks/useSettings'
import numFormatter from '../../utilities/extrafunction/numberConveter'
import { useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOpenDeal } from '../../store/actions/DealAction'
import { getFirstMonthDate } from '../../utilities/extrafunction/getFirstMonth'

function NewDashboard({userType}) {
  const { settings, saveSettings } = useSettings()       
  const theme = useTheme()
  const {palette} = theme
  const [dashboardData, setDashboardData] = useState([]);
  const [InvestorKPI, setInvestorKPI] = useState({})
  const [analysisKPI, setAnalysisKPI] = useState({})
  const dispatch = useDispatch()
  const openDeals = useSelector(state => state.deals.openDealData)
  console.log('openDeals', openDeals)
  // const {firstDayOfMonth ,firstDayOfLastMonth } = getFirstMonthDate()
  const firstDayOfMonth = "2024-01-24"
  const firstDayOfLastMonth = "2024-01-23"

  console.log('firstMonthDate', firstDayOfMonth, firstDayOfLastMonth)
  const totalShares = openDeals?.map(d => d.total_shares)?.reduce((a,b) => parseFloat(a) + parseFloat(b))
  const totalSubscribedShares = openDeals?.filter(d => d.launch_date >= firstDayOfMonth)?.map(d => d.committed_shares)?.reduce((a,b) => parseFloat(a) + parseFloat(b), 0) || 0
  const totalprevSubscribedShares = openDeals?.filter(d => d.launch_date >= firstDayOfLastMonth)?.map(d => d.committed_shares)?.reduce((a,b) => parseFloat(a) + parseFloat(b), 0) || 0
  const newSharesPercent = (1 - totalSubscribedShares / totalprevSubscribedShares ) * 100
  const totalCommitedShares = openDeals?.filter(d => d.launch_date >= firstDayOfMonth)?.map(d => d.committed_shares)?.reduce((a,b) => parseFloat(a) + parseFloat(b), 0) || 0
  const remainingShares = totalSubscribedShares && totalCommitedShares ? totalShares - totalCommitedShares : 0
  const DashboardData = {"totalShares": totalShares, "totalSubscribedShares" : totalSubscribedShares, "totalCommitedShares" : totalCommitedShares, 'remainingShares': remainingShares, lastMonthShares:totalprevSubscribedShares, sharesPercent:newSharesPercent.toFixed(1)}
  console.log(DashboardData)

  useEffect(() => {

    dispatch(fetchOpenDeal())

}, [])

  useEffect(() => {
      const getData = () => {
        const fetchClient = new MainClient('/investor/dashboard');
        const response = fetchClient.getAPI();
        response
          .then((res) => {
            console.log('dashboard', res.data.result);
            setDashboardData(res.data.result);
          })
          .catch((err) => console.log(err));
      };
      const getAdminData = () => {
        const fetchClient = new MainClient('/investor/admin-dashboard');
        const response = fetchClient.getAPI();
        response
          .then((res) => {
            console.log('admin dashboard', res.data.result);
            setDashboardData(res.data.result);
          })
          .catch((err) => console.log(err));
      };
      const getInvestorsData = () => {
        const fetchClient = new MainClient('/deal/new-dashboard/');
        const response = fetchClient.getAPI();
        response
          .then((res) => {
            console.log('new dashboard', res.data);
            setInvestorKPI(res.data.investors);
            setAnalysisKPI(res.data)
          })
          .catch((err) => console.log(err));
      };
      console.log("admin", userType)
      if (userType === "admin") {
        // setDashboardData(InvestorDashboardData);
        // console.log("adminDashboard", InvestorDashboardData)
        getAdminData()
        getInvestorsData()
      } else if (userType === 'investor' ){
        getData();
      }
    }, [userType]);

    // const total_subscription = dashboardData?.total_subscription
    //   ? numFormatter(dashboardData?.total_subscription)
    //   : 0


  return (
    <>
 {/* <ModeToggler settings={settings} saveSettings={saveSettings} /> */}
      <Row className='justify-content-stretch' >
        <Col className='my-1' lg='6'>
          <MonthlyReport DashboardData={DashboardData} />
        </Col>
        <Col className='my-1' lg='6'>
          <KPIAnalysis kpiData={analysisKPI} />
       
        </Col>
        <Col  className='my-3' lg='5'>
       
    {<InvestorsKPI data={InvestorKPI} />}
        </Col>
       
        <Col  className='my-3' lg='4'>
        <Classes classA={analysisKPI?.ClassA} classB={analysisKPI?.ClassB} totalShares={totalShares} />
        </Col>

      </Row>

    </>
  )
}

export default NewDashboard
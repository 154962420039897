import { useTheme } from "@mui/material"
import numberWithCommas from "../../utilities/extrafunction/commaseparate"

const CapitalResultTable = ({ data, len, tierTwo }) => {
    // console.log('cpCash', cpData)
    const theme = useTheme()
    const { palette } = theme
    const { mode } = palette
    const cellStyles = { color: palette.text.primary }
    const cellHeadStyles = {
        color: mode === 'light' ? palette.primary.dark : palette.primary.light,
    //    backgroundColor:
    }
    console.log('data', data)
    return (
        <div className="mb-3">
                {data.tier && <div className="pb-2 fs-16 fw-4 mt-3 mb-2">{data.tier}</div>}
                <div className="table-responsive">
                    <table style={{backgroundColor: palette.customColors.bodyBg}} className="table data-table align-middle table-borderless sponser-index-table"
                        id="sponser-table">
                        <thead className="border-bottom border-top" style={{ 'fontSize': '14px', 'fontWeight': '400' }}>
                            <tr  style={cellHeadStyles} >
                                <th   className="col-1 align-middle">Classes</th>
                                {data.class_A_equity ?
                                <th   className="col-1 text-center">{data.tier==="Initial Equity %" ? "Equity %" : "Share %"} </th>: <th   className="col-1"></th>}
                                <td className="col-1"></td>
                                {data.class_A_year}
                                <th   className="col-1 text-center">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  style={cellStyles} >
                                <td className="col-1">Class A</td>
                                {data.class_A_equity ? 
                                <td className="col-1 text-center">{data.class_A_equity} %</td> :<td className="col-1"></td>}
                                <td className="col-1"></td>
                                {data.class_A}
                                {data.class_A_Total ? <td className="col-1 text-center">{data.class_A_Total}</td>: <td className="col-1"></td>}
                                {/* {data.tier==='% Capital Outstanding' ? data.class_A+'%': data.class_A} */}
                            </tr>
                            {data.class_B.length > 0 &&
                            <tr  style={cellStyles} >
                                <td className="col-1">Class B</td>
                                {data.class_B_equity ? 
                                <td className="col-1 text-center">{data.class_B_equity} %</td> :<td className="col-1"></td>}
                                <td className="col-1"></td>
                                {data.class_B}
                                {data.class_B_Total ? <td className="col-1 text-center">{data.class_B_Total}</td>: <td className="col-1"></td>}
                            </tr>}
                            {data.class_C.length > 0 &&
                            <tr  style={cellStyles} >
                                <td className="col-1">Class C</td>
                                {data.class_C_equity ? 
                                <td className="col-1 text-center">{data.class_C_equity} %</td> : <td className="col-1"></td>}
                                <td className="col-1"></td>
                                {data.class_C}
                                {data.class_C_Total ? <td className="text-center">{data.class_C_Total}</td>: <td className="col-1"></td>}
                            </tr>}
                            {data.sponsor ? data.sponsor.length > 0 &&
                            <tr  style={cellStyles} >
                                <td className="col-1">Sponsor</td>
                                <td className="col-1"></td>
                                <td className="col-1"></td>
                                {data.sponsor}
                                <td className="col-1 text-center">{data.sponsorTotal}</td>
                            </tr>: ''}
                            <tr style={cellStyles} className="border-top">
                                <td className="col-1 fw-6">Total</td>
                                <td className="col-1"></td>
                                <td className="col-1"></td>
                                {data.total}
                                <td className="col-1 text-center">{data.tTotal}</td>
                            </tr>
                            {data.rem &&
                            <tr style={cellStyles} className="border">
                                <td className="col-1 fw-5">Remaining</td>
                                <td className="col-1"></td>
                                <td className="col-1"></td>
                                {data.rem}
                                <td className="col-1 text-center">{data.remTotal}</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
    )
}

export default CapitalResultTable
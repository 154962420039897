export const currenTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
export function formatDateInTimezone(date, timezone) {
  const options = {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // hour: '2-digit',
    // minute: '2-digit',
    // second: '2-digit',
    // hour12: false,
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);

  return formatter.format(new Date(date));
}
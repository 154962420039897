import { NavLink } from "react-router-dom"
import styled from "styled-components"

const StyleNavLink = styled(NavLink)`
    margin-top: 2rem;
    width: 100%;
    padding: 2rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: start;
    // cursor: pointer;
    margin-top: 0.8rem;
    transition:.5s;
    border: 'none';
    cursor:pointer;
    height: 50px;
    &:hover{
    background: ${(props) => (props.active ? '#FFFFFF' : 'transparent')};
    border:  ${(props) => (props.active ? 'none' : '1px solid #FFFFFF')};
    border-left: none;
    border-radius: 0px 34px 34px 0px;
    }
    .white-icon{
      display: none;
    }
    .black-icon{
      display: block;
    }
    &.active{
      background: #2B4479;
      border-radius: 0px 34px 34px 0px;
      // border-left: none;
      width:20px;
      .toggle-span {
        color: #000000;
      }
      .white-icon{
        display: block;
      }
      .black-icon{
        display: none;
      }
      .toggle-span{
        color: #FFFFFF;
      }
    }
    @media(min-width:480px) and (max-width: 920px){
      padding: 0.5rem 0;
      margin-top: 8px;
      .nav-icon{
        width: 25px;
        margin-bottom: 2px;
      }
      .image-logo{
        margin-right: 5px;
      }
    }
    @media(max-width: 480px){
      padding: 0.5rem 0;
      margin-top: 0;
      .nav-icon{
        width: 20px;
      }
      .image-logo{
        margin-right: 5px;
      }
    }
    
    `

const Li = styled.li`
    list-style-type: none;
  `;

const NavElement = ({ url, iconWhite, iconBlack, title }) => {
    return (
        <StyleNavLink to={url} className='w-100 me-3' >
            <Li className="d-flex align-items-center  w-100 ps-4">
                <div className='image-logo black-icon me-md-3'>
                    <img src={iconBlack} className="nav-icon" />
                </div>
                <div className='image-logo white-icon me-md-3'>
                    <img src={iconWhite} className="nav-icon" />
                </div>
                {title==="Add New Deal" ?
                <div className='title-text text-dark mb-md-1 w-100'>
                    <span class='toggle-span'>Add</span>
                    <span class='toggle-span' style={{ marginRight: ".2rem", marginLeft: ".2rem" }}>New </span>
                    <span class='toggle-span'>Deal </span>
                </div>:
                <div className='title-text text-dark mb-md-1'>
                    <span class='toggle-span'>{title}</span>
                </div>}
            </Li>
        </StyleNavLink>
    )
}

export default NavElement
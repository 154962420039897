// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import LinearProgress from '@mui/material/LinearProgress'

// ** Custom Components Imports
import CustomAvatar from 'src/@core/components/mui/avatar'

// ** Icon Imports
import Icon from 'src/@core/components/icon'
import { useState } from 'react'
import { useTheme } from '@mui/material'

const InvestorsKPI = ({data}) => {
  const theme = useTheme()
  const getActivePercent = () => {
    if (data) {
      const perc = data.active / data.total * 100
      return perc.toFixed(2) + '%'
    } else {
      return '0%'
    }
    
  }
  const getPendingPercent = () => {
    if (data) {
      const perc = data.pending / data.total * 100
      return perc.toFixed(2) + '%'
    } else {
      return '0%'
    }
    
  }

  return (
    <Card  style={{boxShadow:'0px 2px 6px 0px rgba(47, 43, 61, 0.14)', background : theme.palette.customColors.bodyBg}}>
      <CardContent sx={{ p: theme => `${theme.spacing(5)} !important` }}>
        <Box sx={{ gap: 2, mb: 5, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <div>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              Investors Overview
            </Typography>
            <Typography variant='h4'>{data?.total}</Typography>
          </div>
          {/* <Typography sx={{ fontWeight: 500, color: 'success.main' }}>+18.2%</Typography> */}
        </Box>
        <Box sx={{ mb: 3.5, gap: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ py: 2.25, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ mb: 2.5, display: 'flex', alignItems: 'center' }}>
              <CustomAvatar skin='light'  variant='rounded' sx={{ mr: 1.5, height: 24, width: 24 }}>
                <Icon icon='tabler:shopping-cart' fontSize='1.125rem' />
              </CustomAvatar>
              <Typography sx={{ color: 'text.secondary' }}>Active</Typography>
            </Box>
            <Typography variant='h5'>{getActivePercent()}</Typography>
            <Typography variant='body2' sx={{ color: 'text.disabled' }}>
             {data.active}
            </Typography>
          </Box>
          <Divider flexItem sx={{ m: 0 }} orientation='vertical'>
            <CustomAvatar
              skin='light'
              color='secondary'
              sx={{ height: 24, width: 24, fontSize: '0.6875rem', color: 'text.secondary' }}
            >
              VS
            </CustomAvatar>
          </Divider>
          <Box sx={{ py: 2.25, display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
            <Box sx={{ mb: 2.5, display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 1.5, color: 'text.secondary' }}>Not verified</Typography>
              <CustomAvatar skin='light' variant='rounded' sx={{ height: 24, width: 24 }}>
                <Icon icon='tabler:link' fontSize='1.125rem' />
              </CustomAvatar>
            </Box>
            <Typography variant='h5'>{getPendingPercent()}</Typography>
            <Typography variant='body2' sx={{ color: 'text.disabled' }}>
              {data.pending}
            </Typography>
          </Box>
        </Box>
        <LinearProgress
          value={getActivePercent()}
          // color='info'
          variant='determinate'
          sx={{
            height: 10,
            '&.MuiLinearProgress-colorInfo': { backgroundColor: 'primary.secondary' },
            '& .MuiLinearProgress-bar': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }
          }}
        />
      </CardContent>
    </Card>
  )
}

export default InvestorsKPI

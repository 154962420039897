import { useSelector } from "react-redux";
import MainClient from "../../utilities/MainClient";
import { investorAction } from "../reducers/InvestorReducer";

export const fetchInvestors = () => {
    return (async (dispatch) => {
        const getData = async () => {
            const fetchClient = new MainClient('/investor/my-investors')
            const response = await fetchClient.getAPI()
            const data = await response.data
            return data.reverse()
        }
        try {
            const data = await getData()
            dispatch(investorAction.replaceInvestor(data))
        } catch (error) {
            dispatch(investorAction.investorMessage(error.messages))
        }
    })
}

export const inviteInvestor = (inviteData) => {
    return (async (dispatch) => {
        dispatch(investorAction.loadingHandler(true))
        const invite = async () => {
            const fetchData = new MainClient('/deal/send-invite/', inviteData)
            const response = await fetchData.postAPI()
            const data = await response.data.result
            return data
        }
        try{
            const data = await invite()
            console.log("inviteData", data)
            dispatch(investorAction.sendInvitations(data))
        }catch(error){
            const errorMessage = JSON.parse(error.request.response)
            console.log('error', errorMessage.message)
            dispatch(investorAction.investorMessage(errorMessage.message))
        }
    })
}

import "../../assets/css/icons.css";
import "../../assets/css/multi-slider.css";
import "../../assets/css/style1.css";
import Sidebar from "../static/Sidebar";
import Header from "../static/Header";
import DealDetails from "./DealDetails";
import Dept from "./Dept";
import Footer from "../static/Footer";
import Shares from "./Shares";
import Subscribe from "./Subscribe";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainClient from "../../utilities/MainClient";
import { useParams } from "react-router-dom";
import WaterFall from "./Waterfall";
import Test from "./Test";
import SubscriptionColumn from "./SubscriptionColumn";
import NewDealDetails from "./NewDealDetails";
import NewDept from "./NewDept";

// Adding Code of cs
import Result from "../newSponsor/Result";
import { Button } from "@mui/material";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import CapitalResult from "../newSponsor/CapitalResult";
import FinalResult from "../newSponsor/FinalResult";
import axios from "axios";
import { defaultCurrency } from "../../store/reducers/deals";
import { setCurrencyData } from "../../store/actions/deals";

const Investor = () => {
  const toggle = useSelector((state) => state.toggle);
  console.log("toggles", toggle);
  const { id } = useParams();
  const [share, setShare] = useState();
  const [document, setDocument] = useState();

  const [terms, setTerms] = useState(1);
  const [data, setData] = useState([]);
  const [opData, setOpdata] = useState();
  const [cpData, setCpdata] = useState();

  const [opCashFlow, setOpCashFlow] = useState([]);
  const [cpCashFlow, setCpCashFlow] = useState([]);

  const [result, setResult] = useState();

  const [showResult, setShowResult] = useState(false);

  const [propertyList, setPropertyList] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get(`http://127.0.0.1:8000/deal/getpropertyinfodeal/${id}/`)
  //     .then((response) => {
  //       const data = response.data;
  //       const properties = data.map((item) => item.fields);
  //       setPropertyList(properties);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [id]);
  const dispatch = useDispatch()
  const [seniorDept, setSeniorDept] = useState({
    capital_stack: "",
    amount: "",
    purchase_basis: "",
    total_capital_basis: "",
  });

  // ***** property start ******* //
  const [property, setProperty] = useState({
    deal_name: "",
    property_title: "",
    address: "",
    city: "",
    state: "",
    units: "",
    description: "",
    user: 2,
  });
  const [metrics, setMetrics] = useState({
    purchase_price: "",
    closing_cost: "",
    capex: "",
    reserves: "",
    entry_noi: "",
    stabilized_noi: "",
    pro_noi: "",
    entry_capbasis: "",
    stabilized_capbasis: "",
    pro_capbasis: "",
  });
  const [entryNoiSelect, setEntryNoiSelect] = useState({
    purchase_price: true,
    closing_cost: false,
    capex: false,
    reserves: false,
    custom: false,
  });
  const [stabilizedNoiSelect, setStabilizedNoiSelect] = useState({
    purchase_price: true,
    closing_cost: false,
    capex: false,
    reserves: false,
    custom: false,
  });
  const [proNoiSelect, setProNoiSelect] = useState({
    purchase_price: true,
    closing_cost: false,
    capex: false,
    reserves: false,
    custom: false,
  });
  const [sponsorData, setSponsorData] = useState();
  console.log("tMetics", metrics);
  const total =
    metrics.purchase_price +
    metrics.closing_cost +
    metrics.capex +
    metrics.reserves;
  useEffect(() => {
    getAdditionPropertyInfo();
    getData();
    getCashflow();
    getCapitalStack();
    getShare();
    getEquityWaterfall();
    getResult();
    // getWaterfall()
  }, [id]);

  const getCashflow = () => {
    const fetchClient = new MainClient(
      `/deal/view-deal-cash-flow/?deal_id=${id}`
    );
    const response = fetchClient.getAPI();
    response.then((res) => {
      console.log("cashFlos", res.data);
      setOpCashFlow(res.data[0].op_cash_flow_years);
      setCpCashFlow(res.data[0].cp_cash_flow_years);
    });
  };

  let T = [];
  const CP = [];
  const CashFlow = opCashFlow.map(
    (dt) => dt.value && T.push(parseInt(dt.value))
  );

  const SeconedCashFlow = cpCashFlow.map(
    (dt) => dt.value && CP.push(parseInt(dt.value))
  );

  let totalCashflow =
    T.reduce((a, b) => a + b, 0) + CP.reduce((a, b) => a + b, 0);

  const getCapitalStack = () => {
    const fetchClient = new MainClient(
      `/deal/view-capital_stack/?deal_id=${id}`
    );
    const response = fetchClient.getAPI();
    response.then((res) => {
      const data = res.data[0];
      console.log("newCap", res.data);
      setSeniorDept({
        // sponsor_profit_share: "70",
        // ...seniorDept,
        capital_stack: res.data[0].senior_debt,
        amount: res.data[0].senior_debt_amount,
      });
      setData(data.classesData);
      // setDataLen(data.classesData.length)
      // setCapId(data.id)
    });
  };

  const getResult = () => {
    const fetchClient = new MainClient(`/deal/waterfall/${id}/`);
    const response = fetchClient.getAPI();
    response
      .then((res) => {
        console.log("response", res.data.response);
        setResult(res.data.response);
      })
      .catch((err) => {
        console.log("responseError", err);
      });
    // response.error('')
  };

  const getAdditionPropertyInfo = () => {
    const fetchClient = new MainClient(`/deal/getpropertyinfodeal/${id}/`);
    const response = fetchClient.getAPI();
    response
      .then((response) => {
        const data = response.data;
        const properties = data.map((item) => item.fields);
        setPropertyList(properties);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const capAmount = data
    ? data.map((dt) => parseInt(dt.amount)).reduce((a, b) => a + b, 0)
    : "";
  const getData = () => {
    const fetchClient = new MainClient(`/deal/api/view-add-deal/${id}/`);
    const response = fetchClient.getAPI();
    response.then((res) => {
      // get currency data
      dispatch(setCurrencyData(res.data.currency || defaultCurrency))

      const data = res.data;
      setDocument(data.upload_document);
      console.log("dta", data);
      const total =
        parseInt(data.purchase_price) +
        parseInt(data.closing_cost) +
        parseInt(data.capex) +
        parseInt(data.reserves);
      console.log("logdata", res.data);
      setProperty(res.data);
      setMetrics({
        purchase_price: parseInt(data.purchase_price),
        closing_cost: parseInt(data.closing_cost),
        capex: parseInt(data.capex),
        reserves: parseInt(data.reserves),
        entry_noi: data.entry_noi,
        stabilized_noi: data.stabilized_noi,
        pro_noi: data.proForma_noi,
      });
      setEntryNoiSelect(data.noi_data[0]);
      setStabilizedNoiSelect(data.noi_data[1]);
      // setProNoiSelect(data.noi_data[2])
      setTerms(data.investment_term);
      // setSeniorDept({
      //     // sponsor_profit_share: "70",
      //     ...seniorDept,
      //     purchase_basis: parseInt(data.purchase_price),
      //     total_capital_basis: total,
      // })
    });
  };
  const getShare = () => {
    const fetchClient = new MainClient(`/deal/investor/${id}/`);
    const response = fetchClient.getAPI();
    response.then((res) => {
      setShare(res.data.result1);
      console.log("companyName", res.data.profile_data[0]);
      setSponsorData(res.data.profile_data[0]);
    });
  };

  const getEquityWaterfall = () => {
    const fetchClient = new MainClient(`/deal/equity-waterfall/?deal_id=${id}`);
    const response = fetchClient.getAPI();
    response.then((res) => {
      console.log("watarfalls", res.data);
      const data = res.data[0];
      setOpdata(data.op_waterfall);
      setCpdata(data.cp_waterfall);
    });
  };
  const targeted_irr = share ? share.targeted_irr : "";
  console.log("response2", result);

  // To Show and Hide Result "Operating Cash Flow"
  const handleClick = () => {
    setShowResult(!showResult);
  };

  return (
    <div>
      {/* <Sidebar/>
            <Header /> */}
      {/* <div class="xp-rightbar">
                <div class="xp-contentbar"> */}
      {/* <DealDetails property={property} metrics={metrics} terms={terms} total={total} targeted_irr={targeted_irr} totalCashflow={totalCashflow} capAmount={capAmount}/> */}
      <NewDealDetails
        property={property}
        propertyList={propertyList}
        setPropertyList={setPropertyList}
        metrics={metrics}
        terms={terms}
        total={total}
        targeted_irr={targeted_irr}
        totalCashflow={totalCashflow}
        capAmount={capAmount}
        sponsor={sponsorData}
        document={document}
      />
      {/* <div class="card data-card h-100 ">
        <NewDept data={data} seniorDept={seniorDept} total={total} />
      </div> */}
      {result ? (
        <SubscriptionColumn
          shares={share}
          data={data}
          result={result}
          terms={terms}
          totals={total}
          targeted_irr={targeted_irr}
          totalCashflow={totalCashflow}
          capAmount={capAmount}
          property={property}
          propertyList={propertyList}
          seniorDept={seniorDept}
          Opdata={opData}
          Cpdata={cpData}
        />
      ) : null}

    </div>
  );
};

export default Investor;

import styled from 'styled-components';
import MobileSidebar from './MobileSidebar';
import NewSidebar from './NewSidebar';
import Sidebar from './Sidebar';

const SidebarMobile = styled.div`
  display: none;
  @media (max-width:1200px){
    display: flex;
  }
`
const SidebarDesktop = styled.div`
  display: flex;
  @media (max-width:1200px){
    display: none;
  }
`

export default function MainSideNav({ user, tg, tgHandler }) {
  return (
    <div>
      <SidebarMobile>
        {/* <a class="btn btn-primary" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
          Link with href
        </a>
        <div class="offcanvas offcanvas-start w-25" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body"> */}
            {/* <MobileSidebar user={user} tg={tg} tgHandler={tgHandler} /> */}
          {/* </div>
        </div> */}
        {/* <Sidebar user={user} tg={tg} tgHandler={tgHandler} /> */}
      </SidebarMobile>
      <SidebarDesktop>
        <NewSidebar user={user} tg={tg} tgHandler={tgHandler} />
      </SidebarDesktop>
    </div>
  )
}
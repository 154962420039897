import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowDown from '../../assets/images/arrowdown.svg'
import Cross from '../../assets/images/cross_btn.svg'
import MainClient from '../../utilities/MainClient'
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsArrowDown } from "react-icons/bs";
import InvestorTable from './InvestorTable'
import { useTheme } from '@mui/material'
import { FormHeader, FormLabel } from '../newSponsor/FormElements'

const WaterFallDetails = ({ Opdata, Cpdata, }) => {
    const [investor, setInvestor] = useState([])
    const [update, setUpdate] = useState(false)
    const { id } = useParams()
    const theme = useTheme()
    const {palette} = theme

    useEffect(() => {
        const fetchClient = new MainClient(`/investor/deal-invitation-send/${id}/`)
        const response = fetchClient.getAPI()
        response.then(res =>{
            console.log("investor", res.data.results)
            setInvestor(res.data.results)}
        ).catch(err => console.log(err))
    }, [update])
    const invitationHandler = (name, email) =>{
        const body = {
            "investor_email":email,
            "investor_name":name
        }
        const fetchClient = new MainClient(`/investor/deal-invitation-send/${id}/`, body)
        const response = fetchClient.postAPI()
        response.then(res =>{
            console.log("investorPost", res.data)
            setUpdate(!update)
            }
        ).catch(err => console.log(err))
    }
    const myInvestor = investor?.length > 0 ? investor.map((dt, index) =>
        <InvestorTable dt={dt} index={index} key={dt.email} invitationHandler={invitationHandler}/>
    ) : ''

    const operatingCashFlow = () => Opdata ? Opdata.map((dt, index) => {
        return (
            <div >
                <div id="operatingCashFlow">
                    <div class='opeClass'>
                        <div class="cash-div">
                            <h4 class="fs-14 fw-4 text-black">{dt.classes}</h4>
                            <h5 class="fs-12 text-muted">{dt.tier}</h5>
                        </div>
                        {Opdata.length === index + 1 ? <div></div> :
                            <div class="text-center my-2">
                                <BsArrowDown size={22} className="text-muted" />
                                {/* <img src={ArrowDown} alt="" /> */}
                            </div>}
                    </div>
                </div>

            </div>
        )
    }) : []

    const capitalCashFlow = () => Cpdata ? Cpdata.map((dt, index) => {
        return (
            <div style={{ position: 'relative' }}>
                <div id="operatingCashFlow">
                    <div className='opeClass'>
                        <div className="cash-div">
                            <h4 className="fs-14 fw-4 text-black">{dt.classes}</h4>
                            <h5 className="fs-12 text-muted">{dt.tier}</h5>
                        </div>
                        {Cpdata.length === index + 1 ? <div></div> :
                            <div className="text-center my-2">
                                {/* <img src={ArrowDown} alt="" /> */}
                                <BsArrowDown size={22} className="text-muted" />
                            </div>}
                    </div>
                </div>
                {/* <div style={{ position: "absolute", right: "-1.4rem" }}>
                    {Cpdata.length === index + 1 ?
                        <button class='btn cross-btn' id="removeCashFlow" onClick={cpRemoveHandler}>
                            <AiOutlineCloseCircle className='bg-danger text-white rounded-circle' />
                        </button> : <div></div>}
                </div> */}
            </div>
        )
    }) : []

    return (
        <div>
            <div class="row mb-3 card-container">
                <div class="col-12">
                    <div class="">
                        <div class="card-body">
                            <div class='Opdata-header mb-3'>
                                <FormHeader theme={theme}>Waterfall</FormHeader>
                            </div>
                            <div class="Opdata-body mt-2">

                                <div class="divider"></div>
                                <div class="row gx-5 mt-4 mb-3">
                                    <div class="col-sm-6 position-relative">
                                        <FormLabel theme={theme}>1:- Operating Cash Flow</FormLabel>
                                        {operatingCashFlow()}
                                    </div>
                                    <div class="col-sm-6">
                                        <FormLabel theme={theme}>2:- Capital Event Cash Flow</FormLabel>
                                        <div id="capitalCashFlow">
                                            {capitalCashFlow()}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <div class="row mt-2 align-items-center justify-content-end">
                <div class="col-auto mb-3">
                    <button class="btn btn-secondary copy-link">Copy Link</button>
                    <button class="btn btn-primary invite2-btn" data-bs-toggle="modal" data-bs-target="#InviteModal">Invite
                        Investor</button>
                </div>
            </div>
            <div class="modal fade " id="InviteModal" tabindex="-1" aria-labelledby="InviteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="container-fluid">
                                <div class="ps-1">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h3 class=" mb-0 fs-16 fw-6 text-dark">All Investors ({investor.length})</h3>

                                        <button class="btn btn-primary invite-all" >
                                            Invite All Invester
                                        </button>
                                        <button class="btn btn-primary invite-reserve" data-bs-toggle="modal"
                                            data-bs-target="#inviteSponser">
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="container-fluid p-0">

                                <div class="scroller">
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <div class="card data-card">

                                                <div class="card-body">

                                              
                                                    <div class="table-responsive">
                                                        <table class="table data-table table-striped align-middle table-borderless">
                                                            <thead class="bg-white">
                                                                <tr>
                                                                    <th scope="col">SR # </th>
                                                                    <th scope="col" className="text-center">Investor name </th>
                                                                    <th scope="col" className="text-center">Phone Number</th>
                                                                    <th scope="col" className="text-center">Email</th>
                                                                    <th scope="col" className="text-center">Address</th>
                                                                    <th scope="col" className="text-center">Country</th>
                                                                    
                                                                    <th scope="col" className="text-center">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {myInvestor}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="modal fade" id="inviteSponser" tabindex="-1" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="text-center">
                                            <img src="../assets/images/congrats.svg" alt="" />
                                        </div>
                                        <h2 class="text-center text-primary mt-3">Send Invite Successfully</h2>
                                        <p class="text-center">Lorem ipsum is a placeholder text commonly used to demonstrate the
                                            visual
                                            form of a document or a
                                            typeface without relying on meaningful content. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default WaterFallDetails
import { useEffect, useState } from "react"
import { DatePicker, message } from 'antd'
import MainClient from '../../utilities/MainClient'
import dateFormat, { masks } from "dateformat";
import moment from "moment";
import { useSelector } from 'react-redux'
import { BsFillXCircleFill } from "react-icons/bs";
import numberWithCommas from "../../utilities/extrafunction/commaseparate";
import numberWithOutCommas from "../../utilities/extrafunction/numberWithoutComma";

const Protfolio = ({ id }) => {
  const [state, setState] = useState({
    property_name: "",
    property_address: '',
    property_city: '',
    property_state: '',
    units: '',
    sf: '',
    acquisition_date: '',
    price: '',
    average_returns: '',
    irr: '',
  })
  const [images, setImages] = useState()
  const [update, setUpdate] = useState(false)
  const states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'IdahoIllinois', 'Indiana', 'IowaKansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsy', 'lvania', 'Rhode', 'Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']
  useEffect(() => {
    const getData = async () => {
      const fetchData = new MainClient(`/deal/get-property-portfolio/${id}`)
      const response = await fetchData.getAPI()
      console.log('fetchData', response.data)
      setState(response.data.data[0])
    }
    getData()
  }, [id, update])
  const changeHandler = (e) => {
    const { name, value } = e.target
    if (name === "acquisition_date") {
      console.log('value', value)
      const date = moment(value).format("MM-DD-YYYY")
      console.log('value of moment', date)
      setState({ ...state, [name]: date })
    }
    else if (name === "price" || name === "average_returns" || name === "units" || name === "sf" ) {
      setState({ ...state, [name]: numberWithOutCommas(value) })
    }
    else {
      setState({ ...state, [name]: value })
    }
  }
  const imageHandler = (e) => {
    setImages(e.target.files)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    const postData = () => {
      const postData = { ...state, user: id }
      const fetchCLient = new MainClient('/deal/property-portfolio/', postData)
      const response = fetchCLient.postAPI()
      response.then(res => {
        message.success("Portfolio Save Successfull")
        if (images) {
          for (let i = 0; i < images.length; i++) {
            let id = res.data.id
            let image = images[i]
            imageUpload(id, image)
          }
        }
      }).catch(err => {
        message.error("Portfolio don't save")
      })
    }
    postData()
  }
  console.log('state', state)
  const updateHandler = (e) => {
    e.preventDefault()
    const postData = () => {
      const fetchCLient = new MainClient(`/deal/property-portfolio/${state?.id}/`, state)
      const response = fetchCLient.putAPI()
      response.then(res => {
        message.success("Portfolio Upload Successfull")
        if (images) {
          for (let i = 0; i < images.length; i++) {
            let id = res.data.id
            let image = images[i]
            imageUpload(id, image)
          }
        }
      }).catch(err => {
        message.error("Portfolio don't update")
      })
    }
    postData()
    console.log('state', state)
  }
  const dateChange = (date, dateString) => {
    console.log('datePicke', date, dateString)
    setState({ ...state, acquisition_date: dateString })
  }
  const imageUpload = (id, image) => {
    let form_data = new FormData()
    form_data.append('images', image, image.name)
    form_data.append('image_data', id)
    const fetchClient = new MainClient('deal/protfolio-image/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    fetchClient.postAPI()

  }
  const imageDelete = (id) => {
    const fetchClient = new MainClient(`/deal/protfolio-image/${id}/`)
    const response = fetchClient.deleteAPI()
    response.then((res) => {
      setUpdate(!update)
      message.success("Image successfully deleted")
    }).error(() => {
      message.success("Image can't deleted")
    })
  }
  return (
    <div class="row mb-3">
      <div class="col-12">
        <div class="card data-card">
          <div class="card-body">
            <h3 class="data-title fw-bold">Company Portfolio</h3>
            <div class="divider"></div>
            <form>
              <div class="row g-3 mb-3">
                <div class="col-sm-6 col-md-4">
                  <label for="property_name" class="form-label">Property Name</label>
                  <input type="text" class="form-control" id="property_name"
                    placeholder="Enter Property Name" name="property_name" value={state?.property_name} onChange={changeHandler} />
                </div>
                <div class="col-sm-6 col-md-4">
                  <label for="address" class="form-label">Address</label>
                  <input type="text" class="form-control" id="address" placeholder="Enter Property Address"
                    name="property_address" value={state?.property_address} onChange={changeHandler} />
                </div>
                <div class="col-sm-6 col-md-4">
                  <label for="city" class="form-label">City</label>
                  <input type="city" class="form-control" id="city"
                    placeholder="Enter City"
                    name="property_city" value={state?.property_city} onChange={changeHandler} />
                </div>
                <div class="col-sm-6 col-md-4">
                  <label for="property_state" class="form-label">State</label>
                  <select class="form-select" aria-label="Default select example" name="property_state" value={state?.property_state} onChange={changeHandler}>
                    <option selected>Please select state</option>
                    {states.map(state =>
                      <option value={state}>{state}</option>
                    )}
                  </select>
                  {/* <input type="text" class="form-control" id="property_state"
                    placeholder="Enter State"
                    name="property_state" value={state?.property_state} onChange={changeHandler} /> */}
                </div>
                <div class="col-sm-6 col-md-4">
                  <label for="units" class="form-label">Units</label>
                  <input type="text" class="form-control" id="units"
                    placeholder="Enter Units"
                    name="units" value={state?.units ? numberWithCommas(state?.units) : ''} onChange={changeHandler} />
                </div>
                <div class="col-sm-6 col-md-4">
                  <label for="sf" class="form-label">Square Feet</label>
                  <input type="text" class="form-control" id="sf"
                    placeholder="Enter SF"
                    name="sf" value={state?.sf ? numberWithCommas(state?.sf) : ''} onChange={changeHandler} />
                </div>
                <div class="col-sm-6 col-md-4">
                  <label for="acquisition_date" class="form-label">Acquisition Date</label>
                  {/* <DatePicker onChange={dateChange} defaultValue={state.acquisition_date}  className='form-control' size="large" /> */}
                  {state?.acquisition_date ?
                    <div className="d-flex align-items-center justify-content-between form-control">
                      <div type="date" >
                        {state?.acquisition_date}
                      </div>
                      <div style={{ width: "7%", border: 0 }}>
                        <DatePicker onChange={dateChange} size="small" className="pe-2 ps-1" />
                      </div>
                    </div> :
                    <DatePicker onChange={dateChange} className='form-control' size="large" />}
                  {/* <input type="date" class="form-control" id="acquisition_date"
                    placeholder="YYYY-MM-DD"
                    name="acquisition_date" value={ state.acquisition_date } onChange={changeHandler} /> */}
                </div>
                <div class="col-sm-6 col-md-4">
                  <label for="price" class="form-label">Price</label>
                  <input type="text" class="form-control" id="price"
                    placeholder="Enter Amount"
                    name="price" value={state?.price ? numberWithCommas(state?.price) : ''} onChange={changeHandler} />
                </div>
                <div class="col-sm-6 col-md-4 col-lg-2">
                  <label for="average_returnsy" class="form-label">Average Returns</label>
                  <input type="text" class="form-control" id="average_returns"
                    placeholder="Enter Average Return"
                    name="average_returns" value={state?.average_returns ? numberWithCommas(state?.average_returns) : ''} onChange={changeHandler} />
                </div>
                <div class="col-sm-6 col-md-4 col-lg-2">
                  <label for="irr" class="form-label">IRR</label>
                  <input type="text" class="form-control" id="irr"
                    placeholder="Enter IRR"
                    name="irr" value={state?.irr} onChange={changeHandler} />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 p-0">
                  <div class="row h-100 d-flex align-items-center" id='embed-imgs' >
                    <div class="col-sm-2">
                      <label for="images" class="form-label">Images</label>
                      <label for="travel-documents" class="form-label id-label">
                        <div
                          class="d-flex upload-style align-items-center justify-content-center h-100">
                          <div>
                            <div class="text-center">
                              {/* <img src={uploadSvg} alt="" /> */}
                              <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.9941 10.9854C11.9941 11.3996 12.3299 11.7354 12.7441 11.7354C13.1584 11.7354 13.4941 11.3996 13.4941 10.9854H11.9941ZM13.2745 0.469481C12.9816 0.176588 12.5067 0.176588 12.2138 0.469481L7.44084 5.24245C7.14795 5.53535 7.14795 6.01022 7.44084 6.30311C7.73373 6.59601 8.20861 6.59601 8.5015 6.30311L12.7441 2.06047L16.9868 6.30311C17.2797 6.59601 17.7545 6.59601 18.0474 6.30311C18.3403 6.01022 18.3403 5.53535 18.0474 5.24245L13.2745 0.469481ZM13.4941 10.9854V0.999811H11.9941V10.9854H13.4941Z" fill="#52575C" fill-opacity="0.9" />
                                <path d="M1 5.99268V13.3623C1 15.0191 2.34315 16.3623 4 16.3623H21.0002C22.657 16.3623 24.0002 15.0191 24.0002 13.3623V5.99268" stroke="#52575C" stroke-opacity="0.9" stroke-width="1.5" stroke-linecap="round" />
                              </svg>
                            </div>
                            <p class="text-muted fs-14 fw-5 mt-2 mb-0"
                              id="upload-id-card">Upload
                            </p>

                          </div>
                        </div>
                      </label>

                      <input class="form-control" type="file" id="travel-documents" multiple
                        accept="image/*" onChange={imageHandler} />
                    </div>
                    {images &&
                      Array.from(images).map((file, index) =>
                        // <div class="col-sm-2" style={{ marginTop: "2rem", marginLeft: ".5rem", marginRight: ".5rem", poniter: "none" }} >
                        // <label class="form-label id-label">
                        <div className="col-sm-2 mt-4" onClick={() => setMainImage(img?.images)} style={{ width: "150px", height: "95px", cursor: "pointer", position: "relative" }}>
                          <div>
                            <img src={URL.createObjectURL(file)} style={{ borderRadius: "5px", width: "140px", height: "95px", border: "2px solid blue" }} />
                          </div>
                          <span style={{ position: "absolute", top: "0", left: "128px" }}>
                            <BsFillXCircleFill size={20} color={"tomato"} onClick={() => setImages(Array.from(images).filter((_, i) => i !== index))} />
                          </span>
                        </div>
                        // </label>

                      )
                    }

                    {state?.image_data?.length > 0 ?
                      state?.image_data?.map(img =>
                        <div className="col-sm-2 mt-4" onClick={() => setMainImage(img?.images)} style={{ width: "150px", height: "95px", cursor: "pointer", position: "relative" }}>
                          <img className="img-fluid" style={{ borderRadius: "5px", width: "100%", height: "100%", border: "2px solid green" }} src={`https://stackshares.io${img?.images}`} alt="" />
                          <span style={{ position: "absolute", top: "0", left: "115px" }} onClick={() => imageDelete(img.id)}>
                            <BsFillXCircleFill size={20} color={"tomato"} />
                          </span>
                        </div>
                      )
                      :
                      <div></div>
                    }

                  </div>
                </div>
                {/* <div className="col-sm-12 col-md-4 col-lg-4 p-0 ">
                                        <div className="google-map-box border rounded">
                                            <GoogleMap
                                                id="map"
                                                mapContainerStyle={mapContainerStyle}
                                                zoom={8}
                                                center={center}
                                                options={options}
                                                // onClick={onMapClick}
                                                onLoad={onMapLoad}
                                            >
                                                <Marker
                                                    key={`${center.lat}-${center.lng}`}
                                                    position={{ lat: center.lat, lng: center.lng }}
                                                    // onClick={() => {
                                                    //     setSelected(marker);
                                                    // }}
                                                    icon={{
                                                        url: `https://img.icons8.com/office/40/000000/marker.png`,
                                                        origin: new window.google.maps.Point(0, 0),
                                                        anchor: new window.google.maps.Point(15, 15),
                                                        scaledSize: new window.google.maps.Size(30, 30),
                                                    }}
                                                />
                                            </GoogleMap>
                                        </div>
                                    </div> */}
              </div>
              <div class="d-flex justify-content-end mb-2" id="submit-btn">
                {/* <button type="submit" class="btn btn-secondary clr-btn">Reset</button> */}
                {state?.id ?
                  <button type="submit" class="btn ms-3 button_color" onClick={updateHandler}>Update</button> :
                  <button type="submit" class="btn ms-3 button_color" onClick={submitHandler}>Save</button>}
              </div>
            </form>
          </div>
        </div>
      </div >
    </div >
  )
}

export default Protfolio
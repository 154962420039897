import { Navigate } from "react-router-dom"

const DashboardPermission = ({ user, children }) =>{
    if(user?.user_type==="sponsor"){
        return <Navigate to='/dashboard/add-new-deal' />
    }
    else{
        return children
    }
}

export default DashboardPermission
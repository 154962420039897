import { useEffect, useState } from "react"
import Common from "./Common"
import AuthClient from "../../utilities/AuthClient"
import { Link, useNavigate } from "react-router-dom"
import Logo from '../../assets/logos/gulfstream2.png'
import InputPasswordToggle from "./elements/InputPasswordToggle"
const Login = () => {
    const [message, setMessage] = useState('')
    const [data, setData] = useState({
        email: "",
        password: "",
    })

    const changeHandler = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
        setMessage({ text: '', color: '' })
    }
    const navigate = useNavigate()
    console.log('message', message)
    const submitHandler = async (e) => {
        e.preventDefault()
        console.log(data)
        const fetchClient = new AuthClient('/user/login/', data)
        await fetchClient.postAPI()
            .then(res => {
                localStorage.setItem("token", res.data.access)
                setData({
                    email: "",
                    password: ""
                })
                setMessage({ text: "Login Successfull.. please Wait", color: 'success' })
                window.location.reload()
            })
            .catch(err => {
                setMessage({ text: 'Invalid Email or Password', color: 'danger' })
            })

    }

    return (
        <div className="row vh-height-100">
            <Common />
            <div className="col-sm-7 ps-0 pe-0 bg-white form-align-center">
                {/* <div className="d-flex align-center justify-content-end pt-2">
                    <div className='col-2'>
                        <Link replace to="/register">Register</Link>
                    </div>
                </div> */}
                <div className="row align-items-center justify-content-center">
                    <div className="col-sm-10">
                        <div className="signup-col-2 d-flex flex-column justify-content-between ">

                            <h2 class="d-flex form-header justify-content-center "> Welcome to Gulfstream Investments</h2>

                            {/* <h2 className="text-center fs-28 fw-5 text-black">Welcome</h2> */}
                            {message.text &&
                                <Link to="/login">
                                    <div class={`alert alert-${message.color}`} role="alert">
                                        <Link to="/login" class="alert-link">{message.text}</Link>
                                    </div>
                                </Link>}
                            <div className="sign-up-form">
                                <form className="needs-validation" onSubmit={submitHandler}>
                                    <div className="mb-3">
                                        <label for="email" className="form-label signup-label">Email address</label>
                                        <input type="email" className="form-control sign-up-control" id="email"
                                            aria-describedby="email"
                                            name="email"
                                            value={data.email}
                                            onChange={changeHandler}
                                            placeholder="Please enter your email"
                                            required />
                                        <div className="invalid-feedback">
                                            Please enter your email.
                                        </div>
                                    </div>
                                     <div className="mb-3">
                                        <label for="password" className="form-label signup-label">Password</label>
                                   {/*     <input type="password" className="form-control sign-up-control" id="password"
                                            name="password"
                                            value={data.password}
                                            onChange={changeHandler}
                                            required />
                                   */}
                                    <InputPasswordToggle
                                        value={data.password}
                                        id='password'
                                        name='password'
                                        className=''
                                        onChange={changeHandler}
                                        required />

                                         </div> 
                                    <div className="mb-3 mt-1 d-flex align-items-center">
                                        <input type="checkbox" className="form-check-input me-1" id="remember" />
                                        <label className="form-check-label" for="remember">Remember me </label>
                                    </div>
                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn button_color mb-3 btn-lg">Log in</button>
                                    </div>
                                    <div className="grid float-end">
                                        <Link replace to="/register" style={{ color: "#2B4479" }}>Register</Link> |
                                        <Link to="/forget-password" className="text-muted fs-14 fw-4" href="#"> Forget Password?</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Login
import { Fragment, useState } from 'react'

// ** MUI Imports
import { Modal, Box } from "@mui/material";
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,

  Row,
  Col,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateFileDetails } from '../../store/actions/deals';

const RenameModal = ({ file, setFile, isOpen, handleCloseModal}) => {
    const dispatch = useDispatch()
    const fileState = useSelector(state => state.deal?.docFileDetails)
    const handleChange = (e) => {
        const {name ,value} = e.target
        setFile({...file, [name] : value})
        dispatch(updateFileDetails({...file, [name] : value}))
    }
  console.log('fileState', fileState)
    return (
    <>
    {
          <Modal
          open={isOpen}
          onClose={() => handleCloseModal()}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 900,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Form >
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="title">Title</Label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      defaultValue={file?.name}
                      placeholder="Enter Title"
                      onChange={(e) => handleChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="deed">Note</Label>
                    <Input
                      type="text"
                      className="form-control smaller-input"
                      name="note"
                      id="note"
                      defaultValue={file?.note}
                      placeholder="Enter Note"
                      onChange={(e) => handleChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  {/* <FormGroup>
                    <Label for="contract">Contract</Label>
                    <Input
                      type="text"
                      className="form-control smaller-input"
                      name="contract"
                      id="contract"
                      defaultValue={file?.contract}
                      placeholder="Enter Contract"
                      onChange={(e) => handleChange(e)}
                    />
                  </FormGroup> */}
                  <Button
                    className="m-1"
                    onClick={() => handleCloseModal()}
                    variant="outlined"
                  >
                    Close
                  </Button>
                </Col>
              </Row>
            </Form>
          </Box>
        </Modal>
    }
    </>
    );
  };

export default RenameModal;  
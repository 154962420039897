import React, { useEffect, useRef, useState, useCallback } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import NumberFormat from "react-number-format";
import axios from "axios";
import Search from "./Search";
import GoogleMapKey from "../Keys/apiKeys";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { message } from "antd";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const center = {
  lat: 37.7749,
  lng: -122.4194,
};

const initialProperty = {
  property_title: "",
  address: "",
  city: "",
  state: "",
  units: "",
  square_feet: "",
};

const AddProperty = ({ id, addPropertyCount }) => {
  const mapRef = useRef();
  const user_id = localStorage.getItem("user_id");

  const [property, setProperty] = useState(initialProperty);
  const [addNewPropert, setAddNewPropert] = useState([]);
  const [showRow, setShowRow] = useState(true);

  const toggleRow = () => {
    setShowRow(!showRow);
  };

  const [center, setCenter] = useState({
    lat: 43.6532,
    lng: -79.3832,
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GoogleMapKey,
    libraries: ["places"],
  });

  const changeHandler = (event) => {
    setProperty({ ...property, [event.target.name]: event.target.value });
  };

  const panTo = useCallback(({ lat, lng }) => {
    console.log("mapLocations", lat, lan);
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(5);
  }, []);

  const getLocation = (location) => {
    setProperty({
      ...property,
      address: location.address,
      city: location.city,
      state: location.state,
    });
  };

  const mapContainerStyle = {
    height: "100%",
    width: "100%",
  };
  const options = {
    // styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };

  console.log("user id", user_id);

  const getLocationNew = async (address) => {
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setCenter({
        lat: lat,
        lng: lng,
      });
    } catch (error) {
      console.log("😱 Error: ", error);
      console.log("Error: ", error);
    }
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const sendData = (event) => {
    event.preventDefault();

    const { property_title, units, square_feet } = property;

    // Check that required fields are not empty
    if (!property_title || !units || !square_feet) {
      message.info("Please fill out all required fields");
      return;
    }

    const existingData =
      JSON.parse(sessionStorage.getItem("NewPropertyData")) || [];
    // Create a new object with the user, deal and property values
    const newData = {
      user: user_id,
      deal: id ? id : null,
      ...property,
    };

    // Append the new data to the existing data
    const updatedData = [...existingData, newData];
    // Store the updated data back to the sessionStorage
    sessionStorage.setItem("NewPropertyData", JSON.stringify(updatedData));
    message.success("Your Data Save Please Update");
    toggleRow();
    // Log the updated data to the console
    console.log(updatedData);
  };

  const storedData = sessionStorage.getItem("NewPropertyData");

  console.log("LocalStorage Data", storedData);

  const addProperty = (event) => {
    event.preventDefault();
    setAddNewPropert([
      ...addNewPropert,
      {
        property_title: "",
        address: "",
        city: "",
        state: "",
        units: "",
        square_feet: "",
      },
    ]);
  };

  useEffect(
    () => {
      console.log("jsID is", id);

      getLocationNew(property.address);
    },
    [property.address],
    id
  );

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  console.log("Property is", property.address);
  console.log("Property is", addNewPropert);

  return (
    <>
      {showRow && (
        <div className="container">
          <div className="row g-3 mb-3">
            <div className="col-lg-12">
              <h2 className="mb-0 fs-16 fw-6 text-black">
                Add Property
              </h2>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <label htmlFor="propertyTitle" className="form-label">
                Property Title
              </label>
              <input
                type="text"
                className="form-control"
                id="propertyTitle"
                placeholder="Enter Property Title"
                name="property_title"
                value={property.property_title}
                onChange={changeHandler}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <Search
                panTo={panTo}
                property={property}
                setProperty={setProperty}
                setCenter={setCenter}
                getLocation={getLocationNew}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <label htmlFor="city" className="form-label">
                City
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                placeholder="Enter City"
                name="city"
                value={property.city}
                onChange={changeHandler}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <label htmlFor="state" className="form-label">
                State
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                placeholder="Enter State"
                name="state"
                value={property.state}
                onChange={changeHandler}
              />
            </div>
            <div className="col-lg-1 col-md-2 col-sm-4">
              <label htmlFor="units" className="form-label">
                Units
              </label>
              <input
                type="text"
                className="form-control smaller-input"
                id="units"
                placeholder="Enter Units"
                name="units"
                value={property.units}
                onChange={changeHandler}
              />
            </div> 
            <div className="col-lg-1 col-md-2 col-sm-4">
              <label htmlFor="squareFeet" className="form-label">
                Square Feet
              </label>
              <NumberFormat
                type="text"
                className="form-control smaller-input"
                id="squareFeet"
                placeholder="Enter sq. ft"
                name="square_feet"
                thousandSeparator={true}
                value={property.square_feet}
                onChange={changeHandler}
              />
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4 p-0 ">
              <div
                className="google-map-box border rounded"
                style={{ height: "100px" }}
              >
                <GoogleMap
                  id="map"
                  mapContainerStyle={mapContainerStyle}
                  zoom={8}
                  center={center}
                  options={options}
                  onLoad={onMapLoad}
                >
                  <Marker
                    key={`${center.lat}-${center.lng}`}
                    position={{ lat: center.lat, lng: center.lng }}
                    icon={{
                      url: `https://img.icons8.com/office/40/000000/marker.png`,
                      origin: new window.google.maps.Point(0, 0),
                      anchor: new window.google.maps.Point(15, 15),
                      scaledSize: new window.google.maps.Size(30, 30),
                    }}
                  />
                </GoogleMap>
              </div>
            </div>
          </div>
          <button onClick={sendData} className="btn btn-primary">
            Add
          </button>
        </div>
      )}
    </>
  );
};

export default AddProperty;

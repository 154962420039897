import React from "react";
import { message } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import numberWithCommas from "../../utilities/extrafunction/commaseparate";
import numberWithOutCommas from "../../utilities/extrafunction/numberWithoutComma";
import MainClient from "../../utilities/MainClient";
import { IRR } from "../newSponsor/utils";
import InvestorResult from "./InvestorResult";
import SubscribeDialog from "./SubscribeDialog";
import { Container, Toast } from "react-bootstrap";
import "../../assets/css/subscriptioncol.css";
import NewDept from "./NewDept";
import Result from "../newSponsor/Result";
import CapitalResult from "../newSponsor/CapitalResult";
import FinalResult from "../newSponsor/FinalResult";
import { BsArrowDown, BsFillXCircleFill } from "react-icons/bs";
import WaterFall from "./Waterfall";
import NewDeptGraph from "./NewDeptGraph";
import { CgDatabase } from "react-icons/cg"
import { BsArrowLeftSquare, BsGraphUp } from "react-icons/bs"
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { getInputStyles, getTextInputStyles } from "../newSponsor/FormStyles";
import { FormHeader } from "../newSponsor/FormElements";

function TestSubScribtion({
  shares,
  data,
  result,
  terms,
  totals,
  targeted_irr,
  totalCashflow,
  capAmount,
  property,
  propertyList,
  seniorDept,
  Opdata,
  Cpdata,
}) {
  const { id } = useParams();
  const theme = useTheme()
  const textStyles = getTextInputStyles(theme)
  const inputStyles = getInputStyles(theme)
  const {kpi, percent, header, out} = theme.palette.subscribe
  const subscribeStyles = {
    kpi: {
      color : kpi
    },
    percent: {
      background : percent
    },
    header: {
      color : header
    },
    out : {
      color : out
    }
    // contrastText: whiteColor,
    // invert : mode === "light" ? '#ede8cc' : '#757263'
  
  }
  const classHeaderStyles = {
    color: '#fff',
    background: theme.palette.primary.main,
  }
  // redux state
  const State = useSelector((state) => state);
  const CurrencySign = State.deal?.currency?.sign
  console.log('{CurrencySign}', CurrencySign)
  // For Subscription Dialog For Public Url
  const user_id = localStorage.getItem("user_id");
  const [showDialog, setShowDialog] = useState(false);

  console.log("result", result);
  // const [result, setResult] = useState([])

  //  Getting Total Units
  const totalAddProUnit = propertyList.reduce(
    (total, property) =>
      total + (property.units ? parseInt(property.units) : 0),
    0
  );
  const totalUnits = parseInt(property.units) + parseInt(totalAddProUnit);

  // Calculate the total square feet
  let addPropertySquareFeet = 0;
  for (const property of propertyList) {
    const squareFeet = property.square_feet
      ? property.square_feet.replace(/,/g, "")
      : 0;
    addPropertySquareFeet += parseInt(squareFeet);
  }

  const totalSquareFeet =
    parseInt(property.square_feet) + parseInt(addPropertySquareFeet);

  const [amount, setAmount] = useState({
    classA: 0,
    classB: 0,
    classC: 0,
  });
  const [share, setShare] = useState({
    classA: 0,
    classB: 0,
    classC: 0,
  });

  const [showButton, setShowButton] = useState(true);
  // console.log('data1', data[0]?.amount)
  const [disClassA, setDisClassA] = useState([]);
  const [disClassB, setDisClassB] = useState([]);
  const [disClassC, setDisClassC] = useState([]);
  const classA = data[0];
  const classB = data[1];
  const classC = data[2];

  // useEffect(()=>{
  //     setShowButton(true)
  // },[result])

  // const distributionClassA = []
  // const distributionClassB = []
  // const distributionClassC = []
  const getDisClassA = disClassA?.map((dt) => dt.value);
  const getDisClassB = disClassB?.map((dt) => dt.value);
  const getDisClassC = disClassC?.map((dt) => dt.value);
  const totalDisClassA = getDisClassA?.reduce((a, b) => a + b, 0);
  const totalDisClassB = getDisClassB?.reduce((a, b) => a + b, 0);
  const totalDisClassC = getDisClassC?.reduce((a, b) => a + b, 0);
  const projected_return = totalDisClassA + totalDisClassB + totalDisClassC;

  const investment_class_A = result.investment_class_A
    ? result.investment_class_A[0]?.value
    : 0;
  const investment_class_B = result.investment_class_B
    ? result.investment_class_B[0]?.value
    : 0;
  const investment_class_C = result.investment_class_C
    ? result.investment_class_C[0]?.value
    : 0;
  // const getClassAInvesment = ((amount.classA) * (share.classA))/100
  // console.log('getInvst', getClassAInvesment)
  let totalInvetment =
    (isNaN(parseInt(amount.classA)) ? 0 : parseInt(amount.classA)) +
    (isNaN(parseInt(amount.classB)) ? 0 : parseInt(amount.classB)) +
    (isNaN(parseInt(amount.classC)) ? 0 : parseInt(amount.classC));
  const cash_yield_class_A = totalDisClassA / totalInvetment;
  const cash_yield_class_B = totalDisClassB / totalInvetment;
  const cash_yield_class_C = totalDisClassC / totalInvetment;

  const totalCashFlowClassA = totalDisClassA + investment_class_A;
  const totalCashFlowClassB = totalDisClassB + investment_class_B;
  const totalCashFlowClassC = totalDisClassC + investment_class_C;

  const irrA = [-1 * amount.classA, ...getDisClassA];
  const irrB = [-1 * amount.classB, ...getDisClassB];
  const irrC = [-1 * amount.classC, ...getDisClassC];
  const getIrrClassA = IRR(irrA);
  const getIrrClassB = IRR(irrB);
  const getIrrClassC = IRR(irrC);
  const getTotalIrr = IRR(irrC);
  const [subscribeTotal, setSubscribeTotal] = useState([]);
  const [totalYeild, setTotalYeild] = useState([]);

  //   For Activatte Button
  const [activeButton, setActiveButton] = useState("Investment Metrics");

  const handleClick = (btnName) => {
    setActiveButton(btnName);
  };

  // console.log('distributionClassA', distributionClassA)
  // console.log('classAShare', share.classA)
  // console.log('classAIrr', getIrrClassA)
  // console.log('TIrr', totalIRR)
  // console.log('classAInVestment', amount.classA)
  // console.log('classAIrr', getIrrClassA, getClassAInvesment, share.classA, getDisClassA )
  const value = Object.values(amount);
  const vl = value.map((vl) => parseInt(vl));
  const dl = vl.filter((vl) => vl > 0);
  const total = dl.reduce((a, b) => a + b, 0);
  const stotal = () => {
    for (let i = 0; i < disClassA.length; i++) {
      console.log("classA", disClassA[i]?.value);
      console.log("classB", disClassB[i]?.value);
      console.log("classC", disClassC[i]?.value);
      // console.log("stotal", disClassA[i]?.value + disClassB[i]?.value + disClassC[i]?.value)
      if (disClassA[i]?.value && disClassB[i]?.value && disClassC[i]?.value) {
        subscribeTotal[i] =
          disClassA[i]?.value + disClassB[i]?.value + disClassC[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else if (disClassA[i]?.value && disClassB[i]?.value) {
        subscribeTotal[i] = disClassA[i]?.value + disClassB[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else if (disClassA[i]?.value && disClassC[i]?.value) {
        subscribeTotal[i] = disClassA[i]?.value + disClassC[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else if (disClassB[i]?.value && disClassC[i]?.value) {
        subscribeTotal[i] = disClassB[i]?.value + disClassC[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else if (disClassA[i]?.value) {
        subscribeTotal[i] = disClassA[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else if (disClassB[i]?.value) {
        subscribeTotal[i] = disClassB[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else if (disClassC[i]?.value) {
        subscribeTotal[i] = disClassC[i]?.value;
        totalYeild[i] = (subscribeTotal[i] / total) * 100;
      } else {
        subscribeTotal[i] = 0;
        totalYeild[i] = 0;
      }
    }
  };
  stotal();
  const irrTotal = [-1 * totalInvetment, ...subscribeTotal];
  const totalIRR = IRR(irrTotal);
  const dis_class_A_year = disClassA?.map((dt) => (
    <th className="text-center">Year {dt.year}</th>
  ));

  const dis_class_B_year = disClassB?.map((dt) => (
    <th className="text-center">Year {dt.year}</th>
  ));

  const dis_class_C_year = disClassC?.map((dt) => (
    <th className="text-center">Year {dt.year}</th>
  ));

  const dis_class_A_value = disClassA.map((dt) => (
    <td className="text-center">{CurrencySign}{numberWithCommas(dt.value.toFixed())}</td>
  ));

  const dis_class_B_value = disClassB.map((dt) => (
    <td className="text-center">{CurrencySign}{numberWithCommas(dt.value.toFixed())}</td>
  ));

  const dis_class_C_value = disClassC.map((dt) => (
    <td className="text-center">{CurrencySign}{numberWithCommas(dt.value.toFixed())}</td>
  ));
  // console.log('subTotal3', subscribeTotal)

  const dis_class_total = subscribeTotal.map((dt) => (
    <td className="text-center fw-5">{CurrencySign}{numberWithCommas(dt.toFixed())}</td>
  ));

  const total_cash_yield = totalYeild.map((dt) => (
    <td className="text-center fw-5">{numberWithCommas(dt.toFixed(2))} %</td>
  ));

  const Data = {
    class_A_year: dis_class_A_year,
    class_B_year: dis_class_B_year,
    class_C_year: dis_class_C_year,
    class_A: dis_class_A_value,
    class_B: dis_class_B_value,
    class_C: dis_class_C_value,
    investment_class_A: amount.classA,
    investment_class_B: amount.classB,
    investment_class_C: amount.classC,
    totalCashFlowClassA: totalDisClassA,
    totalCashFlowClassB: totalDisClassB,
    totalCashFlowClassC: totalDisClassC,
    irr_class_A: getIrrClassA,
    irr_class_B: getIrrClassB,
    irr_class_C: getIrrClassC,
    total: dis_class_total,
    totalInvetment: totalInvetment,
    totalIRR: totalIRR,
    projected_return: projected_return,
    total_cash_yield: total_cash_yield,
  };

  const submitHandler = () => {
    if (user_id) {
      const data = {
        deal_id: id,
        amount: amount,
        share: share,
        projected_return: projected_return,
        projected_irr: totalIRR,
      };
      const fetchClient = new MainClient(
        `/deal/investor-subscription/${id}/`,
        data
      );
      const response = fetchClient.postAPI();
      response
        .then((res) => {
          setShowButton(false);
          message.success("Subscription Successfully !!");
        })
        .catch((err) => message.error("Deal already subscribe"));
    } else {
      setShowDialog(true);
    }
  };

  // console.log('total', totalDisClassA+totalDisClassB+totalDisClassC)
  // const newDistClassA =

  // console.log('result', results)

  // const dtClassA = () => {
  //     let l = []
  //     // for(key in distributionClassA)
  //     for(let i=0; i<distributionClassA.length; i++){
  //         l.push(distributionClassA[i].value * share.classA)
  //     }
  //     console.log('l', l)
  // }
  // console.log('dtClassA', dtClassA)

  // console.log('shares', share[0])
  // dtClassA()
  // const addClass = {...disClassA, ...disClassB, ...disClassC}
  // console.log('addClass', addClass)
  useEffect(() => {
    const getData = () => {
      const fetchClient = new MainClient(`/deal/investor-subscription/${id}/`);
      const response = fetchClient.getAPI();
      response.then((res) => {
        console.log("getData", res.data.data[0].sub_amount);
        const data = res.data.data[0].sub_amount;
        console.log(
          "getDataclassAmount",
          data.filter((dt) => dt.class_name == "class A")[0]?.amount
        );
        const amountClassA = data?.filter((dt) => dt.class_name == "class A")[0]
          ?.amount;
        const amountClassB = data?.filter((dt) => dt.class_name == "class B")[0]
          ?.amount;
        const amountClassC = data?.filter((dt) => dt.class_name == "class C")[0]
          ?.amount;
        const classA_amount = amountClassA ? amountClassA : 0;
        const classB_amount = amountClassB ? amountClassB : 0;
        const classC_amount = amountClassC ? amountClassC : 0;
        const subscription_shareClassA = data?.filter(
          (dt) => dt.class_name == "class A"
        )[0]?.subscription_share;
        const subscription_shareClassB = data?.filter(
          (dt) => dt.class_name == "class B"
        )[0]?.subscription_share;
        const subscription_shareClassC = data?.filter(
          (dt) => dt.class_name == "class C"
        )[0]?.subscription_share;
        const classA_share = subscription_shareClassA
          ? subscription_shareClassA
          : 0;
        const classB_share = subscription_shareClassB
          ? subscription_shareClassB
          : 0;
        const classC_share = subscription_shareClassC
          ? subscription_shareClassC
          : 0;
        console.log("amount", classA_share);
        setAmount({
          classA: classA_amount,
          classB: classB_amount,
          classC: classC_amount,
        });
        setShare({
          classA: classA_share,
          classB: classB_share,
          classC: classC_share,
        });
        setShowButton(false);
      });
    };
    getData();
  }, []);
  console.log("share", share);
  console.log("distribution", disClassA, disClassB);
  useEffect(() => {
    const distributionClassA = result
      ? JSON.parse(JSON.stringify(result.final_dist_summary[0].data))
      : [];
    const distributionClassB = result
      ? JSON.parse(JSON.stringify(result.final_dist_summary[1].data))
      : [];
    const distributionClassC = result
      ? JSON.parse(JSON.stringify(result.final_dist_summary[2].data))
      : [];
    const tds = distributionClassA?.map((dt, index) => {
      for (let key in dt) {
        // console.log('key', dt.year, index + 1, dt.value)
        if (key === "value" && dt.year == index + 1) {
          dt[key] = dt.value * (share.classA / 100);
        }
      }
      return dt;
    });
    setDisClassA(tds);
    // stotal()

    const tds1 = distributionClassB?.map((dt, index) => {
      for (let key in dt) {
        // console.log('key', dt.year, index + 1, dt.value)
        if (key === "value" && dt.year == index + 1) {
          dt[key] = dt.value * (share.classB / 100);
        }
      }
      return dt;
    });
    setDisClassB(tds1);
    const tds2 = distributionClassC?.map((dt, index) => {
      for (let key in dt) {
        // console.log('key', dt.year, index + 1, dt.value)
        if (key === "value" && dt.year == index + 1) {
          dt[key] = dt.value * (share.classC / 100);
        }
      }
      return dt;
    });
    setDisClassC(tds2);
  }, [share.classA, share.classB, share.classC, result]);

  const ChangeHandler = (event) => {
    const { name, value } = event.target;
    // console.log('name, vakue', name, value)
    if (name === "classA") {
      if (numberWithOutCommas(value) >= parseInt(data[0]?.amount)) {
        setAmount({ ...amount, [name]: 0 });
        setShare({ ...share, [name]: 0 });
        message.error("Subscription amount is higher than ClassA total amount");
      } else {
        setAmount({ ...amount, [name]: numberWithOutCommas(value) });
        setShare({
          ...share,
          [name]:
            numberWithOutCommas(value) > 0
              ? (numberWithOutCommas(value) / classA.amount) * 100
              : 0,
        });
      }
    } else if (name === "classB") {
      if (numberWithOutCommas(value) >= parseInt(data[0]?.amount)) {
        setAmount({ ...amount, [name]: 0 });
        setShare({ ...share, [name]: 0 });
        message.error("Subscription amount is higher than ClassB total amount");
      } else {
        setAmount({ ...amount, [name]: numberWithOutCommas(value) });
        setShare({
          ...share,
          [name]:
            numberWithOutCommas(value) > 0
              ? (numberWithOutCommas(value) / classB.amount) * 100
              : 0,
        });
      }
      // setShare({ ...share, [name]: numberWithOutCommas(value) > 0 ? numberWithOutCommas(value) / (classB.amount) * 100 : 0 })
    } else if (name === "classC") {
      if (numberWithOutCommas(value) >= parseInt(data[0]?.amount)) {
        setAmount({ ...amount, [name]: 0 });
        setShare({ ...share, [name]: 0 });
        message.error("Subscription amount is higher than ClassC total amount");
      } else {
        setAmount({ ...amount, [name]: numberWithOutCommas(value) });
        setShare({
          ...share,
          [name]:
            numberWithOutCommas(value) > 0
              ? (numberWithOutCommas(value) / classC.amount) * 100
              : 0,
        });
      }
      // setShare({ ...share, [name]: numberWithOutCommas(value) > 0 ? numberWithOutCommas(value) / (classC.amount) * 100 : 0 })
    } else {
      message.error("Subscription amount is higher than Class amount");
    }
  };

  console.log("sharew", share);
  const price_per_unit = shares ? shares.price_per_unit : "";
  const price_per_sf = shares ? shares.price_per_sf : "";
  const stabilized_noi = shares ? shares.stabilized_noi : "";
  const proforma_noi = shares ? shares.proforma_noi : "";
  // const profit_share = share ? share.projected_profit_share : ''
  // const avg_annual_return = share ? share.projected_avg_annual_returns : ''
  // const projected_irr = share ? share.projected_irr  : ''
  // const projected_equity_mutiple = share ? share.projected_equity_mutiple : ''
  const profit_share = shares ? share.projected_profit_share : "";
  const avg_annual_return = shares ? shares.projected_avg_annual_returns : "";
  const projected_irr = shares ? shares.projected_irr : "";
  // const projected_equity_mutiple = result ? result.projected_equity_mutiple : ''
  const preferred_return = shares ? shares.preferred_interest : "";
  const equity = shares ? shares.equity : "";
  const profit_share_1 = shares ? shares.profit_share : "";
  const classA_stack = data[0] ? data[0].capital_stack : "";
  const classB_stack = data[1] ? data[1].capital_stack : "";
  const classC_stack = data[2] ? data[2].capital_stack : "";
  const classA_amount = data[0] ? data[0].amount : "";
  const classB_amount = data[1] ? data[1].amount : "";
  const classC_amount = data[2] ? data[2].amount : "";
  const entry_cap_rate = shares ? shares.entry_cap_rate : "";

  console.log("price_per_unit", classC_stack);
  const total_cashflows_class_A =
    result?.total_cashflow_class_A.length > 0
      ? result.total_cashflow_class_A[0].value
      : "";
  const total_cashflows_class_B =
    result?.total_cashflow_class_B.length > 0
      ? result.total_cashflow_class_B[0].value
      : "";
  const total_cashflows_class_C =
    result?.total_cashflow_class_C.length > 0
      ? result.total_cashflow_class_C[0].value
      : "";
  const totalLength = result?.tier_2_distribution[0].data.length;

  const avg_return_class_A = (
    (total_cashflows_class_A / totalLength / classA_amount) *
    100
  ).toFixed(1);
  const avg_return_class_B = (
    (total_cashflows_class_B / totalLength / classB_amount) *
    100
  ).toFixed(1);
  const avg_return_class_C = (
    (total_cashflows_class_C / totalLength / classC_amount) *
    100
  ).toFixed(1);
  console.log("newShares", shares);
  const LpClassA = shares?.lp[0]
    ? parseFloat(shares?.lp[0]?.data[0]?.value).toFixed(1)
    : 0;
  const LpClassB = shares?.lp[1]
    ? parseFloat(shares?.lp[1]?.data[0]?.value).toFixed(1)
    : 0;
  const LpClassC = shares?.lp[2]
    ? parseFloat(shares?.lp[2]?.data[0]?.value).toFixed(1)
    : 0;
  const sponsorClassA = shares?.sponsor[0]
    ? parseFloat(shares?.sponsor[0]?.data[0]?.value).toFixed(1)
    : 0;
  const sponsorClassB = shares?.sponsor[0]
    ? parseFloat(shares?.sponsor[1]?.data[0]?.value).toFixed(1)
    : 0;
  const sponsorClassC = shares?.sponsor[0]
    ? parseFloat(shares?.sponsor[2]?.data[0]?.value).toFixed(1)
    : 0;
  const servicedClassA = shares?.serviced[0]
    ? parseFloat(shares?.serviced[0]?.data[0]?.value).toFixed(1)
    : 0;
  const servicedClassB = shares?.serviced[1]
    ? parseFloat(shares?.serviced[1]?.data[0]?.value).toFixed(1)
    : 0;
  const servicedClassC = shares?.serviced[2]
    ? parseFloat(shares?.serviced[2]?.data[0]?.value).toFixed(1)
    : 0;
  const accurdClassA = shares?.accrued[0]
    ? parseFloat(shares?.accrued[0]?.data[0]?.value).toFixed(1)
    : 0;
  const accurdClassB = shares?.accrued[1]
    ? parseFloat(shares?.accrued[1]?.data[0]?.value).toFixed(1)
    : 0;
  const accurdClassC = shares?.accrued[2]
    ? parseFloat(shares?.accrued[2]?.data[0]?.value).toFixed(1)
    : 0;

  console.log("log", shares);
  console.log("log1", accurdClassA);
  return (
    <>
      <div className="subscription_main_container" style={textStyles}>
        <div className="subscription_tabel">
          {showDialog && (
            <SubscribeDialog
              showDialog={showDialog}
              setShowDialog={setShowDialog}
            />
          )}{" "}
          {/* This is Only Use on Public url */}
          <div className="subscription_btn">
            <button
              className="sub_btn "
              style={{
                backgroundColor:
                  activeButton === "Investment Metrics" ? theme.palette.primary.main : "#ffffff",
                color:
                  activeButton === "Investment Metrics" ? "white" : "black",
              }}
              onClick={() => handleClick("Investment Metrics")}
            >
              Investment Metrics
            </button>
            <button
              className="sub_btn"
              style={{
                backgroundColor:
                  activeButton === "Investment Terms" ? theme.palette.primary.main : "#ffffff",
                color: activeButton === "Investment Terms" ? "white" : "black",
              }}
              onClick={() => handleClick("Investment Terms")}
            >
              Investment Terms
            </button>
            <button
              className="sub_btn"
              style={{
                backgroundColor:
                  activeButton === "Projected Returns" ? theme.palette.primary.main : "#ffffff",
                color: activeButton === "Projected Returns" ? "white" : "black",
              }}
              onClick={() => handleClick("Projected Returns")}
            >
              Projected Returns
            </button>

            <button
              className="sub_btn"
              style={{
                backgroundColor:
                  activeButton === "WaterFall Summary" ? theme.palette.primary.main : "#ffffff",
                color: activeButton === "WaterFall Summary" ? "white" : "black",
              }}
              onClick={() => handleClick("WaterFall Summary")}
            >
              WaterFall
            </button>
          </div>
          <div className="border mx-4 rounded my-4" >
            <div className="invester_index_table " style={classHeaderStyles}>
              {classA_stack && (
                <div className="invester_index" >
                  {/* <span className="invester_grey">
                {parseFloat(classA_stack).toFixed()}%
              </span> */}
                  <span  className="invester_class ">Class A Shares</span>
                  <span className="invester_value">
                    {CurrencySign}{numberWithCommas(classA_amount)} |{" "}
                    {parseFloat(classA_stack).toFixed()}%
                  </span>
                </div>
              )}

              {classB_stack && (
                <div className="invester_index">
                  {/* <span className="invester_grey">
                {parseFloat(classB_stack).toFixed()}%
              </span> */}
                  <span className="invester_class ">Class B Shares</span>
                  <span className="invester_value">
                    {CurrencySign}{numberWithCommas(classB_amount)} |{" "}
                    {parseFloat(classB_stack).toFixed()}%
                  </span>
                </div>
              )}

              {classC_stack && (
                <div className="invester_index">
                  {/* <span className="invester_grey">
                {parseFloat(classC_stack).toFixed()}%
              </span> */}
                  <span className="invester_class">Class C Shares</span>
                  <span className="invester_value">
                    {CurrencySign}{numberWithCommas(classC_amount)} |{" "}
                    {parseFloat(classC_stack).toFixed()}%
                  </span>
                </div>
              )}
            </div>

            {activeButton === "Investment Metrics" && (
              <div className="subscription_allclass">
                <span style={subscribeStyles.kpi} className="mx-3">
                  Price Per Unit{" "}
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {price_per_unit[0] && (
                        <span className="c_values">
                          {CurrencySign}
                          {price_per_unit[0] &&
                            numberWithCommas(price_per_unit[0].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}

                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {price_per_unit[1] && (
                        <span className="c_values">
                          {CurrencySign}
                          {price_per_unit[1] &&
                            numberWithCommas(price_per_unit[1].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}

                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {price_per_unit[2] && (
                        <span className="c_values">
                          {CurrencySign}
                          {price_per_unit[2] &&
                            numberWithCommas(price_per_unit[2].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <span style={subscribeStyles.kpi} className="mx-3">
                  Price per SF{" "}
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {price_per_sf[0] && (
                        <span className="c_values">
                          {CurrencySign}
                          {price_per_sf[0] &&
                            numberWithCommas(price_per_sf[0].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}
                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {price_per_sf[1] && (
                        <span className="c_values">
                          {CurrencySign}
                          {price_per_sf[1] &&
                            numberWithCommas(price_per_sf[1].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}

                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {price_per_sf[2] && (
                        <span className="c_values">
                          {CurrencySign}
                          {price_per_sf[2] &&
                            numberWithCommas(price_per_sf[2].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <span style={subscribeStyles.kpi} className="mx-3">
                  Entry Cap Rate
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {entry_cap_rate[0] && (
                        <span className="c_values">
                          {CurrencySign}
                          {entry_cap_rate[0] &&
                            numberWithCommas(entry_cap_rate[0].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}

                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {entry_cap_rate[1] && (
                        <span className="c_values">
                          {CurrencySign}
                          {entry_cap_rate[1] &&
                            numberWithCommas(entry_cap_rate[1].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}
                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {entry_cap_rate[2] && (
                        <span className="c_values">
                          {CurrencySign}
                          {entry_cap_rate[2] &&
                            numberWithCommas(entry_cap_rate[2].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <span style={subscribeStyles.kpi} className="mx-3">
                  Stabilized Cap Rate
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {stabilized_noi[0] && (
                        <span className="c_values">
                          {CurrencySign}
                          {stabilized_noi[0] &&
                            numberWithCommas(stabilized_noi[0].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}
                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {stabilized_noi[1] && (
                        <span className="c_values">
                          {CurrencySign}
                          {stabilized_noi[1] &&
                            numberWithCommas(stabilized_noi[1].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}

                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {stabilized_noi[2] && (
                        <span className="c_values">
                          {CurrencySign}
                          {stabilized_noi[2] &&
                            numberWithCommas(stabilized_noi[2].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <span style={subscribeStyles.kpi} className="mx-3">
                  Pro Forma Cap Rate
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {proforma_noi[0] && (
                        <span className="c_values">
                          {CurrencySign}
                          {proforma_noi[0] &&
                            numberWithCommas(proforma_noi[0].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}

                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header}  className="c_name">Class B</span>
                      {proforma_noi[1] && (
                        <span className="c_values">
                          {CurrencySign}
                          {proforma_noi[1] &&
                            numberWithCommas(proforma_noi[1].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}

                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {proforma_noi[2] && (
                        <span className="c_values">
                          {CurrencySign}
                          {proforma_noi[2] &&
                            numberWithCommas(proforma_noi[2].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {activeButton === "Investment Terms" && (
              <div className="subscription_allclass">
                <span style={subscribeStyles.kpi} className="mx-3">
                  Preferred Return
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {preferred_return[0] && (
                        <span className="c_values">
                          {CurrencySign}
                          {preferred_return[0] &&
                            numberWithCommas(preferred_return[0].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}

                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {preferred_return[1] && (
                        <span className="c_values">
                          {CurrencySign}
                          {preferred_return[1] &&
                            numberWithCommas(preferred_return[1].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}

                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {preferred_return[2] && (
                        <span className="c_values">
                          {CurrencySign}
                          {preferred_return[2] &&
                            numberWithCommas(preferred_return[2].data[0].value)}
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <span style={subscribeStyles.kpi} className="mx-3">
                  Serviced / Accrued
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {preferred_return[0] && (
                        <span className="c_values">
                          {`${servicedClassA}% / ${accurdClassA}%`}
                        </span>
                      )}
                    </div>
                  )}
                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {preferred_return[1] && (
                        <span className="c_values">
                          {`${servicedClassB}% / ${accurdClassB}%`}
                        </span>
                      )}
                    </div>
                  )}

                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {preferred_return[2] && (
                        <span className="c_values">
                          {`${servicedClassC}% / ${accurdClassC}%`}
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <span style={subscribeStyles.kpi} className="mx-3">
                  Hurdle
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {preferred_return[0] && (
                        <span className="c_values"></span>
                      )}
                    </div>
                  )}

                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {preferred_return[1] && (
                        <span className="c_values"></span>
                      )}
                    </div>
                  )}
                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {entry_cap_rate[2] && <span className="c_values"></span>}
                    </div>
                  )}
                </div>
                <span style={subscribeStyles.kpi} className="mx-3">
                  Equity stack %
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {equity[0] && (
                        <span className="c_values">
                          {equity[0] &&
                            parseFloat(equity[0].data[0].value).toFixed(1)}
                          %
                        </span>
                      )}
                    </div>
                  )}
                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {equity[1] && (
                        <span className="c_values">
                          {equity[1] &&
                            parseFloat(equity[1].data[0].value).toFixed(1)}
                          %
                        </span>
                      )}
                    </div>
                  )}

                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {equity[2] && (
                        <span className="c_values">
                          {equity[2] &&
                            parseFloat(equity[2].data[0].value).toFixed(1)}
                          %
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <span style={subscribeStyles.kpi} className="mx-3">
                  Profit Share
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {profit_share_1[0] && (
                        <span className="c_values">
                          {profit_share_1[0] &&
                            numberWithCommas(profit_share_1[0].data[0].value)}
                          %
                        </span>
                      )}
                    </div>
                  )}

                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {profit_share_1[1] && (
                        <span className="c_values">
                          {profit_share_1[1] &&
                            numberWithCommas(profit_share_1[1].data[0].value)}
                          %
                        </span>
                      )}
                    </div>
                  )}

                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {profit_share_1[2] && (
                        <span className="c_values">
                          {profit_share_1[2] &&
                            numberWithCommas(profit_share_1[2].data[0].value)}
                          %
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <span style={subscribeStyles.kpi} className="mx-3">
                  Profit Class Allocation %
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      <span style={subscribeStyles.out} className="c_values p-1">
                        {LpClassA + "%" + " " + "/" + " " + sponsorClassA + "%"}
                      </span>
                    </div>
                  )}

                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      <span style={subscribeStyles.out} className="c_values p-1">
                        {classC_stack
                          ? LpClassB +
                          "%" +
                          " " +
                          "/" +
                          " " +
                          sponsorClassB +
                          "%"
                          : LpClassB + "%"}
                      </span>
                    </div>
                  )}

                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      <span className="c_values">{LpClassC + "%"}</span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {activeButton === "Projected Returns" && (
              <div className="subscription_allclass">
                <span style={subscribeStyles.kpi} className="mx-3">
                  Profit Distribution
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {result?.total_cashflow_class_A[0] && (
                        <span style={subscribeStyles.out} className="c_values">
                          {CurrencySign}
                          {numberWithCommas(
                            parseInt(
                              result?.total_cashflow_class_A[0].value
                            ).toFixed()
                          )}
                        </span>
                      )}
                    </div>
                  )}

                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {result?.total_cashflow_class_B[0] && (
                        <span style={subscribeStyles.out} className="c_values p-1">
                          {CurrencySign}
                          {numberWithCommas(
                            parseInt(
                              result?.total_cashflow_class_B[0].value
                            ).toFixed()
                          )}
                        </span>
                      )}
                    </div>
                  )}

                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {result?.total_cashflow_class_C[0] && (
                        <span style={subscribeStyles.out} className="c_values p-1">
                          {CurrencySign}
                          {numberWithCommas(
                            parseInt(
                              result?.total_cashflow_class_C[0].value
                            ).toFixed()
                          )}
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <span style={subscribeStyles.kpi} className="mx-3">
                  Average Annual Return{" "}
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {total_cashflows_class_A && (
                        <span style={subscribeStyles.out} className="c_values p-1">{avg_return_class_A} %</span>
                      )}
                    </div>
                  )}
                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {total_cashflows_class_B && (
                        <span style={subscribeStyles.out} className="c_values p-1">{avg_return_class_B} %</span>
                      )}
                    </div>
                  )}

                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {total_cashflows_class_C && (
                        <span style={subscribeStyles.out} className="c_values p-1">{avg_return_class_C} %</span>
                      )}
                    </div>
                  )}
                </div>

                <span style={subscribeStyles.kpi} className="mx-3">
                  Projected IRR
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {result?.irr_class_A[0] && (
                        <span style={subscribeStyles.out} className="c_values p-1">
                          {result.irr_class_A[0].value} %
                        </span>
                      )}
                    </div>
                  )}

                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {result?.irr_class_B[0] && (
                        <span style={subscribeStyles.out} className="c_values p-1">
                          {result?.irr_class_B[0].value} %
                        </span>
                      )}
                    </div>
                  )}
                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {result?.irr_class_C[0] && (
                        <span style={subscribeStyles.out} className="c_values p-1">
                          {result.irr_class_C[0].value} %
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <span style={subscribeStyles.kpi} className="mx-3">
                  Equity Multiple
                </span>
                <div className="class_section">
                  {classA_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class A</span>
                      {result?.equity_multiple_class_A[0] && (
                        <span style={subscribeStyles.out} className="c_values p-1">
                          {result?.equity_multiple_class_A[0].value} X
                        </span>
                      )}
                    </div>
                  )}
                  {classB_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class B</span>
                      {result?.equity_multiple_class_B[0] && (
                        <span style={subscribeStyles.out} className="c_values p-1">
                          {result?.equity_multiple_class_B[0].value} X
                        </span>
                      )}
                    </div>
                  )}

                  {classC_stack && (
                    <div className="class_section_div">
                      <span style={subscribeStyles.header} className="c_name">Class C</span>
                      {result?.equity_multiple_class_C[0] && (
                        <span style={subscribeStyles.out} className="c_values p-1">
                          {result?.equity_multiple_class_C[0].value} X
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {activeButton === "WaterFall Summary" && (
              <div className="subscription_allclass">
                {result ? (
                  <>
                    <Result data={result} opData={Opdata} />
                    <CapitalResult data={result} cpData={Cpdata} />
                    <FinalResult data={result} />
                  </>
                ) : (
                  <div
                    className="card-container d-flex justify-content-center"
                    style={{ padding: "1rem" }}
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/stackshare-ebcb9.appspot.com/o/404-not-found.png?alt=media&token=beff32fb-13d3-4d7c-9b4f-765e7f6ddddf"
                      class="img-fluid"
                      alt="Responsive image"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="subscription_container">
            <FormHeader theme={theme}>Subscription Amount</FormHeader>

            <div className="subscription_main">
              <div className="subscription_main_class">
                {data.length == 1 && (
                  <div className="subscription_class">
                    {classA_stack && (
                      <>
                        <div className="sub_icons text-blue">
                          <div className="rounded_sub">{CurrencySign}</div>
                          Class A Investment Amount
                        </div>
                        <NumberFormat
                          style={inputStyles}
                          className="form-control fs-12 w-75"
                          type="text"
                          placeholder="Enter Investment amount"
                          id="investmentAmount"
                          name="classA"
                          value={amount.classA > 0 && amount.classA}
                          thousandSeparator={true}
                          onChange={ChangeHandler}
                        />
                        <span className="sub_amt_text_a">
                          {parseFloat(share.classA).toFixed(2)} Class A Shares
                        </span>
                      </>
                    )}
                  </div>
                )}

                {data.length > 1 && (
                  <>
                    {classA_stack && (
                      <div className="subscription_class">
                        <div className="sub_icons_a text-blue">
                          <div className="rounded_sub_a">{CurrencySign}</div>
                          Class A Investment Amount
                        </div>
                        <NumberFormat
                          style={inputStyles}
                          className="form-control w-100 fs-12 text-center"
                          type="text"
                          placeholder="Enter Investment amount"
                          id="investmentAmount"
                          name="classA"
                          value={amount.classA > 0 && amount.classA}
                          thousandSeparator={true}
                          onChange={ChangeHandler}
                        />
                        <span className="sub_amt_text_a">
                          {parseFloat(share.classA).toFixed(2)} Class A Shares
                        </span>
                      </div>
                    )}
                    {classB_stack && (
                      <div className="subscription_class">
                        <div className="sub_icons_b text-green">
                          <div className="rounded_sub_b">{CurrencySign}</div>
                          Class B Investment Amount
                        </div>
                        <NumberFormat
                        style={inputStyles}
                          className="form-control w-100 text-center fs-12"
                          type="text"
                          placeholder="Enter Investment amount"
                          id="investmentAmount1"
                          name="classB"
                          value={amount.classB > 0 && amount.classB}
                          thousandSeparator={true}
                          onChange={ChangeHandler}
                        />
                        <span className="sub_amt_text_b">
                          {parseFloat(share.classB).toFixed(2)} Class B Shares
                        </span>
                      </div>
                    )}
                    {classC_stack && (
                      <div className="subscription_class">
                        <div
                          className="sub_icons_c "
                          style={{ color: "#A5A0C7" }}
                        >
                          <div className="rounded_sub_c">{CurrencySign}</div>
                          Class C Investment Amount
                        </div>
                        <NumberFormat
                        style={inputStyles}
                          className="form-control w-100 fs-12 text-center"
                          type="text"
                          placeholder="Enter Investment amonut"
                          id="investmentAmount2"
                          name="classC"
                          value={amount.classC > 0 && amount.classC}
                          thousandSeparator={true}
                          onChange={ChangeHandler}
                          max=""
                        />
                        <span className="sub_amt_text_c">
                          {parseFloat(share.classC).toFixed(2)} Class C Shares
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="total_sub">
            <div className="total_sub_class">
              <span className="bold-text">
                <CgDatabase className="sub-btn sub-btn_a" />
                Total Subscription: {CurrencySign}{numberWithCommas(total ? total : 0)}
              </span>

              <span className="bold-text">
                <BsArrowLeftSquare className="sub-btn sub-btn_b" />
                Projected Return: {CurrencySign}
                {numberWithCommas(projected_return.toFixed())}
              </span>

              {totalInvetment > 0 ? (
                <span className="bold-text">
                  <BsGraphUp className="sub-btn sub-btn_c" />
                  Projected IRR:{" "}
                  {isNaN(totalIRR) ? 0 : (totalIRR * 100).toFixed(2)} %
                </span>
              ) : (

                <span className="bold-text">
                  <BsGraphUp className="sub-btn sub-btn_c" />
                  Projected IRR: 0.00 %
                </span>
              )}

              <div className="mt-2 mb-2">
                {showButton ? (
                  <button className="subscribe_btn" onClick={submitHandler}>
                    Subscribe Now
                  </button>
                ) : (
                  <div className="float-end text-success fs-5">
                    <AiOutlineCheckCircle color="green" size={25} /> Subscribed
                  </div>
                )}
              </div>


            </div>

          </div>
        </div>

        {/* <div className="subscription_values">
        <div className="subscription_conatiner">
          <div className="total_terms">
            <h6 className="text-muted fs-12 mb-1">Total Units</h6>
            <span className="fs-12 fw-5 text-black t_values">
              {totalUnits ? numberWithCommas(totalUnits) : 0}
            </span>
          </div>
          <div className="total_terms">
            <h6 className="text-muted fs-12 mb-1">Total Square Feet</h6>
            <span className="fs-12 fw-5 text-black t_values">
              {totalSquareFeet ? numberWithCommas(totalSquareFeet) : 0}
            </span>
          </div>
          <div className="total_terms">
            <h6 className="text-muted fs-12 mb-1">Investment Term</h6>
            <span className="fs-12 fw-5 text-black t_values">{terms} Year</span>
          </div>
          <div className="total_terms">
            <h6 className="text-muted fs-12 mb-1">Purchase Price</h6>
            <span className="fs-12 fw-5 text-black t_values">
              $
              {property.purchase_price &&
                numberWithCommas(property.purchase_price)}
            </span>
          </div>
          <div className="total_terms">
            <h6 className="text-muted fs-12 mb-1">Total Capitalization</h6>
            <span className="fs-12 fw-5 text-black t_values">
              ${totals && numberWithCommas(totals)}
            </span>
          </div>
          <div className="total_terms">
            <h6 className="text-muted fs-12 mb-1">Total Shares</h6>
            <span className="fs-12 fw-5 text-black t_values">
              ${numberWithCommas(capAmount)}
            </span>
          </div>
          <div className="total_terms">
            <h6 className="text-muted fs-12 mb-1">Projected Deal Cash Flow</h6>
            <span className="fs-12 fw-5 text-black t_values">
              ${numberWithCommas(totalCashflow)}
            </span>
          </div>
          <div className="total_terms">
            <h6 className="text-muted fs-12 mb-1">Deal Level IRR</h6>
            <span className="fs-12 fw-5 text-black t_values">
              {targeted_irr} %
            </span>
          </div>
        </div>

        <div className="Subscribe_btn">
          {showButton ? (
            <button className="subscribe_btn" onClick={submitHandler}>
              Subscribe Now
            </button>
          ) : (
            <div className="float-end text-success fs-5">
              <AiOutlineCheckCircle color="green" size={25} /> Subscribed
            </div>
          )}
        </div>
      </div> */}

        <div className="main_subscription_graph">
          <FormHeader theme={theme}>Capital Stack</FormHeader>

          <div className="subscription_graph">
            <div className="resposive_tabel_graph">
              <NewDeptGraph
                data={data}
                total={totals}
                seniorDept={seniorDept}
              />
            </div>
            <div className="waterfall_steps">
              <WaterFall Opdata={Opdata} Cpdata={Cpdata} />
            </div>
          </div>

        </div>


        {/* <div className="waterfall_steps">
          <WaterFall Opdata={Opdata} Cpdata={Cpdata} />
        </div> */}
      </div>
      <div style={{background: theme.palette.customColors.bodyBg}} className="card data-card mt-3 mb-3 p-1">
        <InvestorResult data={Data} CurrencySign={CurrencySign} />
      </div>
    </>
  );
}

export default TestSubScribtion;

import React, { useCallback, useEffect, useState } from 'react';
import {setAddCompany, setAddPartner} from '../../store/actions/companies'
import { message, Spin } from "antd";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { useDispatch, useSelector } from "react-redux"
// import AutoAddress from './AutoAddress';
import Search2 from '../newSponsor/Search2';
import AutoAddress from './AutoAddress';
import NumberFormat from 'react-number-format';
import numberWithOutCommas from '../../utilities/extrafunction/numberWithoutComma';
import { FormData } from '../../data/formData';

const formCountries = FormData?.countries

const AddForm1 = ({handleAddcompany, data}) => {
  const [center, setCenter] = useState({
    lat: 43.6532,
    lng: -79.3832,
  });

  const [formData, setFormData] = useState({
    name: data?.name || '',
    phone: data?.phone || '',
    email: data?.email || '',
    address: '',
    state: data?.state || '',
    city: data?.city || '',
    country: data?.country || '',
    shares_data: data?.shares_data || {},
    // deals: 0,
  });
  const dispatch = useDispatch()
  const handleChange = (e) => {
    const { name, value } = e.target;
   if (name === 'shares_data') {
    const shareOb = data?.shares_data
    if (shareOb) {
      const shareWithId = {...data?.shares_data,...{shares : numberWithOutCommas(value)}}
      setFormData({
        ...formData,
        [name]: shareWithId,
      });
    } else {
      message.error('no share data from server')
    }
   } else if(name === 'phone') {

    setFormData({ 
      ...formData,
      [name]: getPhoneFormat(value), ...{
        user: { email: formData.email, first_name: formData.name, last_name: 'null'}
     },
    })
  } else {
    setFormData({
      ...formData,
      [name]: value,
    });
   }
   
  };
  const getLocation = async (address) => {
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setCenter({
        lat: lat,
        lng: lng,
      });
    } catch (error) {
      console.log("😱 Error: ", error);
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
  
    // return () => {
      dispatch(setAddPartner(formData))
    // }
  }, [formData])
  
  const handleSubmit = (e) => {
    // e.preventDefault();
    console.log('formData',formData)
    if (formData) {
     
     handleAddcompany()
    if (data?.name){
      message.success(`partner ${formData.name} updated successfully !`)
    } else {
      message.success(`partner ${formData.name} added successfully !`)
    }
    } else {
      console.log('no data')
      message.error(`getting error adding partners !`)
    }
    
   
    // Handle form submission logic here
    console.log('Form submitted:');
  };

  return (
    <Container>
      <Form >
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Enter your name"
                defaultValue={formData.name}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                defaultValue={formData.email}
                // disabled={data?.email}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
       
        </Row>
        <Row>
        
        <Col md={6}>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input
                type="text"
                pattern='\d{3}-\d{3}-\d{4}'
                maxLength={13}
                name="phone"
                id="phone"
                placeholder="Enter your phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="admin">Shares</Label>
              {/* <Input
                type="number"
                name="shares"
                id="admin"
                placeholder="Enter Shares"
                defaultValue={formData.shares}
                onChange={handleChange}
              /> */}
               <NumberFormat
                        type="text"
                        className="form-control smaller-input"
                        id="shares_data"
                        placeholder="Enter Shares"
                        name="shares_data"
                        thousandSeparator={true}
                        value={formData.shares_data.shares}
                        onChange={handleChange}
                      />
            </FormGroup>
          </Col>
          
      </Row>
        <Row>
         
        <Col md={6}>
            <FormGroup>
            <Label for="address">Address</Label>
            <AutoAddress formData={formData} setFormData={setFormData} />

            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="city">City</Label>
              <Input
                type="text"
                name="city"
                id="city"
                placeholder="Enter your city"
                defaultValue={formData.city}
                onChange={handleChange}
              />
               {/* <AutoAddress formData={formData} setFormData={setFormData} /> */}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="state">State</Label>
              <Input
                type="text"
                name="state"
                id="state"
                placeholder="Enter your state"
                defaultValue={formData.state}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="country">Country</Label>
              <Input
                type="select"
                name="country"
                id="country"
                defaultValue={formData.country}
                onChange={handleChange}
              >
              { formCountries.map(country => <option value={country}>{country}</option>)  }
              </Input>
            </FormGroup>
          </Col>
         
        </Row>
        
        <Button onClick={handleSubmit} variant='outlined' >
          Submit
        </Button>
      </Form>
    </Container>
  );
};



export default AddForm1;

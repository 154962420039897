import { createSlice } from '@reduxjs/toolkit'

const investorSlice = createSlice({
    name: "investors",
    initialState: {
        investorList: null,
        errorMessage: null,
        show: false,
        loading: false,
        msg: false,
        msgtag: null
    },
    reducers:{
        replaceInvestor(state, action){
            state.investorList = action.payload
        },
        investorMessage(state, action){
            state.errorMessage = action.payload
            state.loading = false
            state.msg = true
            state.msgtag = 'danger'
        },
        sendInvitations(state, action){
            state.investorList.unshift(action.payload)
            // state.show = false
            state.loading = false
            state.msg = true
            state.msgtag = 'success'
        },
        modalShow(state){
            state.show = true
            state.msg = false
        },
        modalClose(state){
            state.show = false
            state.msg = false
        },
        loadingHandler(state, action){
            state.loading = action.payload
        }
    }
})

export const investorAction = investorSlice.actions
export default investorSlice
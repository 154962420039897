import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

// ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutComponent = (props) => {
  const counter = {
    id: "counter",
    beforeDraw(chart, _, options) {
      // Source:
      // https://www.youtube.com/watch?v=E8pSF9JrEvE
      const {
        ctx,
        chartArea: { width, height }
      } = chart;
      ctx.save();
      ctx.font = "600 " + options.fontSize + "px  " + options.fontFamily;
      ctx.textAlign = "center";
      ctx.fillStyle = options.fontColor;
      ctx.fillText(
        Math.min(100, options.val) + "%",
        width / 2,
        height / 2 + options.fontSize * 0.34
      );
    }
  };

  const data = {
    labels: ["Present", "Not Present"],
    datasets: [
      {
        data: [Math.min(100, props.percentage), 100 - props.percentage],
        backgroundColor: [
          props.fill || props.fillColor,
          props.fillempty || "#D9D9D9"
        ],
        cutout: "70%"
      }
    ]
  };

  return (
    <Doughnut
      data={data}
      plugins={[counter]}
      options={{
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
          counter: {
            val: props.percentage,
            fontColor: props.fill || props.fillColor,
            fontSize: 20,
            fontFamily: "sans-serif"
          }
        }
      }}
      {...props}
    />
  );
};

export default DoughnutComponent;

import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotification } from '../../store/actions/NotificationActions';
import { notification } from 'antd';
import 'antd/dist/antd.css';
import './css/pop.css';
import { getMinutesAgo, isToday } from './utils';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';


export const selfNotification = (user) => {
    notification.open({
        message: 'Notification Title',
        description: `${user} successfully notified !`,
        className: 'custom-notification', // Add your custom class for styling
        duration: 3, // Duration in seconds
    });
};

// const checkisToday = isToday(new Date('2024-02-13T18:26:40.696447Z'))
// console.log(checkisToday)
function Notification() {
    const theme = useTheme()
    const navigate = useNavigate()
    const notKey = `open${Date.now()}`;
    const openNotification = (data) => {
        notification.open({
            message: data?.message,
            description: data?.sub_message,
            className: 'custom-notification', // Add your custom class for styling
            style: {
                backgroundColor: theme.palette.primary.main,
                // border: '1px solid blue',
                // borderRadius: '4px',
                color: theme.palette.text.primary,
            },
            duration: 3, // Duration in seconds
            key: notKey,
            onClick: () => {
                
                if (data?.navigate) {
                    navigate(data?.navigate)
                    
                }
                notification.close(notKey)
    
              }
        });
    };
    const stateN = useSelector(
        (state) => state.notifications
    );
    const user_email = useSelector(
        (state) => state.user.userData?.email
    );
    const user_type = useSelector(
        (state) => state.user.userData?.user_type
    );
    const sender = stateN?.status?.sender
    const recipent = stateN?.status?.to_user
    const isCustom = stateN?.isCustom
    const isPopup = stateN?.isPopup

    const [notifications, setNotifications] = useState([])
    const [newNotifications, setNewNotifications] = useState([])

    useLayoutEffect(() => {
        setNotifications(prev => stateN.list.length > 0 ? stateN.list : prev)
        // console.log('state N1', stateN, user_email, notifications)
    }, [stateN.list])

    useEffect(() => {
        setNewNotifications(prev => [...prev, ...[stateN?.status]])
        // console.log('sender...', sender, user_type)
        // console.log('state N2', stateN, user_email, newNotifications)

        if (sender && sender !== user_type && !isCustom && isPopup) {

            openNotification(stateN?.status)
        }
        //  else if (recipent) {
        //     selfNotification(recipent)
        // }
    }, [stateN?.status, user_email ])

    const filtNoti = notifications.filter(noti => {
        const isTodayNotif = isToday(noti?.timestamp)
        // console.log("isTodayNotif",noti?.to_user,  isTodayNotif)
       return isTodayNotif && user_email === noti?.to_user || user_type === noti?.to_user
     })

    const filtNewNoti = newNotifications.filter(noti => user_email === noti?.to_user || user_type === noti?.to_user)


    // console.log('state N', stateN, user_email, notifications, filtNoti)
    return (

        <>
            <li class="media">

                {
                    filtNewNoti.length > 0 && filtNewNoti.map(noti => {
                        return (
                            <>
                                {
                                    <div>
                                        <a >
                                            <p class='mb-0 fs-14'>{noti?.message}</p>

                                            <p class='text-muted fs-12 mb-0'>{noti?.sub_message}</p>
                                        </a>
                                        {noti?.timestamp && <div>
                                            <p class="mb-0 fs-12 fw-4 text-muted">{getMinutesAgo(noti?.timestamp)} min ago</p>
                                        </div>}
                                    </div>
                                }
                            </>
                        )

                    })}
            </li>

            <li class="media">

                {
                 filtNewNoti.length > 0 ||  filtNoti.length > 0 ? filtNoti.map(noti => {
                        return (
                            <>
                                {
                                    <div>
                                        <a >
                                            <p class='mb-0 fs-14'>{noti?.message}</p>

                                            <p class='text-muted fs-12 mb-0'>{noti?.sub_message}</p>
                                        </a>
                                        {noti?.timestamp && <div>
                                            <p class="mb-0 fs-12 fw-4 text-muted">{getMinutesAgo(new Date(noti?.timestamp))} min ago</p>
                                        </div>}
                                    </div>
                                }
                            </>
                        )

                    }) : <p class="fs-12 fw-4 text-muted my-3">No notifications to disturb your peace !</p>}
            </li>
        </>

    )
}

export default Notification
import { message, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { PiLinkSimple, PiLinkSimpleFill } from "react-icons/pi";
import { DeleteSvg, EditSvg } from '../../assets/icons/Icons'
import MainClient from '../../utilities/MainClient';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import MyInvestorData from '../../data/MyInvestorData';
import { useDispatch, useSelector } from 'react-redux';
import { inviteInvestor } from '../../store/actions/investorActions';
import { investorAction } from '../../store/reducers/InvestorReducer';

const MyInvestor1 = ({userOb}) => {
  const [userData, setUserData] = useState({
    investor_name: userOb?.name || "",
    email: userOb?.user?.email || "",
  })
  const [isModalVisible, setIsModalVisible] = useState(false);
  const sendInvitations = useSelector(state => state.investors.loading)
  const [data, setData] = useState()
  const [update, setUpdate] = useState(false)
  const show = useSelector(state => state.investors.show)
  const msg = useSelector(state => state.investors.msg)
  const msgtag = useSelector(state => state.investors.msgtag)
  const handleClose = () => setIsModalVisible(false)//dispatch(investorAction.modalClose());
  const handleShow = () => {
    setIsModalVisible(true)
    // setUserData({
    //   investor_name: userOb?.name || "",
    //   email: userOb?.user?.email || "",
    // })
    // dispatch(investorAction.modalShow())
  };
  const storeInvestors = useSelector(state => state.investors.investorList)
  const store = useSelector(state => state)
  const storeUser = useSelector(state => state.user.userData)
  const dispatch = useDispatch()
  console.log('storeInvestor', storeInvestors)
  console.log('store', store)
  const changeHandler = (e) => {
    const { name, value } = e.target
    setUserData({
      ...userData, [name]: value
    })
  }
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(inviteInvestor(userData))
  }

  useEffect(() => {
    if (storeUser?.user_type == "admin") {
      setData(MyInvestorData)
    } else {
      setData(storeInvestors)
    }
  }, [storeUser, storeInvestors])

  useEffect(() => {
    if (msgtag == "success") {
      setUserData({
        investor_name: "",
        email: "",
      })
    }
  }, [msgtag])

  return (
    <>
      {/* <button class="btn btn-primary add-investor"  data-bs-toggle="modal"
                onClick={handleShow}>
                 <PiLinkSimpleFill  />
              </button> */}
             {/* <div className='d-flex justify-content-center mx-2'> */}
             <PiLinkSimple className='mx-1' onClick={handleShow} />
             {/* </div> */}
      {/* <div class="col-12">
        <div class="card data-card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <h3 class="data-title fw-bold mb-0">My Investors </h3>
              <button class="btn btn-primary add-investor" style={{ background: "#2B4479" }} data-bs-toggle="modal"
                onClick={handleShow}>
                Add Investor
              </button>
            </div>
            <div class="divider"></div>
            <div class="table-responsive">
              <table
                class="table data-table table-striped align-middle table-borderless sponser-profile-table">
                <thead>
                  <tr>
                    <th className="col">Investor name </th>
                    <th className="col text-center">Phone Number</th>
                    <th className="col text-center">Email</th>
                    <th className="col text-center">Address</th>
                    <th className="col text-center">Country</th>
                    <th className="col text-center">Status</th>
                    <th className="text-center" scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {(storeUser?.user_type == "admin" ? MyInvestorData : storeInvestors)?.map((dt) =>
                    <tr>
                      <td className=''>{dt.investor_name}</td>
                      <td className='text-center'>{dt.phone_number ? dt.phone_number : '-'}</td>
                      <td className='text-center'>{dt.email ? dt.email : '-'}</td>
                      <td className='text-center'>{dt.address ? dt.address : '-'}</td>
                      <td className='text-center'>{dt.country ? dt.country : '-'}</td>
                      <td className='text-center'>
                        {dt.status === 'pending' ?
                          <span class="badge table-badge rounded-pill bg-danger fw-4">Pending</span> :
                          <span class="badge table-badge rounded-pill bg-green fw-4">Accepted</span>}
                      </td>
                      <td class="text-center">
                        <a class="">
                          {EditSvg}
                        </a>
                        <a class="del-btn">
                          {DeleteSvg}
                        </a>
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
      <Modal show={isModalVisible} onHide={handleClose} className="p-5 m-5">
        <div className="modal-header p-4" style={{ background: "linear-gradient(146deg, rgba(62,62,61,0.9808298319327731) 8%, rgba(185,143,97,1) 38%)" }}>
          <h5 className="modal-title" id="invite-title" style={{ color: "#ffff" }}>Invite Investor</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
        </div>
        <Modal.Body>
          <p class="text-dark fs-18">Invite Investor by entering their name & email</p>
          {msg &&
            <div className={`alert alert-${msgtag}`} role="alert">
              {msgtag == 'success' ? 'Invited Successfully' : 'Email already invited'}
            </div>}
          <form method='post' onSubmit={submitHandler}>
            <div class="mb-3">
              <label for="sponserName" class="form-label">Investor Name</label>
              <input type="text" class="form-control" id="sponserName" placeholder="Enter Name" name="investor_name" value={userData.investor_name} onChange={changeHandler} required/>
            </div>
            <div class="mb-3">
              <label for="sponserAddress" class="form-label">Email address</label>
              <input type="email" class="form-control" id="sponserAddress" name="email"
                placeholder="Enter email address" value={userData.email} onChange={changeHandler} required />
            </div>
            <div class="d-grid gap-2">
              {sendInvitations ?
                <button class="btn btn-primary">
                  <Spin /> Sending...
                </button> :
                <button class="btn btn-dark" >Send Invite</button>}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
      {/* <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <div class="modal-dialog modal-dialog-centered" id="invitation-modal">
          <div class="modal-content p-0">
            <div class="modal-header">
              <h5 class="modal-title" id="invite-title">Invite Investor</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel}></button>
            </div>
            <div class="modal-body">
              <p class="text-dark fs-18">Invite Investor by entering their name & email</p>
              <form onSubmit={submitHandler}>
                <div class="mb-3">
                  <label for="sponserName" class="form-label">Investor Name</label>
                  <input type="text" class="form-control" id="sponserName" placeholder="Enter Name" name="investor_name" value={userData.investor_name} onChange={changeHandler} />
                </div>
                <div class="mb-3">
                  <label for="sponserAddress" class="form-label">Email address</label>
                  <input type="text" class="form-control" id="sponserAddress" name="email"
                    placeholder="Enter email address" value={userData.email} onChange={changeHandler} />
                </div>
                <div class="d-grid gap-2">
                  {sendInvitations ?
                    <button class="btn btn-primary">
                      <Spin /> Sending...
                    </button> :
                    <button class="btn btn-primary" style={{ background: "#2B4479" }}>Send Invite</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal> */}
    </>

  )
}

export default MyInvestor1
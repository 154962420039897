// ** React Imports
import { useState, useEffect } from 'react'

// ** MUI Components
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import AvatarGroup from '@mui/material/AvatarGroup'
import { DataGrid } from '@mui/x-data-grid'
import LinearProgress from '@mui/material/LinearProgress'

import { LuDollarSign } from "react-icons/lu";
import { FaHandshakeAngle } from "react-icons/fa6";
import { TbMoneybag } from "react-icons/tb";
import { FaRegCopy } from "react-icons/fa";

// ** Custom Component Import
import CustomTextField from '@core/components/mui/text-field'

// ** Third Party Imports
import axios from 'axios'

// ** Custom Components Imports
import OptionsMenu from '@core/components/option-menu'
import CustomAvatar from '@core/components/mui/avatar'

// ** Utils Import
import { getInitials } from '@core/utils/get-initials'
import { hexToRGBA } from '@core/utils/hex-to-rgba'
import percentage from '../../utilities/extrafunction/Persentage'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCloseDeal, fetchOpenDeal } from '../../store/actions/DealAction'
import { DateFormater } from '../../utilities/extrafunction/DateFormater'
import numFormatter from '../../utilities/extrafunction/numberConveter'
import { slugify } from '../../utilities/extrafunction/slugifier'
import { useNavigate, Link } from 'react-router-dom'
// import { Fade, Select } from '@material-ui/core'
import styled from "styled-components"
import CreateToolTip from '../newSponsor/CreateToolTip'
import { getCountryCode } from '../../utilities/extrafunction/getCountryCode'
import { Button, useTheme } from '@mui/material'
import MainClient from '../../utilities/MainClient'
import { message } from 'antd'



const Select = styled.select`
border: none;
padding: .2rem;
border-radius: 16px;
background: ${props => props.color};
color: #FFFFFF;
cursor:pointer;
`
const Option = styled.option`
background:  #FFFFFF;
color: #000000;
`

// ** renders name column
const renderName = row => {
    const getCode = getCountryCode(row.country)
    const ID = (<>
        D{getCode || 'US'}
        <br />
        {row.id}
    </>)
    if (row.avatar) {
        return <CustomAvatar src={row.avatar} sx={{ mr: 2.5, width: 38, height: 38 }} />
    } else {
        return (
            <CustomAvatar
                skin='light'
                color={row.avatarColor || 'primary'}
                sx={{ mr: 2.5, width: 38, height: 38, textAlign:'center', fontSize: theme => theme.typography.caption }}
            >
                {/* {getInitials(row.deal_name || 'John Doe')} */}
                {ID}
            </CustomAvatar>
        )
    }
}


const toolTipsList = ['subscriptions', 'deal view', 'investor view', 'duplicate deal',]

const DealContent = () => {
    // ** State
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsopen] = useState(true)

    const [value, setValue] = useState('')
    const open = useSelector(state => state.deals.open)
    const user = useSelector(state => state.user.userData)
    const openDeals = useSelector(state => state.deals.openDealData)
    const closeDeals = useSelector(state => state.deals.closeDealData)
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 })
    useEffect(() => {

        dispatch(fetchOpenDeal())
        dispatch(fetchCloseDeal())

    }, [])

    const openTabHandler = () => {
        dispatch(dealActions.openHandler())
    }
    const closeTabHandler = () => {
        dispatch(dealActions.closeHandler())
    }
    const updateHandler = (deal_id, status) => {
        const body = {
            "deal_status": status
        }
        const fetchClient = new MainClient(`/deal/deal-status/${deal_id}/`, body)
        const response = fetchClient.putAPI()
        response.then(res => {
            console.log('dealData', res.data)
            dispatch(fetchOpenDeal())
            dispatch(fetchCloseDeal())
            setUpdate(!update)
        }).catch(err => console.log(err))
    }

    const selectedHandler = (e, id) => {
        console.log('selected value', e.target.value)
        updateHandler(id, e.target.value)
    }
    const changeUpdate = () =>{
        setUpdate(!update)
    }
    const CopyDealHandler = (id) =>{
        const fetchClient = new MainClient(`/deal/create-copy-deal/${id}/`)
        const response = fetchClient.postAPI()
        // return response
        response.then(res=>{
            dispatch(fetchOpenDeal())
            message.success("Deal copied successfully")
        }).catch(err=>{
            message.error("Deal dose not copied")
        })
        return response
    }


    ///////////////////////////
    const handleFilter = val => {
        setValue(val)
    }
    const openDealA = openDeals?.length ? openDeals?.slice().reverse() : []
    const closeDealA = closeDeals?.length ? closeDeals?.slice().reverse() : []

    const columns = [
        {
            flex: 0.1,
            field: 'name',
            minWidth: 220,
            headerName: 'Deal Name',
            renderCell: ({ row }) => {
                const { deal_name, launch_date } = row

                return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {renderName(row)}
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography noWrap sx={{ color: 'text.secondary', fontWeight: 500 }}>
                                {deal_name}
                            </Typography>
                            <Typography noWrap variant='body2' sx={{ color: 'text.disabled', textTransform: 'capitalize' }}>
                                {DateFormater(launch_date)}
                            </Typography>
                        </Box>
                    </Box>
                )
            }
        },
        {
            flex: 0.1,
            minWidth: 105,
            field: 'Total_Raise',
            headerName: 'Total Raise',
            renderCell: ({ row }) => <Typography sx={{ color: 'text.secondary' }}>${numFormatter(row.total_shares)}</Typography>
        },
        {
            flex: 0.1,
            field: 'createdby',
            minWidth: 120,
            sortable: false,
            headerName: 'Created By',
            renderCell: ({ row }) => (
                <Typography noWrap sx={{ color: 'text.secondary', fontWeight: 500, display:'flex', alignItems:'center', flexWrap:'wrap' }}>
                <AvatarGroup className='pull-up'>
                    {['/images/avatars/1.png',].map((src, index) => (
                        <CustomAvatar key={index} src={src} sx={{ height: 26, width: 26 }} />
                    ))}
                </AvatarGroup>
                <div className='mx-2'>
                {row.sponsor}
                </div>
                </Typography>
            )
        },
        {
            flex: 0.1,
            minWidth: 150,
            field: 'Total_Subscribed',
            headerName: 'Total Subscribed',
            renderCell: ({ row }) => (
                <>
                    <LinearProgress
                        color='primary'
                        value={row.committed_shares > 0 ? percentage(row.committed_shares, row.total_shares).toFixed() : 0}
                        variant='determinate'
                        sx={{
                            mr: 3,
                            height: 8,
                            width: '100%',
                            borderRadius: 8,
                            backgroundColor: 'secondary.light',
                            '& .MuiLinearProgress-bar': {
                                borderRadius: 8
                            }
                        }}
                    />
                    <Typography sx={{ color: 'text.secondary' }}>{`${row.committed_shares > 0 ? percentage(row.committed_shares, row.total_shares).toFixed() : 0}%`}</Typography>
                </>
            )
        },
        {
            flex: 0.1,
            minWidth: 150,
            field: 'status',
            headerName: 'Status',
            renderCell: ({ row }) => (
                <>
                    <Select className="pe-0" name="cars" id="cars" onChange={(e) => selectedHandler(e, row.id)} color={row.deal_status === "Open" ? "#1B8B41" : row.deal_status === "Closed" ? "#F84A4A" : "#4F7CDA"}>
                        <Option value="Open" selected={row.deal_status === "Open" ? "selected" : ""}>Open</Option>
                        <Option value="Pause" selected={row.deal_status === "Pause" ? "selected" : ""}>Pause</Option>
                        <Option value="Closed" selected={row.deal_status === "Closed" ? "selected" : ""}>Close</Option>
                    </Select>
                </>
            )
        },

        {
            flex: 0.1,
            minWidth: 100,
            sortable: false,
            field: 'actions',
            headerName: 'Actions',
            renderCell: (row) => (
                <div className="text-center ">
                    <div className="d-inline-flex">
                        <Link to={`/dashboard/subscriptions/${row.id}`} ><LuDollarSign id={slugify(toolTipsList[0])} className="mx-1" /></Link>
                        <Link to={`/dashboard/deals/${row.id}`} ><FaHandshakeAngle id={slugify(toolTipsList[1])} className="mx-1" /></Link>
                        <Link to={`/dashboard/investors/${row.id}`} ><TbMoneybag id={slugify(toolTipsList[2])} className="mx-1" /></Link>
                        <Link to='' ><FaRegCopy onClick={() => CopyDealHandler(row.id)} id={slugify(toolTipsList[3])} className="mx-1" /></Link>
                    </div>
                    {toolTipsList.map(target => <CreateToolTip target={target} />)}

                </div>
            )
        }
    ]

    return data ? (
        <Card style={{boxShadow:'0px 2px 6px 0px rgba(47, 43, 61, 0.14)', background : theme.palette.customColors.bodyBg}}>
            <CardHeader
                title='Deals'
                titleTypographyProps={{ sx: { mb: [2, 0] } }}
                action={<>
                <Button onClick={() => setIsopen(!isOpen)}
                variant='outlined'
                sx={{
                    marginX : 2
                }}>{isOpen ? 'Show Closed Deals' : 'Show Opened Deals'}</Button>    
                {/* <CustomTextField value={value} placeholder='Search' onChange={e => handleFilter(e.target.value)} /> */}
                </>}
                sx={{
                    py: 4,
                    flexDirection: ['column', 'row'],
                    '& .MuiCardHeader-action': { m: 0 },
                    alignItems: ['flex-start', 'center']
                }}
            />
            <DataGrid
                autoHeight
                pagination
                rows={isOpen ? openDealA : closeDealA }
                rowHeight={62}
                columns={columns}
                // checkboxSelection
                pageSizeOptions={[5, 10]}
                disableRowSelectionOnClick
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
           sx={{
            '& .MuiTablePagination-toolbar' : {
                alignItems:'baseline'
            }
           }}
           />
        </Card>
    ) : null
}

export default DealContent

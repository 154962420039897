import { Link } from "react-router-dom"
 
import BuildignImages from '../../components/authentication/img/side-img2.svg'
import Logo from '../../assets/logos/gulfstream2.png'


const Common = () => {
    return (
        <div class="col-sm-5 ps-0 pe-0" id="signup-bg">
            <div class="signup-col-1">
                <Link to="/">
                    <h2 class="text-white"></h2>
                    <img src={Logo} style = {{height:'70%'}}
                    className="login-logo img-fluid mx-5
                    "/>
                </Link>
                <div className="d-flex h-100 align-items-center justify-content-center display-none">
                    {/* {homeSvg} */}
                    {/* <img src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/image%2Fbuiling-image.png?alt=media&token=de01ac06-0eb6-4f3d-a131-c1b21e0a5d88"  style={{width:"200px"}}/> */}
                    <img src={BuildignImages}  className="img-fluid"/>
                    {/* <div>
                        <h1 class="text-white fw-6 fs-24">Welcome to Mygulfstream Investmentss</h1>
                        <p class="text-white fw-4 fs-16 mt-3">Lorem ipsum is a placeholder text commonly used to
                            demonstrate the
                            visual form of a
                            document or a typeface without relying on meaningful content.</p>
                    </div> */}
                </div>
            </div>

        </div>
    )
}

export default Common
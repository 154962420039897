import { createSlice } from '@reduxjs/toolkit'

const sponsorSlice = createSlice({
    name: 'sponsors',
    initialState: {
        directSponsors: null,
        invitedSponsors: null,
        direct: false,
        invited: true
    },
    reducers:{
        replaceDirectSponsor(state, action){
            state.directSponsors = action.payload
        },
        replaceInvitedSponsor(state, action){
            state.invitedSponsors = action.payload
        },
        replaceDirect(state, action){
            state.direct = true
            state.invited = false
        },
        replaceInvited(state, action){
            state.invited = true
            state.direct = false
        }
    }
})

export const sponsorAction = sponsorSlice.actions
export default sponsorSlice
import React from 'react'
import numberWithCommas from '../../utilities/extrafunction/commaseparate'

const ProfileDetails = ({ data, company }) => {
    return (
      <div class="row mb-3">
      <div class="col-12">
          <div class="card data-card mt-3">
              <div class="card-body">
                  <h3 class="data-title fw-bold">Company Details</h3>
                  <div class="divider"></div>
                  <form>
                      <div class="row g-3 mb-3 fs-18">
                          {/* <div class="col-sm-6 col-md-4 col-lg-2 ">
                              <label for="firstName" class="fs-15 fw-5 pb-2 pt-1">Name</label>
                              <div>{data?.user?.first_name} {data?.user?.last_name}</div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-2 ">
                              <label for="email" class="fs-15 fw-5 pb-2 pt-1">Email</label>
                              <div>{data?.user?.email}</div>
                          </div> */}
                          <div class="col-sm-6 col-md-4 col-lg-2 text-start">
                              <label for="company" class="fs-15 fw-5 pb-2 pt-1">Assects Under Management</label>
                              <div>{company?.assets ? numberWithCommas(company?.assets) : ''}</div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-2 text-lg-center">
                              <label for="email" class="fs-15 fw-5 pb-2 pt-1">Units Under Management</label>
                              <div>{company?.units ? numberWithCommas(company?.units) : ''}</div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-2 text-lg-center">
                              <label for="address" class="fs-15 fw-5 pb-2 pt-1">SF Under Management</label>
                              <div>{company?.sf ? numberWithCommas(company?.sf) : ''}</div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-2 text-lg-center">
                              <label for="companyCountry" class="fs-15 fw-5 pb-2 pt-1">Years in Business</label>
                              <div>{company?.bussiness_years ? numberWithCommas(company?.bussiness_years) : ''}</div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-2 text-lg-center">
                              <label for="companyCountry" class="fs-15 fw-5 pb-2 pt-1">States Active</label>
                              <div>{company?.company_state}</div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-2 text-lg-center">
                              <label for="companyCountry" class="fs-15 fw-5 pb-2 pt-1">Average Annual Returns</label>
                              <div>{company?.average_annual_return ? numberWithCommas(company?.average_annual_return) : ''}</div>
                          </div>
                          <div class="col-12">
                              <label for="companyCountry" class="fs-15 fw-5 pb-2 pt-1 mt-2">About the company</label>
                              <div>{company?.about_company}</div>
                          </div>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
    )
}

export default ProfileDetails
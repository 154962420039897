import { InputNumber, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import numberWithCommas from '../../utilities/extrafunction/commaseparate'
import numberWithOutCommas from '../../utilities/extrafunction/numberWithoutComma'
import MainClient from '../../utilities/MainClient'
import { Radio, Select } from 'antd';
// import React, { useState } from 'react';

const CompanyDetails = () => {
    const user = useSelector(state => state.user.userData)
    const [len, setLen] = useState(0)
    console.log('userId', user)
    const userId = user?.user_id
    const [company, setCompany] = useState({
        "user": userId,
        "company_name": "",
        "company_address": "",
        "company_country": "",
        "company_state": "",
        "company_phone_number": "",
        "assets": "",
        "sf": "",
        "units": "",
        "bussiness_years": "",
        "average_annual_return": "",
        "about_company": ""
    })
    const states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'IdahoIllinois', 'Indiana', 'IowaKansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsy', 'lvania', 'Rhode', 'Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']
    const { Option } = Select;
    const children = [];
    for (let i = 0; i < states.length; i++) {
        children.push(<Option key={states[i]}>{states[i]}</Option>);
        console.log('hello')
    }
    const handleChange = (value) => {
        console.log(`Selected: ${value}`);
        const val = value.toString()
        setCompany({...company, company_state:val})
    };
    useEffect(() => {
        const getCompany = () => {
            const fetchData = new MainClient('/user/company-profile')
            const response = fetchData.getAPI()
            response.then(res => {
                console.log('response1', res.data.data[0])
                setLen(res.data.data.length)
                if (res.data.data.length > 0) {
                    setCompany(res.data.data[0])
                }
            })
        }
        getCompany()
    }, [])
    const changeHandler = (e) => {
        const { name, value } = e.target
        if (name == "company_state" || name == "about_company") {
            setCompany({ ...company, [name]: value })
        } else {
            setCompany({ ...company, [name]: numberWithOutCommas(value) })
        }
    }
    const submitHandler = (e) => {
        e.preventDefault()
        console.log('id', company)
        const fetchData = new MainClient(`/user/company-profile`, company)
        const response = fetchData.postAPI()
        response.then(res => {
            message.success("Company details save successfully")
            setLen(1)
            setCompany(res.data.data)
        }).catch(err => console.log('err', err))
    }
    console.log('company', states.length)
    const updateHandler = (e) => {
        e.preventDefault()
        console.log('id', company)
        const fetchData = new MainClient(`/user/update-company-profile/${company.id}`, company)
        const response = fetchData.putAPI()
        response.then(res => {
            message.success("Company update successfully")
        }).catch(err => console.log('err', err))
    }
    const dstate = (company?.company_state)?.split(',')
    return (
        <div class="row mb-3">
            <div class="col-12">
                <div class="card data-card">
                    <div class="card-body">
                        <h3 class="data-title fw-bold">Company Details</h3>
                        <div class="divider"></div>
                        <form >
                            <div class="row g-3 mb-3">
                                <div class="col-sm-6 col-md-4">
                                    <label for="assets" class="form-label">Assets Under Management</label>
                                    {/* <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                                defaultValue={company?.assets}
                                                placeholder="Enter Assets"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                name="assets"
                                                onChange={changeHandler}
                                            /> */}
                                    <input type="text" class="form-control" id="assets"
                                        placeholder="Enter Assets" name="assets" value={numberWithCommas(company?.assets)} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="units" class="form-label">Units Under Management</label>
                                    <input type="text" class="form-control" id="units" placeholder="Enter Units"
                                        name="units" value={numberWithCommas(company?.units)} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="sf" class="form-label">SF Under Management</label>
                                    <input type="text" class="form-control" id="sf" placeholder="Enter SF"
                                        name="sf" value={numberWithCommas(company?.sf)} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="bussiness_years" class="form-label">Years in Business</label>
                                    <input type="text" class="form-control" id="bussiness_years" placeholder="Enter Years"
                                        name="bussiness_years" value={numberWithCommas(company?.bussiness_years)} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="company" class="form-label">States Active</label>
                                    {company.company_state !== '' ? 
                                    <Select
                                        mode="multiple"
                                        size={"large"}
                                        showArrow
                                        placeholder="Please select states"
                                        // defaultValue={ dstate }
                                        value={company.company_state !== ''  && dstate }
                                        onChange={handleChange}
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                        {children}
                                    </Select>:
                                    <Select
                                    mode="multiple"
                                    size={"large"}
                                    showArrow
                                    placeholder="Please select states"
                                    // value={dstate.length > 0 ? dstate : 'TAG'}
                                    onChange={handleChange}
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    {children}
                                </Select>
}
                                    {/* <input type="text" class="form-control" id="company"
                                        placeholder="Enter Company Name"
                                        name="company_state" value={company?.company_state} onChange={changeHandler} /> */}
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="average_annual_return" class="form-label">Average Annual Returns</label>
                                    <input type="text" class="form-control" id="average_annual_return"
                                        placeholder="Enter Amount"
                                        name="average_annual_return" value={numberWithCommas(company?.average_annual_return)} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-12">
                                    <label for="about_company" class="form-label">About the Companay</label>
                                    <textarea type="text" class="form-control" id="about_company"
                                        placeholder="Enter about of your company"
                                        name="about_company" value={company?.about_company} onChange={changeHandler} />
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mb-2" id="submit-btn">
                                {/* <button type="submit" class="btn btn-secondary clr-btn">Reset</button> */}
                                {len > 0 ?
                                    <button type="submit" class="btn ms-3 button_color" onClick={updateHandler}>Update</button> :
                                    <button type="submit" class="btn ms-3 button_color" onClick={submitHandler}>Save</button>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyDetails